// import { get } from 'lodash';

import request from '../../common/request';
import { URLQueryParams } from '../prospect/prospect.api';

export const getBuyer = (setFetching, setResponse, search, project) => {
  setFetching(true);

  // const userId = get(storage.get('user'), 'id') || 2;
  // 4 is the Seller type id
  const queryParams = URLQueryParams.construct({
    ...(search && { search }),
    ...(project && { project }),
  });
  const url = `/buyer/project?${queryParams}`;
  const response = request.get(url);
  const dataPromise = response.then((res) => {
    setFetching(false);
    if (setResponse) {
      setResponse(res.data.data);
    }
    return res.data.data;
  });
  return dataPromise;
};
