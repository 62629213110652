import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer, Divider, IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import { MenuList } from '../menu_list/menu_list'

const drawerWidth = 238

function MenuStrip({ setOpen, open, isOnTop }) {
  let user = JSON.parse(localStorage.getItem('user'))
  useEffect(() => {}, [user])

  const useStyles = makeStyles(() => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: isOnTop && 999999999
    },
    drawerPaper: {
      width: drawerWidth,
      background: 'rgb(244, 246, 249)',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 0px ',
    },
  }))
  const classes = useStyles()

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={`${classes.drawerHeader}`}>
        <img
          src={require(`../../assets/images/name_icons/${user?.firstName
            ?.substring(0, 1)
            .toLowerCase()}.svg`)}
          alt="name intial"
          height="20px"
          className=" padding-left-30"
        />
        <p className="main-font-semi-bold font-size-14 w-100 left-text padding-left-20 ">
          {user?.firstName} <br></br> {user?.lastName}
        </p>
        <IconButton onClick={handleDrawerClose}>
          <img
            src={require('../../assets/images/icons/backarrow_darkbleu.svg')}
            alt="close"
            className="icon-img"
          />
        </IconButton>
      </div>
      <Divider />
      <MenuList />
      <img
        className="logo-footer"
        src={require('../../assets/images/logo/logo_color.svg')}
        alt="close"
      />
    </Drawer>
  )
}

MenuStrip.defaultProps = {
  open: false,
  setOpen: () => {
    return false
  },
  isOnTop: false
}

MenuStrip.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  isOnTop: PropTypes.bool
}
export default MenuStrip
