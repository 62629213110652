import React, { useEffect, useState } from "react";
import axios from "axios";
import { SQLDateToString } from "../../../common/utils/format";
import { updateProjectUnit } from "../../../common/api/routes";

import "../projecten.scss";

export function AktenItem(props) {
    const [ dropdownState, setDropdownState] = useState(false)
    const [ updatedId, setUpdatedId ] = useState(0);
    const { data, sellerList } = props;

    function getActiveSeller() {
        if(data?.contract_seller_id) {
            const activeSeller = sellerList.find(seller => seller.id == (updatedId !== 0 ? updatedId : data?.contract_seller_id));
            return activeSeller?.company || "-";
        }
        return "-";
    }

    function updateSeller(seller) {
        axios.put(updateProjectUnit(data.id), {
            contract_seller_id: seller.id,
        }).then(response => {
            setDropdownState(false)
            setUpdatedId(seller.id)
        }).catch(error => console.log(error))
    }

    return(
        <div className = "projecten-grid-container-five fade-in-translate">
            <div className = "width-auto projecten-grid-container-border-half">
                <div className = "projecten-grid-row-border">
                    <div className = "projecten-grid-box-eleven vertical-center projecten-grid-border-right center-content">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey center-text">{data?.user?.firstName || ""} {data?.user?.lastName || ""}</p>
                    </div>
                    <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right relative" tabIndex = "1" onBlur = {() => setDropdownState(false)}>
                        <div className = "height-auto width-auto vertical-center center-content cursor-pointer" onClick =  {() => setDropdownState(!dropdownState)}>
                            <p className = "main-font-regular font-size-14 font-color-blue-grey center-text">{getActiveSeller()}</p>
                        </div>
                        <div className = "akten-dropdown-container animatable-400 ease-in-out-quart" style = {{opacity: dropdownState ? 1 : 0, transform: dropdownState ? 'scaleY(1)' : 'scaleY(0)', height: sellerList.length * 40}}>
                            {sellerList && sellerList.length > 0 && sellerList.map(seller => (
                                <div className = "akten-dropdown-item center-content" onClick = {() => updateSeller(seller)} >
                                    <p className = "main-font-regular font-size-14 font-color-blue-grey center-text">{seller?.company || ""}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className = "projecten-grid-box-twelve vertical-center projecten-grid-border-right center-content">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey center-text">{data?.buyer?.sales_agreement ? SQLDateToString(data?.buyer?.sales_agreement).split(" ")[0] : ""}</p>
                    </div>
                    <div className = "projecten-grid-box-thirteen vertical-center projecten-grid-border-right center-content">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey center-text">{data?.buyer?.condition_precedent || ""}</p>
                    </div>
                    <div className = "projecten-grid-box-thirteen vertical-center projecten-grid-border-right center-content">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey center-text">{data?.buyer?.bank_notification ? SQLDateToString(data?.buyer?.bank_notification).split(" ")[0] : ""}</p>
                    </div>
                    <div className = "projecten-grid-box-thirteen vertical-center center-content">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey center-text">{data?.buyer?.deed_date ? SQLDateToString(data?.buyer?.deed_date).split(" ")[0] : ""}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function AktenTotal(props) {
    const [ appartmentCount, setAppartmentCount ] = useState(0)
    const { data } = props;

    useEffect(() => {
        if(data && data.length > 0) {
            let appartments = data.filter(data => data?.type?.id == 1);
            setAppartmentCount(appartments.length)
        }
        else {
            setAppartmentCount(0)
        }
    }, [data])

    return(
        <div className = "projecten-grid-container-five fade-in-translate">
            <div className = "projecten-grid-total-container flex-row">
                <div className = "projecten-grid-box-eleven vertical-center" />
                <div className = "projecten-grid-box-four vertical-center" />
                <div className = "projecten-grid-box-twelve vertical-center" />
                <div className = "projecten-grid-box-thirteen vertical-center projecten-grid-total horizontal-between for-height-over-flow">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal: </p>
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">{appartmentCount}</p>
                </div>
            </div>
        </div>
    )
}

export default function Akten() {

    return(
        <div className = "projecten-grid-container-five fade-in-translate">
            <div className = "projecten-grid-container-border width-auto">
                <div className = "projecten-grid-header-box center-content">
                    <p className = "main-font-semi-bold font-size-14 font-color-blue-grey">Verkoopovereenkomsten & Akten Notaris</p>
                </div>
                <div className = "projecten-grid-row-border background-color-light-grey">
                    <div className = "projecten-grid-box-eleven vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Aankooper</p>
                    </div>
                    <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Aanbreng</p>
                    </div>
                    <div className = "projecten-grid-box-twelve vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Verkoopovereenkomst</p>
                    </div>
                    <div className = "projecten-grid-box-thirteen vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Nummer</p>
                    </div>
                    <div className = "projecten-grid-box-thirteen vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Notificatie Bank</p>
                    </div>
                    <div className = "projecten-grid-box-thirteen vertical-center">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Aktedatum</p>
                    </div>
                </div>
            </div>
        </div>
    )
}