import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';

const Verkoopovereenkomsten = ({
  data,
}) => {
  const [priceContractTotal, setPriceContractTotal] = useState(0);
  const [priceContractGround, setPriceContractGround] = useState(0);
  const [priceContractConstruction, setPriceContractConstruction] = useState(0);
  const [priceContractDiscount, setPriceContractDiscount] = useState(0);

  useEffect(() => {
    if (data) {
      setPriceContractTotal(data?.price_contract_total || 0);
      setPriceContractGround(data?.price_ground || 0);
      setPriceContractConstruction(data?.price_ground || 0);
      setPriceContractDiscount(data?.price_total || 0);
    }
  }, [data]);

  const changeMetricsAndFormat = (value) => {
    if (value !== null && value !== undefined && isNumber(parseFloat(value))) {
      const newValue = value.toString().split('.');
      newValue[0] = [...newValue[0]].reverse().join('');
      if (newValue[0].length > 3) {
        if (newValue[0].length % 3 !== 0) {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
        } else {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
          newValue[0] = newValue[0].slice(0, newValue[0].length - 1);
        }
      }
      newValue[0] = [...newValue[0]].reverse().join('');
      return `${newValue[0] || '0'}.${newValue[1] || '00'}`;
    }
    return '-';
  };

  return (
    <>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat(priceContractTotal) || 0}</td>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat(priceContractGround.toFixed(2)) || 0}</td>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat((priceContractTotal - priceContractConstruction).toFixed(2)) || 0}</td>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat((priceContractTotal - priceContractDiscount).toFixed(2)) || 0}</td>
    </>
  );
};

Verkoopovereenkomsten.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Verkoopovereenkomsten;

export function VerkoopovereenkomstenTableTotal({
  data,
}) {
  const getTotal = (key) => {
    if (data?.length) {
      let total = 0;
      data.forEach((unit) => {
        total += (unit[key] || 0);
      });
      return total;
    }

    return 0;
  };

  const changeMetricsAndFormat = (value) => {
    if (value !== null && value !== undefined && isNumber(parseFloat(value))) {
      const newValue = value.toString().split('.');
      newValue[0] = [...newValue[0]].reverse().join('');
      if (newValue[0].length > 3) {
        if (newValue[0].length % 3 !== 0) {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
        } else {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
          newValue[0] = newValue[0].slice(0, newValue[0].length - 1);
        }
      }
      newValue[0] = [...newValue[0]].reverse().join('');
      return `${newValue[0] || '0'}.${newValue[1] || '00'}`;
    }
    return '-';
  };

  return (
    <>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getTotal('price_contract_total')) || 0}`}</td>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getTotal('price_ground')) || 0}`}</td>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat((getTotal('price_contract_total') - getTotal('price_ground')).toFixed(2)) || 0}`}</td>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat((getTotal('price_contract_total') - getTotal('price_total')).toFixed(2)) || 0}`}</td>
    </>
  );
}

export function VerkoopovereenkomstenTableTotal2({
  data,
}) {
  const getPriceTotal = () => {
    if (data) {
      let totalContractPrice = 0;
      let totalPrice = 0;
      data.forEach((unit) => {
        totalContractPrice += unit.price_contract_total;
        totalPrice += (unit.price_total + (unit.price_contract_total - unit.price_total));
      });
      let output = (totalContractPrice / totalPrice) * 100;
      output = output.toFixed(2);
      return output !== 'NaN' ? output : 0;
    }

    return 0;
  };

  const getTotalDiscount = () => {
    if (data) {
      let totalkorting = 0;
      let totalPrice = 0;
      data.forEach((unit) => {
        totalkorting += (unit.price_contract_total - unit.price_total);
        totalPrice += unit.price_total;
      });
      const finalTotalPrice = totalPrice + totalkorting;
      let output = (totalkorting / finalTotalPrice) * 100;
      output = output.toFixed(2);
      return (output.includes('NaN') || output.includes('Infinity')) ? 0 : output;
    }

    return 0;
  };

  const changeMetricsAndFormat = (value) => {
    if (value !== null && value !== undefined && isNumber(parseFloat(value))) {
      const newValue = value.toString().split('.');
      newValue[0] = [...newValue[0]].reverse().join('');
      if (newValue[0].length > 3) {
        if (newValue[0].length % 3 !== 0) {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
        } else {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
          newValue[0] = newValue[0].slice(0, newValue[0].length - 1);
        }
      }
      newValue[0] = [...newValue[0]].reverse().join('');
      return `${newValue[0] || '0'}.${newValue[1] || '00'}`;
    }
    return '-';
  };

  return (
    <>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getPriceTotal()) || 0}%`}</td>
      <td />
      <td />
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getTotalDiscount()) || 0}%`}</td>
    </>
  );
}
