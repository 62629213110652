import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from "react-dropzone";
import clsx from 'clsx';
import PropTypes from "prop-types";
import axios from "axios";

import { SpaceRow } from '../../components/space/space';
import { getAllProject, getProjectDocuments, createDocument, getDocumentsByUnit, updateDocument, getProject, deleteDocument, getDocumentCategories, createDocumentCategory, deleteDocumentCategory, updateDocumentCategory as updateDocumentCategoryApi, readNotification } from "../../common/api/routes";
import { DropdownMenuExtended } from '../../components/DropdownMenu';
import { CheckRound } from "../aankoper/tab.documenten";
import { SQLDateToString } from "../../common/utils/format";
import AppBarComponent from '../../components/appbar/appbar';
import MenuStrip from '../../components/menu_strip/menu_strip';
import Dropdown from "../../components/dropdown/dropdown";
import ProgressBar from '../../components/progress_bar/progress_bar';
import { baseUrl } from '../../common/api/routes';

import "./document_panel.scss";

const drawerWidth = 238;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export function DocumentDropzone(props) {
    const [ files, setFiles ] = useState(null)
    const onDrop = useCallback(file => {
        if(file) {
            setFiles(file)
        }
    }, []);

    useEffect(() => {
        if(files) {
            uploadFile(files);
        }
    }, [files])

    const { getRootProps, getInputProps } = useDropzone({onDrop, multiple: true, noClick: true});
    const { uploadFile, customStyle } = props;

    return(
        <div className = "document-dropzone-container" style = {customStyle} {...getRootProps()}>
            <input {...getInputProps()} />
            {props.children}
        </div>
    )
}

function DocumentItem(props) {
    const [ documentGrond, setDocumentGrond ] = useState("");
    const { fileName, concerned, uploadDate, updateDate, endDate, fileType, category, categoryId, status, documentId, path, fetchUpdatedData } = props;
    console.log(fileName);

    useEffect(() => {
        if(concerned)  {
            setDocumentGrond(concerned)
        }
    }, [concerned])

    function updateDocumentStatus(status) {
        axios.put(updateDocument(documentId), {
            status,
            statusText: status == 1 ? 'Factuur voldaan' : 'Factuur nog te betalen'
        }).then(response => {
            console.log(response.data);
        }).catch(error => console.log(error))
    }

    const updateDocumentConcerned = () => {
        axios.put(updateDocument(documentId), {
            concerned: documentGrond
        }).then(response => {
            console.log(response.data);
        }).catch(error => console.log(error))
    }

    const handleDocumentDownload = () => {
        axios.get(baseUrl + path, {
            responseType: 'blob',
        }).then(response => {
            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch(error => console.log(error))
    }

    const handleDocumentDelete = () => {
        axios.delete(deleteDocument(documentId)).then(response => {
            fetchUpdatedData();
            console.log(response.data)
        }).catch(error => console.log(error))
    }

    return(
        <div className = "document-panel-main-list-document-item animatable-400 ease-in-out-quart">
            <Grid container item sm = {6} alignItems = "center" wrap='nowrap'>
                <div className = "document-panel-main-list-document-icon-container center-content margin-left-11">
                    {fileType && fileType === 'pdf' ? (
                        <img src = {require('../../assets/images/document/pdf.svg')} alt = "pdf" className = "document-panel-main-list-document-icon" />
                    ) : (fileType === 'doc' || fileType === 'docx') ? (
                        <img src = {require('../../assets/images/document/doc.svg')} alt = "doc" className = "document-panel-main-list-document-icon" />
                    ) : (fileType === 'xls' || fileType === 'xlsx') ? (
                        <img src = {require('../../assets/images/document/xls.svg')} alt = "xls" className = "document-panel-main-list-document-icon" />
                    ) : (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') ? (
                        <img src = {require('../../assets/images/document/img.svg')} alt = "img" className = "document-panel-main-list-document-icon" />
                    ) : (
                        <img src = {require('../../assets/images/document/other.svg')} alt = "other" className = "document-panel-main-list-document-icon" />
                    )}
                </div>
                <p className = "main-font-semi-bold font-size-14 font-color-blue-grey margin-left-17 wordBreakAll">{fileName}</p>
            </Grid>
            <Grid container item sm = {6} alignItems = "center">
                <Grid container item sm = {3}>
                    {categoryId == 5 && (
                        <input className = "main-font-regular font-size-14 font-color-grey background-color-transparent width-auto"  value = {documentGrond} onChange = {event => setDocumentGrond(event.target.value)} onBlur = {updateDocumentConcerned} />
                    )}
                </Grid>
                <Grid container item sm = {3}>
                    <p className = "main-font-regular font-size-12 font-color-grey">{uploadDate}</p>
                </Grid>
                {/* <Grid container item sm = {3}> */}
                    {/* <p className = "main-font-regular font-size-12 font-color-grey">{endDate}</p> */}
                {/* </Grid> */}
                <Grid container item sm = {2} justify="center">
                    {categoryId == 5 && (
                        <CheckRound initialState = {status === 0 ? false : true} onClick = {updateDocumentStatus} />
                    )}
                    {(categoryId == 4 || category?.parent_id == 4) && (
                        <>
                            {status === 3 ? (
                                <img src = {require('../../assets/images/icons/cross_red.svg')} alt = "cross red" className = "tab-documenten-check-container" />
                            ) : (
                                <CheckRound initialState = {status === 0 ? false : true} disabled = {true} />
                            )}
                        </>
                    )}
                </Grid>
                {(categoryId == 4 || category?.parent_id == 4 || categoryId == 5) && (<Grid container item sm={3}>
                    {status==1 && <p className="main-font-regular font-size-12 font-color-grey">{updateDate}</p>}
                </Grid>)}
                <Grid container item sm = {1}>
                    <DropdownMenuExtended disableEdit handleDownload = {handleDocumentDownload} handleView = {() => window.open(baseUrl + path)} handleDelete = {() => handleDocumentDelete(documentId)} />
                </Grid>
            </Grid>
        </div>
    )
}

function DocumentPanelView() {
    const [ selectedBuilding, setSelectedBuilding ] = useState(0);
    const [ projectData, setProjectData ] = useState([]);
    const [ unitDocumentList, setUnitDocumentList ]= useState([]);
    const [ selectedUnit, setSelectedUnit ] = useState(0);
    const [ selectedFolder, setSelectedFolder ] = useState(0); // 0 being the gebouw option
    const [ modalState, setModalState ] = useState(false)
    const [ documentList, setDocumentList ] = useState([])
    const [ manualUpdate, setManualUpdate ] = useState(false)
    const [ updateDocumentCategory, setUpdateDocumentCategory ] = useState(false)
    const [ documentCategoryEditState, setDocumentCategoryEditState ] = useState("0");
    const [ modalInputState, setModalInputState ] = useState("")
    const [ documentCategories, setDocumentCategories ] = useState(false);
    const [ allDocumentCategories, setAllDocumentCategories ] = useState([]);
    const [ searchTerm, setSearchTrem ] = useState("");
    const [ selectedAfwerkingFolder, setSelectedAfwerkingFolder ] = useState(0);
    const fileInput = useRef(0);
    const progressBarRef = useRef(0);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        axios.get(getAllProject()).then(response => {
            let cleanup = response.data.data;
            // remove data that doesn't have anything to display
            cleanup = cleanup.filter(item => item.name);
            // setProjectData(cleanup);
            getProjectUnits(cleanup);
        }).catch(error => {
            console.log(error)
        })
    }, [])

    useEffect(() => {
        if(projectData && projectData.length > 0) {
            if(selectedUnit !== 0) {
                axios.get(getDocumentCategories).then(response => {
                    setAllDocumentCategories(response.data.data);
                    const projectCatogories = response.data.data.filter(category => category.unit_id === projectData[selectedBuilding].project_units[selectedUnit - 1].id)
                    setDocumentCategories(projectCatogories)
                }).catch(error => console.log(error))
            }
        }
    }, [projectData, selectedUnit, updateDocumentCategory])

    // on every single update from dropdown we fetch units
    useEffect(() => {
        if(projectData && projectData.length > 0) {
            getProjectUnits();
            setSelectedUnit(0);
        }
    }, [selectedBuilding])

    // when project units is updated for that project after user has selected another project, we fetch documents
    useEffect(() => {
        if(projectData && projectData.length > 0) {
            axios.get(getProjectDocuments(projectData[selectedBuilding].id)).then(response => {
                setDocumentList(response.data.data)
            }).catch(error => console.log(error))
        }
    }, [projectData, manualUpdate])

    useEffect(() => {
        if(projectData && projectData.length > 0) {
            if(selectedUnit !== 0 && selectedFolder !== 0) {
                getUnitDocumentList(projectData[selectedBuilding].project_units[selectedUnit - 1].id, (selectedUnit - 1))
            }
        }
    }, [manualUpdate])

    const handleFileDialog = () => {
        fileInput.current.click();
    }

    const onFileChange = (event) => {
        event.preventDefault();
        uploadFile(event.target.files);
    }

    function getProjectUnits(updatedData) {
        axios.get(getProject(updatedData ? updatedData[selectedBuilding].id : projectData[selectedBuilding].id)).then(response => {
          let singleProjectData = response.data.data;
          let projectDataCopy = [...(updatedData || projectData)];
          projectDataCopy[selectedBuilding].project_units = singleProjectData?.project_units;
          setProjectData(projectDataCopy);
        }).catch(error => console.log(error))
      }

    function getFileType(pathString) {
        let output = pathString.split(".");
        return output[output.length - 1];
    }

    function originalFileName(name) {
        var lastIndex = name.lastIndexOf('.');
        // let output = name.split(".");
        return name.substring(0, lastIndex);
    }

    function uploadFile(files, fileIndex, newDocumentList) {
        if(fileIndex) {
            let documentData = new FormData();
            documentData.append('project_id', projectData[selectedBuilding].id)
            if ((selectedFolder + 1) === 4 && selectedAfwerkingFolder !== 4) {
                documentData.append('category_id', selectedAfwerkingFolder);
            } else {
                documentData.append('category_id', (selectedFolder + 1));
            }
            documentData.append('path', files[fileIndex]);
            if(selectedFolder !== 0 && selectedUnit !== 0) {
                documentData.append('unit_id', projectData[selectedBuilding]?.project_units[selectedUnit - 1]?.id);
            }
            if((selectedFolder + 1) === 5) {
                documentData.append('statusText', 'Deze factuur dient betaald te worden');
            }
            if((selectedFolder + 1) === 4) {
                documentData.append('statusText', 'Het document is nog niet goedgekeurd');
            }
            axios.post(createDocument, documentData, {
                onUploadProgress: progress => {
                    const status = progress.loaded/progress.total;
                    if(status === 1) {
                        progressBarRef.current.uploadFinish()
                    }
                    else {
                        progressBarRef.current.onUploading(parseInt(status * 100), files[fileIndex])
                    }
                },
                cancelToken: source.token,
            }).then(response => {
                let newUpdatedDocumentList = [...newDocumentList]
                newUpdatedDocumentList.push(response.data.data);
                if(fileIndex < (files.length - 1)) {
                    uploadFile(files, fileIndex + 1, newUpdatedDocumentList)
                }
                else {
                    if(response.data.data.category_id == 1) {
                        setDocumentList(newUpdatedDocumentList);
                    }
                    else {
                        setUnitDocumentList(newUpdatedDocumentList);
                    }
                }
            }).catch(error => console.log(error))
        }
        else {
            let documentData = new FormData();
            documentData.append('project_id', projectData[selectedBuilding].id)
            if ((selectedFolder + 1) === 4 && selectedAfwerkingFolder !== 4) {
                documentData.append('category_id', selectedAfwerkingFolder);
            } else {
                documentData.append('category_id', (selectedFolder + 1));
            }
            documentData.append('path', files[0]);
            if(selectedFolder !== 0 && selectedUnit !== 0) {
                documentData.append('unit_id', projectData[selectedBuilding]?.project_units[selectedUnit - 1]?.id);
            }
            if((selectedFolder + 1) === 5) {
                documentData.append('statusText', 'Deze factuur dient betaald te worden');
            }
            if((selectedFolder + 1) === 4) {
                documentData.append('statusText', 'Het document is nog niet goedgekeurd');
            }
            axios.post(createDocument, documentData, {
                onUploadProgress: progress => {
                    const status = progress.loaded/progress.total;
                    if(status === 1) {
                        progressBarRef.current.uploadFinish()
                    }
                    else {
                        progressBarRef.current.onUploading(parseInt(status * 100), files[0])
                    }
                },
                cancelToken: source.token,
            }).then(response => {
                let newDocumentList = response.data.data.category_id == 1 ? [...documentList] : [...unitDocumentList];
                newDocumentList.push(response.data.data);
                if(files.length > 1) {
                    uploadFile(files, 1, newDocumentList)
                }
                else {
                    if(response.data.data.category_id == 1) {
                        setDocumentList(newDocumentList)
                    }
                    else {
                        setUnitDocumentList(newDocumentList);
                    }
                }
            }).catch(error => console.log(error))
        }
    }

    function getUnitDocumentList(unitId, index) {
        axios.get(getDocumentsByUnit(unitId)).then(response => {
            setSelectedUnit(index + 1);
            setUnitDocumentList(response.data.data);
        }).catch(error => console.log(error))
    }
 function handleSearch(value){
    setSearchTrem(value.target.value)
 }
 const results = searchTerm === ""
    ? documentList
    : documentList.filter(x=> x.filename.match(searchTerm));
    useEffect(() => {
        setDocumentList(results);
      }, []);

    const handleDocumentCategoryCreate = () => {
        axios.post(createDocumentCategory, {
            title: modalInputState,
            unit_id: projectData[selectedBuilding].project_units[selectedUnit - 1].id
        }).then(response => {
            setModalState(false)
            setModalInputState("")
            setUpdateDocumentCategory(!updateDocumentCategory)
        }).catch(error => console.log(error))
    }

    const handleDocumentCategoryUpdate = () => {
        axios.put(updateDocumentCategoryApi(documentCategoryEditState), {
            title: modalInputState,
        }).then(response => {
            setModalState(false)
            setModalInputState("")
            setUpdateDocumentCategory(!updateDocumentCategory)
            setDocumentCategoryEditState("0")
        }).catch(error => console.log(error))
    }

    const handleDocumentCategoryDelete = (categoryId) => {
        axios.delete(deleteDocumentCategory(categoryId)).then(response =>  {
            setUpdateDocumentCategory(!updateDocumentCategory)
        }).catch(error => console.log(error))
    }

    function fetchUpdatedData() {
        setManualUpdate(!manualUpdate)
    }

    return(
        <>
        <div className = "document-panel-container">
            <Grid container className = "height-95-p">
                <Grid container>
                    <Dropdown data = {projectData || []} selected = {selectedBuilding} setSelected = {setSelectedBuilding} marginRight = {15} />
                    <div className="document-panel-input-container vertical-center flex-row">
                        <div className = "document-panel-input-icon center-content flex-shrink-disable">
                            <img className = "height-50-p width-50-p object-fit-contain" src = {require('../../assets/images/icons/search_darkbleu.svg')} alt = "search" />
                        </div>
                        <input className = "document-panel-input main-font-regular font-size-14 font-color-black" placeholder = "Zoeken"  onChange= {(event)=>handleSearch(event)}/>
                    </div>
                </Grid>
                <SpaceRow top="21" />
                <Grid container className = "height-auto">
                    <div className = "document-panel-main-container overflow-hidden">
                        <Grid container className = "height-auto">
                            <Grid container sm = {2} className = "height-auto">
                                <div className = "height-auto width-auto document-panel-main-divider">
                                    <div className = "document-panel-main-header vertical-center cursor-pointer" onClick = {() => { setSelectedFolder(0); setSelectedUnit(0); }} style = {{backgroundColor: (selectedFolder === 0 && selectedUnit === 0) ? 'RGBA(148, 150, 152, 0.17)' : 'white'}}>
                                        <div className = "flex-row vertical-center margin-left-24">
                                            <img className = "document-panel-header-icon" alt = "people" src = {require('../../assets/images/icons/people_dark.svg')} />
                                            <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-10">Gebouw</p>
                                        </div>
                                    </div>
                                    <div className = "document-panel-main-list-container">
                                        {projectData && projectData.length > 0 && projectData[selectedBuilding]?.project_units && projectData[selectedBuilding]?.project_units.map((unit, index) => (
                                            <>
                                                <SpaceRow top = "16" />
                                                <div className = "document-panel-main-list-item vertical-center" onClick = {() => getUnitDocumentList(unit.id, index) } style = {{backgroundColor: selectedUnit === (index + 1) ? 'rgba(148, 150, 152, 0.17)' : 'rgba(0, 0, 0, 0)'}} >
                                                    <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-24">{unit?.type?.title} {unit.general_number}</p>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            <Grid container sm = {selectedFolder === 3 ? 2 : 3} className = "height-auto" style = {{display: selectedUnit !== 0 ? 'flex' : 'none'}}>
                                <div className = "height-auto width-auto document-panel-main-divider">
                                    <div className = "document-panel-main-header vertical-center fade-in-translate" style = {{display: selectedUnit !== 0 ? 'flex' : 'none'}}>
                                        <button type = "button" className = "document-panel-main-list-button margin-left-15 center-content animatable-400 ease-in-out-quart" onClick = {() => setModalState(true) }>
                                            <p className = "main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart">Maak een privé folder</p>
                                        </button>
                                    </div>
                                    <div className = "document-panel-main-list-container">
                                        {selectedUnit !== 0 && (
                                            <>
                                                <SpaceRow top = "16" />
                                                <div className = "document-panel-main-list-item vertical-center fade-in-translate" onClick = {() => setSelectedFolder(1)} style = {{backgroundColor: selectedFolder === 1 ? 'RGBA(148, 150, 152, 0.17)' : 'white'}}>
                                                    <img className = "document-panel-header-icon margin-left-18" alt = "people" src = {require('../../assets/images/icons/people_dark.svg')} />
                                                    <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-11">Aankoop</p>
                                                </div>
                                                <SpaceRow top = "16" />
                                                <div className = "document-panel-main-list-item vertical-center fade-in-translate" onClick = {() => setSelectedFolder(2)} style = {{backgroundColor: selectedFolder === 2 ? 'RGBA(148, 150, 152, 0.17)' : 'white'}}>
                                                    <img className = "document-panel-header-icon margin-left-18" alt = "people" src = {require('../../assets/images/icons/people_dark.svg')} />
                                                    <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-11">Plannen</p>
                                                </div>
                                                <SpaceRow top = "16" />
                                                <div className = "document-panel-main-list-item vertical-center fade-in-translate" onClick = {() => setSelectedFolder(3)} style = {{backgroundColor: selectedFolder === 3 ? 'RGBA(148, 150, 152, 0.17)' : 'white'}}>
                                                    <img className = "document-panel-header-icon margin-left-18" alt = "people" src = {require('../../assets/images/icons/people_dark.svg')} />
                                                    <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-11">Afwerking</p>
                                                </div>
                                                <SpaceRow top = "16" />
                                                <div className = "document-panel-main-list-item vertical-center fade-in-translate" onClick = {() => setSelectedFolder(4)} style = {{backgroundColor: selectedFolder === 4 ? 'RGBA(148, 150, 152, 0.17)' : 'white'}}>
                                                    <img className = "document-panel-header-icon margin-left-18" alt = "people" src = {require('../../assets/images/icons/people_dark.svg')} />
                                                    <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-11">Facturatie</p>
                                                </div>
                                                <SpaceRow top = "16" />
                                                <div className = "document-panel-main-list-item vertical-center fade-in-translate" onClick = {() => setSelectedFolder(5)} style = {{backgroundColor: selectedFolder === 5 ? 'RGBA(148, 150, 152, 0.17)' : 'white'}}>
                                                    <img className = "document-panel-header-icon margin-left-18" alt = "people" src = {require('../../assets/images/icons/people_dark.svg')} />
                                                    <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-11">Beheer</p>
                                                </div>
                                                {documentCategories && documentCategories.length > 0 && documentCategories.map((category, categoryIndex) => (
                                                    <>
                                                        <SpaceRow top = "16" />
                                                        <div className = "document-panel-main-list-item vertical-center horizontal-between fade-in-translate relative" onClick = {() => setSelectedFolder(categoryIndex + 6)} style = {{backgroundColor: selectedFolder === (categoryIndex + 6) ? 'RGBA(148, 150, 152, 0.17)' : 'transparent', zIndex: parseInt(1000/(categoryIndex + 1))}}>
                                                            <div className = "flex-row">
                                                                <img className = "document-panel-folder-icon margin-left-18" alt = "see no" src = {require('../../assets/images/icons/see_no_darkbleu.svg')} />
                                                                <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-11">{category?.title || ""}</p>
                                                            </div>
                                                            <div className = "flex-row">
                                                                <DropdownMenuExtended disableDownload disableView handleDelete = {() => handleDocumentCategoryDelete(category.id)} handleEdit = {() =>  { setModalState(true); setDocumentCategoryEditState(category.id); }} />
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Grid>

                            {/* Afwerking sub folders will only appear when Afwerking is selected */}
                            {selectedFolder === 3 && (
                                <Grid container sm = {2} className = "height-auto" style = {{display: selectedUnit !== 0 ? 'flex' : 'none'}}>
                                <div className = "height-auto width-auto document-panel-main-divider">
                                    <div className = "document-panel-main-header vertical-center fade-in-translate" style = {{display: selectedUnit !== 0 ? 'flex' : 'none'}}>    
                                    </div>
                                    <div className = "document-panel-main-list-container">
                                        {selectedUnit !== 0 && (
                                            <>
                                                {allDocumentCategories?.filter(category => category.parent_id == 4)?.map((category, categoryIndex) => (
                                                    <Fragment key={categoryIndex}>
                                                        <SpaceRow top="16" />
                                                        <div className="document-panel-main-list-item vertical-center fade-in-translate" onClick={() => setSelectedAfwerkingFolder(category.id)} style={{ backgroundColor: selectedAfwerkingFolder === category.id ? 'RGBA(148, 150, 152, 0.17)' : 'white' }}>
                                                            <img className="document-panel-header-icon margin-left-18" alt="people" src={require('../../assets/images/icons/people_dark.svg')} />
                                                            <p className="main-font-regular font-size-14 font-color-blue-grey margin-left-11">{category.title}</p>
                                                        </div>
                                                    </Fragment>
                                                ))}
                                                <SpaceRow top = "16" />
                                                <div className = "document-panel-main-list-item vertical-center fade-in-translate" onClick = {() => setSelectedAfwerkingFolder(4)} style = {{backgroundColor: selectedAfwerkingFolder === 4 ? 'RGBA(148, 150, 152, 0.17)' : 'white'}}>
                                                    <img className = "document-panel-header-icon margin-left-18" alt = "people" src = {require('../../assets/images/icons/people_dark.svg')} />
                                                    <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-11">Andere</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            )}
                            {/* Document upload and list area */}
                            <Grid container sm = {selectedFolder === 3 ? 6 : 7} className = "height-auto">
                                <div className = "height-auto width-auto ">
                                    <div className = "document-panel-main-header vertical-center">
                                        <button type = "button" className = "document-panel-main-list-button margin-left-15 center-content animatable-400 ease-in-out-quart" onClick = {handleFileDialog}>
                                            <p className = "main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart">Upload documenten</p>
                                            <input type = "file" ref = {fileInput} style = {{display: 'none'}} onChange = {onFileChange} multiple />
                                        </button>
                                    </div>
                                    <div className = "document-panel-main-list-container padding-left-6 padding-right-15">
                                        <div className = "document-panel-main-type vertical-center">
                                            <Grid container className = "height-auto">
                                                <Grid container item sm = {6}/>
                                                <Grid container item sm = {6}>
                                                     <Grid container item sm = {3}>
                                                        {selectedFolder === 4 && (
                                                            <p className = "main-font-regular font-size-12 font-color-grey">Onderwerp</p>
                                                        )}
                                                    </Grid>
                                                    <Grid container item sm = {3}>
                                                        <p className = "main-font-regular font-size-12 font-color-grey">Upload datum</p>
                                                    </Grid>
                                                    {/* <Grid container item sm = {3}> */}
                                                        {/* <p className = "main-font-regular font-size-12 font-color-grey">Laatst gezien</p> */}
                                                    {/* </Grid> */}
                                                    <Grid container item sm = {2} justify="center">
                                                        {(selectedFolder === 3 || selectedFolder === 4) && (
                                                            <p className = "main-font-regular font-size-12 font-color-grey">Status</p>
                                                        )}
                                                    </Grid>
                                                    {(selectedFolder === 3 || selectedFolder === 4) && (
                                                    <Grid container item sm = {3}>
                                                        <p className = "main-font-regular font-size-12 font-color-grey">Datum goedkeuring</p>
                                                    </Grid>)}
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Grid container style = {{height: 'calc(100% - 16px)'}}>
                                            <DocumentDropzone uploadFile = {uploadFile}>
                                                {selectedUnit !== 0 && selectedFolder !== 0 && (
                                                    <>
                                                        {unitDocumentList && unitDocumentList.length > 0 && unitDocumentList.map(document => {
                                                            if(document.category_id == (selectedFolder + 1) || document.category?.parent_id == (selectedFolder + 1)) {
                                                                if(selectedFolder===3){
                                                                    if(document.category_id == (selectedAfwerkingFolder)) {
                                                                        return <DocumentItem fileName={originalFileName(document.filename)} fileType={getFileType(document.path)} concerned={document.concerned} uploadDate={SQLDateToString(document.created_at).split(" ")[0]} updateDate = {SQLDateToString(document.updated_at).split(" ")[0]} category={document.category} categoryId={document.category_id} status={document.status} documentId={document.id} path={document.path} fetchUpdatedData={fetchUpdatedData} />
                                                                    }                                                                    
                                                                } else {
                                                                    return <DocumentItem fileName={originalFileName(document.filename)} fileType={getFileType(document.path)} concerned={document.concerned} uploadDate={SQLDateToString(document.created_at).split(" ")[0]} updateDate = {SQLDateToString(document.updated_at).split(" ")[0]} categoryId={document.category_id} status={document.status} documentId={document.id} path={document.path} fetchUpdatedData={fetchUpdatedData} />
                                                                }
                                                            }
                                                        })}
                                                    </>
                                                )}
                                                {selectedFolder === 0 && selectedUnit === 0 && (
                                                    <>
                                                        {results && results.length > 0 && results.map(document => {
                                                            if(document.category_id === "1") {
                                                                return <DocumentItem fileName = {originalFileName(document.filename)} fileType = {getFileType(document.path)} concerned = {document.concerned} uploadDate = {SQLDateToString(document.created_at).split(" ")[0]} categoryId = {document.category_id} status = {document.status} documentId = {document.id} path = {document.path} fetchUpdatedData = {fetchUpdatedData} />
                                                            }
                                                        })}
                                                    </>
                                                )}
                                                <ProgressBar ref = {progressBarRef} cancelToken = {source} />
                                            </DocumentDropzone>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
        <div className = "document-panel-modal-container animatable-400 ease-in-out-quart" style = {{opacity: modalState ? 1 : 0, transform: modalState ? 'scale(1)' : 'scale(0)'}}>
            <div className = "document-panel-modal center-content">
                <div className = "document-panel-modal-box">
                    <div className = "width-auto horizontal-end">
                        <img src = {require('../../assets/images/icons/cross_gray.svg')} alt = "cross grey" className = "document-panel-modal-close" onClick = {() => {setModalState(false); setModalInputState(""); setDocumentCategoryEditState("0"); }} />
                    </div>
                    <div className = "width-auto horizontal-center vertical-center">
                        <img src = {require('../../assets/images/icons/add_darkbleu.svg')} alt = "add dark blue" className = "document-panel-modal-add" />
                        <p className = "main-font-semi-bold font-size-16 font-color-blue-grey margin-left-13">Naam van uw privé folder</p>
                    </div>
                    <div className = "width-auto horizontal-center margin-top-55">
                        <input className = "document-panel-modal-input main-font-regular font-size-14 font-color-blue-grey" placeholder = "Foldernaam" value = {modalInputState} onChange = {event => setModalInputState(event.target.value)} />
                    </div>
                    <div className = "width-auto horizontal-center margin-top-36">
                        <button type = "button" className = "document-panel-modal-button center-content" onClick = {documentCategoryEditState != 0 ? handleDocumentCategoryUpdate : handleDocumentCategoryCreate}>
                            <p className = "main-font-regular font-size-14 font-color-white">Bewaren</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default function DocumentPanel() {
    const [open, setOpen] = useState(true);
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <AppBarComponent open={open} setOpen={setOpen} pagename="Documenten" title="Documenten" />
            <MenuStrip open={open} setOpen={setOpen} />
            <main
                className={clsx(classes.content, {
                [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} style={{ marginTop: 80 }} />
                <DocumentPanelView />
            </main>
        </div>
    )
}

DocumentItem.propTypes = {
    fileName: PropTypes.string,
    type: PropTypes.string,
    uploadDate: PropTypes.string,
    endDate: PropTypes.string,
}

DocumentItem.defaultProps = {
    fileName: 'Verkoopplan',
    type: 'pdf',
    uploadDate: '02/03/20',
    endDate: '04/03/20'
}
