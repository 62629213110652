import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { delay, debounce, trim } from 'lodash';
import Picker from 'emoji-picker-react';

import AppBarComponent from '../../components/appbar/appbar';
import MenuStrip from '../../components/menu_strip/menu_strip';
import MessageContact from './message_contact/message_contact';
import MessageItem from './message_item/message_item';
import { SpaceRow } from '../../components/space/space';
import Dropdown from '../../components/dropdown/dropdown';
import {
  getAllProject,
  getChatByProject,
  sendMessage,
} from '../../common/api/routes';

import './message_panel.scss';
import { SearchInput } from '../../components/search_input/search_input';

const drawerWidth = 238;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function MessagePanelView() {
  const [selectedBuilding, setSelectedBuilding] = useState(0);
  const [projectData, setProjectData] = useState([]);
  const [conversationList, setConversationList] = useState([]);
  const [selectedContact, setSelectedContact] = useState(0);
  const [messageState, setMessageState] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);
  const [emojiModalState, setEmojiModalSTate] = useState(false);
  const [emojiMouseState, setEmojiMouseState] = useState(false);
  const [isSubmittingMessage, setIsSubmittingMessage] = useState(false);

  const userData = JSON.parse(localStorage.getItem('user'));
  const messagesListContainerRef = useRef(0);

  useEffect(() => {
    const newDate = new Date();
    console.log(newDate)
    axios.get(getAllProject())
      .then((response) => {
        let cleanup = response.data.data;
        // remove data that doesn't have anything to display
        cleanup = cleanup.filter((item) => item.name);
        setProjectData(cleanup);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // we create the realtime functionality here
    // we keep an update frequency of 5 seconds
    delay(() => {
      setIsUpdated(!isUpdated);
    }, 5000);
  }, [isUpdated]);

  useEffect(() => {
    if (projectData && projectData.length > 0) {
      getMessagesData(projectData[selectedBuilding].id);
    }
  }, [projectData, selectedBuilding, isUpdated]);

  useEffect(() => {
    if(selectedContact) {
      messagesListContainerRef.current.scrollTop = messagesListContainerRef.current.scrollHeight;
    }
  }, [selectedContact])

  const handleMessageChange = (event) => {
    setMessageState(event.target.value);
  };

  const getMessagesData = (id, search) => {
    axios.get(getChatByProject(id, search))
      .then((response) => {
        setConversationList(response.data.data);
      })
      .catch((error) => console.log(error));
  };

  const submitMessage = () => {
    if (selectedContact !== 0 && trim(messageState) && !isSubmittingMessage) {
      setIsSubmittingMessage(true);
      axios.post(sendMessage, {
        chat_id: conversationList[selectedContact - 1].id,
        user_id: userData.id,
        message: messageState,
        sent_by: 1
      })
        .then((response) => {
          const conversationListCopy = [...conversationList];
          conversationListCopy[selectedContact - 1].messages.push({
            ...response.data.data,
            ...{
              user: {
                id: userData.id,
                firstName: userData.firstName,
                lastName: userData.lastName,
              },
            },
          });
          setConversationList(conversationListCopy);
          setMessageState('');
          messagesListContainerRef.current.scrollTop = messagesListContainerRef.current.scrollHeight;
        })
        .catch((error) => console.log(error))
        .finally(()=>setIsSubmittingMessage(false));
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    setMessageState(messageState + emojiObject.emoji);
  };

  return (
    <div className="message-panel-container">
      <Grid container className="height-95-p">
        <Grid container>
          <Dropdown
            data={projectData || []}
            selected={selectedBuilding}
            setSelected={setSelectedBuilding}
            marginRight={15}
          />
          <SearchInput
            handleSearch={debounce((value) => getMessagesData(projectData[selectedBuilding].id, value), 1000)}
          />
        </Grid>
        <SpaceRow top="21" />
        <Grid container className="height-auto">
          <Grid container sm={4} className="height-auto">
            <Grid container sm={11} className="height-auto">
              <div className="message-panel-list-container">
                {conversationList && conversationList.length > 0 && conversationList.map((conversation, index) => (
                  <div onClick={() => setSelectedContact(index + 1)}>
                    <MessageContact
                      data={conversation}
                      buildingData={projectData[selectedBuilding]}
                      isSelected={index === (selectedContact - 1)}
                      onReloadData={() => {getMessagesData(projectData[selectedBuilding].id); setSelectedContact(0);}}
                    />
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
          <Grid container sm={8} className="height-auto">
            <div className="message-panel-messages-container">
              <div className="message-panel-messages-list-container">
                <div
                  className="message-panel-messages-list-heading center-content"
                >
                  <p
                    className="main-font-regular font-size-14 font-color-blue-grey"
                  >
                    Betreft
                    {' '}
                    {conversationList[selectedContact - 1]?.document?.filename || '*Document Name*'}
                  </p>
                </div>
                <div className="message-panel-messages-list-view" ref = {messagesListContainerRef}>
                  {selectedContact !== 0 && (
                    <>
                      <SpaceRow top="16" />
                      {conversationList && conversationList.length > 0 && conversationList[selectedContact - 1].messages.map((message) => (
                        <MessageItem
                          type={userData?.id != message?.user_id}
                          data={message}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div className="message-panel-messages-input-container">
                <div
                  className="message-panel-messages-input-box margin-right-15"
                >
                  <div
                    className="message-panel-messages-input-emoji flex-shrink-disable relative"
                    tabIndex="3"
                    onBlur={() => {
                      if (!emojiMouseState) setEmojiModalSTate(false);
                    }}
                    onMouseOver={() => setEmojiMouseState(true)}
                    onMouseLeave={() => setEmojiMouseState(false)}
                  >
                    <div
                      className="message-panel-messages-input-emoji-container animatable-400 ease-in-out-quart"
                      style={{
                        opacity: emojiModalState ? 1 : 0,
                        transform: emojiModalState ? 'scaleY(1)' : 'scaleY(0)',
                      }}
                    >
                      <Picker onEmojiClick={onEmojiClick} />
                    </div>
                    <div
                      className="height-auto width-auto center-content"
                      onClick={() => setEmojiModalSTate(!emojiModalState)}
                    >
                      <p
                        className="main-font-regular font-size-12 font-color-white"
                      >
                        😃
                      </p>
                    </div>
                  </div>
                  <input
                    className="width-auto height-auto main-font-regular font-size-14 font-color-black background-color-transparent message-panel-input-placeholder"
                    placeholder="Typ bericht ..."
                    value={messageState}
                    onChange={handleMessageChange}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') submitMessage();
                    }}
                  />
                </div>
                <button
                  type="button"
                  className="message-panel-messages-input-button center-content flex-shrink-disable"
                  onClick={submitMessage}
                >
                  <img
                    src={require('../../assets/images/icons/send_white.svg')}
                    alt="send"
                    className="height-70-p width-70-p object-fit-contain"
                  />
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default function MessagePanel() {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBarComponent
        open={open}
        setOpen={setOpen}
        pagename="Berichten"
        title="Berichten"
      />
      <MenuStrip open={open} setOpen={setOpen} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} style={{ marginTop: 80 }} />
        <MessagePanelView />
      </main>
    </div>
  );
}
