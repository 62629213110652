import React, {
  useEffect, useState, forwardRef, useImperativeHandle,
} from 'react';
import { useHistory, useParams, useLocation} from 'react-router-dom';

import {
  Grid, FormControl, NativeSelect, Button,
} from '@material-ui/core';
import axios from 'axios';
import { SpaceRow } from '../../components/space/space';
import Select from '../../components/select/select';
import { CheckboxRound, Checkbox } from '../../components/checkbox/Checkbox';
import Btn from '../../components/button/button';
import {
  getUserByType, sendAccess, createNotification
} from '../../common/api/routes';
import {
  getUserNameTitles,
} from '../dashboard/propects_details/prospectDetails.api';

export function CustomDropdown(props) {
  const [dropdownState, setDropdownState] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [data, setData] = useState([]);
  const { id } = useParams();

  const { dataKey, dropdownData, onClick } = props;

  useEffect(() => {
    if (dropdownData && dropdownData.length > 0) {
      setData(dropdownData);
    }
  }, [dropdownData]);

  function handleClick(itemIndex) {
    onClick(data, itemIndex);
    setDropdownState(false);
  }

  return (
    <div
      className="custom-dropdown-container margin-left-15 margin-top-15"
      style={{
        width: '171px', border: 'solid 1px RGB(223, 223, 222)', borderRadius: '10px', height: '36px',
      }}
      tabIndex="1"
      onBlur={() => setDropdownState(false)}
    >
      <div className="height-auto width-auto flex-row horizontal-between vertical-center" onClick={() => setDropdownState(!dropdownState)}>
        {/* <p className = "main-font-semi-bold font-size-14 font-color-black margin-left-5">{data && data.length > 0 ? (dataKey ? data[selectedValue][dataKey] : data[selectedValue].value) : ""}</p> */}
        <p />
        <img src={require('../../assets/images/icons/arrow_down.svg')} alt="arrow down" className="custom-dropdown-arrow" />
      </div>
      <div className="custom-dropdown-expand animatable-400 ease-in-out-quart" style={{ opacity: dropdownState ? 1 : 0, transform: dropdownState ? 'scaleY(1)' : 'scaleY(0)' }}>
        {data && data.length > 0 && data.map((item, itemIndex) => (
          <div className="custom-dropdown-expand-item" style = {{height: '25px'}} onClick={() => handleClick(itemIndex)}>
            <p className="main-font-semi-bold font-size-14 font-color-black">{dataKey ? item[dataKey] : item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function UserField(props) {
  const [dropdownValueUpdate, setDropdownValueUpdate] = useState(false);
  const { id } = useParams();

  const optionsStatus = [{ lable: 'Volle eigendom', value: 0 }, { lable: 'Naakte eigendom', value: 1 }, { lable: 'vruchtgebruiker', value: 2 }];
  const optionsAcess = [{ lable: 'Beslissingsnemer', value: 0 }, { lable: 'Lezer', value: 1 }];

  const {
    userData, newUserList, setNewUserList, userDataIndex, newFormState, removeUser, projectData, adminData, userTitles, buyerData, errorModalRef, updateBuyer
  } = props;

  useEffect(() => {
    if(!newFormState) {
      // current method makes the data not in sync, since we update core data state and boolean separately
      // it's not  always going to be the case, where the data has updated through by the time boolean updates
      // temporary approach

      updateBuyer()
    }
  }, [dropdownValueUpdate])

  const handleFieldChange = (value, key) => {
    if(key === "access") {
      const newUserListCopy = [...newUserList];
      // we change all the users value to the secondary one
      newUserListCopy.forEach(user => {
        user.access = 1;
      })
      newUserListCopy[userDataIndex][key] = value;
      setNewUserList(newUserListCopy);
    }
    else {
      const newUserListCopy = [...newUserList];
      newUserListCopy[userDataIndex][key] = value;
      setNewUserList(newUserListCopy);
    }
  };

  const handleDropdownChange = (value, key) => {
    handleFieldChange(value, key);
    setDropdownValueUpdate(!dropdownValueUpdate);
  }

  const removeEntry = () => {
    removeUser(userDataIndex);
  };

  const sendUserAccess = () => { 
    if(buyerData?.id && userData?.id) {
      let exists = false;
      if(buyerData.user.id === userData.id)  {
        exists = true;
      }
      buyerData.childs.forEach(user => {
        if(user.user.id === userData.id) {
          exists = true;
        }
      })
      if(exists)  {
        axios.get(sendAccess(userData.id, projectData.id)).then(response => {
          createAccessNotification();
          errorModalRef.current.openModal('E-mail succesvol verzonden.', 'Succes', true)
        }).catch(error => console.log(error))
      }
    }
  }
  
  const createAccessNotification = () => {
    axios.post(createNotification, {
      user_id: userData.id,
      project_id: projectData.id,
      title: 'Send Access',
      description: `${adminData.firstName} ${adminData.lastName} heeft toegang verleend aan ${userData.firstName} ${userData.lastName}`,
      type: 3,
      notification_type: 0,
      mail: true,
    }).then(response => {
      console.log(response.data)
    }).catch(error => console.log(error))
  }

  const onBlurEvent = () => {
    if(!newFormState)  {
      updateBuyer();
    }
  }

  return (
    <>
      <Grid container style={{ paddingBottom: '50px', marginBottom: '20px', borderBottom: ' 1px solid rgb(69, 84, 93)' }}>
        <Grid item sm={12} md={4}>
          <Grid container alignItems="center" spacing={2} md={12}>
            <Grid container item sm={6} md={4}>
              <p className="main-font-regular font-size-14 font-color-grey">
                Aanspreking
              </p>
            </Grid>
            <Grid container item sm={6} md={8}>
              <Select options={userTitles} onChange = {event => handleDropdownChange(event.target.value, 'title_id')} selected = {userData.title_id || 0} />
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container spacing={2}>
            <Grid container item sm={6} md={4}>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36">Voornaam</p>
            </Grid>
            <Grid container item sm={6} md={8}>
              <input className=" main-font-semi-bold font-size-14 font-color-black focused-input" value={userData.firstName} disabled={!!((newFormState && userData.id))} onChange={(event) => handleFieldChange(event.target.value, 'firstName')} onBlur = {onBlurEvent} />
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container spacing={2}>
            <Grid container item sm={6} md={4}>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36">Naam</p>
            </Grid>
            <Grid container item sm={6} md={8}>
              <input className=" main-font-semi-bold font-size-14 font-color-black  focused-input" value={userData.lastName} disabled={!!((newFormState && userData.id))} onChange={(event) => handleFieldChange(event.target.value, 'lastName')} onBlur = {onBlurEvent} />
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container spacing={2}>
            <Grid container item sm={6} md={4}>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36">E-mailadres </p>
            </Grid>
            <Grid container item sm={6} md={8}>
              <input className=" main-font-semi-bold font-size-14 font-color-black focused-input" value={userData.email} disabled={!!((newFormState && userData.id))} onChange={(event) => handleFieldChange(event.target.value, 'email')} onBlur = {onBlurEvent} />
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container spacing={2}>
            <Grid container item sm={6} md={4}>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36">Telefoon</p>
            </Grid>
            <Grid container item sm={6} md={8}>
              <input className=" main-font-semi-bold font-size-14 font-color-black focused-input" value={userData.phone} disabled={!!((newFormState && userData.id))} onChange={(event) => handleFieldChange(event.target.value, 'phone')} onBlur = {onBlurEvent} />
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container spacing={2}>
            <Grid container item sm={6} md={4}>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36">Straat & nr </p>
            </Grid>
            <Grid container item sm={6} md={8}>
              <input className=" main-font-semi-bold font-size-14 font-color-black focused-input" value={userData.street} disabled={!!((newFormState && userData.id))} onChange={(event) => handleFieldChange(event.target.value, 'street')} onBlur = {onBlurEvent} />
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container spacing={2}>
            <Grid container item sm={6} md={4}>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36"> Postcode </p>
            </Grid>
            <Grid container direction="row" item sm={6} md={8}>
              <input className=" main-font-semi-bold font-size-14 font-color-black focused-input" style={{ width: '45px' }} disabled={!!((newFormState && userData.id))} value={userData.zip} onChange={(event) => handleFieldChange(event.target.value, 'zip')} onBlur = {onBlurEvent} />
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container spacing={2}>
            <Grid container item sm={6} md={4}>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36"> Woonpl.  </p>
            </Grid>
            <Grid container direction="row" item sm={6} md={8}>
              <input name="city" className=" main-font-semi-bold font-size-14 font-color-black focused-input" value={userData.city} disabled={!!((newFormState && userData.id))} onChange={(event) => handleFieldChange(event.target.value, 'city')} onBlur = {onBlurEvent} />
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          {id && <Grid container spacing={2}>
            <Grid container item sm={6} md={4}>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36"> Wachtwoord  </p>
            </Grid>
            <Grid container item sm={6} md={8}>
              <input className=" main-font-semi-bold font-size-14 font-color-black focused-input" type="password" value={userData.password}  onChange={(event) => handleFieldChange(event.target.value, 'password')} />
            </Grid>
          </Grid> }
          <SpaceRow top="15" />
          <Grid container spacing={2}>
            <Grid container item sm={6} md={4}>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36">Meer info    </p>
            </Grid>
            <Grid container item sm={6} md={8}>
              <input className=" main-font-semi-bold font-size-14 font-color-black focused-input" value={userData.comments} disabled={!!((newFormState && userData.id))} onChange={(event) => handleFieldChange(event.target.value, 'comments')} onBlur = {onBlurEvent} />
            </Grid>
          </Grid>
          <SpaceRow top="15" />

        </Grid>

        <Grid item sm={12} md={8}>
          <Grid container justify="space-between" md={12}>
            <Grid container alignItems = "center" spacing={2}>
              <Grid container item sm={6} md={3}>
                <p className="main-font-regular font-size-14 font-color-grey lineHeight36">
                  Gebruikersrechten
                </p>
              </Grid>
              <Grid container item sm={3} md={5}>
                <Select options={optionsAcess} onChange={(event) => handleDropdownChange(event.target.value, "access")} selected = {userData?.access || 0} disabled = {newFormState} />
              </Grid>
              <Grid container sm={3} md={4} justify="flex-end" alignItems="center">
                {newUserList.length > 1 && !newFormState && (
                  <p className="main-font-regular font-size-14 font-color-red cursor-pointer text-underline" onClick={removeEntry}>Verwijder mede-eigenaar</p>
                )}
                {newFormState && (
                  <p className="main-font-regular font-size-14 font-color-red cursor-pointer text-underline" onClick={removeEntry}>Verwijder mede-eigenaar</p>
                )}
              </Grid>
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container alignItems = "center" spacing={2}>
            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36">Eigendomsrechten</p>
            </Grid>
            <Grid container item sm={6} md={9}>
              <Select options={optionsStatus} onChange={(event) => handleDropdownChange(event.target.value, "status")} selected = {userData?.status || 0} disabled = {newFormState} />
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container alignItems="center" spacing={2}>
            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36">Percentage</p>
            </Grid>
            <Grid container item sm={6} md={9} alignItems="center">
              <input className="background-color-transparent main-font-semi-bold font-size-14 font-color-black right-text" style={{ width: '25px' }} value={userData?.percentage} onChange={(event) => handleFieldChange(event.target.value, "percentage")} onBlur = {onBlurEvent} disabled = {newFormState} />
              <p className="main-font-semi-bold font-size-14 font-color-black">%</p>
            </Grid>
          </Grid>
          {/* <Grid container>
            {newFormState && (
              <p className = "main-font-regular font-size-14 font-color-red lineHeight36 margin-top-15">Opmerking: u kunt de bovenstaande velden bijwerken nadat de koper is aangemaakt.</p>
            )}
          </Grid> */}
          <SpaceRow top="75" />
          <Grid container spacing={2}>
            <Grid container item sm={6} md={3} direction = "column">
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36">Communicatie </p>
              <p className="main-font-regular font-size-14 font-color-grey lineHeight36">Facturatie</p>
            </Grid>
            <Grid container item direction="column" sm={6} md={9}>
              <div className="flex-row vertical-center ">
                <CheckboxRound checkState = {userData.preference_invoices} setCheckState = {(value) => handleFieldChange(value, "preference_invoices")} />
                <p className="main-font-semi-bold font-size-14 font-color-black margin-left-9 lineHeight36">Gewone zending</p>
              </div>
              <SpaceRow top="5" />
              <div className="flex-row vertical-center ">
                <CheckboxRound checkState = {!userData.preference_invoices} setCheckState = {(value) => handleFieldChange(value, "preference_invoices")} secondary />
                <p className="main-font-semi-bold font-size-14 font-color-black margin-left-9 lineHeight36">Email</p>
              </div>
            </Grid>
          </Grid>
          <SpaceRow top="50" />
          <Grid container spacing={2}>
            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey">
                Communicatie
                <br />
                Beheer
              </p>
            </Grid>
            <Grid container item direction="column" sm={6} md={9}>
              <div className="flex-row vertical-center ">
                <CheckboxRound checkState = {userData?.preference_communication} setCheckState = {(value) => handleDropdownChange(value, "preference_communication")} />
                <p className="main-font-semi-bold font-size-14 font-color-black margin-left-9 lineHeight36">Aangetekende zending</p>
              </div>
              <SpaceRow top="5" />
              <div className="flex-row vertical-center ">
                <CheckboxRound checkState = {!userData?.preference_communication} setCheckState = {(value) => handleDropdownChange(value, "preference_communication")} secondary />
                <p className="main-font-semi-bold font-size-14 font-color-black margin-left-9 lineHeight36">Gewone zending</p>
              </div>
              <SpaceRow top="5" />
              <div className="flex-row vertical-center ">
                <Checkbox initialState = {userData?.preference_communication_email} onChange = {value => handleDropdownChange(value, "preference_communication_email")} />
                <p className="main-font-semi-bold font-size-14 font-color-black margin-left-9 lineHeight36">Email</p>
              </div>
            </Grid>
          </Grid>
          {id &&  <Grid container spacing={2}>
            <Grid container item sm={6} md={12}>
              <SpaceRow top="15" />
              <Grid container item direction="column" sm={6} md={3}>
                <Btn onClick = {sendUserAccess}>
                  Toegang verlenen
                </Btn>
              </Grid>
            </Grid>
         </Grid>}
        </Grid>
      </Grid>
      <SpaceRow top="15" />
    </>
  );
}

const TabContentMedeEigennaar = forwardRef((props, ref) => {
  const [userTitles, setUserTitles] = useState([]);
  
  const {
    buyerData, newFormState, newUserList, setNewUserList, buyerList, setBuyerList, removeUser, adminData, errorModalRef, updateBuyer
  } = props;

  useEffect(() => {
    axios.get(getUserByType('2,3')).then((response) => {
      setBuyerList(response.data.data);
    }).catch((error) => console.log(error));
    getUserNameTitles(setUserTitles);
  }, []);

  useEffect(() => {
    if (buyerData && buyerData.user) {
      const userlist = [{...buyerData.user, ...{
        access: buyerData.access,
        status: buyerData.status,
        percentage: buyerData.percentage
      }}];
      buyerData.childs.forEach((user) => {
        userlist.push({...user.user, ...{
          access: user.access,
          status: user.status,
          percentage: user.percentage,
          buyer_project_id: user.id
        }});
      });
      // delete userlist[0].user.child;
      setNewUserList(userlist);
    }
  }, [buyerData]);

  const addUser = () => {
    const newUser = {
      title_id: 1,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      street: '',
      zip: '',
      city: '',
      comments: '',
      preference_invoices: false,
      preference_communication: false,
      preference_communication_email: false,
      access: newUserList.length > 0 ? 1 : 0,
      percentage: 0,
      status: 0,
      type: [{ id: "2" }],
    };

    const newUserListCopy = [...newUserList];
    newUserListCopy.push(newUser);
    setNewUserList(newUserListCopy);
  };

  function loadBuyer(data, index) {
    const newUser = {
      id: data[index]?.id || null,
      firstName: data[index].firstName,
      lastName: data[index].lastName,
      email: data[index].email,
      phone: data[index].phone,
      street: data[index].street,
      zip: data[index].zip,
      city: data[index].city,
      comments: data[index].comments,
      parent: data[index].parent,
      preference_invoices: data[index].preference_invoices,
      preference_communication:  data[index].preference_communication,
      preference_communication_email: data[index].preference_communication_email,
      access: newUserList.length > 0 ? 1 : 0,
      percentage: 0,
      status: 0,
      parent: data[index].parent,
      child: data[index].child,
    };

    // then we check if the user already exists in the list or not
    let exists = false;
    newUserList.forEach((user) => {
      if (user.id == newUser.id) {
        exists = true;
      }
    });

    if (!exists) {
      const newUserListCopy = [...newUserList];
      newUserListCopy.push(newUser);
      setNewUserList(newUserListCopy);
    } else {
      errorModalRef.current.openModal('Deze Gebruiker Bestaat Al.')
    }
  }

  return (
    <div className="profile-details-container">
      <Grid container>
        {newUserList.map((userData, userDataIndex) => (
          <Grid container>
            <UserField userData={userData} newUserList={newUserList} setNewUserList={setNewUserList} userDataIndex={userDataIndex} newFormState={newFormState} removeUser = {removeUser} projectData = {buyerData?.project || null} adminData = {adminData} userTitles = {userTitles} buyerData = {buyerData} errorModalRef = {errorModalRef} updateBuyer = {updateBuyer} />
          </Grid>
        ))}
        <Grid container item direction="column" sm={6} md={2} >
          <div className="margin-top-15">
            <Btn onClick={addUser} >
              + Mede-eigenaar
            </Btn>
          </div>
        </Grid>
        <Grid container item direction="column" sm={6} md={2}>
          <CustomDropdown dropdownData={buyerList} dataKey="firstName" onClick={loadBuyer} />
        </Grid>
      </Grid>
    </div>
  );
});

export default TabContentMedeEigennaar;
