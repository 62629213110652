import { isEmpty, isUndefined } from 'lodash';

import request from '../../../common/request';

export const getAllProjects = (setFetching, setResponse, search, isProject = false) => {
  if (setFetching) {
    setFetching(true);
  }

  const url = search ? `/project?search=${search}` : '/project';
  const response = request.get(url);

  response
    .then((res) => {
      if (setFetching) {
        setFetching(false);
      }
      const abc = res.data.data;
      if (isProject) {
        abc.unshift({
          name: 'Alle projecten',
          value: '',
        });
      }
      setResponse(abc);
    })
    .catch(() => {
      if (setFetching) {
        setFetching(false);
      }
    });
};

export const adNewProject = (setFetching, setResponse, payload, history) => {
  setFetching(true);

  const response = request.post(
    '/project',
    payload,
  );

  response
    .then((res) => {
      setFetching(false);
      setResponse(res.data.data);
      const unitId = res?.data?.data?.id;
      if (unitId) {
        history.push(`/lijst/projects/units?${unitId}`);
      }
    })
    .catch(() => {
      setFetching(false);
    });
};

export const updateProject = (setResponse, payload, setProjects, toggleShowCodePopup) => {
  delete payload.brutoM2;
  const response = request.put(
    `/project/${payload.id}`,
    payload,
  );

  response
    .then((res) => {
      setResponse(res.data.data);
      getAllProjects(null, setProjects);
      toggleShowCodePopup(false);
    })
    .catch(() => {
      toggleShowCodePopup(false);
    });
};

export const deleteProject = (projectId, setProjects) => {
  const response = request.delete(`/project/delete/${projectId}`);

  response
    .then(() => {
      getAllProjects(null, setProjects);
    });
};

export const getAllCodesList = (setFetching, setResponse, usedCodesList) => {
  if (setFetching) {
    setFetching(true);
  }

  const response = request.get('/project/code');

  response
    .then((res) => {
      if (setFetching) {
        setFetching(false);
      }
      const newList = res.data.data.map((c) => ({ id: c.id, title: c.code, color: c.color }));
      if (!isEmpty(usedCodesList)) {
        const codes = [];
        newList.forEach((l) => {
          const index = usedCodesList.findIndex((f) => f.code === l.title && f.color === l.color);
          if (index < 0) {
            codes.push(l);
          }
        });
        setResponse(codes);
      } else {
        setResponse(newList);
      }
    })
    .catch(() => {
      if (setFetching) {
        setFetching(false);
      }
    });
};

export const addNewCode = (payload, setResponse, usedCodesList) => {
  const response = request.post('/project/code', payload);

  response
    .then((res) => {
      getAllCodesList(false, setResponse, usedCodesList);
      return res.data.data;
    });
};

export const updateCode = (codeId, payload, setResponse, usedCodesList) => {
  const response = request.put(`/project/code/${codeId}`, payload);

  response
    .then((res) => {
      getAllCodesList(false, setResponse, usedCodesList);
      return res.data.data;
    });
};

export const deleteCode = (codeId, setResponse, usedCodesList) => {
  const response = request.delete(`/project/code/delete/${codeId}`);

  response
    .then((res) => {
      getAllCodesList(false, setResponse, usedCodesList);
      return res.data.data;
    });
};

export const getAllWebProjects = (setFetching, setResponse, webStatus) => {
  if (setFetching) {
    setFetching(true);
  }

  const url = !isUndefined(webStatus) ? `/web/project?web=${webStatus}` : '/web/project';
  const response = request.get(url);

  response
    .then((res) => {
      if (setFetching) {
        setFetching(false);
      }
      setResponse(res.data.data);
    })
    .catch(() => {
      if (setFetching) {
        setFetching(false);
      }
    });
};

export const adNewWebProject = (setFetching, setResponse, payload) => {
  setFetching(true);

  const response = request.post(
    '/web/project',
    payload,
  );

  response
    .then(() => {
      setFetching(false);
      getAllWebProjects(null, setResponse);
    })
    .catch(() => {
      setFetching(false);
    });
};

export const updateWebProject = (setResponse, projectId, payload, webStatus) => {
  const response = request.put(
    `/web/project/update/${projectId}`,
    payload,
  );

  response
    .then(() => {
      getAllWebProjects(null, setResponse, webStatus);
    })
    .catch(() => {});
};
