import React, { useState, useEffect } from "react";

import "./custom_switch.scss";

export default function CustomSwitch(props) {
    const [ switchState, setSwitchState ] = useState(false);
    const { value, state, setState, index, forceUpdate } = props;

    useEffect(() => {
        setSwitchState(value.value)
    }, [value])

    const handleClickEvent = () => {
        let stateCopy = [...state];
        stateCopy[index].value = !value.value;
        setState(stateCopy);
        setSwitchState(!switchState)
    }

    return(
        <div className = "custom-switch-container" onClick = {handleClickEvent} >
            <div className = "custom-switch">
                <div className = "custom-switch-ball animatable-400 ease-in-out-quart" style = {{left: switchState ? '9px' : '-1px'}} />
            </div>
        </div>
    )
}