import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { debounce, isEmpty, trim } from 'lodash';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Select from '../../components/select/select';
import Wrapper from '../../components/wrapper/wrapper';
import {
  getUserNameTitles,
  getSellerByProject,
  getUnsoldUnitsByProject,
  getAllStatus,
  getAllProspectProjects,
  getProspectnBuyer,
} from '../dashboard/propects_details/prospectDetails.api';
import ErrorModal from '../../components/error_modal/error_modal';
import Spinner from '../../components/spinner/Spinner';
import AddLinkedProspect2 from '../dashboard/propects_details/AddLinkedProspect2.view';
import { CustomDropdown } from '../aankoper/tab.medeeigennaar';
import { SpaceRow } from '../../components/space/space';
import { createProspect } from '../../common/api/routes';
import ConfirmationModal from '../../components/confirmation_modal/confirmation_modal';
import { getAllProspects } from './prospect.api';

const ProspectCreate = () => {
  const history = useHistory();
  const location = useLocation();

  const [Data, setData] = useState({
    project: '0',
    units: []
  });
  const [userLinkState, setUserLinkState] = useState({});
  const [selectedSalesPerson, setSelectedSalesPerson] = useState('0');
  const [selectedStatus, setSelectedStatus] = useState('0');
  const [userTitles, setUserTitles] = useState([]);
  const [salesPersons, setSalesPersons] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [userList, setUserList] = useState([]);
  const [unitList, setunitList] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [isFetchingProspects, setIsFetchingProspects] = useState(false);
  const [buyerList, setBuyerList] = useState([]);
  const [addDropdownState, setAddDropdownState] = useState(false);
  const [unitDropdownState, setUnitDropdownState] = useState(0);
  const [existingProjectProspects, setExistingProjectProspects] = useState([]);

  const errorModalRef = useRef(0);
  const confirmationModalRef = useRef(0);

  useEffect(() => {
    getAllProspectProjects(setIsFetchingProspects, setUserProjects, true);
    getUserNameTitles(setUserTitles);
    getAllStatus(setStatusOptions);
    getProspectnBuyer(setIsFetchingProspects, setBuyerList);
  }, []);

  useEffect(() => {
    if (!isEmpty(location) && location?.search && Data.project === '0') {
      const search = location?.search;
      let userLinkData = search.split('=')[1];
      userLinkData = atob(userLinkData);
      userLinkData = JSON.parse(userLinkData);
      Data.project = userLinkData?.project_id;
      setData(Data);
      addUser(userLinkData);
      setUserLinkState(userLinkData);
    }

    if (!isEmpty(userProjects)) {
      getUnsoldUnitsByProject(setunitList, userProjects[0]?.value);
      getSellerByProject(setSalesPersons, userProjects[0]?.value);
      Data.project = userProjects[0]?.value;
      setData(Data);
    }
  }, [userProjects]);

  useEffect(() => {
    if (statusOptions && statusOptions.length > 0) {
      setSelectedStatus(statusOptions[0].value);
    }
  }, [statusOptions]);

  useEffect(() => {
    if (salesPersons && salesPersons.length > 0) {
      setSelectedSalesPerson(salesPersons[0].value);
    }
  }, [salesPersons]);

  useEffect(() => {
    if (!isEmpty(userProjects)) {
      handleProjectChange();
    }
  }, [userProjects]);

  const SaveData = () => {
    let validation = false;
    if (userList && userList.length > 0) {
      validation = true;
    } else {
      errorModalRef.current.openModal('Maak of selecteer mede-eigenaren.');
    }

    if (validation) {
      const user_units = [];
      Data.units.forEach((unit) => {
        user_units.push({
          unit_id: unit.value,
        });
      });
      axios.post(createProspect, {
        users: userList,
        project: {
          project_id: Data?.project != 0 ? Data?.project : null,
          status_id: selectedStatus,
          user_units,
          comm: userList[0].preference_communication,
          seller_id: selectedSalesPerson != 0 ? selectedSalesPerson : null,
        },
      }).then((response) => {
        console.log(response.data);
        history.push(`/toevoegen/prospects/${response.data.data.project_data.id}`);
        history.go(0);
      }).catch(error => {
        if(error.response.data.data.message.includes('unique_email')) {
          errorModalRef.current.openModal('Dit e-mailadres word reeds gebruikt in het systeem.', 'ERROR', 3000)
        }
        console.log(error)
      })
    }
  };

  const handleVerifyBeforeSaveData = () => {
    let prospectMatchedUser = [];
    for (let index = 0; index < userList.length; index++) {
      if (userList[index].isNew) {
        const existedUser = buyerList.find(user => user.lastName === userList[index].lastName
          && user.email === userList[index].email
          && user.phone === userList[index].phone);
        if (existedUser) {
          return confirmationModalRef.current.openModal(`Er bestaat reeds een gebruiker met dezelfde naam: ${userList[index].lastName}, email: ${userList[index].email} en telefoonnummer ${userList[index].phone}`, 'Waarschuwing', undefined, undefined, undefined, undefined, 'Terug');
        }
        const existedEmailUser = buyerList.find(user => user.email === userList[index].email);
        if (existedEmailUser) {
          return confirmationModalRef.current.openModal(`Er bestaat reeds een gebruiker met email:${userList[index].email}.`, 'Waarschuwing', undefined, undefined, undefined, undefined, 'Terug');
        }
      }
    }

    for (let i = 0; i < existingProjectProspects.length; i++) {
      prospectMatchedUser[i] = { id: existingProjectProspects[i].id, match: 0 };
      for (let userIndex = 0; userIndex < userList.length; userIndex++) {
        if (userList[userIndex].isNew) {
          if (existingProjectProspects[i].user.email === userList[userIndex].email || existingProjectProspects[i].user.phone === userList[userIndex].phone)
            prospectMatchedUser[i].match++;
          if (existingProjectProspects[i].childs.find(item => item.user.email === userList[userIndex].email || item.user.phone === userList[userIndex].phone))
            prospectMatchedUser[i].match++;
        } else {
          if (existingProjectProspects[i].user.id === userList[userIndex].id || existingProjectProspects[i].childs.find(item => item.user.id === userList[userIndex].id)) {
            prospectMatchedUser[i].match++;
          }
        }
      }
    }
    // console.log('prospectMatchedUser', JSON.stringify(prospectMatchedUser))
    if (Data.project === existingProjectProspects[0]?.projectId) {
      if (prospectMatchedUser.find(item => item.match === userList.length))
        return confirmationModalRef.current.openModal(`Er bestaat reeds een een prospect met deze waarden.`, 'Waarschuwing', undefined, undefined, undefined, 'Toch doorgaan', 'Terug');
      else if (prospectMatchedUser.find(item => item.match > 0 && item.match < userList.length))
        return confirmationModalRef.current.openModal(`Er bestaat reeds een prospect met overeenkomstig project & minstens één mede prospect`, 'Waarschuwing', undefined, undefined, undefined, 'Toch doorgaan', 'Terug');
    }

    SaveData();
  }

  const handleProjectChange = (event) => {
    console.log("handleProjectChange",userProjects)
    if (event) {
      const { value } = event.target;
      const index = userProjects.findIndex((p) => p.value === value);
      Data.project = userProjects[index]?.value;
      Data.units = [];
      setData(Data);
      getSellerByProject(setSalesPersons, userProjects[index].value);
      getUnsoldUnitsByProject(setunitList, userProjects[index].value);
      getAllProspects(()=>{}, setExistingProjectProspects, null, userProjects[index].value);
    } else {
      Data.project = userProjects[0]?.value;
      setData(Data);
    }
  };

  const handleUserData = (index, value, key) => {
    const userListCopy = [...userList];
    if (key === 'preference_communication') {
      userListCopy[index][key] = (value === 'true');
    } else {
      userListCopy[index][key] = value;
    }
    trim(value) && checkExistedProspectUser(buyerList, index, value, key);
    setUserList(userListCopy);
  };

  const handleCheckExistedProspectUser = debounce((buyerList, index, value, key) => {
    if (key === 'lastName') {
      const existedUser = buyerList.find(user => user.lastName === trim(value));
      existedUser && errorModalRef.current.openModal('Er bestaat reeds een gebruiker met dezelfde achternaam', 'Waarschuwing');
      return false;
    }
    if (key === 'phone') {
      const existedUser = buyerList.find(user => user.phone === trim(value));
      existedUser && errorModalRef.current.openModal('Er bestaat reeds een gebruiker met dezelfde telefoonnummer', 'Waarschuwing');
      return false;
    }
    if (key === 'email') {
      const existedUser = buyerList.find(user => user.email === trim(value));
      existedUser && errorModalRef.current.openModal('Er bestaat reeds een gebruiker met dezelfde email', 'Waarschuwing');
      return false;
    }
    return true;

  }, 1000);
  const checkExistedProspectUser = useCallback(handleCheckExistedProspectUser, []);

  const addUser = (userData) => {
    const userListCopy = [...userList];
    userListCopy.push({
      title_id: 1,
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      email: userData?.email || '',
      phone: userData?.mobile || '',
      street: userData?.project_street || '',
      zip: '',
      city: '',
      comments: userData?.message || '',
      type: [{
        id: 3,
      }],
      parent: [],
      preference_communication: false,
      isNew: !userData?.email
    });
    console.log('userdata', userData)
    setUserList(userListCopy);
  };

  const loadBuyer = (data, index) => {
    const newUser = {
      id: data[index]?.id || null,
      title_id: data[index].titleId,
      firstName: data[index].firstName,
      lastName: data[index].lastName,
      email: data[index].email,
      phone: data[index].phone,
      street: data[index].street,
      zip: data[index].zip,
      city: data[index].city,
      comments: data[index].comments,
      preference_communication: data[index].preferenceCommunication,
      type: data[index].type,
      parent: data[index].parent,
      child: data[index].child,
    };

    // then we check if the user already exists in the list or not
    let exists = false;
    userList.forEach((user) => {
      if (user.id == newUser.id) {
        exists = true;
      }
    });

    if (!exists) {
      const userListCopy = [...userList];
      userListCopy.push(newUser);
      setUserList(userListCopy);
    } else {
      errorModalRef.current.openModal('Deze Gebruiker Bestaat Al.');
    }
  };

  const addUnitToSelected = (unit) => {
    const dataCopy = { ...Data };
    const unitsCopy = [...dataCopy.units];
    const exists = unitsCopy.find((existingUnit) => existingUnit.value == unit.value);
    if (!exists) {
      unitsCopy.push(unit);
      dataCopy.units = unitsCopy;
      setData(dataCopy);
    } else {
      errorModalRef.current.openModal('Eenheid bestaat al in de lijst.');
    }
  };

  const changeUnitToSelected = (unit, unitIndex) => {
    const dataCopy = { ...Data };
    const unitsCopy = [...dataCopy.units];
    const exists = unitsCopy.find((existingUnit) => existingUnit.value == unit.value);
    if (!exists) {
      unitsCopy[unitIndex] = unit;
      dataCopy.units = unitsCopy;
      setData(dataCopy);
    } else {
      errorModalRef.current.openModal('Eenheid bestaat al in de lijst.');
    }
  };

  const removeSelectedUnitFromApi = (unitIndex) => {
    const dataCopy = { ...Data };
    const unitsCopy = [...dataCopy.units];
    unitsCopy.splice(unitIndex, 1);
    dataCopy.units = unitsCopy;
    setData(dataCopy);
  };

  const removeUser = (index) => {
    const userListCopy = [...userList];
    userListCopy.splice(index, 1);
    setUserList(userListCopy);
  };

  return (
    <Wrapper
      titleButton={(
        <Button
          variant="contained"
          style={{
            // marginTop: 15,
            background: '#2EA0B4',
            height: 36,
            width: 192,
            fontWeight: 400,
            color: '#fff',
            boxShadow: 'none',
            float: 'right',
            // top: -45,
            textTransform: 'none',
            whiteSpace: 'nowrap',
          }}
          onClick={handleVerifyBeforeSaveData}
        >
          Opslaan
        </Button>
      )}
      title="Prospect toevoegen"
      pagename="Prospect"
    >
      {isFetchingProspects ? (
        <div className="text-center padding-top-100">
          <Spinner text="Fetching Details..." />
        </div>
      ) : (
        <div className="profile-details-container margin-top-85">

          <Grid container style={{ paddingBottom: 25, borderBottom: '1px solid #45545D' }}>
            <Grid item xs={12} sm={6} md={4}>
              <Grid container>
                <Grid item xs={12} sm={12} md={4}>
                  <p
                    className="main-font-regular font-size-14 font-color-grey lineHeight34 wordBreakAll"
                  >
                    Project
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={8} style={{ margin: '-4px 0 10px' }}>
                  <Select
                    style={{ width: 150 }}
                    options={userProjects}
                    onChange={handleProjectChange}
                    selected={userLinkState?.project_id}
                  />
                </Grid>
              </Grid>
              {Data.project > 0 && (  
                <Grid container>
                  <Grid item xs={12} sm={12} md={4}>
                    <p
                      className="main-font-regular font-size-14 font-color-grey  lineHeight34 wordBreakAll"
                    >
                      Entiteit
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Grid container direction="column">
                      {Data.units && Data.units.length > 0 && Data.units.map((unit, unitIndex) => (
                        <div className="relative" tabIndex="2" onBlur={() => setUnitDropdownState(0)}>
                          <div className="flex-row vertical-center">
                            <img src={require('../../assets/images/icons/minus.svg')} alt="minus" className="tab-informatie-minus margin-right-10" onClick={() => removeSelectedUnitFromApi(unitIndex)} />
                            <p className="main-font-semi-bold font-size-14 font-color-black cursor-pointer" onClick={() => setUnitDropdownState(unitDropdownState === 0 ? (unitIndex + 1) : 0)}>
                              {unit?.lable}
                            </p>
                          </div>
                          <div
                            className="custom-dropdown-expand animatable-400 ease-in-out-quart"
                            style={{
                              opacity: (unitDropdownState === unitIndex + 1) ? 1 : 0, transform: unitDropdownState === (unitIndex + 1) ? 'scaleY(1)' : 'scaleY(0)', width: '90px', zIndex: 9999,
                            }}
                          >
                            {unitList && unitList.length > 0 && unitList.map((projectUnit) => (
                              <div className="custom-dropdown-expand-item cursor-pointer center-content" onClick={() => changeUnitToSelected(projectUnit, unitIndex)}>
                                <p className="main-font-semi-bold font-size-14 font-color-black center-text">
                                  {projectUnit?.lable}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </Grid>
                    <Grid container className="relative" style={{ zIndex: 999 }}>
                      <div className="tab-informatie-add relative" tabIndex="4" onBlur={() => setAddDropdownState(false)}>
                        <button type="button" className="height-auto width-auto center-content cursor-pointer" onClick={() => setAddDropdownState(!addDropdownState)}>
                          <p className="main-font-semi-bold font-size-14 font-color-black"> + Toevoegen</p>
                        </button>
                        <div className="custom-dropdown-expand animatable-400 ease-in-out-quart" style={{ opacity: addDropdownState ? 1 : 0, transform: addDropdownState ? 'scaleY(1)' : 'scaleY(0)', width: '90px' }}>
                          {unitList && unitList.length > 0 && unitList.map((unit) => (
                            <div className="custom-dropdown-expand-item cursor-pointer center-content" onClick={() => addUnitToSelected(unit)}>
                              <p className="main-font-semi-bold font-size-14 font-color-black center-text">
                                {unit.lable}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
         
            </Grid>
            {Data.project > 0 && (  

            <Grid item xs={12} sm={6} md={4}>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} style={{ marginBottom: 10 }}>
                  <p
                    className="main-font-regular font-size-14 font-color-grey lineHeight34 wordBreakAll"
                  >
                    Verantwoordelijke verkoop
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Select style={{ width: 150 }} options={salesPersons} onChange={(event) => setSelectedSalesPerson(event.target.value)} />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={12} md={4}>
                  <p
                    className="main-font-regular font-size-14 font-color-grey lineHeight34 wordBreakAll"
                  >
                    Status
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={8} style={{ marginBottom: 5 }}>
                  <Select style={{ width: 150 }} options={statusOptions} onChange={(event) => setSelectedStatus(event.target.value)} />
                </Grid>
              </Grid>
            </Grid>
                          )}

          </Grid>
          {userList && userList.length > 0 && userList.map((user, userIndex) => (
            <AddLinkedProspect2
              userTitles={userTitles}
              index={userIndex}
              onChange={(value, key) => handleUserData(userIndex, value, key)}
              data={user}
              removeUser={removeUser}
            />
          ))}
          <Grid container>
            <Grid container>
              <SpaceRow top="15" />
            </Grid>
            <Grid container item xs={12} sm={12} md={12} alignItems="center">
              <Button
                variant="contained"
                style={{
                  opacity: 1,
                  background: '#F0F1F2',
                  height: 36,
                  width: 184,
                  fontWeight: 400,
                  color: '#45545D',
                  boxShadow: 'none',
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                  marginTop:15
                }}
                onClick={addUser}
              >
                + Informatie Prospect
              </Button>
              <CustomDropdown dropdownData={buyerList} dataKey="firstName" onClick={loadBuyer} />
            </Grid>
          </Grid>
        </div>
      )}
      <ErrorModal ref={errorModalRef} />
      <ConfirmationModal ref={confirmationModalRef} onProceed={SaveData}/>
    </Wrapper>
  );
};

export default ProspectCreate;
