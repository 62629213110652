import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isString } from 'lodash';

import './dropdown.scss';

export default function Dropdown(props) {
  const [dropdownState, setDropdownState] = useState(false);

  // combining data and selected we can display the current selected item from the list, i.e. if the value of selected is 2, it means the selected item is in the 3rd index of data
  const {
    defaultValue, data, selected, setSelected, icon, marginRight, marginBottom, marginTop, marginLeft, styles,
  } = props;

  const getDefaultValue = () => {
    let selectedVal = '';
    if (!isEmpty(defaultValue) && isEmpty(selected)) {
      selectedVal = defaultValue;
    } else if (isString(selected)) {
      selectedVal = selected;
    } else if (data && data.length > 0) {
      selectedVal = data[selected]?.name;
    }
    return selectedVal;
  };

  return (
    <div
      className="dropdown-container outline-none"
      tabIndex="0"
      onClick={() => setDropdownState(!dropdownState)}
      onBlur={() => setDropdownState(false)}
      style={{
        zIndex: 2,
        marginRight: `${marginRight || 0}px`,
        marginLeft: `${marginLeft || 0}px`,
        marginTop: `${marginTop || 0}px`,
        marginBottom: `${marginBottom || 0}px`,
      }}
    >
      <div className="dropdown vertical-center" style={styles}>
        <img src={icon || require('../../assets/images/icons/building_darkbleu.svg')} alt="building" className="dropdown-building-icon margin-right-14" />
        <p className="width-auto main-font-regular font-size-12 font-color-black">{getDefaultValue()}</p>
        <img src={require('../../assets/images/icons/arrow_down.svg')} alt="arrow" className="dropdown-arrow-icon margin-left-14" />
      </div>
      <div className="dropdown-expand flex-column vertical-center margin-top-5 animatable-400 ease-in-out-quart" style={{ height: data && data.length > 0 ? `${data.length * 36}px` : '0px', transform: dropdownState ? 'scaleY(1)' : 'scaleY(0)', opacity: dropdownState ? 1 : 0 }}>
        {data && data.length > 0 && data.map((item, index) => (
          <div className="dropdown-expand-item flex-row vertical-center" onClick={() => setSelected(index)}>
            <p className="width-auto main-font-regular font-size-12 font-color-black">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.number,
  setSelected: PropTypes.func,
  icon: PropTypes.func,
  marginRight: PropTypes.number,
  marginTop: PropTypes.number,
  marginLeft: PropTypes.number,
  marginBottom: PropTypes.number,
  defaultValue: PropTypes.string,
  styles: PropTypes.object,
};

Dropdown.defaultProps = {
  data: [],
  selected: 0,
  setSelected: () => {},
  icon: null,
  marginRight: 0,
  marginLeft: 0,
  marginTop: 0,
  marginBottom: 0,
  defaultValue: '',
  styles: {},
};
