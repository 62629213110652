import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import './cookies.scss';

export default function CookiePolicy() {
  const history = useHistory();
  const [showBanner, setBannerState] = useState(
    document.cookie.indexOf('cookies-bim-frontend') === -1,
  );

  const setUserCookie = () => {
    const exdays = 365;
    const cname = 'cookies-bim-frontend';
    const cvalue = 'user-cookie';
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
    setBannerState(false);
  };

  return (
    <>
      {showBanner === true && (
        <div className="cookie-policy-banner">
          <Grid container justify="center">
            <Grid item md={1}>
              <div className="cookie-policy-cookie-icon mt-3" />
            </Grid>

            <Grid item md={9} className="text-cookie">
              <span className="title-in-cookies">
                Wij maken gebruik van koekies
              </span>
              <br />
              <span className="text-cookies">
                We maken gebruik van cookies voor functionele doeleinden en
                openen van informatie en/of het opslaan op een apparaat.
                <br />
                Door op 'Cookies accepteren' te klikken, gaat u hiermee
                akkoord. Wil u niet alle soorten cookies toestaan, wij
                gebruiken enkel de hoogstnoodzakelijke om de website te
                laten functioneren. Om huidige voorkeuren aan te passen klik
                op 'voorkeuren wijzigen' Wenst u meer uitleg over het gebruik
                van uw gegevens? Contacteer ons dan via email
                info@gaelensbouwprojecten.be .
              </span>
            </Grid>

            <Grid item md={2} justifyContent="center">
              <button
                className="btn-accept"
                variation="primary"
                onClick={() => {
                  setUserCookie();
                }}
              >
                Cookies accepteren
              </button>

              <button
                className="btn-reject"
                onClick={() => {
                  setBannerState(false);
                  localStorage.clear();
                  history.push('/');
                }}
              >
                Voorkeuren wijzigen
              </button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}
