import { isNumber } from "lodash";
import React, { useState, useEffect } from "react";

import "../projecten.scss";

export function VerkoopovereenkomstenItem(props) {
    const [ priceContractTotal, setPriceContractTotal ] = useState(0);
    const [ priceContractGround, setPriceContractGround ] = useState(0);
    const [ priceContractConstruction, setPriceContractConstruction ] = useState(0);
    const [ priceContractDiscount, setPriceContractDiscount ] = useState(0);
    const [ activeInput, setActiveInput ] = useState(0)

    const { data, handleUpdate, total } = props;

    useEffect(() => {
        if(data) {
            setPriceContractTotal(data?.price_contract_total);
            setPriceContractGround(data?.price_ground);
            setPriceContractConstruction(data?.price_ground);
            setPriceContractDiscount(data?.price_total);
        }
    }, [data])

    const handleBlur = () => {
        const updatedData = {
            price_contract_total: priceContractTotal,
        }

        handleUpdate(updatedData, data.id)
        setActiveInput(0)
    }

    const resetField = (event, inputType) => {
        setActiveInput(inputType)
    }

    const changeMetricsAndFormat = value => {
        if(value !== null && value !== undefined && isNumber(parseFloat(value))) {
            if(value[0] === "-") {
                let newValue = value.toString().substring(1, value.length);
                newValue = newValue.toString().split(".");
                newValue[0] = [...newValue[0]].reverse().join("");
                if(newValue[0].length > 3) {
                    if(newValue[0].length % 3 !== 0 ) {
                        newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                    }
                    else {
                        newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                        newValue[0] = newValue[0].slice(0, newValue[0].length - 1)
                    }
                }
                newValue[0] = [...newValue[0]].reverse().join("");
                return `-${newValue[0] || "0"},${newValue[1] ||  "00"}`;
            }
            else {
                let newValue = value.toString().split(".");
                newValue[0] = [...newValue[0]].reverse().join("");
                if(newValue[0].length > 3) {
                    if(newValue[0].length % 3 !== 0 ) {
                        newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                    }
                    else {
                        newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                        newValue[0] = newValue[0].slice(0, newValue[0].length - 1)
                    }
                }
                newValue[0] = [...newValue[0]].reverse().join("");
                return `${newValue[0] || "0"},${newValue[1] ||  "00"}`;
            }
        }
        else {
            return "-";
        }
    }

    return(
        <div className = "projecten-grid-container-four fade-in-translate">
            <div className = "width-auto projecten-grid-container-border-half">
                <div className = "projecten-grid-row-border">
                    <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <div className = "flex-row width-auto relative">
                            <p className = "main-font-regular font-size-14 font-color-blue-grey width-auto center-text" style = {{opacity: activeInput === 1 ? 0 : 1}}>{changeMetricsAndFormat(priceContractTotal)}</p>
                            <input className = "main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text input-no-arrows absolute" type = "number" name = "price_contract_total" value = {priceContractTotal || ""} onChange = {event => setPriceContractTotal(event.target.value)} onBlur = {handleBlur} onClick = {event => resetField(event, 1)} style = {{opacity: activeInput === 1 ? 1 : 0}} />
                        </div>
                    </div>
                    <div className = "projecten-grid-box-seven vertical-center horizontal-between projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€ </p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">{typeof priceContractGround === "number" ? changeMetricsAndFormat(priceContractGround.toFixed(2)) : '-'}</p>
                    </div>
                    <div className = "projecten-grid-box-four vertical-center horizontal-between projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€ </p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">{(typeof priceContractTotal === "number" && typeof priceContractConstruction === "number") ? changeMetricsAndFormat((priceContractTotal - priceContractConstruction).toFixed(2)) : '-'}</p>
                    </div>
                    <div className = "projecten-grid-box-four vertical-center horizontal-between">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€ </p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">{changeMetricsAndFormat(((isNumber(priceContractTotal) ? priceContractTotal : 0) - (isNumber(priceContractDiscount) ? priceContractDiscount : 0)).toFixed(2))}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function VerkoopovereenkomstenTotal(props) {
    const { data } = props;

    const getTotal = (key) => {
        if(data) {
            let total = 0;
            data.forEach(unit => {
                total = total + (unit[key] || 0);
            })
            let output = total;
            output = output.toFixed(2);
            return output !== "NaN" ? output : 0;
        }
        else {
            return 0;
        }
    }

    const getPriceTotal = () => {
        if(data) {
            let totalContractPrice = 0;
            let totalPrice = 0;
            data.forEach(unit => {
                totalContractPrice += unit.price_contract_total
                totalPrice += (unit.price_total + (unit.price_contract_total - unit.price_total))
            })
            let output = (totalContractPrice/totalPrice) * 100;
            output = output.toFixed(2);
            return output !== "NaN" ? output : 0;
        }
        else {
            return 0;
        }
    }

    const getTotalDiscount = () => {
        if(data) {
            let totalkorting = 0;
            let totalPrice = 0;
            data.forEach(unit => {
                totalkorting += (unit.price_contract_total - unit.price_total)
                totalPrice += unit.price_total
            })
            const finalTotalPrice = totalPrice + totalkorting;
            let output = (totalkorting/finalTotalPrice) * 100;
            output = output.toFixed(2);
            return (output.includes("NaN") || output.includes("Infinity")) ? 0 : output;
        }
        else {
            return 0;
        }
    }

    const changeMetricsAndFormat = value => {
        if(value !== null && value !== undefined && isNumber(parseFloat(value))) {
            if(value[0] === "-") {
                let newValue = value.toString().substring(1, value.length);
                newValue = newValue.toString().split(".");
                newValue[0] = [...newValue[0]].reverse().join("");
                if(newValue[0].length > 3) {
                    if(newValue[0].length % 3 !== 0 ) {
                        newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                    }
                    else {
                        newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                        newValue[0] = newValue[0].slice(0, newValue[0].length - 1)
                    }
                }
                newValue[0] = [...newValue[0]].reverse().join("");
                return `-${newValue[0] || "0"},${newValue[1] ||  "00"}`;
            }
            else {
                let newValue = value.toString().split(".");
                newValue[0] = [...newValue[0]].reverse().join("");
                if(newValue[0].length > 3) {
                    if(newValue[0].length % 3 !== 0 ) {
                        newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                    }
                    else {
                        newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                        newValue[0] = newValue[0].slice(0, newValue[0].length - 1)
                    }
                }
                newValue[0] = [...newValue[0]].reverse().join("");
                return `${newValue[0] || "0"},${newValue[1] ||  "00"}`;
            }
        }
        else {
            return "-";
        }
    }

    return(
        <div className = "projecten-grid-container-four fade-in-translate">
            <div className = "projecten-grid-total-container flex-row projecten-grid-container-border">
                <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right horizontal-between for-height-over-flow">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <div className = "flex-row">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(getTotal('price_contract_total'))}</p>
                    </div>
                </div>
                <div className = "projecten-grid-box-seven vertical-center projecten-grid-border-right horizontal-between for-height-over-flow">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <div className = "flex-row">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(getTotal('price_ground'))}</p>
                    </div>
                </div>
                <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right horizontal-between for-height-over-flow">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <div className = "flex-row">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat((getTotal('price_contract_total') - getTotal('price_ground')).toFixed(2))}</p>
                    </div>
                </div>
                <div className = "projecten-grid-box-four vertical-center horizontal-between for-height-over-flow">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <div className = "flex-row">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat((parseFloat(getTotal('price_contract_total')) - parseFloat(getTotal('price_total'))).toFixed(2))}</p>
                    </div>
                </div>
            </div>
            <div className = "projecten-grid-total-container flex-row projecten-grid-container-border-half" style = {{marginTop: '0px'}}>
                <div className = "projecten-grid-box-four vertical-center projecten-grid-total horizontal-between for-height-over-flow" style = {{borderTop: 'none', borderLeft: 'none', width: '107px'}}>
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(getPriceTotal())}%</p>
                </div>
                <div className = "projecten-grid-box-seven" />
                <div className = "projecten-grid-box-four" />
                <div className = "projecten-grid-box-four vertical-center projecten-grid-total horizontal-between for-height-over-flow" style = {{borderTop: 'none', borderRight: 'none', width: '106px', marginLeft: 'auto'}}>
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(getTotalDiscount())}%</p>
                </div>
            </div>
        </div>
    )
}

export default function Verkoopovereenkomsten() {

    return(
        <div className = "projecten-grid-container-four fade-in-translate">
            <div className = "projecten-grid-container-border width-auto">
                <div className = "projecten-grid-header-box center-content">
                    <p className = "main-font-semi-bold font-size-14 font-color-blue-grey">Verkoopprijs Verkoopovereenkomst</p>
                </div>
                <div className = "projecten-grid-row-border background-color-light-grey">
                    <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal</p>
                    </div>
                    <div className = "projecten-grid-box-seven vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Grondwaarde</p>
                    </div>
                    <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Constructie</p>
                    </div>
                    <div className = "projecten-grid-box-four vertical-center ">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Korting</p>
                    </div>
                </div>
            </div>
        </div>
    )
}