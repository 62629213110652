import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import 'date-fns';
import { Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios';
import { SpaceRow } from '../../components/space/space';
import Select from '../../components/select/select';
import {
  getAllProspectProjects,
  getUserNameTitles,
  getSalesPersons,
} from '../dashboard/propects_details/prospectDetails.api';

import { getUnsoldUnits, updateProjectUnit } from '../../common/api/routes';

import './tab.informatie.scss';
import { JSDateToString, SQLDateToString } from '../../common/utils/format';

function CustomDropdown(props) {
  const [ dropdownState, setDropdownState ] = useState(false);
  const [ selectedValue, setSelectedValue ] = useState(1)
  const [ data, setData ] = useState([]);

  const { size, key, dropdownData, onClick, initialValue } = props;

  useEffect(() => {
    if (dropdownData && dropdownData.length > 0) {
      setData(dropdownData);
    }
  }, [dropdownData]);

  useEffect(() => {
    if(typeof initialValue === "number") {
      setSelectedValue(initialValue)
    }
  }, [initialValue])

  const handleClickEvent = (itemIndex) => {
    onClick(itemIndex);
    setSelectedValue(itemIndex);
    setDropdownState(false)
  }

  return(
    <div className = "custom-dropdown-container" style = {{width: size === "small" ? '60px' : '120px'}} tabIndex = "1" onBlur = {() => setDropdownState(false)}>
      <div className = "height-auto width-auto flex-row horizontal-between vertical-center" onClick = {() => setDropdownState(!dropdownState)}>
        <p className = "main-font-semi-bold font-size-14 font-color-black">{data && data.length > 0 ? (key ? data[selectedValue][key] : data[selectedValue]?.value) : ""}</p>
        <img src = {require('../../assets/images/icons/arrow_down.svg')} alt = "arrow down" className = "custom-dropdown-arrow" />
      </div>
      <div className="custom-dropdown-expand animatable-400 ease-in-out-quart" style={{ opacity: dropdownState ? 1 : 0, transform: dropdownState ? 'scaleY(1)' : 'scaleY(0)' }}>
        {data && data.length > 0 && data.map((item, itemIndex) => (
          <div className = "custom-dropdown-expand-item" style = {{height: '25px'}} onClick = {() => handleClickEvent(itemIndex)}>
            <p className = "main-font-semi-bold font-size-14 font-color-black">{key ? item[key] : item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const TabContentInformatie = forwardRef((props, ref) => {
  
  const [salesPersons, setSalesPersons] = useState([]);
  const [isFetchingProspects, setIsFetchingProspects] = useState(false);
  const [addDropdownState, setAddDropdownState] = useState(false);
  const [currentDropdownState, setCurrentDropdownState] = useState(0);
  const [oldDropdownState, setOldDropdownState] = useState(0);
  const [informatieDataUpdateState, setInformatieDataUpdateState ] = useState(false)
  
  const {
    buyerData, setBuyerData, newFormState, userProjects, setUserProjects, selectedUserProject, setSelectedUserProject, projectUnits, setProjectUnits, selectedUnits, setSelectedUnits, informatieData, setInformatieData, updateUnits, errorModalRef, updateBuyer
  } = props;

  useEffect(() => {
    getAllProspectProjects(setIsFetchingProspects, setUserProjects);
    getSalesPersons(setSalesPersons);
  }, []);

  useEffect(() => {
    if (userProjects) {
      // setSelectedUserProject(userProjects[0]);
      // setSelectedUnits([])
    }
  }, [userProjects]);

  useEffect(() => {
    if (selectedUserProject?.value || buyerData?.project?.id) {
      axios.get(getUnsoldUnits(buyerData?.project?.id || selectedUserProject.value)).then((response) => {
        setProjectUnits(response?.data?.data || '');
      }).catch((error) => console.log(error));
    }
  }, [selectedUserProject, buyerData]);

  useEffect(() => {
    if(!newFormState) {
      updateBuyer();
    }
  }, [informatieDataUpdateState])

  const handleProjectChange = (event) => {
    if (event) {
      const { value } = event.target;
      const index = userProjects.findIndex((p) => p.value == value);
      setSelectedUserProject(userProjects[index]);
      setSelectedUnits([])
    } else {
      setSelectedUserProject(userProjects[0]);
    }
  };

  function addUnitToSelected(unit) {
    if (newFormState) {
      let exists = false;
      selectedUnits.forEach((selectedUnit) => {
        if (selectedUnit.id == unit.id) {
          exists = true;
        }
      });
      if (!exists) {
        const selectedUnitsCopy = [...selectedUnits];
        selectedUnitsCopy.push(unit);
        setSelectedUnits(selectedUnitsCopy);
        setAddDropdownState(false);
      } else {
        errorModalRef.current.openModal('Die eenheid is al toegevoegd.')
      }
    } else if (buyerData && buyerData.id) {
      const buyerDataCopy = { ...buyerData };
      const userUnitsCopy = [...buyerData.user_units];
      let exists = false;
      buyerDataCopy.user_units.forEach(unit => {
        if(unit.units.id === unit.id) {
          exists = true;
        }
      })
      if(!exists) {
        buyerDataCopy.user_units.push({
          units: unit,
        });
        userUnitsCopy.push({
          units: unit,
        });
        // we simplify user units by only keeping id values
        axios.put(updateProjectUnit(unit.id), {
          sold: true,
        }).catch(error => {
          console.log(error)
        })
         
        buyerDataCopy.user_units = userUnitsCopy;
        updateUnits(buyerDataCopy.id, buyerDataCopy)
      }
      else {
        errorModalRef.current.openModal('Die eenheid is al toegevoegd.')
      }
    }
  }

  function changeUnitToSelected(unit, index) {
    if (newFormState) {
      let exists = false;
      selectedUnits.forEach((selectedUnit) => {
        if (selectedUnit.id == unit.id) {
          exists = true;
        }
      });
      if (!exists) {
        const selectedUnitsCopy = [...selectedUnits];
        selectedUnitsCopy[index] = unit;
        setSelectedUnits(selectedUnitsCopy);
        setCurrentDropdownState(0);
      } else {
        errorModalRef.current.openModal('Die eenheid is al toegevoegd.')
      }
    } else {
      const buyerDataCopy = { ...buyerData };
      const userUnitsCopy = [...buyerData.user_units];
      let exists = false;
      buyerDataCopy.user_units.forEach(unit => {
        if(unit.units.id === unit.id) {
          exists = true;
        }
      })
      if(!exists) {
        axios.put(updateProjectUnit(buyerDataCopy.user_units[index].units.id), {
          sold: false,
        }).catch(error => console.log(error))

        axios.put(updateProjectUnit(unit.id), {
          sold: true,
        }).catch(error => console.log(error))

        buyerDataCopy.user_units[index] = {
          units: unit,
        };
        userUnitsCopy[index] = {
          units: unit,
        };
        buyerDataCopy.user_units = userUnitsCopy;
        updateUnits(buyerDataCopy.id, buyerDataCopy)
      }
      else {
        errorModalRef.current.openModal('Dat toestel staat al in de lijst.')
      }
    }
  }

  function removeSelectedUnit(unitIndex) {
    const selectedUnitsCopy = [...selectedUnits];
    selectedUnitsCopy.splice(unitIndex, 1);
    setSelectedUnits(selectedUnitsCopy);
  }

  function removeSelectedUnitFromApi(unitIndex) {
    if (buyerData && buyerData.id) {
      const buyerDataCopy = { ...buyerData };
      const userUnitsCopy = [...buyerDataCopy.user_units];
      axios.put(updateProjectUnit(userUnitsCopy[unitIndex].units.id), {
        sold: false,
      }).catch(error => console.log(error))
      
      userUnitsCopy.splice(unitIndex, 1);
      buyerDataCopy.user_units = userUnitsCopy;
      updateUnits(buyerDataCopy.id, buyerDataCopy)
    }
  }

  function setInformatieItem(value, key) {
    
    if(key ==="sales_number" && !value){value=0;}

    const informatieDataCopy = { ...informatieData };
    informatieDataCopy[key] = value;
    setInformatieData(informatieDataCopy);
  }

  function calculateEinde(format) {
    if(informatieData?.sales_agreement && informatieData?.sales_number > 0) {
      const eindeDate = new Date(informatieData.sales_agreement.getFullYear(), informatieData.sales_agreement.getMonth(), (informatieData.sales_agreement.getDate() + parseInt(informatieData.sales_number)))
      if(format) {
        return eindeDate;
      }
      else {
        return JSDateToString(eindeDate)
      }
    }
    else {
      if(informatieData?.sales_agreement) {
        if(format) {
          return informatieData.sales_agreement;
        }
        else {
          return JSDateToString(informatieData?.sales_agreement, format);
        }
      }
      else {
        return "-";
      }
    }
  }

  function calculateDeed() {
    if(informatieData?.sales_agreement) {
      const eindeDate = calculateEinde(true);
      let deedDate = new Date(eindeDate.getFullYear(), eindeDate.getMonth() + ((informatieData.deed_month || 0)  + 1), eindeDate.getDate());
      return JSDateToString(deedDate)
    }
    else {
      return "-";
    }
  }
  
  function updateDate(date, key) {
    setInformatieItem(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12), key)
    setInformatieDataUpdateState(!informatieDataUpdateState) 
  }

  function updateDropdown(value, key) {
    setInformatieItem(value, key)
    setInformatieDataUpdateState(!informatieDataUpdateState) 
  }

  const onBlurEvent = () => {
    if(!newFormState)  {
      updateBuyer();
    }
  }

  return (
    <div className="profile-details-container">
      <Grid container>
        <Grid item sm={12} md={4} alignItems="center">

          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Project
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={9} alignItems="center">
              {buyerData && buyerData?.project ? (
                <p className="main-font-semi-bold font-size-14 font-color-black">{buyerData.project.name}</p>
              ) : (
                <Select
                  style={{ width: 150 }}
                  options={userProjects}
                  onChange={handleProjectChange}
                  value = {selectedUserProject?.value || 0}
                />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Entiteit
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={9} alignItems="center" justify="text-end">
              <Grid container direction="column">
                {buyerData && buyerData.user_units && buyerData.user_units.length > 0 && buyerData.user_units.map((unit, unitIndex) => (
                  <div className="relative" tabIndex="2" onBlur={() => setOldDropdownState(0)}>
                    <div className="flex-row vertical-center">
                      <img src={require('../../assets/images/icons/minus.svg')} alt="minus" className="tab-informatie-minus margin-right-10" onClick={() => removeSelectedUnitFromApi(unitIndex)} />
                      <p className="main-font-semi-bold font-size-14 font-color-black cursor-pointer" onClick={() => setOldDropdownState(oldDropdownState === 0 ? (unitIndex + 1) : 0)}>
                        {unit?.units?.type?.title}
                        {' '}
                        {unit?.units?.general_number}
                      </p>
                    </div>
                    <div
                      className="custom-dropdown-expand animatable-400 ease-in-out-quart"
                      style={{
                        opacity: (oldDropdownState === unitIndex + 1) ? 1 : 0, transform: oldDropdownState === (unitIndex + 1) ? 'scaleY(1)' : 'scaleY(0)', width: '125px', zIndex: 999,
                      }}
                    >
                      {projectUnits && projectUnits.length > 0 && projectUnits.map((projectUnit) => (
                        <div className="custom-dropdown-expand-item cursor-pointer center-content" onClick={() => changeUnitToSelected(projectUnit, unitIndex)}>
                          <p className="main-font-semi-bold font-size-14 font-color-black center-text">
                            {projectUnit?.type?.title}
                            {' '}
                            {projectUnit?.general_number}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                {selectedUnits && selectedUnits.length > 0 && selectedUnits.map((unit, unitIndex) => (
                  <div className="relative" tabIndex="3" onBlur={() => setCurrentDropdownState(0)}>
                    <div className="flex-row vertical-center">
                      <img src={require('../../assets/images/icons/minus.svg')} alt="minus" className="tab-informatie-minus margin-right-10" onClick={() => removeSelectedUnit(unitIndex)} />
                      <p className="main-font-semi-bold font-size-14 font-color-black cursor-pointer" onClick={() => setCurrentDropdownState(currentDropdownState === 0 ? unitIndex + 1 : 0)}>
                        {unit.type.title}
                        {' '}
                        {unit.general_number}
                      </p>
                    </div>
                    <div
                      className="custom-dropdown-expand animatable-400 ease-in-out-quart"
                      style={{
                        opacity: currentDropdownState === (unitIndex + 1) ? 1 : 0, transform: currentDropdownState === (unitIndex + 1) ? 'scaleY(1)' : 'scaleY(0)', width: '125px', zIndex: 9999,
                      }}
                    >
                      {projectUnits && projectUnits.length > 0 && projectUnits.map((projectUnit) => (
                        <div className="custom-dropdown-expand-item cursor-pointer center-content" onClick={() => changeUnitToSelected(projectUnit, unitIndex)}>
                          <p className="main-font-semi-bold font-size-14 font-color-black center-text">
                            {projectUnit.type.title}
                            {' '}
                            {projectUnit.general_number}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </Grid>
              <Grid container>
                <div className="tab-informatie-add relative horizontal-center margin-top-10" tabIndex="4" onBlur={() => setAddDropdownState(false)}>
                  <button type="button" className="height-auto width-auto center-content cursor-pointer" onClick={() => setAddDropdownState(!addDropdownState)}>
                    <p className="main-font-semi-bold font-size-14 font-color-black"> + Toevoegen</p>
                  </button>
                  <div className="custom-dropdown-expand animatable-400 ease-in-out-quart" style={{ opacity: addDropdownState ? 1 : 0, transform: addDropdownState ? 'scaleY(1)' : 'scaleY(0)', width: '125px' }}>
                    {projectUnits && projectUnits.length > 0 && projectUnits.map((unit) => (
                      <div className="custom-dropdown-expand-item cursor-pointer center-content" onClick={() => addUnitToSelected(unit)}>
                        <p className="main-font-semi-bold font-size-14 font-color-black center-text">
                          {unit.type.title}
                          {' '}
                          {unit.general_number}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <Grid item sm={12} md={7}>
          <Grid container alignItems="center" style = {{zIndex: 9999}}>

            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey">
                Verkoopovereenkomst Datum
              </p>
            </Grid>
            <Grid container item sm={6} md={8} className="padding-left-50">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container className = "relative" alignItems = "center">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={informatieData?.sales_agreement || ''}
                    onChange={(date) => updateDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12), 'sales_agreement')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <p className = "main-font-semi-bold font-size-14 font-color-black absolute" style = {{display: informatieData?.sales_agreement ? "none" : "inline"}}>-</p>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container>
            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey">Nummer verkoop</p>
            </Grid>
            <Grid container item sm={6} md={8} className="padding-left-50">
              <input className="background-transparent main-font-semi-bold font-size-14 font-color-black focused-input" value={informatieData?.condition_precedent || ''} onChange={(event) => setInformatieItem(event.target.value, 'condition_precedent')} onBlur = {onBlurEvent} />
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container>
            <Grid container item sm={6} md={3} direction = "column">
              <p className="main-font-regular font-size-14 font-color-grey">Opschortende voorwaarde</p>
              <p className="main-font-regular font-size-14 font-color-grey">Financiering</p>
            </Grid>
            <Grid container item sm={6} md={8} className="padding-left-50" alignItems = "center">
              <CustomDropdown size = "small" dropdownData = {[{value: 'Nee'}, {value: 'Ja'}]} onClick = {(value) => setInformatieItem(value, "sales_number")} initialValue = {informatieData?.sales_number > 0 ? 1 : 0} /> 
              <input type = "number" className = "tab-informatie-number background-tranparent main-font-semi-bold font-size-14 font-color-black margin-left-50 focused-input" style = {{width: '30px'}} value = {informatieData?.sales_number || 0} onChange = {event => setInformatieItem(event.target.value, "sales_number")} onBlur = {onBlurEvent} />
              <p className = "main-font-semi-bold font-size-14 font-color-black">dagen</p>
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container>
            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey">Einde OV </p>
            </Grid>
            <Grid container item sm={6} md={8} className="padding-left-50">
              <p className=" main-font-semi-bold font-size-14 font-color-black">{calculateEinde()}</p>
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container>
            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey">Termijn voor tekenen akte</p>
            </Grid>
            <Grid container item sm={6} md={8} className="padding-left-50" style = {{zIndex: 999}}>
              <CustomDropdown dropdownData = {[{value: '1 maand'}, {value: '2 maanden'}, {value: '3 maanden'}, {value: '4 maanden'}, {value: '5 maanden'}, {value: '6 maanden'}]} onClick = {(value) => updateDropdown(value, "deed_month")} initialValue = {informatieData?.deed_month || 0} /> 
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container>
            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey">Uiterste datum akte</p>
            </Grid>
            <Grid container item sm={6} md={8} className="padding-left-50">
              <p className=" main-font-semi-bold font-size-14 font-color-black">{calculateDeed()}</p>
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container alignItems="center">
            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey"> Notificatie aan bank </p>
            </Grid>
            <Grid container item sm={6} md={8} className="padding-left-50">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container className = "relative">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={informatieData?.bank_notification || ''}
                    onChange={(date) => updateDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12), 'bank_notification')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <p className = "main-font-semi-bold font-size-14 font-color-black absolute" style = {{display: informatieData?.bank_notification ? "none" : "inline"}}>-</p>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container alignItems="center">
            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey"> Notificatie aan notaris  </p>
            </Grid>
            <Grid container item sm={6} md={8} className="padding-left-50">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container className = "relative">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={informatieData?.notary_notification || ''}
                    onChange={(date) => updateDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12), 'notary_notification')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <p className = "main-font-semi-bold font-size-14 font-color-black absolute" style = {{display: informatieData?.notary_notification ? "none" : "inline"}}>-</p>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container alignItems="center">
            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey"> Akte datum   </p>
            </Grid>
            <Grid container item sm={6} md={8} className="padding-left-50">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container className = "relative">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={informatieData?.deed_date || ''}
                    onChange={(date) => updateDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12), 'deed_date')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <p className = "main-font-semi-bold font-size-14 font-color-black absolute" style = {{display: informatieData?.deed_date ? "none" : "inline"}}>-</p>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <SpaceRow top="15" />
          <Grid container alignItems="center">
            <Grid container item sm={6} md={3}>
              <p className="main-font-regular font-size-14 font-color-grey">  Garantie wet Breyne   </p>
            </Grid>
            <Grid container item sm={6} md={8} className="padding-left-50">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container className = "relative">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={informatieData?.warranty_act || ''}
                    onChange={(date) => updateDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12), 'warranty_act')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <p className = "main-font-semi-bold font-size-14 font-color-black absolute" style = {{display: informatieData?.warranty_act ? "none" : "inline"}}>-</p>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SpaceRow top="15" />
    </div>
  );
});

export default TabContentInformatie;
