import React from 'react';
import {
  TableCell, TableRow, TableContainer, Table,
} from '@material-ui/core';

import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: '100%',
  },
}));
export const TaCell = withStyles(() => ({
  head: {
    backgroundColor: '#f2f2f2',
    color: 'var(--darkBleu)',
    padding: '0.55em 5px !important;',
    paddingLeft: '15px',
    border: '1px solid rgb(223, 223, 222)',
    letterSpacing: '0px',
    '&.last': {
      backgroundColor: '#fff',
      border: '0px',
    },
    '& img': {
      top: '3px',
      left: '-5px',
      position: 'relative',
      paddingLeft: '5px',
    },
  },
  body: {
    border: '1px solid rgb(223, 223, 222)',
    padding: '0.55em 5px !important;',
    fontSize: 14,
  },
}))(TableCell);

export const TRow = withStyles(() => ({
  root: {
    '& td.last': {
      backgroundColor: '#fff',
      border: '0px',
    },
    '&:hover img': {
      visibility: 'visible !important',
    },
  },
}))(TableRow);

export const TCell = ({
  children,
  style,
  colSpan,
  className,
}) => {
  return (
    <TaCell style={style} colSpan={colSpan} className={className}>
      {children}
    </TaCell>
  );
};

export const TableCustom = ({ children, id }) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table className={classes.table} id={id}>
        {children}
      </Table>
    </TableContainer>
  );
};
