import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Select from '../../../components/select/select';

const salesOptions = [{
  lable: 'Ja',
  value: '1',
}, {
  lable: 'Nee',
  value: '2',
}];

const AddLinkedProspect = ({
  isAdding,
  userTitles,
  handleAddNewProspect,
  selectedUnitProspects,
  toggleShowNewProspectForm,
}) => {
  const [payload, setPayload] = useState({
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    street: '',
    city: '',
    comments: '',
  });

  const handleOnChange = (e) => {
    const {
      value,
      name,
    } = e.target;
    const updatedPayload = {
      ...payload,
      [name]: value,
    };
    setPayload(updatedPayload);
  };

  const handleSubmit = () => {
    const reqPayload = selectedUnitProspects[0];
    reqPayload.childs.push({
      user: {
        title_id: payload.title || userTitles[0].value,
        parent: [],
        type: [
          {
            id: 3,
          },
        ],
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phoneNumber,
        street: payload.street,
        zip: payload.zip || 0,
        city: payload.city || 0,
        comments: payload.comments || '',
      },
    });
    toggleShowNewProspectForm(false);
    handleAddNewProspect(reqPayload);
  };

  return (
    <>
      <Grid
        container
        style={{
          paddingBottom: 25,
          marginTop: 25,
          borderBottom: '1px solid #45545D',
        }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Aanspreking
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={9} style={{ margin: '-4px 0 10px' }}>
              <Select
                style={{ width: 150 }}
                options={userTitles}
                name="title"
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Voornaam
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={9} style={{ margin: '-4px 0 10px' }}>
              <input
                id="firstName"
                name="firstName"
                type="text"
                value={payload.firstName}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 3,
                  padding: 5,
                  height: 30,
                  width: 175,
                  marginBottom: 5,
                }}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Naam
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={9} style={{ margin: '-4px 0 10px' }}>
              <input
                id="lastName"
                name="lastName"
                type="text"
                value={payload.lastName}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 3,
                  padding: 5,
                  height: 30,
                  width: 175,
                  marginBottom: 5,
                }}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                E-mailadres
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={9} style={{ margin: '-4px 0 10px' }}>
              <input
                id="email"
                name="email"
                type="email"
                value={payload.email}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 3,
                  padding: 5,
                  height: 18,
                  width: 163,
                  marginBottom: 5,
                }}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Telefoon
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={9} style={{ margin: '-4px 0 10px' }}>
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                value={payload.phoneNumber}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 3,
                  padding: 5,
                  height: 30,
                  width: 175,
                  marginBottom: 5,
                }}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Straat & nr
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={9} style={{ margin: '-4px 0 10px' }}>
              <input
                id="street"
                name="street"
                type="text"
                value={payload.street}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 3,
                  padding: 5,
                  height: 30,
                  width: 175,
                  marginBottom: 5,
                }}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Postcode & Woonpl.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={9} style={{ margin: '-4px 0 10px' }}>
              <input
                id="city"
                name="city"
                type="text"
                value={payload.city}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 3,
                  padding: 5,
                  height: 30,
                  width: 175,
                  marginBottom: 5,
                }}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={3} style={{ marginBottom: 5 }}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Commerciële Communicatie
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Select style={{ width: 150 }} options={salesOptions} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                More comments
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={9} style={{ marginBottom: 5 }}>
              <textarea
                onChange={handleOnChange}
                value={payload.comments}
                name="comments"
                id="comments"
                cols="19"
                rows="10"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        style={{
          marginTop: 15,
          opacity: isAdding ? 0.5 : 1,
          background: '#F0F1F2',
          height: 36,
          width: 184,
          fontWeight: 400,
          color: '#45545D',
          boxShadow: 'none',
          textTransform: 'none',
          whiteSpace: 'nowrap',
        }}
        onClick={handleSubmit}
        disabled={isAdding}
      >
        Save
      </Button>

      <Button
        variant="contained"
        style={{
          marginTop: 15,
          marginLeft: 25,
          background: '#F0F1F2',
          opacity: isAdding ? 0.5 : 1,
          height: 36,
          width: 184,
          fontWeight: 400,
          color: '#45545D',
          boxShadow: 'none',
          textTransform: 'none',
        }}
        onClick={() => toggleShowNewProspectForm(false)}
        disabled={isAdding}
      >
        Cancel
      </Button>
    </>
  );
};

AddLinkedProspect.defaultProps = {
  isAdding: false,
};

AddLinkedProspect.propTypes = {
  isAdding: PropTypes.bool,
  userTitles: PropTypes.array.isRequired,
  handleAddNewProspect: PropTypes.func.isRequired,
  selectedUnitProspects: PropTypes.object.isRequired,
  toggleShowNewProspectForm: PropTypes.func.isRequired,
};

export default AddLinkedProspect;
