import { isNumber } from "lodash";
import React, { useState, useEffect } from "react";

import "../projecten.scss";

export function VerkoopprijzenItem(props)  {
    const [ priceTotal, setPriceTotal ] = useState(0);
    const [ pricePerM, setPricePerM ] = useState(0);
    const [ priceGround, setPriceGround ] = useState(0);
    const [ priceConstruction, setPriceConstruction ] = useState(0);
    const [ activeInput, setActiveInput ] = useState(0)

    const { data, handleUpdate, terassPercentage, tuinPercentage } = props;

    useEffect(() => {
        if(data) {
            setPriceTotal(data?.price_total);
            setPricePerM(data?.price_m);
            setPriceGround(data?.price_ground);
            setPriceConstruction(data?.price_construction);
        }
    }, [data])

    const handleBlur = () => {
        const updatedData = {
            price_total: priceTotal,
            price_m: pricePerM,
            price_ground: priceGround,
            price_construction: priceConstruction,
        }

        handleUpdate(updatedData, data.id)
        setActiveInput(0)
    }

    const resetField = (event, inputType) => {
        
        
        if(inputType) {
            setActiveInput(inputType)
        }
    }

    const calculatePond = () => {
        let terassValue = data.surface_terrace;
        let tuinValue = data.surface_garden_terrace;
        terassValue = terassValue * (terassPercentage/100);
        tuinValue = tuinValue * (tuinPercentage/100);
        return data.surface_brut_m + terassValue + tuinValue;
    }

    const calculatePrijs = () => {
        const pond = calculatePond();
        const totalPrijs = (priceTotal/pond).toFixed(2);
        return (totalPrijs === "NaN" || totalPrijs === "Infinity") ? 0 : totalPrijs;
    }

    const changeMetricsAndFormat = value => {
        if(value !== null && value !== undefined && isNumber(parseFloat(value))) {
            let newValue = value.toString().split(".");
            newValue[0] = [...newValue[0]].reverse().join("");
            if(newValue[0].length > 3) {
                if(newValue[0].length % 3 !== 0 ) {
                    newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                }
                else {
                    newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                    newValue[0] = newValue[0].slice(0, newValue[0].length - 1)
                }
            }
            newValue[0] = [...newValue[0]].reverse().join("");
            return `${newValue[0] || "0"},${newValue[1] ||  "00"}`;
        }
        else {
            return "-";
        }
    }

    return(
        <div className = "projecten-grid-container-three fade-in-translate">
            <div className = "projecten-grid-container-border-half width-auto">
                <div className = "projecten-grid-row-border">
                    <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <div className = "flex-row width-auto relative horizontal-end">
                            <p className = "main-font-regular font-size-14 font-color-blue-grey" >{changeMetricsAndFormat(calculatePrijs())}</p>
                        </div>
                    </div>
                    <div className = "projecten-grid-box-eight vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <div className = "flex-row width-auto relative">
                            <p className = "main-font-regular font-size-14 font-color-blue-grey width-auto center-text" style = {{opacity: activeInput === 1 ? 0 : 1}}>{changeMetricsAndFormat(priceTotal)}</p>
                            <input className = "main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text input-no-arrows absolute" type = "number" name = "price_total" value = {priceTotal  || ""} onChange = {event => setPriceTotal(event.target.value)} onBlur = {handleBlur} onClick = {event => resetField(event, 1)} style = {{opacity: activeInput === 1 ? 1 : 0}} />
                        </div>
                    </div>
                    <div className = "projecten-grid-box-nine vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <div className = "flex-row width-auto relative">
                            <p className = "main-font-regular font-size-14 font-color-blue-grey width-auto center-text" style = {{opacity: activeInput === 3 ? 0 : 1}}>{changeMetricsAndFormat(priceGround)}</p>
                            <input className = "main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text input-no-arrows absolute" type = "number" name = "price_ground" value = {priceGround  || ""} onChange = {event => setPriceGround(event.target.value)} onBlur = {handleBlur} onClick = {event => resetField(event, 3)} style = {{opacity: activeInput === 3 ? 1 : 0}} />
                        </div>
                    </div>
                    <div className = "projecten-grid-box-ten vertical-center">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <div className = "flex-row width-auto relative">
                            <p className = "main-font-regular font-size-14 font-color-blue-grey width-auto center-text" >{changeMetricsAndFormat((priceTotal - priceGround).toFixed(2))}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function VerkoopprijzenTotal(props) {
    const { data, projectData } = props;

    const getTotal = (key) => {
        if(data) {
            let total = 0;
            data.forEach(unit => {
                total = total + (unit[key] || 0);
            })
            return total.toFixed(2);
        }
        else {
            return 0;
        }
    }

    const getTotalDiscount = () => {
        if(data) {
            let totalContractPrice = 0;
            let totalPrice = 0;
            data.forEach(unit => {
                if(unit.price_contract_total) {
                    totalContractPrice += unit.price_contract_total;
                }
                if(unit.price_total) {
                    totalPrice += unit.price_total;
                }
            })
            return (totalContractPrice - totalPrice).toFixed(2);
        }
        else {
            return 0;
        }
    }

    const calculatePrijsTotal = () => {
        let totalPrijs = 0;
        data.forEach(unit => {
            if(isNumber(unit.surface_terrace) && isNumber(unit.surface_garden_terrace) && isNumber(unit.surface_brut_m) && isNumber(unit?.price_total)) {
                let terassTotal = (unit.surface_terrace * (projectData.terass/100));
                let tuinTotal = (unit.surface_garden_terrace * (projectData.tuin/100));
                let totalBrut = unit.surface_brut_m;
                let totalPond = (!isNaN(totalBrut) ? totalBrut : 0)  + (!isNaN(terassTotal) ? terassTotal : 0) + (!isNaN(tuinTotal) ? tuinTotal : 0);
                let totalCombined = unit.price_total/totalPond;
                if(!isNaN(totalCombined)) {
                    totalPrijs += totalCombined;
                }
            }
        })
        return typeof totalPrijs === "number" ? totalPrijs.toFixed(2) : 0;
    }

    const changeMetricsAndFormat = value => {
        if(value !== null && value !== undefined && isNumber(parseFloat(value))) {
            let newValue = value.toString().split(".");
            newValue[0] = [...newValue[0]].reverse().join("");
            if(newValue[0].length > 3) {
                if(newValue[0].length % 3 !== 0 ) {
                    newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                }
                else {
                    newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                    newValue[0] = newValue[0].slice(0, newValue[0].length - 1)
                }
            }
            newValue[0] = [...newValue[0]].reverse().join("");
            return `${newValue[0] || "0"},${newValue[1] ||  "00"}`;
        }
        else {
            return "-";
        }
    }

    return(
        <div className = "projecten-grid-container-three fade-in-translate">
            <div className = "projecten-grid-total-container flex-row projecten-grid-container-border">
                <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right horizontal-between for-height-over-flow">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <div className = "flex-row">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(calculatePrijsTotal())}</p>
                    </div>
                </div>
                <div className = "projecten-grid-box-eight vertical-center projecten-grid-border-right horizontal-between for-height-over-flow">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <div className = "flex-row">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(getTotal('price_total'))}</p>
                    </div>
                </div>
                <div className = "projecten-grid-box-nine vertical-center projecten-grid-border-right horizontal-between for-height-over-flow">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <div className = "flex-row">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(getTotal('price_ground'))}</p>
                    </div>
                </div>
                <div className = "projecten-grid-box-ten vertical-center horizontal-between for-height-over-flow">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <div className = "flex-row">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat((getTotal('price_total') - getTotal('price_ground')).toFixed(2))}</p>
                    </div>
                </div>
            </div>
            <div className = "projecten-grid-total-container flex-row" style = {{marginTop: '0px'}}>
                <div className = "projecten-grid-box-four vertical-center  horizontal-between for-height-over-flow" />
                <div className = "projecten-grid-box-eight vertical-center projecten-grid-total horizontal-between for-height-over-flow" style = {{borderTop: 'none', width: '153px'}}>
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <div className = "flex-row">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(getTotalDiscount())}</p>
                    </div>
                </div>
            </div>
            <div className = "projecten-grid-total-container flex-row" style = {{marginTop: '0px'}}>
                <div className = "projecten-grid-box-four vertical-center  horizontal-between for-height-over-flow" />
                <div className = "projecten-grid-box-eight vertical-center projecten-grid-total horizontal-between for-height-over-flow" style = {{borderTop: 'none', width: '153px'}}>
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <div className = "flex-row">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p>
                        <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat((parseFloat(getTotal('price_total')) + parseFloat(getTotalDiscount())).toFixed(2))}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function Verkoopprijzen() {

    return(
        <div className = "projecten-grid-container-three fade-in-translate">
            <div className = "projecten-grid-container-border width-auto">
                <div className = "projecten-grid-header-box center-content">
                    <p className = "main-font-semi-bold font-size-14 font-color-blue-grey">Verkoopprijs</p>
                </div>
                <div className = "projecten-grid-row-border background-color-light-grey">
                    <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Prijs per m2</p>
                    </div>
                    <div className = "projecten-grid-box-eight vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Toegepaste prijzen</p>
                    </div>
                    <div className = "projecten-grid-box-nine vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Grondwaarde</p>
                    </div>
                    <div className = "projecten-grid-box-ten vertical-center">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Constr. waarde</p>
                    </div>
                </div>
            </div>
        </div>
    )
}