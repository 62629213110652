import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, NativeSelect, TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import './select.scss';

export default function Select({
  options,
  selected,
  style,
  onChange,
  name,
  onBlur,
  className,
  value,
  disabled
}) {
  return (
    <FormControl disabled = {disabled}>
      <NativeSelect
        style={style}
        name={name}
        onBlur={onBlur}
        className={className}
        value = {value}
        IconComponent={() => disabled ? (
          <img
            src={require('../../assets/images/icons/arrow_down_grey.svg')}
            alt="icon"
            style={{
              height: '12px',
              margin: ' -15px',
              marginTop: ' -10px',
            }}
          />
          ) : (
            <img
              src={require('../../assets/images/icons/send_white_copy.svg')}
              alt="icon"
              style={{
                height: '12px',
                margin: ' -15px',
                marginTop: ' -10px',
              }}
            />
          )}
        onChange={onChange}
      >
        {options.map((opt) => (
          <option
            selected={selected === opt.value}
            value={opt.value}
            key={opt.value}
          >
            {opt.lable}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}

Select.defaultProps = {
  selected: '',
  options: [],
  style: {},
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
  options: PropTypes.array,
  style: PropTypes.object,
};

const Img_Icon = () => {
  return (
    <img
      src={require('../../assets/images/icons/send_white_copy.svg')}
      alt="icon"
      style={{
        height: '12px',
        margin: ' -10px',
        marginTop: ' -10px',
      }}
    />
  );
};

const InputField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(223, 223, 222)',
      },
    },
  },
}))(TextField);

export function SelectOptionOutline({
  options,
  selected,
  startAdornment,
  style,
}) {
  const styles = {
    // width: 180,
  };
  return (

    <InputField
      select
      SelectProps={{
        native: true,
        IconComponent: Img_Icon,
        style: styles,
      }}
      InputProps={{
        startAdornment,

      }}
      variant="outlined"
      style={style}
    >
      {options.map((opt) => (
        <option
          selected={selected === opt.value}
          value={opt.value}
          key={opt.value}
        >
          {opt.lable}
        </option>
      ))}
    </InputField>

  );
}
