import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ErrorModal from '../../components/error_modal/error_modal';
import AppBarComponent from '../../components/appbar/appbar';
import MenuStrip from '../../components/menu_strip/menu_strip';
import Generaal, { GeneraalItem, GeneraalTotal } from './views/generaal';
import Oppervlaktegegevens, {
  OppervlaktegegevensItem,
  OppervlaktegegevensTotal,
} from './views/oppervlaktegegevens';
import Verkoopprijzen, {
  VerkoopprijzenItem,
  VerkoopprijzenTotal,
} from './views/verkoopprijzen';
import Verkoopovereenkomsten, {
  VerkoopovereenkomstenItem,
  VerkoopovereenkomstenTotal,
} from './views/verkoopovereenkomsten';
import Akten, { AktenItem, AktenTotal } from './views/akten';
import Dropdown from '../../components/dropdown/dropdown';
import CustomSwitch from '../../components/custom_switch/custom_switch';
import DropdownMenu from '../../components/DropdownMenu';

import { SpaceRow } from '../../components/space/space';
import {
  getAllProject,
  updateProjectUnit,
  createNewUnit,
  getAllProjectUnitTypes,
  getUserByType,
  getUnsoldUnits,
  deleteProjectUnit,
  getDocumentCategoryByProject,
} from '../../common/api/routes';

import './projecten.scss';
import GeneralTable, { GeneralTotal } from './updatedViews/General';
import NotarisTable, { NotarisTableTotal } from './updatedViews/Notaris';
import VerkoopprijzenTable, { VerkoopprijzenTableTotal } from './updatedViews/Verkoopprijzen';
import OppervlaktegegevenTable, { OppervlaktegegevensTableTotal } from './updatedViews/Oppervlaktegegevens';
import VerkoopovereenkomstenTable, {
  VerkoopovereenkomstenTableTotal,
  VerkoopovereenkomstenTableTotal2,
} from './updatedViews/Verkoopovereenkomsten';

const drawerWidth = 238;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const allesData = [
  {
    id: 0,
    value: false,
    name: 'Algemeen',
  },
  {
    id: 1,
    value: false,
    name: 'Oppervlaktegegevens',
  },
  {
    id: 2,
    value: false,
    name: 'Verkoopprijs',
  },
  {
    id: 3,
    value: false,
    name: 'Verkoopprijs Verkoopovereenkomst',
  },
  {
    id: 4,
    value: false,
    name: 'Verkoopovereenkomsten & Akten Notaris',
  },
];

function ProjectenView(props) {
  const [selectedBuilding, setSelectedBuilding] = useState(0);
  const [unitDropdownState, setUnitDropdownState] = useState(false);
  const [unitDropdownMouseState, setUnitDropdownMouseState] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [originalProjectData, setOriginalProjectData] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [projectUnitTypes, setProjectUnitTypes] = useState([]);
  const [projectUnitTypesFilter, setProjectUnitTypesFilter] = useState([]);
  const [tableFilter, setTableFilter] = useState(allesData);
  const [manualUpdate, setManualUpdate] = useState(false);
  const [documentCategories, setDocumentCategories] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [forceStateUpdate, setForceStateUpdate] = useState(false);
  const [mouseRowState, setMouseRowState] = useState(null);
  const [searchState, setSearchState] = useState('');

  const [brutState, setBrutState] = useState('100');
  const [terassState, setTerassState] = useState('50');
  const [tuinState, setTuinState] = useState('2,5');

  const { projectTitle, setProjectTitle } = props;
  const location = useLocation();
  const searchRef = useRef(0);
  const errorModalRef = useRef(0);

  useEffect(() => {
    axios.get(getAllProject())
      .then((response) => {
        const cleanup = response.data.data;
        // remove data that doesn't have anything to display
        getProjectUnits(cleanup);
      })
      .catch((error) => {
        console.log(error);
      });

    axios.get(getAllProjectUnitTypes)
      .then((response) => {
        let copyData = response.data.data
        for (let x in copyData) {
          copyData[x].name = copyData[x].title
        }
        copyData.unshift({name:"Alle Types"})
        setProjectUnitTypes(response.data.data);
        setProjectUnitTypesFilter(copyData)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [manualUpdate, searchState]);

  useEffect(() => {
    if (projectData?.length > 0) {
      axios.get(getDocumentCategoryByProject(projectData[selectedBuilding].id))
        .then((response) => {
          setDocumentCategories(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [projectData]);

  useEffect(() => {
    if (originalProjectData && originalProjectData.length > 0) {
      if (searchState === '') {
        getProjectUnits();
      } else {
        searchRef.current.value = '';
        setSearchState('');
      }
      setProjectTitle(projectData[selectedBuilding].name);
    }
  }, [selectedBuilding]);

  useEffect(() => {
    axios.get(getUserByType(4))
      .then((response) => {
        setSellerList(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (projectData && projectData.length > 0 && location.search.length > 0 && !isLoaded) {
      const searchParam = location.search.substring(1, location.search.length);
      const searchedBuilding = projectData.findIndex((project) => project.id == searchParam);
      setProjectTitle(projectData[searchedBuilding].name);
      setSelectedBuilding(searchedBuilding);
      setIsLoaded(true);
    }
  }, [location.search, projectData]);

  useEffect(() => {
    if (originalProjectData && originalProjectData.length > 0) {
      const originalProjectDataCopy = JSON.parse(JSON.stringify(originalProjectData));
      const newUnits = [];
      originalProjectData[selectedBuilding].project_units.forEach((unit, unitIndex) => {

        if(searchState){
          if (unit?.type?.title.toLowerCase() === projectUnitTypes[searchState].title.toLowerCase()) {
            newUnits.push(unit);
          }
          //  else if (unit.buyer) {
          //   const username = `${unit?.buyer?.user?.firstName} ${unit?.buyer?.user?.lastName}`;
          //   if (username.toLowerCase().includes(projectUnitTypes[searchState].title.toLowerCase())) {
          //     newUnits.push(unit);
          //   }
          // }
        }else{
          newUnits.push(unit);
        }

      });
      originalProjectDataCopy[selectedBuilding].project_units = newUnits;
      setProjectData(originalProjectDataCopy);
    }
  }, [originalProjectData]);

  const searchProjects = debounce(() => {
    setSearchState(searchRef.current.value);
  }, 2000);

  function getProjectUnits(updatedData) {
    // we have two data sources of units, unsold units and sold units
    // sold units are grabbed from the buyer project since we need the buyer project data
    axios.get(getUnsoldUnits(updatedData ? updatedData[selectedBuilding]?.id : originalProjectData[selectedBuilding]?.id))
      .then((response) => {
        // now we reassemble the data and replace project_units
        const projectDataCopy = JSON.parse(JSON.stringify(updatedData || originalProjectData));
        const newUnits = [];
        projectDataCopy[selectedBuilding].user_project.forEach((project) => {
          project.user_units.forEach((unit) => {
            if (unit?.units) {
              const unitObject = { ...unit.units };
              unitObject.user = project.user;
              const simplifiedProject = { ...project };
              delete simplifiedProject.user_units;
              unitObject.buyer = simplifiedProject;
              unitObject.sold = true;
              newUnits.push(unitObject);
            }
          });
        });
        response.data.data.forEach((unit) => {
          newUnits.push(unit);
        });
        projectDataCopy[selectedBuilding].project_units = getOrderedUnits(newUnits);
        // setProjectData(projectDataCopy);
        setOriginalProjectData(projectDataCopy);
      })
      .catch((error) => console.log(error));
  }

  function getOrderedUnits(units) {
    units.sort((a, b) => (a.type.id - b.type.id));
    // to place new unit(by create New) at end of the list
    let orderedUnits = [
      ...units.filter(unit => unit.general_type),
      ...units.filter(unit => !unit.general_type),
    ];
    return orderedUnits;
  }

  function handleProjectUnitUpdate(data, unitId) {
    axios.put(updateProjectUnit(unitId), data)
      .then((response) => {
        setManualUpdate(!manualUpdate);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleNewUnitCreate() {
    // if a new unit is created then it is automatically regarded as unsold
    if (projectData[selectedBuilding]) {
      const defaultData = {
        project_id: projectData[selectedBuilding].id,
        general_type: null,
        general_number: '',
        precad: '',
        general_house_number: '',
        contract_buyer_id: 0,
        contract_seller_id: 0,
        contract_date: null,
      };

      axios.post(createNewUnit(), defaultData)
        .then((response) => {
          setManualUpdate(!manualUpdate);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const deleteUnit = (unitId) => {
    errorModalRef.current.openDeleteModal('Weet u zeker dat u deze entiteit wenst te verwijderen?', 'Bevestigen', unitId);
  };

  const unitDelete = (unitId) => {
    axios.delete(deleteProjectUnit(unitId))
      .then((response) => {
        console.log(response.data);
        setManualUpdate(!manualUpdate);
      })
      .catch((error) => console.log(error));
  };

  const handleDragEnd = (result) => {
    const tableFilterCopy = [...tableFilter];
    const item = tableFilterCopy[result.source.index];
    tableFilterCopy[result.source.index] = tableFilterCopy[result.destination.index];
    tableFilterCopy[result.destination.index] = item;
    setTableFilter(tableFilterCopy);
  };

  const RenderTable = (type, id, value, data) => {
    if (type === 1) {
      if (!value) {
        if (id === 0) {
          return (<Generaal />);
        } if (id === 1) {
          return (
            <Oppervlaktegegevens
              brutState={brutState}
              setBrutState={setBrutState}
              terassState={terassState}
              setTerassState={setTerassState}
              tuinState={tuinState}
              setTuinState={setTuinState}
              projectData={data}
              update={() => setManualUpdate(!manualUpdate)}
            />
          );
        } if (id === 2) {
          return (<Verkoopprijzen />);
        } if (id === 3) {
          return (<Verkoopovereenkomsten />);
        } if (id === 4) {
          return (<Akten />);
        }
      }
    } else if (type === 2) {
      if (!value) {
        if (id === 0) {
          return (
            <GeneraalItem
              data={data}
              handleUpdate={handleProjectUnitUpdate}
              projectUnitTypes={projectUnitTypes}
            />
          );
        } if (id === 1) {
          return (
            <OppervlaktegegevensItem
              data={data}
              handleUpdate={handleProjectUnitUpdate}
              terassPercentage={projectData[selectedBuilding].terass}
              tuinPercentage={projectData[selectedBuilding].tuin}
            />
          );
        } if (id === 2) {
          return (
            <VerkoopprijzenItem
              data={data}
              handleUpdate={handleProjectUnitUpdate}
              terassPercentage={projectData[selectedBuilding].terass}
              tuinPercentage={projectData[selectedBuilding].tuin}
            />
          );
        } if (id === 3) {
          return (
            <VerkoopovereenkomstenItem
              data={data}
              handleUpdate={handleProjectUnitUpdate}
              total={getTotal('price_contract_total')}
            />
          );
        } if (id === 4) {
          return (
            <AktenItem
              data={data}
              handleUpdate={handleProjectUnitUpdate}
              sellerList={sellerList}
            />
          );
        }
      }
    } else if (type === 3) {
      if (!value) {
        if (id === 0) {
          return (
            <GeneraalTotal
              data={projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || []}
              projectData={projectData[selectedBuilding]}
              documentCategories={documentCategories}
            />
          );
        } if (id === 1) {
          return (
            <OppervlaktegegevensTotal
              data={projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || []}
              projectData={projectData[selectedBuilding]}
              documentCategories={documentCategories}
            />
          );
        } if (id === 2) {
          return (
            <VerkoopprijzenTotal
              data={projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || []}
              projectData={projectData[selectedBuilding]}
            />
          );
        } if (id === 3) {
          return (
            <VerkoopovereenkomstenTotal
              data={projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || []}
            />
          );
        } if (id === 4) {
          return (
            <AktenTotal
              data={projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || []}
            />
          );
        }
      }
    }
  };

  const getTotal = (key) => {
    const data = projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || [];

    if (data?.length) {
      let total = 0;
      data.forEach((unit) => {
        total += (unit[key] || 0);
      });
      return total;
    }
    return 0;
  };

  const getTotalDiscount = () => {
    const data = projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || [];
    if (data) {
      let totalContractPrice = 0;
      let totalPrice = 0;
      data.forEach((unit) => {
        if (unit.price_contract_total) {
          totalContractPrice += unit.price_contract_total;
        }
        if (unit.price_total) {
          totalPrice += unit.price_total;
        }
      });
      return (totalContractPrice - totalPrice).toFixed(2);
    }

    return 0;
  };

  const changeMetricsAndFormat = (value) => {
    const newValue = value.toString().split('.');
    newValue[0] = [...newValue[0]].reverse().join('');
    if (newValue[0].length > 3) {
      if (newValue[0].length % 3 !== 0) {
        newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
      } else {
        newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
        newValue[0] = newValue[0].slice(0, newValue[0].length - 1);
      }
    }
    newValue[0] = [...newValue[0]].reverse().join('');
    return `${newValue[0] || '0'}.${newValue[1] || '00'}`;
  };

  return (
    <div className="projecten-container">
      <Grid container direction="column" wrap="nowrap" className="height-95-p">
        <Grid container justify="space-between">
          <div className="flex-row" style={{ zIndex: 9999999 }}>
            <Dropdown
              data={projectData || []}
              selected={selectedBuilding}
              setSelected={setSelectedBuilding}
              marginRight={15}
            />
            <div
              className="message-panel-dropdown-container margin-right-15"
              tabIndex="1"
              onBlur={() => {
                if (!unitDropdownMouseState) setUnitDropdownState(false);
              }}
              onMouseOver={() => setUnitDropdownMouseState(true)}
              onMouseLeave={() => setUnitDropdownMouseState(false)}
            >
              <div
                className="message-panel-dropdown vertical-center"
                onClick={() => setUnitDropdownState(!unitDropdownState)}
              >
                <img
                  src={require('../../assets/images/icons/list_darkbleu.svg')}
                  alt="building"
                  className="message-panel-dropdown-building-icon margin-right-14"
                />
                <p
                  className="width-auto main-font-regular font-size-12 font-color-black"
                >
                  Alles
                </p>
                <img
                  src={require('../../assets/images/icons/arrow_down.svg')}
                  alt="arrow"
                  className="message-panel-dropdown-arrow-icon margin-left-14"
                />
              </div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <div
                  className="message-panel-dropdown-expand flex-column vertical-center margin-top-5 animatable-400 ease-in-out-quart"
                  style={{
                    height: `${allesData.length * 36}px`,
                    transform: unitDropdownState ? 'scaleY(1)' : 'scaleY(0)',
                    opacity: unitDropdownState ? 1 : 0,
                  }}
                >
                  <Droppable droppableId="projecten-dragdrop">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="relative"
                      >
                        {tableFilter.map((item, index) => (
                          <Draggable
                            key={item.id.toString()}
                            draggableId={item.id.toString()}
                            index={index}
                          >
                            {(provided2) => (
                              <div
                                className="projecten-alles-row"
                                ref={provided2.innerRef}
                                {...provided2.draggableProps}
                                {...provided2.dragHandleProps}
                              >
                                <div
                                  className="message-panel-dropdown-expand-item flex-row vertical-center horizontal-between"
                                >
                                  <div className="flex-row vertical-center">
                                    <img
                                      src={require('../../assets/images/icons/list_group.svg')}
                                      alt="building"
                                      className="message-panel-dropdown-building-icon margin-right-14"
                                    />
                                    <p
                                      className="width-auto main-font-regular font-size-12 font-color-black"
                                    >
                                      {item.name}
                                    </p>
                                  </div>
                                  <div className="flex-row vertical-center">
                                    <CustomSwitch
                                      state={tableFilter}
                                      setState={setTableFilter}
                                      value={item}
                                      index={index}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>

            <Dropdown
              data={projectUnitTypesFilter || []}
              // selected={}
              setSelected={setSearchState}
              marginRight={15}
              icon={require('../../assets/images/icons/settings_darkbleu.svg')}
            />

            {/* <div
              className="document-panel-input-container vertical-center flex-row"
            >
              <div
                className="document-panel-input-icon center-content flex-shrink-disable"
              >
                <img
                  className="height-50-p width-50-p object-fit-contain"
                  src={require('../../assets/images/icons/search_darkbleu.svg')}
                  alt="search"
                />
              </div>
              <input
                className="document-panel-input main-font-regular font-size-14 font-color-black"
                placeholder="Zoeken"
                ref={searchRef}
                onChange={searchProjects}
              />
            </div> */}
          </div>
          <div className="flex-row">
            <button
              type="button"
              className="projecten-button-blue center-content margin-right-12"
              onClick={handleNewUnitCreate}
            >
              <p className="main-font-regular font-size-14 font-color-white">
                +
                Entiteit
              </p>
            </button>
            <button
              type="button"
              className="projecten-button-grey center-content"
            >
              <p
                className="main-font-regular font-size-14 font-color-blue-grey"
              >
                <ReactHTMLTableToExcel
                  className="download-table-xls-button"
                  id="projects-list-table-id"
                  table="table-to-download"
                  buttonText="Download .xls"
                  filename="ProjectUnitsList"
                  sheet="Project Units List"
                />
              </p>
            </button>
          </div>
        </Grid>
        <SpaceRow top="53" />
        <Grid container>
          <div className="projecten-sub-container">
            <div className="projecten-grid">
              <div className="flex-row">
                <table id="table-to-download" style={{ display: 'none' }}>
                  <tr>
                    <th style={{ background: '#f0f1f2' }} colSpan={7}>Algemeen</th>
                    <th style={{ background: '#f0f1f2' }}>&nbsp;</th>

                    <th style={{ background: '#f0f1f2' }} colSpan={5}>Oppervlaktegegevens</th>
                    <td style={{ background: '#f0f1f2' }}>&nbsp;</td>

                    <th style={{ background: '#f0f1f2' }} colSpan={4}>Verkoopprijs</th>
                    <td style={{ background: '#f0f1f2' }}>&nbsp;</td>

                    <th style={{ background: '#f0f1f2' }} colSpan={4}>Verkoopprijs Verkoopovereenkomst</th>
                    <td style={{ background: '#f0f1f2' }}>&nbsp;</td>

                    <th style={{ background: '#f0f1f2' }} colSpan={6}>Verkoopovereenkomsten & Akten Notaris</th>
                    <td style={{ background: '#f0f1f2' }}>&nbsp;</td>
                  </tr>

                  <tr>
                    <th style={{ background: '#f0f1f2' }}>Type</th>
                    <th style={{ background: '#f0f1f2' }}>Nr.</th>
                    <th style={{ background: '#f0f1f2' }}>Niveau</th>
                    <th style={{ background: '#f0f1f2' }}>Slaapkamers</th>
                    <th style={{ background: '#f0f1f2' }}>Quotiteiten</th>
                    <th style={{ background: '#f0f1f2' }}>Précad</th>
                    <th style={{ background: '#f0f1f2' }}>Huisnummer</th>
                    <th style={{ background: '#f0f1f2' }}>&nbsp;</th>

                    <th style={{ background: '#f0f1f2' }}>Netto m2</th>
                    <th style={{ background: '#f0f1f2' }}>{`Bruto m2 ${brutState}%`}</th>
                    <th style={{ background: '#f0f1f2' }}>{`Terras ${terassState}%`}</th>
                    <th style={{ background: '#f0f1f2' }}>{`Tuin ${tuinState}%`}</th>
                    <th style={{ background: '#f0f1f2' }}>Ponderatie</th>
                    <th style={{ background: '#f0f1f2' }}>&nbsp;</th>

                    <th style={{ background: '#f0f1f2' }}>Prijs per m2</th>
                    <th style={{ background: '#f0f1f2' }}>Toegepaste prijzen</th>
                    <th style={{ background: '#f0f1f2' }}>Grondwaarde</th>
                    <th style={{ background: '#f0f1f2' }}>Constructie waarde</th>
                    <th style={{ background: '#f0f1f2' }}>&nbsp;</th>

                    <th style={{ background: '#f0f1f2' }}>Totaal</th>
                    <th style={{ background: '#f0f1f2' }}>Grondwaarde</th>
                    <th style={{ background: '#f0f1f2' }}>Constructie</th>
                    <th style={{ background: '#f0f1f2' }}>Korting</th>
                    <th style={{ background: '#f0f1f2' }}>&nbsp;</th>

                    <th style={{ background: '#f0f1f2' }}>Aankooper </th>
                    <th style={{ background: '#f0f1f2' }}>Aanbreng</th>
                    <th style={{ background: '#f0f1f2' }}>Verkoopovereenkomst</th>
                    <th style={{ background: '#f0f1f2' }}>Nummer</th>
                    <th style={{ background: '#f0f1f2' }}>Notificatie Bank</th>
                    <th style={{ background: '#f0f1f2' }}>Aktedatum</th>
                    <th style={{ background: '#f0f1f2' }}>&nbsp;</th>
                  </tr>
                  {projectData && projectData.length > 0
                    && projectData[selectedBuilding].project_units
                    && projectData[selectedBuilding].project_units.map((data) => (
                      <tr>
                        <GeneralTable
                          data={data}
                          projectUnitTypes={projectUnitTypes}
                          handleUpdate={handleProjectUnitUpdate}
                        />
                        <td>&nbsp;</td>

                        <OppervlaktegegevenTable
                          data={data}
                          handleUpdate={handleProjectUnitUpdate}
                          terassPercentage={projectData[selectedBuilding]?.terass}
                          tuinPercentage={projectData[selectedBuilding]?.tuin}
                        />
                        <td>&nbsp;</td>

                        <VerkoopprijzenTable
                          data={data}
                          handleUpdate={handleProjectUnitUpdate}
                          projectData={projectData[selectedBuilding]}
                          tuinPercentage={projectData[selectedBuilding].tuin}
                          terassPercentage={projectData[selectedBuilding].terass}
                        />
                        <td>&nbsp;</td>

                        <VerkoopovereenkomstenTable
                          data={data}
                          handleUpdate={handleProjectUnitUpdate}
                        />
                        <td>&nbsp;</td>

                        <NotarisTable
                          data={data}
                          sellerList={sellerList}
                          handleUpdate={handleProjectUnitUpdate}
                        />
                      </tr>
                    ))}

                  <tr>
                    <GeneralTotal
                      data={projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || []}
                      projectData={projectData[selectedBuilding]}
                      documentCategories={documentCategories}
                    />
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>

                    <OppervlaktegegevensTableTotal
                      data={projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || []}
                      projectData={projectData[selectedBuilding]}
                      documentCategories={documentCategories}
                    />
                    <td>&nbsp;</td>

                    <VerkoopprijzenTableTotal
                      data={projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || []}
                      projectData={projectData[selectedBuilding]}
                    />
                    <td>&nbsp;</td>

                    <VerkoopovereenkomstenTableTotal
                      data={projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || []}
                    />
                    <td>&nbsp;</td>

                    <NotarisTableTotal
                      data={projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || []}
                    />

                  </tr>
                  <tr>
                    <td colSpan={15} />
                    <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getTotalDiscount())}`}</td>
                    <td colSpan={3} />
                    <VerkoopovereenkomstenTableTotal2
                      data={projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units || []}
                    />
                  </tr>
                  <tr>
                    <td colSpan={15} />
                    <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat((parseFloat(getTotal('price_total')) + parseFloat(getTotalDiscount())).toFixed(2))}`}</td>
                  </tr>

                </table>
                {tableFilter.map((table) => (
                  RenderTable(1, table.id, table.value, projectData[selectedBuilding])
                ))}
              </div>

              {projectData && projectData.length > 0 && projectData[selectedBuilding].project_units && projectData[selectedBuilding].project_units.map((data, dataIndex) => (
                <div className="flex-row" onMouseOver={() => setMouseRowState(dataIndex)} style={{ zIndex: parseInt(100000 / (dataIndex + 1)) }}>
                  {tableFilter.map((table) => (
                    RenderTable(2, table.id, table.value, data)
                  ))}
                  <DropdownMenu handleDeleteAction={() => deleteUnit(data.id)} showDropdown={!(mouseRowState === dataIndex)} />
                  <div className="margin-right-25" />
                </div>
              ))}
              <div className="flex-row">
                {tableFilter.map((table) => (
                  RenderTable(3, table.id, table.value)
                ))}
              </div>
            </div>
            <SpaceRow top="75" />
          </div>
        </Grid>
        <SpaceRow top="50" />
      </Grid>
      <ErrorModal ref={errorModalRef} onDelete={(id) => unitDelete(id)} />
    </div>
  );
}

export default function Projecten() {
  const [open, setOpen] = useState(true);
  const [projectTitle, setProjectTitle] = useState('');
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBarComponent
        open={open}
        setOpen={setOpen}
        pagename="Berichten"
        title={projectTitle}
        isOnTop
      />
      <MenuStrip open={open} setOpen={setOpen} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} style={{ marginTop: 80 }} />
        <ProjectenView projectTitle={projectTitle} setProjectTitle={setProjectTitle} />
      </main>
    </div>
  );
}
