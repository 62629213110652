import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { SpaceRow } from "../../components/space/space";

import "./tab.eenheid.scss";
import { isNumber } from 'lodash';

function UnitDescription(props) {
  const { title, value, price } = props;

  return(
    <Grid container>
      <Grid container item sm = {6}>
        <p className = "main-font-regular font-size-14 font-color-grey">{title}</p>
      </Grid>
      <Grid container item sm = {6}>
        {price && (
          <p className = "main-font-semi-bold font-size-14 font-color-black">€ &nbsp;</p>
        )}
        <p className = "main-font-semi-bold font-size-14 font-color-black">{value}</p>
      </Grid>
    </Grid>
  )
}

export default function TabContentEenheid(props) {
  const [ selectedUnit, setSelectedUnit ] = useState(0)
  const [ unitList, setUnitList ] = useState([])
  const [ projectData, setProjectData ] = useState({})
  const { unitsData, buyerData } = props;

  useEffect(() => {
    if(buyerData && buyerData.user_units.length > 0) {
      setUnitList(buyerData.user_units);
      setProjectData(buyerData.project);
    }
  }, [buyerData])

  const changeMetricsAndFormat = value => {
      if(value !== null && value !== undefined && isNumber(parseFloat(value))) {
          let newValue = value.toString().split(".");
          let negativeValue = false;
          if(newValue[0].includes("-")) {
            negativeValue = true;
          }
          if(negativeValue) {
            newValue[0] = newValue[0].replace("-", ""); 
          }
          newValue[0] = [...newValue[0]].reverse().join("");
          if(newValue[0].length > 3) {
            if(newValue[0].length % 3 !== 0 ) {
              newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
            }
            else {
              newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
              newValue[0] = newValue[0].slice(0, newValue[0].length - 1)
            }
          }
          newValue[0] = [...newValue[0]].reverse().join("");
          return `${(negativeValue ? "-" : "") + newValue[0] || "0"},${newValue[1] ? newValue[1].substring(0, 2) : "00"}`;
      }
      else {
          return "-";
      }
  }

  const calculatePond = (terrace, tuin, brutM) => {
    let terassValue = terrace;
    let tuinValue = tuin;
    terassValue = terassValue * (projectData?.terass ? parseFloat(projectData.terass)/100 : 0/100);
    tuinValue = tuinValue * (projectData?.tuin ? parseFloat(projectData.tuin)/100 : 0/100);
    return brutM + terassValue + tuinValue;
  }

  const calculatePrijs = priceTotal => {
    const pond = calculatePond(unitList[selectedUnit]?.units?.surface_terrace, unitList[selectedUnit]?.units?.surface_garden_terrace, unitList[selectedUnit]?.units?.surface_brut_m).toFixed(2);
    const totalPrijs = (priceTotal/pond).toFixed(2);
    return (totalPrijs === "NaN" || totalPrijs === "Infinity") ? 0 : totalPrijs;
  }

  return (
    <div className="tab-eenheid-container">
      <Grid container>
        {unitList && unitList.length > 0 && unitList.map((unit, unitIndex) => (
          <div className = "main-font-regular font-size-14 font-color-blue-grey margin-right-50 cursor-pointer animatable-400 ease-in-out-quart" style = {{color: selectedUnit === unitIndex ? 'RGB(46, 160, 180)' : 'RGB(69, 84, 93)'}} onClick = {() => setSelectedUnit(unitIndex)}>{unit.units.type.title} {unit.units.general_number}</div>
        ))}
      </Grid>
      <SpaceRow top = "27" />
      <Grid container>
        <Grid container>
          <div className = "tab-eenheid-box-one margin-right-38 fade-in-translate" style = {{animationDelay: '0s'}}>
            <Grid container>
              <p className = "main-font-semi-bold font-size-16 font-color-blue-grey">Algemeen</p>
            </Grid>
            <SpaceRow top = "17" />
            <Grid container>
              <UnitDescription title = "type" value = {unitList[selectedUnit]?.units.type?.title || ""} />
              <SpaceRow top = "10" />
              <UnitDescription title = "Nr." value = {unitList[selectedUnit]?.units.general_number || ""} />
              <SpaceRow top = "10" />
              <UnitDescription title = "Aantal slpks." value = {unitList[selectedUnit]?.units.general_rooms || ""} />
              <SpaceRow top = "10" />
              <UnitDescription title = "Quotiteiten" value = {unitList[selectedUnit]?.units.general_quotity || ""} />
              <SpaceRow top = "10" />
              <UnitDescription title = "Huisnummer" value = {unitList[selectedUnit]?.units.general_house_number || ""} />
              <SpaceRow top = "10" />
            </Grid>
          </div>
          <div className = "tab-eenheid-box-one fade-in-translate" style = {{animationDelay: '0.1s'}}>
            <Grid container>
              <p className = "main-font-semi-bold font-size-16 font-color-blue-grey">Oppervlaktegegevens</p>
            </Grid>
            <SpaceRow top = "17" />
            <Grid container>
              <UnitDescription title = "Netto m2" value = {changeMetricsAndFormat(unitList[selectedUnit]?.units.surface_net_m)} />
              <SpaceRow top = "10" />
              <UnitDescription title = "Bruto m2" value = {changeMetricsAndFormat(unitList[selectedUnit]?.units.surface_brut_m)} />
              <SpaceRow top = "10" />
              <UnitDescription title = "Tuin/terras" value = {changeMetricsAndFormat(unitList[selectedUnit]?.units.surface_garden_terrace)} />
              <SpaceRow top = "10" />
              <UnitDescription title = "Teras" value = {changeMetricsAndFormat(unitList[selectedUnit]?.units.surface_terrace)} />
              <SpaceRow top = "10" />
              <UnitDescription title = "Ponderatie" value = {changeMetricsAndFormat(calculatePond(unitList[selectedUnit]?.units?.surface_terrace, unitList[selectedUnit]?.units?.surface_garden_terrace, unitList[selectedUnit]?.units?.surface_brut_m).toFixed(2))} />
              <SpaceRow top = "10" />
            </Grid>
          </div>
        </Grid>
        <SpaceRow top = "27" />
        <Grid container>
          <div className = "tab-eenheid-box-two margin-right-38 fade-in-translate" style = {{animationDelay: '0.2s'}}>
            <Grid container>
              <p className = "main-font-semi-bold font-size-16 font-color-blue-grey">Verkoopprijs</p>
            </Grid>
            <SpaceRow top = "17" />
            <Grid container>
              <UnitDescription title = "Prijs per m2" value = {changeMetricsAndFormat(calculatePrijs(unitList[selectedUnit]?.units.price_total))} price />
              <SpaceRow top = "10" />
              <UnitDescription title = "Totaal" value = {changeMetricsAndFormat(unitList[selectedUnit]?.units.price_total)} price />
              <SpaceRow top = "10" />
              <UnitDescription title = "Grondwaarde" value = {changeMetricsAndFormat(unitList[selectedUnit]?.units.price_ground)} price />
              <SpaceRow top = "10" />
              <UnitDescription title = "Constructie waarde" value = {changeMetricsAndFormat((unitList[selectedUnit]?.units.price_total - unitList[selectedUnit]?.units.price_ground).toFixed(2))} price />
              <SpaceRow top = "10" />
            </Grid>
          </div>
          <div className = "tab-eenheid-box-two fade-in-translate" style = {{animationDelay: '0.3s'}}>
            <Grid container>
              <p className = "main-font-semi-bold font-size-16 font-color-blue-grey">Verkoopprijs<br></br>Verkoopovereenkomst</p>
            </Grid>
            <SpaceRow top = "17" />
            <Grid container>
              <UnitDescription title = "Totaal" value = {changeMetricsAndFormat(unitList[selectedUnit]?.units.price_contract_total)} price />
              <SpaceRow top = "10" />
              <UnitDescription title = "Grondwaarde" value = {changeMetricsAndFormat(unitList[selectedUnit]?.units.price_ground)} price />
              <SpaceRow top = "10" />
              <UnitDescription title = "Constructie" value = {!isNaN(unitList[selectedUnit]?.units.price_contract_total - unitList[selectedUnit]?.units.price_ground) ? changeMetricsAndFormat(unitList[selectedUnit]?.units?.price_contract_total - unitList[selectedUnit]?.units?.price_ground) : "-"} price />
              <SpaceRow top = "10" />
              <UnitDescription title = "Korting" value = {!isNaN(unitList[selectedUnit]?.units.price_total - unitList[selectedUnit]?.units.price_contract_total) ? changeMetricsAndFormat(unitList[selectedUnit]?.units?.price_total - unitList[selectedUnit]?.units?.price_contract_total) : "-"} price />
              <SpaceRow top = "10" />
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
