import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import './core.scss';
import '../assets/styles/scss/fonts.css';
import '../assets/styles/scss/globals.css';

// page imports here
import Dashboard from './dashboard/Dashboard.view';
import Settings from './settings/Settings.view';
import Projects from './dashboard/projects/Projects.view';
import MessagePanel from './message_panel/message_panel';
import DocumentPanel from './document_panel/document_panel';
import Prospect from './prospect/prospect';
import ProspectDetils from './dashboard/propects_details/ProspectDetails.view';
import LoginPage from './auth/Login.view';
import ErrorMessage from '../components/error-message/ErrorMessage';
import Projecten from './projecten/projecten';
import Aankoper from './aankoper/aankoper';
import ProspectCreate from './prospect/prospectcreate';
import Kopers from './kopers/kopers';
import CookiesPopUp from '../components/cookie-pop-up/cookies';
import ForgotPassword from './auth/forgot_password/forgot_password';
import ResetPassword from './auth/reset_password/reset_password';
import Laatste from './dashboard/website/laatste/Laatste.view';
import Huidige from './dashboard/website/huidige/Huidige.view';
import ProjectDetail from './dashboard/website/project_detail/project_detail';

export default function Core() {
  return (
    <Switch>
      <Route path="/toevoegen/aankoper/:id">
        <Aankoper />
        <CookiesPopUp />
      </Route>
      <Route path="/toevoegen/koper">
        <Aankoper />
        <CookiesPopUp />
      </Route>
      <Route exact path="/toevoegen/prospects/:id">
        <ProspectDetils />
        <CookiesPopUp />
      </Route>
      <Route path="/toevoegen/prospects/">
        <ProspectCreate />
        <CookiesPopUp />
      </Route>
      <Route path="/toevoegen/projects">
        <Projects />
        <CookiesPopUp />
      </Route>
      <Route path="/instellingen">
        <Settings />
        <CookiesPopUp />
      </Route>
      <Route path="/website/referenties">
        <Laatste />
        <CookiesPopUp />
      </Route>
      <Route path="/website/huidige">
        <Huidige />
        <CookiesPopUp />
      </Route>
      <Route path="/berichten">
        <MessagePanel />
        <CookiesPopUp />
      </Route>
      <Route path="/documenten">
        <DocumentPanel />
        <CookiesPopUp />
      </Route>
      <Route exact path="/lijst/prospects">
        <Prospect />
        <CookiesPopUp />
      </Route>
      <Route exact path="/lijst/projects">
        <Projects />
        <CookiesPopUp />
      </Route>
      <Route exact path="/lijst/projects/units">
        <Projecten />
        <CookiesPopUp />
      </Route>
      <Route exact path="/lijst/kopers">
        <Kopers />
        <CookiesPopUp />
      </Route>
      <Route exact path="/Overzicht">
        <Dashboard />
        <CookiesPopUp />
      </Route>
      <Route path="/forgot_password">
        <ForgotPassword />
      </Route>
      <Route path="/reset_password">
        <ResetPassword />
      </Route>
      <Route path = "/website/projecten/:id">
        <ProjectDetail />
      </Route>
      <Route exact path="/">
        <ErrorBoundary FallbackComponent={ErrorMessage}>
          <LoginPage />
        </ErrorBoundary>
      </Route>
    </Switch>
  );
}
