import React, { useRef } from "react";
import { Grid, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import {ReactComponent as TrashIcon} from '../../../assets/images/icons/trash_red.svg';

import { SpaceRow } from "../../../components/space/space";

import "./message_contact.scss"
import ErrorModal from "../../../components/error_modal/error_modal";
import axios from "axios";
import { createChat, deleteChat } from "../../../common/api/routes";

export default function MessageContact(props) {
    const { data, buildingData, isSelected, onReloadData } = props;
    const errorModalRef = useRef(0);

    const getUnitType = (type) => {
        if(type == 1) {
            return "App."
        }
        else if(type == 2) {
            return "Park."
        }
        else if(type == 3) {
            return "Berg."
        }
        else {
            return "";
        }
    }
    const handleDeleteChatBtn = () => {
        errorModalRef.current.openDeleteModal('Weet u zeker dat u dit bericht wenst te verwijderen?', 'Bevestigen', data.id);
    }

    const handleDeleteChat = (id) => {
        axios.delete(deleteChat(id))
            .then((response) => {
                if (response.data.success) {
                    if (!data.document_id) {
                        axios.post(createChat, {
                            buyer_project_id: data.buyer_project_id,
                            project_id: data.project_id
                        }).then(chatResponse => {
                        }).catch(error => console.log(error))
                    }
                    onReloadData && onReloadData();
                }
            })
            .catch((error) => console.log(error));
    };

    return(
        <Grid container style={{position:'relative'}}>
            <div className = "message-contact-container" style = {{backgroundColor: isSelected ? 'rgb(240, 241, 242)' : 'rgba(0, 0, 0, 0)'}}>
                <p className = "main-font-regular font-size-14 font-color-black">{`${data?.buyer_project?.user?.firstName || ""} ${data?.buyer_project?.user?.lastName || ""}`}</p>
                <SpaceRow top = "3" />
                <p className = "main-font-regular font-size-14 font-color-blue-grey">{data?.document?.project?.code || ""} - {getUnitType(data?.document?.unit?.general_type)} {data?.document?.unit?.general_number}</p>
                <SpaceRow top = "3" />
                <p className = "main-font-regular font-size-14 font-color-grey">Betreft {data?.document?.filename || ""}</p>
            </div>
            <IconButton color="primary" aria-label="upload picture" component="span" className="message-contact-delete-btn" onClick={handleDeleteChatBtn}>
                <TrashIcon style={{ width: '12px' }} height={'12px'} />
            </IconButton>
            <ErrorModal ref = {errorModalRef} onDelete = {handleDeleteChat} />
        </Grid>
    )
}

