import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { updateProjectUnit } from '../../../common/api/routes';
import { SQLDateToString } from '../../../common/utils/format';

const Notaris = ({
  data,
  sellerList,
}) => {
  const [dropdownState, setDropdownState] = useState(false);
  const [updatedId, setUpdatedId] = useState(0);

  function getActiveSeller() {
    if (data?.contract_seller_id) {
      const activeSeller = sellerList.find((seller) => seller.id == (updatedId !== 0 ? updatedId : data?.contract_seller_id));
      return activeSeller?.company || '-';
    }
    return '-';
  }

  function updateSeller(seller) {
    axios.put(updateProjectUnit(data.id), {
      contract_seller_id: seller.id,
    })
      .then((response) => {
        setDropdownState(false);
        setUpdatedId(seller.id);
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <td>
        {data?.user?.firstName || ''}
        {' '}
        {data?.user?.lastName || ''}
      </td>
      <td style={{ textAlign: 'center' }}>
        {getActiveSeller()}
      </td>
      <td style={{ textAlign: 'center' }}>
        {data?.buyer?.sales_agreement ? SQLDateToString(data?.buyer?.sales_agreement) : ''}
      </td>
      <td style={{ textAlign: 'center' }}>
        {data?.buyer?.sales_number || ''}
      </td>
      <td style={{ textAlign: 'center' }}>
        {data?.buyer?.bank_notification ? SQLDateToString(data?.buyer?.bank_notification) : ''}
      </td>
      <td style={{ textAlign: 'center' }}>
        {data?.buyer?.deed_date ? SQLDateToString(data?.buyer?.deed_date) : ''}
      </td>
    </>
  );
};

Notaris.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Notaris;

export function NotarisTableTotal({
  data,
}) {
  const [appartmentCount, setAppartmentCount] = useState(0);

  useEffect(() => {
    if (data && data?.length > 0) {
      const appartments = data.filter((data) => data?.type?.id == 1);
      setAppartmentCount(appartments.length);
    }
  }, [data]);

  return (
    <>
      <td />
      <td />
      <td />
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${appartmentCount || 0}`}</td>
    </>
  );
}
