import React, { useState, useRef } from 'react';
import {
  Grid, TableBody, TableHead, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { debounce, isEmpty } from 'lodash';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Btn from '../../components/button/button';
import Select from '../../components/select/select';
import { SpaceRow } from '../../components/space/space';
import { SearchInput } from '../../components/search_input/search_input';
import { TCell, TRow, TableCustom } from '../../components/table/table';
import ErrorModal from '../../components/error_modal/error_modal';
import { getAllProjects } from '../dashboard/projects/projects.api';
import {
  getSellers,
  adNewUser,
  delUser,
  updateUserDetailsById,
  adSellertoProject,
} from './settings.api';
import Spinner from '../../components/spinner/Spinner';
import DropdownMenu from '../../components/DropdownMenu';
import { defaultUrl } from '../../common/api/routes';

const useStyles = makeStyles((theme) => ({
  spacing: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function TabContentSeller() {
  const [row, setRow] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [user, setUser] = React.useState([]);
  const [showSelect, setShowSelect] = useState();
  const [showUpdate, setShowUpdate] = useState();
  const [value, setValue] = useState();
  const [isFetching, setIsFetching] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [tableRowHover, setTableRowHover] = useState(-1);
  const [filteredProject, setFilteredProject] = useState();
  let fill;

  const errorModalRef = useRef(0);

  React.useEffect(() => {
    getAllProjects(setIsFetching, setProjects);
    getSellers(setIsFetching).then((data) => {
      const rowsx = data.map((r) => {
        const temp = r;
        temp.toggle = false;
        return temp;
      });
      setRows(rowsx);
    });
  }, []);

  React.useEffect(() => {
    const tempoptions = projects.map((value) => {
      return {
        value: value.id,
        lable: value.name,
      };
    });
    tempoptions.push({
      value: '',
      lable: 'Select Project',
    });
    setOptions(tempoptions);
  }, [projects, rows]);

  const saveOnBlur = () => {
    if (
      row.company
      && row.firstName
      && row.lastName
      // && row.project
      && row.email
      && row.phone
    ) {
      let payload = {
        company: row.company,
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
        phone: row.phone,
        type: [{ id: '4' }],
        title_id: '1',
      };

      adNewUser(setIsSaving, () => {}, payload, errorModalRef)
        .then((data) => {
          payload = {
            seller_id: data?.id,
            project_id: row?.project,
          };
          setOpen(false);
          setRow({});
          if (!isEmpty(data)) {
            adSellertoProject(setIsSaving, payload).then(() => {
              getSellers(setIsSaving).then((Newdata) => {
                const rowsx = Newdata.map((r) => {
                  const temp = r;
                  temp.toggle = false;
                  return temp;
                });
                setRows(rowsx);
              });
            });
          }
        });
    }
  };

  const handleFieldEdit = (fieldkey, value, index) => {
    const newUserData = { ...user };
    newUserData[fieldkey] = value;
    setUser(newUserData);
    rows[index][fieldkey] = value;
    setRows(rows);
    console.log('row', rows);
    console.log('user', user);
  };
  const handleFormUpdate = (id) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData.id) {
      let payload = {
        company: user?.company,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phone: user?.phone,
        id,
      };
      updateUserDetailsById(setIsFetching, payload)
        .then((response) => {
          payload = {
            seller_id: response.id,
            project_id: user.project_id,
          };
          setOpen(false);
          // adSellertoProject(setIsFetching, payload).then(() => {
          getSellers(setIsFetching).then((Newdata) => {
            const rowsx = Newdata.map((r) => {
              const temp = r;
              temp.toggle = false;
              return temp;
            });
            setRows(rowsx);
          });
          // })
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setShowSelect();
    setShowUpdate();
    setUser();
  };

  const deleteSeller = (id) => {
    // e.currentTarget.parentNode.parentNode.remove();
    errorModalRef.current.openDeleteModal('Weet je zeker dat je deze verkoper wenst te verwijderen?', 'Bevestigen', id);
  };

  const sellerDelete = (id) => {
    const payload = { id };
    delUser(setIsFetching, () => {}, payload).then(() => {
      getSellersData();
    });
  };

  const handleTextFieldChange = (e) => {
    const key = e.target.name;
    const data = row;
    // eslint-disable-next-line no-unused-expressions
    data[key] = e.target.value;
    setRow(data);
    console.log(row);
  };

  const Click = () => {
    setOpen(!open);
  };

  const getSellersData = (search) => {
    getSellers(setIsFetching, search).then((data) => {
      const rowsx = data.map((rr) => {
        const temp = rr;
        temp.toggle = false;
        return temp;
      });
      setRows(rowsx);
    });
  };
  const handleRemoveProject = (sellerId, id) => {
    rows
      && rows.map((single) => {
        if (single.id === sellerId) {
          const filtered = single?.sellerProjects?.filter(
            (row) => row.id !== id,
          );
          const r = window.confirm(
            'Weet u zeker dat u dit project wenst te verwijderen?',
          );
          if (r === true) {
            const payload = {
              seller_projects: filtered,
            };
            axios
              .put(
                `${defaultUrl}user/${sellerId}`,
                payload,
              )
              .then((ress) => {
                getSellersData();
              });
          }
        }
      });
  };
  const handleUpdateProject = (sellerId, id, index, i) => {
    if (user?.project_id) {
      rows
        && rows.map((single) => {
          if (single.id === sellerId) {
            const fill = single?.sellerProjects?.filter((row) => row);
            const elementsIndex = fill.findIndex(
              (element) => element.id == id.id,
            );
            const newArray = [...fill];
            newArray[elementsIndex] = {
              ...newArray[elementsIndex],
              project_id: user?.project_id,
            };
            const payload = {
              company: user?.company,
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.email,
              phone: user?.phone,
              seller_projects: newArray,
              id: sellerId,
            };
            let status = 0;
            fill
              && fill.map((sin) => {
                if (sin.projectId === user?.project_id) {
                  return (status = 1);
                }
              });
            if (status === 0) {
              updateUserDetailsById(setIsFetching, payload).then((response) => {
                setOpen(false);
                getSellers(setIsFetching).then((Newdata) => {
                  const rowsx = Newdata.map((r) => {
                    const temp = r;
                    temp.toggle = false;
                    return temp;
                  });
                  setRows(rowsx);
                });
              });
            } else {
              getSellers(setIsFetching).then((Newdata) => {
                const rowsx = Newdata.map((r) => {
                  const temp = r;
                  temp.toggle = false;
                  return temp;
                });
                setRows(rowsx);
              });
            }
          }
        });
      setShowUpdate();
      setUser();
    }
  };
  const addNewProject = (id) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData.id) {
      let payload = {
        company: user?.company,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phone: user?.phone,
        id,
      };
      updateUserDetailsById(setIsFetching, payload)
        .then((response) => {
          payload = {
            seller_id: response.id,
            project_id: user?.project_id,
          };
          setOpen(false);
          if (user) {
            let status = 0;
            rows
              && rows.map((single) => {
                const abc = single?.sellerProjects?.map((row) => {
                  if (row.sellerId === id) {
                    if (row.projectId === user?.project_id) {
                      return (status = 1);
                    }
                  }
                });
              });
            if (status === 0) {
              adSellertoProject(setIsFetching, payload).then(() => {
                getSellers(setIsFetching).then((Newdata) => {
                  const rowsx = Newdata.map((r) => {
                    const temp = r;
                    temp.toggle = false;
                    return temp;
                  });
                  setRows(rowsx);
                });
              });
            } else {
              getSellers(setIsFetching).then((Newdata) => {
                const rowsx = Newdata.map((r) => {
                  const temp = r;
                  temp.toggle = false;
                  return temp;
                });
                setRows(rowsx);
              });
            }
          } else {
            getSellers(setIsFetching).then((Newdata) => {
              const rowsx = Newdata.map((r) => {
                const temp = r;
                temp.toggle = false;
                return temp;
              });
              setRows(rowsx);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    setShowSelect();
    setUser();
  };
  const classes = useStyles();
  // console.log('roes', rows);
  return (
    <div>
      <Grid container>
        <Grid container item sm={6} md={7} alignItems="center">
          <SearchInput handleSearch={debounce((value) => getSellersData(value), 1000)} />
        </Grid>
        <Grid container item sm={6} md={5} justify="flex-end">
          <div className={classes.spacing} style={{ marginRight: 22 }}>
            <Btn variation="primary" onClick={Click}>
              + Verkoper
            </Btn>
            <Btn variation="secondary">
              <ReactHTMLTableToExcel
                className="download-table-xls-button"
                id="projects-list-table-id"
                table="sellers-list-table"
                buttonText="Download .xls"
                filename="VerkopersList"
                sheet="Verkopers List"
              />
            </Btn>
          </div>
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      {isFetching ? (
        <div className="text-center padding-top-20">
          <Spinner text="Fetching Data..." />
        </div>
      ) : (
        <Grid container>
          <Grid item md={12}>
            <TableCustom>
              <TableHead>
                <TRow>
                  <TCell align="left">Bedrijf</TCell>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/Combined_Shape__1_.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Voornaam
                  </TCell>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/Combined_Shape__1_.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Naam
                  </TCell>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/phone_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Telefoon
                  </TCell>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/mail_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    E-mailadres
                  </TCell>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/dashboard_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Project
                  </TCell>
                  <TCell align="left" className="last" style={{ width: 20 }} />
                </TRow>
              </TableHead>
              <TableBody>
                {/* {JSON.stringify(rows)} */}
                {rows.map((row, i) => (
                  <>
                    <TRow
                      key={row.id}
                      style={{ height: 47 }}
                      onMouseEnter={() => setTableRowHover(i)}
                      onMouseLeave={() => setTableRowHover(-1)}
                    >
                      <TCell align="left" className="setting-project-">
                        {' '}
                        <input
                          className="main-font-regular  font-size-14 font-color-black cursor-pointer profile-editable"
                          value={row?.company || ''}
                          onChange={(event) => handleFieldEdit('company', event.target.value, i)}
                          onBlur={() => {
                            handleFormUpdate(row.id);
                          }}
                        />
                      </TCell>
                      <TCell align="left" className="setting-project-">
                        {' '}
                        <input
                          className="main-font-regular  font-size-14 font-color-black cursor-pointer profile-editable"
                          value={row?.firstName || ''}
                          onChange={(event) => handleFieldEdit('firstName', event.target.value, i)}
                          onBlur={() => {
                            handleFormUpdate(row.id);
                          }}
                        />
                      </TCell>
                      <TCell align="left" className="setting-project-">
                        {' '}
                        <input
                          className="main-font-regular  font-size-14 font-color-black cursor-pointer profile-editable"
                          value={row?.lastName || ''}
                          onChange={(event) => handleFieldEdit('lastName', event.target.value, i)}
                          onBlur={() => {
                            handleFormUpdate(row.id);
                          }}
                        />
                      </TCell>
                      <TCell align="left" className="setting-project-">
                        {' '}
                        <input
                          className="main-font-regular  font-size-14 font-color-black cursor-pointer profile-editable"
                          value={row?.phone || ''}
                          onChange={(event) => handleFieldEdit('phone', event.target.value, i)}
                          onBlur={() => {
                            handleFormUpdate(row.id);
                          }}
                        />
                      </TCell>
                      <TCell align="left" className="setting-project-">
                        {' '}
                        <input
                          className="main-font-regular  font-size-14 font-color-black cursor-pointer profile-editable"
                          value={row?.email || ''}
                          onChange={(event) => handleFieldEdit('email', event.target.value, i)}
                          onBlur={() => {
                            handleFormUpdate(row.id);
                          }}
                        />
                      </TCell>

                      <TCell align="left" className="setting-project-">
                        <span
                          onClick={() => {
                            row.toggle = true;
                            rows[i] = row;
                            setRows([...rows]);
                          }}
                        >
                          <div align="left" style={{ width: '100%' }}>
                            {row?.sellerProjects?.length === 0 ? (
                              <Select
                                options={options}
                                onChange={(event) => handleFieldEdit(
                                  'project_id',
                                  event.target.value,
                                  i,
                                )}
                                onBlur={() => {
                                  addNewProject(row.id);
                                  row.toggle = true;
                                  rows[i] = row;
                                  setRows([...rows]);
                                }}
                                name="project_id"
                                selected={
                                  !!row?.sellerProjects && !!row?.sellerProjects
                                    ? row?.sellerProjects[0]?.id
                                    : ''
                                }
                                value={row.seller_projects}
                              />
                            ) : (
                              row?.sellerProjects
                                // ?.sort((a, b) => a.id - b.id)
                                ?.map((single, index) => (
                                  <>
                                    {single.id !== showUpdate && (
                                      <span
                                        style={
                                          single?.project !== null
                                            ? {
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              padding: '6px 0px',
                                            }
                                            : {}
                                        }
                                      >
                                        {' '}
                                        <span
                                          onClick={() => {
                                            setShowUpdate(
                                              single.id === showUpdate
                                                ? ''
                                                : single.id,
                                            );
                                            setShowSelect();
                                            setValue(single);
                                          }}
                                        >
                                          {single?.project?.name !== null && single?.project?.name}
                                        </span>
                                        <div>
                                          <img
                                            src={require('../../assets/images/choose/Combined_Shape__5_.svg')}
                                            alt="no image"
                                            style={{
                                              width: '20px',
                                              heigth: '20px',
                                              cursor: 'pointer',
                                              padding: '0px 5px',
                                            }}
                                            onClick={() => {
                                              handleRemoveProject(
                                                row.id,
                                                single.id,
                                              );
                                            }}
                                          />
                                        </div>
                                      </span>
                                    )}
                                    {single.id === showUpdate && (
                                      <Select
                                        options={options}
                                        onChange={(event) => handleFieldEdit(
                                          'project_id',
                                          event.target.value,
                                          i,
                                        )}
                                        onBlur={() => {
                                          handleUpdateProject(
                                            row.id,
                                            single,
                                            index,
                                            i,
                                          );
                                          row.toggle = true;
                                          rows[i] = row;
                                          setRows([...rows]);
                                        }}
                                        // name="project_id"
                                        selected={value?.project?.id}
                                        // style={!row.toggle ? { display: 'none' } : {}}
                                        value={value?.project?.id}
                                      />
                                    )}
                                  </>
                                ))
                            )}
                            {row.id === showSelect && (
                              <div>
                                <Select
                                  options={options}
                                  onChange={(event) => handleFieldEdit(
                                    'project_id',
                                    event.target.value,
                                    i,
                                  )}
                                  onBlur={() => {
                                    addNewProject(row.id);
                                    row.toggle = true;
                                    rows[i] = row;
                                    setRows([...rows]);
                                  }}
                                  name="project_id"
                                  // selected={
                                  //   !!row?.sellerProjects && !!row?.sellerProjects
                                  //     ? row?.sellerProjects[0]?.id
                                  //     : ''
                                  // }
                                  // style={!row.toggle ? { display: 'none' } : {}}
                                  // value={row.seller_projects}
                                />
                              </div>
                            )}
                            {(row?.sellerProjects?.length >= 1 && row.id !== showSelect) && (
                              <div style={{ float: 'right' }}>
                                <img
                                  src={require('../../assets/images/choose/Combined_Shape__4_.svg')}
                                  alt="no image"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    cursor: 'pointer',
                                    padding: '0px 5px',
                                  }}
                                  onClick={() => {
                                    setShowSelect(row.id === showSelect ? '' : row.id);
                                    setShowUpdate();
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </span>
                      </TCell>
                      <TCell
                        align="left"
                        className="last setting-project- dotted-image-container"
                      >
                        {tableRowHover === i && (
                        <DropdownMenu
                          className="abc"
                          handleDeleteAction={() => deleteSeller(row.id)}
                        />
                        )}
                      </TCell>
                    </TRow>
                  </>
                ))}

                {open && (
                  <>
                    <TRow>
                      <TCell style={{ border: 'none' }} colSpan={6} />
                    </TRow>
                    <TRow>
                      <TCell style={{ border: 'none' }} colSpan={6} />
                    </TRow>
                    <TRow>
                      <TCell style={{ border: 'none' }} colSpan={6} />
                    </TRow>

                    <TRow>
                      <TCell className="padding-50 " align="left">
                        <TextField
                          value={row.company}
                          name="company"
                          onChange={handleTextFieldChange}
                        />
                      </TCell>
                      <TCell align="left">
                        <TextField
                          value={row.firstName}
                          name="firstName"
                          onChange={handleTextFieldChange}
                        />
                      </TCell>
                      <TCell align="left">
                        <TextField
                          value={row.lastName}
                          name="lastName"
                          onChange={handleTextFieldChange}
                        />
                      </TCell>
                      <TCell align="left">
                        <TextField
                          value={row.phone}
                          name="phone"
                          onChange={handleTextFieldChange}
                        />
                      </TCell>
                      <TCell align="left">
                        <TextField
                          value={row.email}
                          name="email"
                          onChange={handleTextFieldChange}
                        />
                      </TCell>
                      <TCell align="left">
                        <Select
                          options={options}
                          onChange={handleTextFieldChange}
                          name="project"
                          value={row.project}
                        />
                      </TCell>
                    </TRow>
                    <TRow>
                      <TCell style={{ border: 'none' }} />
                      <TCell style={{ border: 'none' }} />
                      <TCell style={{ border: 'none' }} />
                      <TCell style={{ border: 'none' }} />
                      <TCell style={{ border: 'none' }} />
                      <TCell style={{ border: 'none' }}>
                        <Btn disabled={isSaving} variation="primary" onClick={saveOnBlur} style={{ marginLeft: 2, width: '100%' }}>
                          Opslaan
                        </Btn>
                      </TCell>
                    </TRow>
                  </>
                )}
              </TableBody>
            </TableCustom>

            <table id="sellers-list-table" style={{ display: 'none' }}>
              <tr>
                <th>Bedrijf</th>
                <th>Voornaam</th>
                <th>Naam</th>
                <th>Telefoon</th>
                <th>E-mailadres</th>
                <th>Project</th>
              </tr>
              {rows.map((rr) => (
                <tr key={rr.id}>
                  <td>{rr?.company || ''}</td>
                  <td>{rr?.firstName || ''}</td>
                  <td>{rr?.lastName || ''}</td>
                  <td>{rr?.phone || ''}</td>
                  <td>{rr?.email || ''}</td>
                  <td>
                    {rr?.sellerProjects
                      ?.sort((a, b) => a.id - b.id)
                      ?.map((single) => (
                        <span>
                          {single?.project?.name !== null
                            && single?.project?.name}
                          <br />
                        </span>
                      ))}
                  </td>
                </tr>
              ))}
            </table>
          </Grid>
        </Grid>
      )}
      <ErrorModal ref={errorModalRef} onDelete={(id) => sellerDelete(id)} />
    </div>
  );
}
