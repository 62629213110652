import React from 'react';
import {
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  btn: {
    minWidth: 90,
    fontFamily: 'Vietnam',
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'none',
    padding: '10px',
    height: '24px',
    borderRadius: '5px',
  },
  btnGreen: {
    background: 'var(--green)',
    color: '#fff',
    '&:hover': {
      background: 'var(--green)',
      color: '#fff',
    },
  },
  btnGray: {
    background: '#F4F4F4',
    color: 'var(--darkBleu)',
  },
}));
const BtnCompact= ({ variation, children,onClick, style }) => {
  const classes = useStyles();
  let styling = classes.btnGray;
  if (variation === 'primary') {
    styling = classes.btnGreen;
  }

  return (

    <Button style={style} className={`${classes.btn} ${styling}`} onClick={onClick}>
      {children}
    </Button>
  );
};

export default BtnCompact;
