import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import axios from "axios";
import { delay, trim } from "lodash";
import Picker from "emoji-picker-react";

import { getChatById, sendMessage, getChatsbyProjectUnitUser, getGeneralChatFromBuyer, getUserById, readChatNotification } from "../../common/api/routes";
import { SQLDateTimeToString } from "../../common/utils/format";
import { SpaceRow } from '../../components/space/space';

import "./tab.berichten.scss";

export default function TabContentBerichten(props) {
  const [selectedDocument, setSelectedDocument ] = useState(0)
  const [ messageState, setMessageState ] = useState("");
  const [ data, setData ] = useState([]);
  const [ isUpdated, setIsUpdated ] = useState(false)
  const [ emojiModalState, setEmojiModalSTate ] = useState(false);
  const [ emojiMouseState, setEmojiMouseState ] = useState(false)
  const [ adminData, setAdminData ] = useState({})
  const [isSubmittingMessage, setIsSubmittingMessage] = useState(false);

  const { buyerData } = props;
  const userData = JSON.parse(localStorage.getItem('user'));
  const messagesListContainerRef = useRef(0)

  useEffect(() => {
    if(buyerData) {
      axios.get(getChatsbyProjectUnitUser(buyerData.id)).then(response => {
        let messageData = response.data.data;
        axios.get(getGeneralChatFromBuyer(buyerData.id)).then(response => {
          if(response?.data?.data?.id) {
            messageData.unshift(response.data.data);
          }
        }).then(() => {
          if(messageData[selectedDocument]?.id) {
            axios.get(getChatById(messageData[selectedDocument]?.id)).then(response => {
              messageData[selectedDocument].messages = [...response.data.data.messages];
              // load the general chat again after loading the data
              setData(messageData)
              if(data.length <= 0) {
                // first instance we auto scroll down, we don't autoscroll on every update
                messagesListContainerRef.current.scrollTop = messagesListContainerRef.current.scrollHeight;
              }
            }).catch(error => console.log(error))
          }
        }).catch(error => console.log(error))
      }).catch(error => console.log(error))

      if(buyerData?.user?.id && adminData?.id && data[selectedDocument]?.id) {
        const assignedProject = adminData.seller_projects.find(project => project.project_id == buyerData.project.id )
        if(assignedProject) {
          // this means the admin is responsbile for this project
          readNotification();
        }
      }
    }
  }, [buyerData, isUpdated])

  useEffect(() => {
    // we create the realtime functionality here
    // we keep an update frequency of 5 seconds
    delay(() => {
      setIsUpdated(!isUpdated);
    }, 5000);

  }, [isUpdated])

  useEffect(() => {
    axios.get(getUserById(userData.id)).then(response => {
      setAdminData(response.data.data)
    }).catch(error => console.log(error))
  }, [])

  useEffect(() => {
    messagesListContainerRef.current.scrollTop = messagesListContainerRef.current.scrollHeight;
  }, [selectedDocument])

  const handleMessageChange = (event) => {
    setMessageState(event.target.value)
  }

  const handleSendMessage = () => {
    if(data[selectedDocument] && trim(messageState) && !isSubmittingMessage){
      setIsSubmittingMessage(true);
      const chatData = {
        chat_id: data[selectedDocument].id,
        user_id: userData.id,
        message: messageState,
        sent_by: 1
      }

      axios.post(sendMessage, chatData).then(response => {
        let dataCopy = [...data];
        dataCopy[selectedDocument].messages.push({...response.data.data, ...{user: {id: userData.id, firstName: userData.firstName, lastName: userData.lastName}}});
        setData(dataCopy);
        setMessageState("")
        messagesListContainerRef.current.scrollTop = messagesListContainerRef.current.scrollHeight;
      }).catch(error => console.log(error))
      .finally(()=>setIsSubmittingMessage(false));
    }
  }

  const handleKeyPress = (event) => {
    if(event.key === "Enter") {
      handleSendMessage()
    }
  }

  const onEmojiClick = (event, emojiObject) => {
    setMessageState(messageState + emojiObject.emoji)
  }

  function readNotification(){
    axios.put(readChatNotification(data[selectedDocument].id)).then(response => {
      console.log(response.data.data)
    }).catch(error => console.log(error))
  }

  return (
    <div className="tab-berichten-container">
      <Grid container wrap = "nowrap" className = "height-auto">
        <div className = "tab-berichten-document-container">
          {data && data.length > 0 && data.map((conversation, index) => (
            <div className = "tab-berichten-document-item vertical-center cursor-pointer" onClick = {() => setSelectedDocument(index)} style = {{backgroundColor: selectedDocument === index ? "RGB(46, 160, 180)" : "RGB(240, 241, 242)"}}>
              {/* {conversation.unread > 0 && (
                <div className = "tab-berichten-document-notif center-content flex-shrink-disable">
                  <p className = "main-font-regular font-size-12 font-color-white">{conversation?.unread}</p>
                </div>
              )} */}
              <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-14" style = {{color: selectedDocument === index ? "white" : "RGB(69, 84, 93)"}}>{(conversation?.buyer_project_id && !conversation?.document_id) ? "Algemeen" : conversation?.document?.filename || ""}</p>
            </div>
          ))}
        </div>
        <div className = "tab-berichten-messages-container">
          <div className = "tab-berichten-messages-view-container">
            <div className = "tab-berichten-messages-view-header center-content">
              <p className = "main-font-regular font-size-14 font-color-blue-grey">Betreft {data[selectedDocument]?.document?.filename || ""}</p>
            </div>
            <div className = "tab-berichten-messages-view-sub" ref = {messagesListContainerRef}>
              {data && data.length > 0 && data[selectedDocument].messages.map(message => (
                <>
                  <Grid container direction = "column" alignItems = {message?.user_id !== userData?.id ? 'flex-start' : 'flex-end'}>
                    <div className = {`flex-row tab-berichten-messages-view-item-${message?.user_id !== userData?.id ? 'left' : 'right'}`}  style = {{justifyContent: message?.user_id === userData?.id && 'flex-end'}}>
                      <p className = "main-font-regular font-size-14 font-color-white">{message.message}</p>
                    </div>
                    <div className = {`flex-row margin-top-2 ${message?.user_id !== userData?.id ? 'margin-left-22' : 'margin-right-22'}`}>
                      <p className = "main-font-regular font-size-12 font-color-blue-grey">{message?.user?.firstName} {message?.user?.lastName} - {message?.created_at ? SQLDateTimeToString(message?.created_at) : ""}</p>
                    </div>
                  </Grid>
                  <SpaceRow top = "25" />
                </>
              ))}
            </div>
          </div>
          <div className = "tab-berichten-messages-input-container">
            <div className = "flex-row width-auto">
              <div className = "tab-berichten-messages-input-bar">
                <div className="message-panel-messages-input-emoji flex-shrink-disable relative" tabIndex = "3" onBlur = {() => { if(!emojiMouseState) setEmojiModalSTate(false); }} onMouseOver = {() => setEmojiMouseState(true)} onMouseLeave = {() => setEmojiMouseState(false)}>
                  <div className = "message-panel-messages-input-emoji-container animatable-400 ease-in-out-quart" style = {{opacity: emojiModalState ? 1 : 0, transform: emojiModalState ? 'scaleY(1)' : 'scaleY(0)'}}>
                    <Picker onEmojiClick = {onEmojiClick} />
                  </div>
                  <div className = "height-auto width-auto center-content" onClick = {() => setEmojiModalSTate(!emojiModalState)}>
                    <p className = "main-font-regular font-size-12 font-color-white">😃</p>
                  </div>
                </div>
                <input className = "width-auto background-color-transparent main-font-regular font-size-14 font-color-blue-grey tab-berichten-messages-input-field" placeholder = "Typ bericht ..." value = {messageState} onChange = {handleMessageChange} onKeyPress = {handleKeyPress} />
              </div>
              <div className = "tab-berichten-messages-input-button center-content" onClick = {handleSendMessage}>
                <img src = {require('../../assets/images/icons/send_white.svg')} alt = "Send" className = "tab-berichten-messages-input-button-icon" />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
}
