import { BrowserRouter as Router } from 'react-router-dom';
import * as React from 'react';

import Core from './views/core';

// import history from './common/utils/history';

function App() {
  return (
    // history={history}
    <Router >
      <Core />
    </Router>
  );
}

export default App;
