import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UnitDocumentDropdown from '../components/unit_document_dropdown';

import { updateProject } from '../../../common/api/routes';

import '../projecten.scss';
import { isNumber } from 'lodash';

export function OppervlaktegegevensItem(props) {
  const [netM, setNetM] = useState(0);
  const [brutM, setBrutM] = useState(0);
  const [terrace, setTerrace] = useState(0);
  const [tuin, setTuin] = useState(0);
  const [pond, setPond] = useState(0);
  const [activeInput, setActiveInput] = useState(0);

  const {
    data, handleUpdate, terassPercentage, tuinPercentage,
  } = props;

  useEffect(() => {
    if (data) {
      setNetM(data?.surface_net_m);
      setBrutM(data?.surface_brut_m);
      setTerrace(data?.surface_terrace);
      setTuin(data?.surface_garden_terrace);
      setPond(data?.surface_ponderation);
    }
  }, [data]);

  const handleBlur = () => {
    const updatedData = {
      surface_net_m: netM,
      surface_brut_m: brutM,
      surface_terrace: terrace,
      surface_garden_terrace: tuin,
      surface_ponderation: pond,
    };

    handleUpdate(updatedData, data.id);
    setActiveInput(0);
  };

  const resetField = (event, inputType) => {
    // if(event.target.name === "surface_net_m") {
    //     if(netM == 0) {
    //         setNetM('')
    //     }
    // }
    // else if(event.target.name === "surface_brut_m") {
    //     if(brutM == 0) {
    //         setBrutM('')
    //     }
    // }
    // else if(event.target.name === "surface_terrace") {
    //     if(terrace == 0) {
    //         setTerrace('')
    //     }
    // }
    // else if(event.target.name === "surface_garden_terrace") {
    //     if(tuin == 0) {
    //         setTuin('')
    //     }
    // }
    // else if(event.target.name === "surface_ponderation") {
    //     if(pond == 0) {
    //         setPond('')
    //     }
    // }

    if (inputType) {
      setActiveInput(inputType);
    }
  };

  const calculatePond = () => {
    let terassValue = terrace;
    let tuinValue = tuin;
    terassValue *= (terassPercentage / 100);
    tuinValue *= (tuinPercentage / 100);
    return brutM + terassValue + tuinValue;
  };

  const changeMetricsAndFormat = (value) => {
    if (value !== null && value !== undefined && isNumber(parseFloat(value))) {
      const newValue = value.toString().split('.');
      newValue[0] = [...newValue[0]].reverse().join('');
      if (newValue[0].length > 3) {
        if (newValue[0].length % 3 !== 0) {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
        } else {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
          newValue[0] = newValue[0].slice(0, newValue[0].length - 1);
        }
      }
      newValue[0] = [...newValue[0]].reverse().join('');
      return `${newValue[0] || '0'},${newValue[1] || '00'}`;
    }
    return '-';
  };

  return (
    <div className="projecten-grid-container-two fade-in-translate">
      <div className="width-auto projecten-grid-container-border-half">
        <div className="projecten-grid-row-border">
          <div className="projecten-grid-box-five vertical-center projecten-grid-border-right">
            <div className="flex-row width-auto relative">
              <p className="main-font-regular font-size-14 font-color-blue-grey width-auto center-text" style={{ opacity: activeInput === 1 ? 0 : 1 }}>{changeMetricsAndFormat(netM)}</p>
              <input className="main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text input-no-arrows absolute" type="number" name="surface_net_m" value={netM || ''} onChange={(event) => setNetM(event.target.value)} onBlur={handleBlur} onClick={(event) => resetField(event, 1)} style={{ opacity: activeInput === 1 ? 1 : 0 }} />
            </div>
          </div>
          <div className="projecten-grid-box-six vertical-center projecten-grid-border-right">
            {/* <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p> */}
            <div className="flex-row width-auto relative">
              <p className="main-font-regular font-size-14 font-color-blue-grey width-auto center-text" style={{ opacity: activeInput === 2 ? 0 : 1 }}>{changeMetricsAndFormat(brutM)}</p>
              <input className="main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text input-no-arrows absolute" type="number" name="surface_brut_m" value={brutM || ''} onChange={(event) => setBrutM(event.target.value)} onBlur={handleBlur} onClick={(event) => resetField(event, 2)} style={{ opacity: activeInput === 2 ? 1 : 0 }} />
            </div>
          </div>
          <div className="projecten-grid-box-seven vertical-center projecten-grid-border-right">
            {/* <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p> */}
            <div className="flex-row width-auto relative">
              <p className="main-font-regular font-size-14 font-color-blue-grey width-auto center-text" style={{ opacity: activeInput === 3 ? 0 : 1 }}>{changeMetricsAndFormat(terrace)}</p>
              <input className="main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text input-no-arrows absolute" type="number" name="surface_terrace" value={terrace || ''} onChange={(event) => setTerrace(event.target.value)} onBlur={handleBlur} onClick={(event) => resetField(event, 3)} style={{ opacity: activeInput === 3 ? 1 : 0 }} />
            </div>
          </div>
          <div className="projecten-grid-box-seven vertical-center projecten-grid-border-right">
            {/* <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p> */}
            <div className="flex-row width-auto relative">
              <p className="main-font-regular font-size-14 font-color-blue-grey width-auto center-text" style={{ opacity: activeInput === 4 ? 0 : 1 }}>{changeMetricsAndFormat(tuin)}</p>
              <input className="main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text input-no-arrows absolute" type="number" name="surface_garden_terrace" value={tuin || ''} onChange={(event) => setTuin(event.target.value)} onBlur={handleBlur} onClick={(event) => resetField(event, 4)} style={{ opacity: activeInput === 4 ? 1 : 0 }} />
            </div>
          </div>
          <div className="projecten-grid-box-seven vertical-center">
            {/* <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p> */}
            <div className="flex-row width-auto relative horizontal-end">
              <p className="main-font-regular font-size-14 font-color-blue-grey">{changeMetricsAndFormat(parseFloat(calculatePond()).toFixed(2))}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function OppervlaktegegevensTotal(props) {
  const { data, documentCategories, projectData } = props;

  const getTotal = (key) => {
    if (data) {
      let total = 0;
      data.forEach((unit) => {
        total += (unit[key] || 0);
      });
      return total.toFixed(2);
    }

    return 0;
  };

  const calculatePondTotal = () => {
    let terassTotal = 0;
    let tuinTotal = 0;
    let totalBrut = 0;
    data.forEach((unit) => {
      terassTotal += (unit.surface_terrace * (projectData.terass / 100));
      tuinTotal += (unit.surface_garden_terrace * (projectData.tuin / 100));
      totalBrut += unit.surface_brut_m;
    });
    return (totalBrut + terassTotal + tuinTotal).toFixed(2);
  };

  const changeMetricsAndFormat = (value) => {
    const newValue = value.toString().split('.');
    newValue[0] = [...newValue[0]].reverse().join('');
    if (newValue[0].length > 3) {
      if (newValue[0].length % 3 !== 0) {
        newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
      } else {
        newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
        newValue[0] = newValue[0].slice(0, newValue[0].length - 1);
      }
    }
    newValue[0] = [...newValue[0]].reverse().join('');
    return `${newValue[0] || '0'},${newValue[1] || '00'}`;
  };

  return (
    <div className="projecten-grid-container-two fade-in-translate">
      <div className="projecten-grid-total-container flex-row projecten-grid-container-border">
        <div className="projecten-grid-box-five vertical-center projecten-grid-border-right horizontal-between for-height-over-flow">
          <p className="main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
          <div className="flex-row">
            {/* <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p> */}
            <p className="main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(getTotal('surface_net_m'))}</p>
          </div>
        </div>
        <div className="projecten-grid-box-six vertical-center projecten-grid-border-right horizontal-between for-height-over-flow">
          <p className="main-font-regular font-size-14 font-color-blue-grey text-end">Totaal:</p>
          <div className="flex-row">
            {/* <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p> */}
            <p className="main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(getTotal('surface_brut_m'))}</p>
          </div>
        </div>
        <div className="projecten-grid-box-seven vertical-center projecten-grid-border-right horizontal-between for-height-over-flow">
          <p className="main-font-regular font-size-14 font-color-blue-grey text-end">Totaal:</p>
          <div className="flex-row">
            {/* <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p> */}
            <p className="main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(getTotal('surface_terrace'))}</p>
          </div>
        </div>
        <div className="projecten-grid-box-seven vertical-center projecten-grid-border-right horizontal-between for-height-over-flow">
          <p className="main-font-regular font-size-14 font-color-blue-grey text-end">Totaal:</p>
          <div className="flex-row">
            {/* <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p> */}
            <p className="main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(getTotal('surface_garden_terrace'))}</p>
          </div>
        </div>
        <div className="projecten-grid-box-seven vertical-center horizontal-between for-height-over-flow">
          <p className="main-font-regular font-size-14 font-color-blue-grey text-end">Totaal:</p>
          <div className="flex-row">
            {/* <p className = "main-font-regular font-size-14 font-color-blue-grey">€</p> */}
            <p className="main-font-regular font-size-14 font-color-blue-grey text-end">{changeMetricsAndFormat(calculatePondTotal())}</p>
          </div>
        </div>
      </div>
      <div className="projecten-grid-total-container flex-row">
        <div className="projecten-grid-box-five center-content">
          <button type="button" className="projecten-grid-upload-button-one center-content">
            <UnitDocumentDropdown categoryType="netm" projectData={projectData} categoryData={documentCategories.find((category) => category.title === 'netm')} />
          </button>
        </div>
        <button type="button" className="projecten-grid-box-four center-content">
          <UnitDocumentDropdown categoryType="meters" projectData={projectData} categoryData={documentCategories.find((category) => category.title === 'meters')} type="long" />
        </button>
      </div>
    </div>
  );
}

export default function Oppervlaktegegevens(props) {
  const [brutModalState, setBrutModalState] = useState(false);
  const [terassModalState, setTerassModalState] = useState(false);
  const [tuinModalState, setTuinModalState] = useState(false);

  const [brutEditState, setBrutEditState] = useState(false);
  const [terassEditState, setTerassEditState] = useState(false);
  const [tuinEditState, setTuinEditState] = useState(false);

  const [brutMouseState, setBrutMouseState] = useState(false);
  const [terassMouseState, setTerassMouseState] = useState(false);
  const [tuinMouseState, setTuinMouseState] = useState(false);

  const {
    projectData,
    update,
    setBrutState,
    setTerassState,
    setTuinState,
  } = props;

  useEffect(() => {
    if (projectData) {
      setBrutState(projectData?.bruto_m2);
      setTerassState(projectData?.terass);
      setTuinState(projectData?.tuin);
    }
  }, [projectData]);

  useEffect(() => {
    if (props.brutState && !brutEditState && projectData?.id) {
      axios.put(updateProject(projectData.id), {
        bruto_m2: parseFloat(props.brutState),
      }).then(() => {
        update();
      }).catch((error) => console.log(error));
    }
  }, [brutEditState]);

  useEffect(() => {
    if (props.terassState && !terassEditState && projectData?.id) {
      axios.put(updateProject(projectData.id), {
        terass: parseFloat(props.terassState),
      }).then(() => {
        update();
      }).catch((error) => console.log(error));
    }
  }, [terassEditState]);

  useEffect(() => {
    if (props.tuinState && !tuinEditState && projectData?.id) {
      axios.put(updateProject(projectData.id), {
        tuin: parseFloat(props.tuinState),
      }).then(() => {
        update();
      }).catch((error) => console.log(error));
    }
  }, [tuinEditState]);

  const handleBrutChange = (event) => {
    props.setBrutState(event.target.value);
  };

  const handleTerassChange = (event) => {
    props.setTerassState(event.target.value);
  };

  const handleTuinChange = (event) => {
    props.setTuinState(event.target.value);
  };

  const changeMetricsAndFormat = (value) => {
    if (value !== null && value !== undefined && isNumber(parseFloat(value))) {
      const newValue = value.toString().split('.');
      newValue[0] = [...newValue[0]].reverse().join('');
      if (newValue[0].length > 3) {
        if (newValue[0].length % 3 !== 0) {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
        } else {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
          newValue[0] = newValue[0].slice(0, newValue[0].length - 1);
        }
      }
      newValue[0] = [...newValue[0]].reverse().join('');
      return `${newValue[0] || '0'},${newValue[1] || '00'}`;
    }
    return '-';
  };

  return (
    <div className="projecten-grid-container-two fade-in-translate" style={{ zIndex: 150000 }}>
      <div className="width-auto projecten-grid-container-border">
        <div className="projecten-grid-header-box center-content">
          <p className="main-font-semi-bold font-size-14 font-color-blue-grey">Oppervlaktegegevens</p>
        </div>
        <div className="projecten-grid-row-border background-color-light-grey">
          <div className="projecten-grid-box-five vertical-center projecten-grid-border-right">
            <p className="main-font-regular font-size-14 font-color-blue-grey">Netto m2</p>
          </div>
          <div className="projecten-grid-box-six projecten-grid-border-right relative outline-none" tabIndex="0" onBlur={() => { if (!brutMouseState) setBrutModalState(false); }} onMouseOver={() => setBrutMouseState(true)} onMouseLeave={() => setBrutMouseState(false)}>
            <div className="height-auto width-auto vertical-center horizontal-between cursor-pointer" onClick={() => setBrutModalState(!brutModalState)}>
              <div className="flex-row">
                <p className="main-font-regular font-size-14 font-color-blue-grey">Bruto m2</p>
              </div>
              <div className="flex-row">
                <div className="projecten-grid-box-per-container center-content">
                  <p className="main-font-regular font-size-10 font-color-blue-grey">
                    {changeMetricsAndFormat(props.brutState)}
                    %
                  </p>
                </div>
              </div>
            </div>
            <div className="projecten-grid-box-modal-container animatable-400 ease-in-out-quart" style={{ transform: brutModalState ? 'scaleY(1)' : 'scaleY(0)', opacity: brutModalState ? 1 : 0 }}>
              <div className="flex-row width-auto vertical-center horizontal-between">
                <p className="main-font-regular font-size-14 font-color-blue-grey">Percentage:</p>
                <input className="main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-modal-input center-content" value={props.brutState} onChange={handleBrutChange} disabled={!brutEditState} />
              </div>
              <div className="flex-row width-auto margin-top-10">
                <button type="button" className="projecten-grid-box-modal-button-active center-content" onClick={() => setBrutEditState(!brutEditState)} style={{ backgroundColor: brutEditState ? 'RGB(46, 160, 180)' : 'RGB(240, 241, 242)' }}>
                  <p className="main-font-semi-bold font-size-14 font-color-white" style={{ color: brutEditState ? 'white' : 'RGB(69, 84, 93)' }}>{brutEditState ? 'Save' : 'Edit'}</p>
                </button>
              </div>
            </div>
          </div>
          <div className="projecten-grid-box-seven projecten-grid-border-right relative outline-none" tabIndex="0" onBlur={() => { if (!terassMouseState) setTerassModalState(false); }} onMouseOver={() => setTerassMouseState(true)} onMouseLeave={() => setTerassMouseState(false)}>
            <div className="height-auto width-auto vertical-center horizontal-between cursor-pointer" onClick={() => setTerassModalState(!terassModalState)}>
              <div className="flex-row">
                <p className="main-font-regular font-size-14 font-color-blue-grey">Teras</p>
              </div>
              <div className="flex-row">
                <div className="projecten-grid-box-per-container center-content">
                  <p className="main-font-regular font-size-10 font-color-blue-grey">
                    {changeMetricsAndFormat(props.terassState)}
                    %
                  </p>
                </div>
              </div>
            </div>
            <div className="projecten-grid-box-modal-container animatable-400 ease-in-out-quart" style={{ transform: terassModalState ? 'scaleY(1)' : 'scaleY(0)', opacity: terassModalState ? 1 : 0 }}>
              <div className="flex-row width-auto vertical-center horizontal-between">
                <p className="main-font-regular font-size-14 font-color-blue-grey">Percentage:</p>
                <input className="main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-modal-input center-content" value={props.terassState} onChange={handleTerassChange} disabled={!terassEditState} />
              </div>
              <div className="flex-row width-auto margin-top-10">
                <button type="button" className="projecten-grid-box-modal-button-active center-content" onClick={() => setTerassEditState(!terassEditState)} style={{ backgroundColor: terassEditState ? 'RGB(46, 160, 180)' : 'RGB(240, 241, 242)' }}>
                  <p className="main-font-semi-bold font-size-14 font-color-white" style={{ color: terassEditState ? 'white' : 'RGB(69, 84, 93)' }}>{terassEditState ? 'Save' : 'Edit'}</p>
                </button>
              </div>
            </div>
          </div>
          <div className="projecten-grid-box-seven projecten-grid-border-right relative" tabIndex="0" onBlur={() => { if (!tuinMouseState) setTuinModalState(false); }} onMouseOver={() => setTuinMouseState(true)} onMouseLeave={() => setTuinMouseState(false)}>
            <div className="height-auto width-auto vertical-center horizontal-between cursor-pointer" onClick={() => setTuinModalState(!tuinModalState)}>
              <div className="flex-row">
                <p className="main-font-regular font-size-14 font-color-blue-grey">Tuin</p>
              </div>
              <div className="flex-row">
                <div className="projecten-grid-box-per-container center-content">
                  <p className="main-font-regular font-size-10 font-color-blue-grey">
                    {changeMetricsAndFormat(props.tuinState)}
                    %
                  </p>
                </div>
              </div>
            </div>
            <div className="projecten-grid-box-modal-container animatable-400 ease-in-out-quart" style={{ transform: tuinModalState ? 'scaleY(1)' : 'scaleY(0)', opacity: tuinModalState ? 1 : 0 }}>
              <div className="flex-row width-auto vertical-center horizontal-between">
                <p className="main-font-regular font-size-14 font-color-blue-grey">Percentage:</p>
                <input className="main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-modal-input center-content" value={props.tuinState} onChange={handleTuinChange} disabled={!tuinEditState} />
              </div>
              <div className="flex-row width-auto margin-top-10">
                <button type="button" className="projecten-grid-box-modal-button-active center-content" onClick={() => setTuinEditState(!tuinEditState)} style={{ backgroundColor: tuinEditState ? 'RGB(46, 160, 180)' : 'RGB(240, 241, 242)' }}>
                  <p className="main-font-semi-bold font-size-14 font-color-white" style={{ color: tuinEditState ? 'white' : 'RGB(69, 84, 93)' }}>{tuinEditState ? 'Save' : 'Edit'}</p>
                </button>
              </div>
            </div>
          </div>
          <div className="projecten-grid-box-seven vertical-center">
            <p className="main-font-regular font-size-14 font-color-blue-grey">Ponderatie</p>
          </div>
        </div>
      </div>
    </div>
  );
}
