import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import "./dropdown_menu.scss";

const StyledMenu = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Menu {...props} classes={{ paper: className }} style={{ top: 13, zIndex: 9999999999 }} />
))`
  border: none;
  box-shadow: 0px -12px 5px 10px rgb(0 0 0 / 4%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;

  li {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
  }
`;

const useStyles = makeStyles({
  arrowUp: {
    width: 0,
    height: 0,
    position: 'absolute',
    zIndex: 10000000000000,
    borderBottom: '8px solid white',
    borderLeft: '7px solid transparent',
    borderRight: '7px solid transparent',
  },
});

const DropdownMenu = ({
  handleDeleteAction, showDropdown
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleDeleteAction();
    setAnchorEl(null);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <img
        alt="search icon"
        src={require('../assets/images/icons/dots.svg')}
        className = "animatable-400 ease-in-out-quart"
        style={{
          height: 20,
          marginTop: 5,
          cursor: 'pointer',
          opacity: !showDropdown ? 1 : 0,
        }}
        onClick={!showDropdown ? handleClick : () => {}}
      />
      {Boolean(anchorEl) && (<div className={classes.arrowUp} />)}
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleDelete}>
          <img
            alt="search icon"
            src={require('../assets/images/icons/trash_red.svg')}
            style={{
              height: 15,
              marginTop: -2,
              marginRight: 10,
              cursor: 'pointer',
            }}
          />
          Verwijderen
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export const DropdownMenuExtended = (props) => {
  const [ dropdownState, setDropdownState ] = useState(false);
  const { handleDownload, handleView, handleDelete, handleEdit, disableDelete, disableDownload, disableView, disableEdit } = props;

  const handleDropdownState = () => {
    setDropdownState(!dropdownState);
  }

  const handleDownloadClick = () => {
    handleDownload();
    setDropdownState(false)
  }

  const handleViewClick = () => {
    handleView();
    setDropdownState(false)
  }

  const handleDeleteClick = () => {
    handleDelete();
    setDropdownState(false)
  }

  const handleEditClick = () => {
    handleEdit();
    setDropdownState(false)
  }

  return(
    <div className = "relative flex-column vertical-center" tabIndex = "2" onBlur = {() => setDropdownState(false)}>
      <img
        alt="search icon"
        src={require('../assets/images/icons/dots.svg')}
        style={{
          height: 20,
          marginTop: 5,
          cursor: 'pointer',
        }}
        onClick = {handleDropdownState}
      />
      <div className = "dropdown-menu-container animatable-400 ease-in-out-quart" style = {{opacity: dropdownState ? 1 : 0, transform: dropdownState ? 'scaleY(1)' : 'scaleY(0)', right: '0px'}}>
        {!disableDownload && (
          <div className = "dropdown-menu-item center-content cursor-pointer" onClick = {handleDownloadClick}>
            <p className = "main-font-regular font-size-14 font-color-black">Download</p>
          </div>
        )}
        {!disableView && (
          <div className = "dropdown-menu-item center-content cursor-pointer" onClick = {handleViewClick}>
            <p className = "main-font-regular font-size-14 font-color-black">View</p>
          </div>
        )}
        {!disableDelete && (
          <div className = "dropdown-menu-item center-content cursor-pointer" onClick = {handleDeleteClick}>
            <p className = "main-font-regular font-size-14 font-color-black">Delete</p>
          </div>
        )}
        {!disableEdit && (
          <div className = "dropdown-menu-item center-content cursor-pointer" onClick = {handleEditClick}>
            <p className = "main-font-regular font-size-14 font-color-black">Edit</p>
          </div>
        )}
      </div>
    </div>
  )
}

DropdownMenu.propTypes = {
  handleDeleteAction: PropTypes.func.isRequired,
  showDropdown: PropTypes.bool,
};

DropdownMenuExtended.propTypes=  {
  handleDownload: PropTypes.func,
  handleView: PropTypes.func,
  handleDelete: PropTypes.func
}

DropdownMenuExtended.defaultProps = {
  handleDownload: () => {},
  handleView: () => {},
  handleDelete: () => {}
}

DropdownMenu.defaultProps = {
  showDropdown: false,
}

export default DropdownMenu;
