import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import SideCardHeader from '../sliding_card/SideCardHeader';
import SlidingCard from '../sliding_card/sliding_card';
import { SpaceRow } from '../space/space';

import './renew_password.scss';
import { changePassword } from '../../common/api/routes';

export default function RenewPassword(props) {
  const [oldPasswordState, setOldPasswordState] = useState('');
  const [newPasswordState, setNewPasswordState] = useState('');
  const [confirmPasswordState, setConfirmPasswordState] = useState('');

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [oldPasswordStateError, setOldPasswordStateError] = useState(false);
  const [newPasswordStateError, setNewPasswordStateError] = useState(false);
  const [confirmPasswordStateError, setConfirmPasswordStateError] = useState(false);

  const [loadState, setLoadState] = useState(false);
  const [errorState, setLastErrorState] = useState('');
  const [successState, setSuccessState] = useState('');

  const userData = JSON.parse(localStorage.getItem('user'));
  const {
    isOpen,
    toggleVisibility,
  } = props;

  const handleOldPassword = (event) => {
    setOldPasswordState(event.target.value);
    setOldPasswordStateError(false);
    setSuccessState('');
    setLastErrorState('');
  };

  const handleNewPassword = (event) => {
    setNewPasswordState(event.target.value);
    setNewPasswordStateError(false);
    setSuccessState('');
    setLastErrorState('');
  };

  const handleConfirmPassword = (event) => {
    setConfirmPasswordState(event.target.value);
    setConfirmPasswordStateError(false);
    setSuccessState('');
    setLastErrorState('');
  };

  const isValid = () => {
    let validation = true;
    if (oldPasswordState === '') {
      validation = false;
      setSuccessState('');
      setLastErrorState('');
      setOldPasswordStateError(true);
    }
    if (newPasswordState === '') {
      validation = false;
      setSuccessState('');
      setLastErrorState('');
      setNewPasswordStateError(true);
    }
    if (confirmPasswordState === '') {
      validation = false;
      setSuccessState('');
      setLastErrorState('');
      setConfirmPasswordStateError(true);
    }
    if (newPasswordState !== confirmPasswordState) {
      validation = false;
      setSuccessState('');
      setLastErrorState('');
      setNewPasswordStateError(true);
      setConfirmPasswordStateError(true);
    }
    return validation;
  };

  const handleButtonSubmit = () => {
    if (isValid() && userData) {
      setLoadState(true);
      axios.post(changePassword, {
        user_id: userData?.id,
        password: oldPasswordState,
        new_password: newPasswordState,
      }).then((res) => {
        setSuccessState(res?.data?.data?.message);
        setLoadState(false);
        setOldPasswordState('');
        setNewPasswordState('');
        setConfirmPasswordState('');
        setOldPasswordStateError(false);
        setNewPasswordStateError(false);
        setConfirmPasswordStateError(false);
      }).catch((error) => {
        setLastErrorState(error?.response?.data?.data?.message);
        setLoadState(false);
      });
    }
  };

  return (
    <SlidingCard isOpen={isOpen} ShowSligingCard={() => toggleVisibility()}>
      <Grid container style={{ padding: 20 }} className="height-auto">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          src={require('../../assets/images/icons/cross_gray.svg')}
          alt="close"
          onClick={() => toggleVisibility()}
          className="cursor-pointer close-btn"
        />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          // src={require('../../assets/images/iconround/back_gray.svg')}
          alt="back"
          onClick={() => toggleVisibility()}
          className="cursor-pointer back-btn"
        />
        <SideCardHeader
          title="Nieuw wachtwoord"
          toggleVisibility={toggleVisibility}
          headerImage={require('../../assets/images/icons/lock_darkbleu.svg')}
        />
        <div className="renew-password-container">
          <div className="flex-column width-auto">

            {errorState && (
              <Grid container>
                <Grid item md={12} style={{ textAlign: 'center' }}>
                  <p
                    className="animatable-400 ease-in-out-quart"
                    style={{ color: 'red' }}
                  >
                    {errorState}
                  </p>
                </Grid>
              </Grid>
            )}
            {successState && (
              <Grid container>
                <Grid item md={12} style={{ textAlign: 'center' }}>
                  <p
                    className="animatable-400 ease-in-out-quart"
                    style={{ color: 'green' }}
                  >
                    {successState}
                  </p>
                </Grid>
              </Grid>
            )}
            <SpaceRow top="42" />

            <Grid container alignItems="center">
              <Grid container item xs={6}>
                <p
                  className="main-font-regular font-size-14 font-color-grey animatable-400 ease-in-out-quart"
                  style={{ color: oldPasswordStateError ? 'red' : '#9b9b9b' }}
                >
                  Huidig wachtwoord
                </p>
              </Grid>
              <Grid container item xs={6}>
                <div className="flex-row width-auto horizontal-between">
                  <input
                    value={oldPasswordState}
                    onChange={handleOldPassword}
                    type={showOldPassword ? 'text' : 'password'}
                    className="renew-password-input main-font-semi-bold font-size-14 font-color-black"
                  />
                  <button
                    type="button"
                    className="renew-password-eye-container"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    <img
                      alt="eye"
                      style={{ opacity: showOldPassword ? 0 : 1 }}
                      src={require('../../assets/images/icons/see_darkbleu.svg')}
                      className="see_darkbleu renew-password-eye animatable-400 ease-in-out-quart"
                    />
                    <img
                      alt="eye"
                      style={{ opacity: showOldPassword ? 1 : 0 }}
                      src={require('../../assets/images/icons/see_no_darkbleu.svg')}
                      className="see_darkbleu renew-password-eye animatable-400 ease-in-out-quart"
                    />
                  </button>
                </div>
              </Grid>
            </Grid>
            <SpaceRow top="20" />
            <Grid container alignItems="center">
              <Grid container item xs={6}>
                <p
                  className="main-font-regular font-size-14 font-color-grey animatable-400 ease-in-out-quart"
                  style={{ color: newPasswordStateError ? 'red' : '#9b9b9b' }}
                >
                  Nieuw wachtwoord bevestigen
                </p>
              </Grid>
              <Grid container item xs={6}>
                <div className="flex-row width-auto horizontal-between">
                  <input
                    className="renew-password-input main-font-semi-bold font-size-14 font-color-black"
                    type={showNewPassword ? 'text' : 'password'}
                    onChange={handleNewPassword}
                    value={newPasswordState}
                  />
                  <button
                    type="button"
                    className="renew-password-eye-container"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    <img
                      className="see_darkbleu renew-password-eye animatable-400 ease-in-out-quart"
                      src={require('../../assets/images/icons/see_darkbleu.svg')}
                      style={{ opacity: showNewPassword ? 0 : 1 }}
                      alt="eye"
                    />
                    <img
                      alt="eye"
                      style={{ opacity: showNewPassword ? 1 : 0 }}
                      src={require('../../assets/images/icons/see_no_darkbleu.svg')}
                      className="see_darkbleu renew-password-eye animatable-400 ease-in-out-quart"
                    />
                  </button>
                </div>
              </Grid>
            </Grid>
            <SpaceRow top="20" />
            <Grid container alignItems="center">
              <Grid container item xs={6}>
                <p
                  className="main-font-regular font-size-14 font-color-grey animatable-400 ease-in-out-quart"
                  style={{ color: confirmPasswordStateError ? 'red' : '#9b9b9b' }}
                >
                  Wachtwoord
                  bevestigen
                </p>
              </Grid>
              <Grid container item xs={6}>
                <div className="flex-row width-auto horizontal-between">
                  <input
                    className="renew-password-input main-font-semi-bold font-size-14 font-color-black"
                    type={showConfirmPassword ? 'text' : 'password'}
                    onChange={handleConfirmPassword}
                    value={confirmPasswordState}
                  />
                  <button
                    type="button"
                    className="renew-password-eye-container"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <img
                      src={require('../../assets/images/icons/see_darkbleu.svg')}
                      alt="eye"
                      className="see_darkbleu renew-password-eye animatable-400 ease-in-out-quart"
                      style={{ opacity: showConfirmPassword ? 0 : 1 }}
                    />
                    <img
                      alt="eye"
                      style={{ opacity: showConfirmPassword ? 1 : 0 }}
                      src={require('../../assets/images/icons/see_no_darkbleu.svg')}
                      className="see_darkbleu renew-password-eye animatable-400 ease-in-out-quart"
                    />
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
          <button
            type="button"
            disabled={loadState}
            onClick={handleButtonSubmit}
            className="renew-password-button center-content"
          >
            {/* eslint-disable-next-line max-len */}
            {/* <img src={require('../../assets/images/iconround/check_white.svg')} alt="round check" className="renew-password-button-icon" /> */}
            <p className="main-font-semi-bold font-size-14 font-color-white">
              {loadState ? 'Updating...' : 'Bewaren'}
            </p>
          </button>
        </div>
      </Grid>
    </SlidingCard>
  );
}

RenewPassword.propTypes = {
  toggleVisibility: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

RenewPassword.defaultProps = {
  isOpen: false,
};
