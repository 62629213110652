import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  get,
  uniqBy,
  isEmpty,
  upperCase,
} from 'lodash';

import { deleteCode } from './projects.api';

const codes = [{
  title: 'Gray',
  color: '#F4F4F4',
}, {
  title: 'Brown',
  color: '#E9D6CF',
}, {
  title: 'Orange',
  color: '#FEE0D2',
}, {
  title: 'Yellow',
  color: '#FBEED5',
}, {
  title: 'Green',
  color: '#DDE7D2',
}, {
  title: 'Blue',
  color: '#D3E5F9',
}, {
  title: 'Purple',
  color: '#E1D3FA',
}, {
  title: 'Pink',
  color: '#F4E1E1',
}, {
  title: 'Red',
  color: '#F6A6A6',
}];

const CodePopUp = ({
  onClose,
  updateCode,
  addNewCode,
  codeToEdit,
  setCodesList,
  usedCodesList,
  existingCodes,
  handleCodeUpdate,
  position
}) => {

  const useStyles = makeStyles(() => ({
    container: {
      position: 'absolute',
      width: 200,
      borderRadius: 5,
      background: '#fff',
      border: '1px solid #e1e1e1',
      boxShadow: '2px 2px 4px #9e9e9e',
      // marginTop: "-130%",
      marginLeft: 125,
      top:position[1]-position[0],
      // marginTop:"100%"
      // left:position[0]
    },
    codeItem: {
      padding: '10px 0px',
    },
    title: {
      color: '#45545D',
      textAlign: 'left',
      paddingLeft: 15,
    },
    deleteIcon: {
      borderBottom: '1px solid #ccc',
      padding: '10px 15px',
      marginBottom: 10,
      cursor: 'pointer',
    },
    codeColor: {
      padding: '5px 15px',
      cursor: 'pointer',

      '&:hover': {
        background: '#F7F7F5',
      },
    },
  }));

  const classes = useStyles();

  const wrapperRef1 = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperRef1.current && !wrapperRef1.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    // Bind the event listener
    console.log("position",position)

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const [showError, setError] = useState(false);
  const [newCode, setNewCode] = useState(get(codeToEdit, 'title', ''));
  const [selectedColor, setSelectedColor] = useState(get(codeToEdit, 'color', ''));

  const handleOnChange = (node) => {
    const { value } = node.target;
    setNewCode(value);
    setError(false);
  };

  const handleAddNewCode = () => {
    if (newCode.length === 3 && !isEmpty(selectedColor)) {
      const updatedCodes = existingCodes;
      const codeExists = existingCodes.find(
        (c) => c.title === upperCase(newCode) && selectedColor === c.color,
      );

      if (!isEmpty(codeToEdit)) {
        const index = updatedCodes.findIndex((i) => i.title === codeToEdit.title);
        updatedCodes[index].title = upperCase(newCode);
        updatedCodes[index].color = selectedColor;
        updateCode(
          codeToEdit.id,
          { color: selectedColor, code: upperCase(newCode) },
          setCodesList,
          usedCodesList,
        );
        setError(false);
      } else if (!isEmpty(newCode) && isEmpty(codeToEdit) && isEmpty(codeExists)) {
        updatedCodes.push({
          title: upperCase(newCode),
          color: selectedColor,
        });
        addNewCode({ color: selectedColor, code: upperCase(newCode) }, setCodesList, usedCodesList);
        handleCodeUpdate({ color: selectedColor, title: upperCase(newCode) });
        setError(false);
      }
      setCodesList(uniqBy(updatedCodes, 'title'));
      onClose();
    }
    setError(true);
  };

  const handleDelete = () => {
    const updatedCodes = existingCodes;
    const index = updatedCodes.findIndex((i) => i.title === codeToEdit.title);
    updatedCodes.splice(index, 1);
    setCodesList(uniqBy(updatedCodes, 'title'));
    deleteCode(codeToEdit.id, setCodesList, usedCodesList);
    onClose();
  };

  return (
    <div className={classes.container} ref={wrapperRef1}>
      <Grid container className={classes.codeItem}>
        <Grid item md={12}>
          <input
            id="code"
            name="code"
            type="text"
            value={newCode}
            style={{
              border: '1px solid #ccc',
              borderRadius: 3,
              padding: 17.5,
              height: 17,
              width: 160,
              marginBottom: 5,
              margin: "0 auto",
              display: "block"
            }}
            onChange={handleOnChange}
            placeholder="Schrijf hier uw code"
          />
        </Grid>

        {!isEmpty(codeToEdit) && (
          <Grid container className={classes.deleteIcon} onClick={handleDelete}>
            <Grid item md={1}>
              <img
                alt="trash icon"
                src={require('../../../assets/images/icons/trash_red.svg')}
                style={{
                  height: 15,
                }}
              />
            </Grid>
            <Grid item md={11} className={classes.title}>
              Delete
            </Grid>
          </Grid>
        )}

        {codes.map((code) => (
          <Grid
            container
            className={classes.codeColor}
            onClick={() => setSelectedColor(code.color)}
            style={selectedColor === code.color ? { background: '#F7F7F5' } : {}}
          >
            <Grid item md={1}>
              <span
                style={{
                  background: code.color,
                  borderRadius: 4,
                  padding: "2px 7.5px 2px 7.5px",
                }}
              />
            </Grid>
            <Grid item md={11} className={classes.title}>
              {code.title}
            </Grid>
          </Grid>
        ))}

        {showError && (
          <div style={{ margin: 15, color: 'red' }}>
            {isEmpty(newCode) && <p>Er dient nog een code ingevuld te worden</p>}
            {!isEmpty(newCode) && newCode?.length !== 3
            && <p>De code mag maar uit 3 letters bestaan</p>}
            <br />
            {isEmpty(selectedColor) && <p>Er dient nog een kleur geselecteerd te worden</p>}
          </div>
        )}

        <Button
          variant="contained"
          style={{
            background: '#F0F1F2',
            height: 36,
            width: '100%',
            fontWeight: 400,
            color: '#45545D',
            boxShadow: 'none',
            textTransform: 'none',
            whiteSpace: 'nowrap',
            margin: '0 15px 15px 15px',
            marginTop: showError ? 0 : 15,
          }}
          onClick={handleAddNewCode}
        >
          {!isEmpty(codeToEdit) ? 'Update' : 'Save'}
        </Button>
      </Grid>
    </div>
  );
};

CodePopUp.styles = {};

CodePopUp.defaultProps = {
  codeToEdit: {},
};

CodePopUp.propTypes = {
  codeToEdit: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  updateCode: PropTypes.func.isRequired,
  addNewCode: PropTypes.func.isRequired,
  setCodesList: PropTypes.func.isRequired,
  existingCodes: PropTypes.func.isRequired,
  usedCodesList: PropTypes.array.isRequired,
};

export default CodePopUp;
