import React, { useEffect, useState, useRef } from 'react';
import { Grid, Container } from '@material-ui/core';
import axios from "axios";
import { useHistory, useLocation } from 'react-router-dom';

import { SpaceRow } from '../../../components/space/space';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { resetPassword } from "../../../common/api/routes";
import ErrorMessage from "../../../components/error-message/ErrorMessage";
import ErrorModal from '../../../components/error_modal/error_modal';

import "./reset_password.scss";
import "../login.scss";

export default function ResetPassword() {
    const [passwordState, setPasswordState] = useState('');
    const [confirmPasswordState, setConfirmPasswordState] = useState('');
    const [ email, setEmail ] = useState("");
    const [ token, setToken ] = useState("");
    const [ loadState, setLoadState ] = useState(false);
    const [ lastErrorState, setLastErrorState ] = useState(null);

    const [passwordStateError, setPasswordStateError] = useState(false);
    const [confirmPasswordStateError, setConfirmPasswordStateError] = useState(false);

    const history =  useHistory();
    const location = useLocation();
    const errorModalRef = useRef(0)
    
    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        }
    }, [])

    useEffect(() => {
        if(location.search) {
            let filteredQuery = location.search.split("&");
            const email = filteredQuery[0].split("=")[1];
            const token = filteredQuery[1].split("=")[1];
            setEmail(email);
            setToken(token);
        }
    }, [location.search])

    const handlePasswordChange = (event) => {
        if (event.target.validity.valid) {
        setPasswordStateError(false);
        } else {
        setPasswordStateError(true);
        }
        setPasswordState(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPasswordStateError(false);
        setConfirmPasswordState(event.target.value);
    };

    const isValid = () => {
        let validity = true;
        if (passwordState === '' || passwordStateError) {
            validity = false;
            setPasswordStateError(true)
        }
        if (confirmPasswordState === '' || confirmPasswordStateError) {
            validity = false;
            setConfirmPasswordStateError(true)
        }
        return validity;
    }; 

    const handleLoginEvent = () => {
        if(isValid() && !loadState) {
            if(token && email && token.length > 0 && email && email.length > 0) {
                axios.post(resetPassword, { 
                    email,
                    token,
                    password: passwordState,
                    password_confirmation: confirmPasswordState,
                }).then(response => {
                    errorModalRef.current.openModal("Wachtwoord succesvol gereset", 'SUCCES',null,1)
                }).catch(error => {
                    const errorMessage = error.response.data.errors.find(error => error.msg.includes('same value'));
                    const errorMessage2 = error.response.data.errors.find(error => error.msg.includes('8 char'));
                    if(errorMessage) {
                        errorModalRef.current.openModal("Beide wachtwoorden zijn niet identiek aan elkaar. Gelieve 2 identieke wachtwoorden in te geven.", true)
                    } else if(errorMessage2){
                        errorModalRef.current.openModal("Min. lengte van het wachtwoord is 8 tekens.", true)
                    }
                    else {
                        errorModalRef.current.openModal("An error occurred.", true)
                    }
                    setLoadState(false)
                })
            }
            else {
                errorModalRef.current.openModal("invalid or expired url", true)
            }
        }
    }

    const handleKeyPress = (event) => {
        if(event.keyCode === 13) {
            handleLoginEvent();
        }
    }

    return(
        <Grid container>
      <div className="container-full">
        <div className="login-background center-content">

          <Container maxWidth="lg">

            <Grid container justify="center">
              <div className="login-box-container center-content relative">
                <div className="login-box-layer" />
                <div className="login-box vertical-center relative">

                  <Grid container justify="center">
                    <img
                      src={require('../../../assets/images/logo/logo_white.png')}
                      className="login-logo"
                      alt="logo"
                    />
                  </Grid>
                    <Grid container >
                        <SpaceRow top="45" />
                        <Grid container>
                        <div className="login-input-container">
                            <div
                            className="login-input-container-layer animatable-400 ease-in-out-quart"
                            style={{ transform: passwordStateError ? 'scaleX(1)' : 'scaleX(0)' }}
                            />
                            <div className="login-input-container-main">
                            <img
                                className="login-input-icon flex-shrink-disable"
                                src={require('../../../assets/images/icons/lock_white.svg')}
                                alt="person white"
                            />
                            <input
                                className="login-input flex-shrink-disable main-font-regular font-size-14 font-color-white"
                                value={passwordState}
                                type="password"
                                placeholder="Nieuw Wachtwoord"
                                onChange={handlePasswordChange}
                            />
                            </div>
                        </div>
                        </Grid>
                        <SpaceRow top="45" />
                        <Grid container>
                            <div className="login-input-container">
                                <div
                                className="login-input-container-layer animatable-400 ease-in-out-quart"
                                style={{ transform: confirmPasswordStateError ? 'scaleX(1)' : 'scaleX(0)' }}
                                />
                                <div className="login-input-container-main">
                                <img
                                    className="login-input-icon flex-shrink-disable"
                                    src={require('../../../assets/images/icons/lock_white.svg')}
                                    alt="person white"
                                />
                                <input
                                    type="password"
                                    className="login-input flex-shrink-disable main-font-regular font-size-14 font-color-white"
                                    value={confirmPasswordState}
                                    placeholder="Herhaal nieuw wacthwoord"
                                    onChange={handleConfirmPasswordChange}
                                />
                                </div>
                            </div>
                        </Grid>
                        <SpaceRow top="45" />

                        <Grid container className = "relative">
                            <button
                                type="button"
                                className="login-button center-content animatable-400 ease-in-out-quart"
                                onClick = {handleLoginEvent}
                                style = {{opacity: loadState ? 0 : 1, transform: loadState ? 'translateY(-15px)' : 'translate(0px)'}}
                            >
                                <p
                                className="main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart"
                                >
                                    Bevestig nieuw wachtwoord
                                </p>
                            </button>
                        </Grid>
                        <ErrorMessage error = {lastErrorState} isOpen = {lastErrorState !== null ? true : false} />
                    </Grid>
                </div>
              </div>
            </Grid>

          </Container>

        </div>
      </div>
      <ErrorModal ref = {errorModalRef} />
    </Grid>
    )
}