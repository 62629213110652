import clsx from 'clsx';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import ProjectsList from './ProjectsList.view';
import AppBarComponent from '../../../components/appbar/appbar';
import MenuStrip from '../../../components/menu_strip/menu_strip';
import { SearchInput } from '../../../components/search_input/search_input';
import { getAllProjects } from './projects.api';

const drawerWidth = 238;

const Projects = () => {
  const [showAddRow, handleShowAddRow] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getAllProjects(setIsFetching, setProjects, '');
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingRight: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    buttonStyle: {
      padding: '10px 40px',
      whiteSpace: 'nowrap',
      textTransform: 'inherit',
    },
  }));

  const getAllUsedCodesList = () => {
    const list = [];
    if (projects) {
      projects.forEach((p) => {
        list.push({
          color: p.color,
          code: p.code,
        });
      });
    }
    return list;
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBarComponent open={open} setOpen={setOpen} pagename="Project" title="Lijst Projecten" />
      <MenuStrip open={open} setOpen={setOpen} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} style={{ marginTop: 80 }} />

        <div>
          <Grid container style={{ marginTop: 80 }}>
            <Grid container alignItems="center" lg={7} style={{ paddingLeft: 10 }}>
              <SearchInput
                handleSearch={
                  debounce((value) => getAllProjects(setIsFetching, setProjects, value), 1000)
                }
              />
            </Grid>

            <Grid
              container
              item
              lg={5}
              justify="flex-end"
              style={{ paddingRight: 55 }}
            >
              <div className={classes.spacing}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonStyle}
                  style={{
                    marginRight: 20,
                    background: '#2EA0B4',
                    height: 36,
                    fontWeight: 400,
                    width: 142,
                    boxShadow: 'none',
                  }}
                  onClick={() => handleShowAddRow(!showAddRow)}
                >
                  + Project
                </Button>

                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  style={{
                    marginRight: 20,
                    background: '#F0F1F2',
                    height: 36,
                    width: 142,
                    fontWeight: 400,
                    color: '#45545D',
                    boxShadow: 'none',
                  }}
                >
                  <ReactHTMLTableToExcel
                    className="download-table-xls-button"
                    id="projects-list-table-id"
                    table="projects-list-table"
                    buttonText="Download .xls"
                    filename="ProjectsList"
                    sheet="Projects List"
                  />
                </Button>

              </div>
            </Grid>

            <Grid item md={12} style={{ padding: 10 }}>
              <ProjectsList
                usedCodesList={getAllUsedCodesList()}
                handleShowAddRow={handleShowAddRow}
                setProjects={setProjects}
                showAddRow={showAddRow}
                isFetching={isFetching}
                projects={projects}
              />
            </Grid>
          </Grid>
        </div>

      </main>

    </div>
  );
};

export default Projects;
