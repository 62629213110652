import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';

import "./error_modal.scss";

const ConfirmationModal = forwardRef((props, ref) => {
    const [modalState, setModalState] = useState(false)
    const [messageState, setMessageState] = useState("")
    const [titleState, setTitleState] = useState("ERROR");
    const [timerState, setTimerState] = useState(false)
    const [typeState, setTypeState] = useState(false)
    const [proceedBtnLabel, setProceedBtnLabel] = useState()
    const [cancelBtnLabel, setCancelBtnLabel] = useState()
    const [data, setData] = useState();
    const [redirect, setRedirect] = useState(0);
    const history = useHistory();

    const { onProceed, onCancel } = props;

    useEffect(() => {
        if (timerState) {
            setTimeout(() => {
                setModalState(false)
                setTimerState(false)
            }, 3000);
        }
    }, [timerState])

    useImperativeHandle(ref, () => ({
        openModal: (message, title, timer, redirection, data, proceedBtnText, cancelBtnText) => {
            setModalState(true)
            setMessageState(message || "")
            setTitleState(title || "ERROR")
            if (redirection === 1) {
                setRedirect(redirection);
            }
            if (timer) {
                setTimerState(true);
            }
            setProceedBtnLabel(proceedBtnText || "");
            setCancelBtnLabel(cancelBtnText || "");
            data && setData(data);
            setTypeState(false);
        }
    }))

    const handleProceedClick = () => {
        if (data) {
            onProceed(data)
        }
        else {
            onProceed();
        }
        setModalState(false);
    }

    const handleCancelClick = () => {
        if (redirect === 1) {
            return history.push("/");
        }
        onCancel && onCancel();
        setModalState(false);
    }

    return (
        <div className="error-modal-container center-content animatable-400 ease-in-out-quart" style={{ opacity: modalState ? 1 : 0, transform: modalState ? 'scale(1)' : 'scale(0)' }}>
            <div className="error-modal-mask" onClick={() => setModalState(false)} />
            <div className="error-modal-box flex-column center-content">
                <div className="error-modal-box-header">
                    <p className="main-font-semi-bold font-size-18 font-color-white">{titleState}</p>
                    <p className="main-font-regular font-size-14 font-color-white">{messageState}</p>
                </div>
                <div className="error-modal-sub-container horizontal-end vertical-center">
                    <button type="button" className="error-modal-button center-content" style={{ background: typeState ? '#B4422E' : 'RGB(46, 160, 180)' }} onClick={handleCancelClick}>
                        <p className="main-font-regular font-size-14 font-color-white">{cancelBtnLabel || 'Nee'}</p>
                    </button>
                    {proceedBtnLabel && (
                        <button type="button" className="error-modal-button center-content margin-left-15" onClick={handleProceedClick}>
                            <p className="main-font-regular font-size-14 font-color-white">{proceedBtnLabel || 'Ja'}</p>
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
})

export default ConfirmationModal;

ConfirmationModal.defaultProps = {
    message: '',
}

ConfirmationModal.propTypes = {
    message: PropTypes.string,
    onProceed: PropTypes.func,
    onCancel: PropTypes.func,
}