import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";

import "./progress_bar.scss";

const ProgressBar = forwardRef((props, ref) => {
    const [ uploadState, setUploadState ] = useState(false);
    const [ progressState, setProgressState ] = useState(0)
    const [ fileState, setFileState ] = useState(null)

    const { cancelToken } =  props;

    useImperativeHandle(ref, () => ({
        startUpload: (currentFile) => {
            setUploadState(true);
            setFileState(currentFile || null)
        },
        onUploading: (percentage, currentFile) => {
            setUploadState(true);
            setFileState(currentFile || null)
            setProgressState(percentage)
        },
        uploadFinish: () => {
            setUploadState(false)
            setProgressState(0)
            setFileState(null)
        }
    }))

    const handleCancel = () => {
        cancelToken.cancel('upload cancelled');
        setUploadState(false)
    }

    return(
        <div className = "progress-bar-container fade-in-translate vertical-center" style = {{display: uploadState ? 'flex' : 'none'}}>
            <div className = "flex-row flex-shrink-disable">
                <div className = "document-panel-main-list-document-icon-container center-content margin-left-11 margin-right-15">
                    {fileState?.type && fileState?.type.includes('pdf') ? (
                        <img src = {require('../../assets/images/document/pdf.svg')} alt = "pdf" className = "document-panel-main-list-document-icon" />
                    ) : fileState?.type.includes('doc') ? (
                        <img src = {require('../../assets/images/document/doc.svg')} alt = "doc" className = "document-panel-main-list-document-icon" />
                    ) : fileState?.type.includes('xls') ? (
                        <img src = {require('../../assets/images/document/xls.svg')} alt = "xls" className = "document-panel-main-list-document-icon" />
                    ) : (fileState?.type.includes('png') || fileState?.type.includes('jpg') || fileState?.type.includes('jpeg')) ? (
                        <img src = {require('../../assets/images/document/img.svg')} alt = "img" className = "document-panel-main-list-document-icon" />
                    ) : (
                        <img src = {require('../../assets/images/document/other.svg')} alt = "other" className = "document-panel-main-list-document-icon" />
                    )}
                </div>
            </div>
            <div className = "flex-column width-auto">
                <div className = "flex-row horizontal-between vertical-center">
                    <div className = "flex-row vertical-center">
                        <div className = "main-font-semi-bold font-size-14 font-color-blue-grey">{fileState?.name}</div>
                        <div className = "main-font-regular font-size-14 font-color-blue-grey margin-left-15">{(fileState?.size/1048576).toFixed(1)} mb</div>
                    </div>
                    <div className = "flex-row vertical-center">
                        <img src = {require('../../assets/images/icons/cross.svg')} alt = "cross" className = "progress-bar-close" onClick = {handleCancel} />
                    </div>
                </div>
                <div className = "flex-row margin-top-5 margin-bottom-5">
                    <div className = "progress-bar-main">
                        <div className = "progress-bar-main-layer animatable-200 ease-in-out-quart" style = {{left: `${progressState - 100}%`}} />
                    </div>
                </div>
                <div className = "flex-row">
                    <div className = "main-font-regular font-size-14 font-color-blue-grey">{progressState}%</div>
                </div>
            </div>
        </div>
    )
})

export default ProgressBar;