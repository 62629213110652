import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ErrorModal from '../../../components/error_modal/error_modal';

import Select from '../../../components/select/select';

function CustomDropdown(props) {
  const [dropdownState, setDropdownState] = useState(false);

  const {
    size,
    key,
    dropdownData,
    onClick,
    setSelectedValue,
    selectedValue,
    handleUnitChange,
    errorModalRef,
  } = props;

  const handleClickEvent = (itemIndex) => {
    let exists = false;
    selectedValue.forEach((unit) => {
      if (unit.unit_id === dropdownData[itemIndex].value) {
        exists = true;
      }
    });
    if (!exists) {
      onClick(itemIndex);
      selectedValue.push(dropdownData[itemIndex].value);
      setSelectedValue(selectedValue);
      setDropdownState(false);
      const abc = selectedValue;
      handleUnitChange(abc);
    } else {
      errorModalRef.current.openModal('Die eenheid bestaat al in de lijst.');
    }
  };

  return (
    <div
      tabIndex="1"
      onBlur={() => setDropdownState(false)}
      style={{ width: size === 'small' ? '120px' : '120px' }}
      className="custom-dropdown-container horizontal-center relative"
    >
      <div
        className="height-auto width-auto flex-row horizontal-between vertical-center"
        onClick={() => setDropdownState(!dropdownState)}
      >
        <Button
          variant="contained"
          style={{
            marginTop: 15,
            background: '#F0F1F2',
            height: 26,
            width: 'fit-content',
            fontWeight: 400,
            color: '#45545D',
            boxShadow: 'none',
            textTransform: 'none',
          }}
        >
          + Toevoegen
        </Button>
      </div>
      <div
        className="custom-dropdown-expand animatable-400 ease-in-out-quart absolute"
        style={{
          zIndex: 1000,
          position: 'absolute',
          opacity: dropdownState ? 1 : 0,
          transform: dropdownState ? 'scaleY(1)' : 'scaleY(0)',
        }}
      >
        {dropdownData && dropdownData.length > 0 && dropdownData.map((item, itemIndex) => (
          <div
            className="custom-dropdown-expand-item  center-content"
            onClick={() => handleClickEvent(itemIndex)}
          >
            <p
              className="main-font-semi-bold font-size-14 font-color-black bg-white center-text"
            >
              {key ? item[key] : item.lable}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

const ProspectUnits = ({
  totalUnits,
  onlySoldList,
  selectedUnits,
  handleUnitChange,
  userProjectsUnits,
  handleProjectUnitChange,
}) => {
  const [units, setUnits] = useState(0);
  const [selectedValue, setSelectedValue] = useState([]);

  const errorModalRef = useRef(0);

  useEffect(() => {
    setTimeout(() => {
      if (selectedUnits && selectedUnits.length) {
        setUnits(selectedUnits && selectedUnits.length);
        setSelectedValue(selectedUnits);
      }
    }, 1000);
  }, [selectedUnits]);

  const handleAddUnit = () => {
    let newUnits = 0;
    if (units < 3) {
      newUnits = Number(units) + 1;
      setUnits(newUnits);
    }
  };

  const handleDeleteUnit = (i) => {
    let newUnits = units;

    if (newUnits > 0) {
      newUnits -= 1;
      setUnits(newUnits);
      selectedValue.splice(i, 1);
      setSelectedValue(selectedValue);
      handleUnitChange(selectedValue);
    }
  };
  useEffect(() => {
    setUnits([]);
  }, [userProjectsUnits]);

  const handleChange = (data, i) => {
    let exists = false;
    selectedValue.forEach((unit) => {
      if (unit.unit_id === data) {
        exists = true;
      }
    });
    if (!exists) {
      selectedValue[i] = data;
      setSelectedValue(selectedValue);
      handleUnitChange(selectedValue);
    } else {
      errorModalRef.current.openModal('Dit toestel is al toegevoegd.');
    }
  };

  useEffect(() => {
    handleProjectUnitChange(selectedValue);
  }, [units]);

  const renderUnits = () => {
    const data = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < units; i++) {
      data.push(
        <Grid container key={i} spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <p
              className="main-font-regular font-size-14 font-color-grey"
            >
              {i === 0 && 'Entiteit'}
            </p>
          </Grid>

          <Grid item xs={12} sm={12} md={8} >
            <img
              src={require('../../../assets/images/icons/less_darkbleu.svg')}
              alt="icon"
              style={{
                cursor: 'pointer',
                height: 12,
                marginRight: 8,
                paddingTop: 12,
              }}
              onClick={() => handleDeleteUnit(i)}
            />
            <Select
              style={{
                width: 150,
                height: 'inherit',
              }}
              options={userProjectsUnits}
              selected={selectedUnits[i]?.units?.id}
              className={selectedUnits[i]?.units?.sold ? 'show-red-color' : ''}
              onChange={(event) => handleChange(event.target.value, i)}
            />
          </Grid>
        </Grid>,
      );
    }

    return data || <></>;
  };

  return (
    <>
      {totalUnits > 0 && renderUnits()}

      <Grid container>
        <Grid item xs={12} sm={12} md={4} />
        <Grid item xs={12} sm={12} md={8}>
          <CustomDropdown
            size="small"
            onClick={handleAddUnit}
            dropdownData={onlySoldList}
            errorModalRef={errorModalRef}
            setSelectedValue={setSelectedValue}
            handleUnitChange={handleUnitChange}
            selectedValue={selectedUnits || selectedValue}
          />
        </Grid>
      </Grid>
      <ErrorModal ref={errorModalRef} />
    </>
  );
};

ProspectUnits.propTypes = {
  handleProjectUnitChange: PropTypes.func.isRequired,
  handleUnitChange: PropTypes.func.isRequired,
  userProjectsUnits: PropTypes.array,
  selectedUnits: PropTypes.array,
  onlySoldList: PropTypes.array,
  totalUnits: PropTypes.number,
};

ProspectUnits.defaultProps = {
  userProjectsUnits: [],
  selectedUnits: [],
  onlySoldList: [],
  totalUnits: 0,
};

export default ProspectUnits;
