import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import EmailExtension from "../email_extension"
import { emailProvider } from "../../../../common/api/routes";
import "./mailchat.scss"


const MailChat = (props) => {
    const [ mailDate, setMailDate ] = useState(new Date());
    const { notif, onClick } = props;

    useEffect(() => {
        if(notif) {
            const newDate = new Date(notif.created_at)
            setMailDate(newDate)
        }
    }, [notif])

    return (
        <div className="mainstyle">
            <div className="submainstyle">
                <EmailExtension onClick = {onClick} date = {`${mailDate.getDate()}/${mailDate.getMonth() + 1}/${mailDate.getFullYear()}`} time = {`${mailDate.getHours()}:${mailDate.getMinutes()}`} toEmail = {notif?.user?.email} fromEmail = {emailProvider} />
                <div className="stylebord" >
                    <img className="styleimage"
                        src={require('../../../../assets/images/logo/logo.svg')}
                        alt="person white"
                    />
                </div>
                <div className="stylepara" >
                    <p>Beste {notif?.user?.title?.title} {notif?.user?.lastName}</p>
                    <p className="parstyle">
                        U heeft een nieuw bericht ontvangen voor uw aankoop "{notif?.project?.name}".
                        U kan dit bericht eenvoudig raadplegen via het platform.
                    </p>
                </div>
                <button className="stylebutton">Dosier raadplegen</button>
                <div className="newpara">
                    <p>Heeft u een vraag over uw aankoop of een probleem met het online platform neem dan contact met ons op via
                    het telefoonnummer +32 9245 03 33 of via email klantenportaal@gaelensbouwprojecten.be</p>
                    <p className="parstyle">Fijne dag verder.</p>
                    <p className="parstyle">Caroline Gaelens</p>
                </div>
                <footer className="innerfooter">
                    <div className="newparagraph">
                        <p style = {{textAlign: 'center'}}>Gaelens Bouwprojecten</p>
                        <p className="parstyle" style = {{textAlign: 'center'}}>Kortrijksesteenweg 1008/001
                            B-9000 Gent</p>
                    </div>
                </footer>
            </div>
        </div>

    )
}

export default MailChat;

MailChat.propTypes = {
    notif: PropTypes.object,
    onClick: PropTypes.func,
}

MailChat.defaultProps = {
    notif: {},
    onClick: () => {},
}