export const defaultUrl = 'https://backend.develop.bim.flykube.agifly.cloud/api/v1/';
const defaultFrontendUrl = 'https://frontend.develop.bim.flykube.agifly.cloud/';
export const baseUrl = 'https://backend.develop.bim.flykube.agifly.cloud';
export const emailProvider = 'info@gaelenbouwprojection.be';

// auth api routes here
export const login = `${defaultUrl}auth/sign_in`

export const changePassword = `${defaultUrl}auth/change_password`

export const generateLoginToken = (userId) => {
  return `${defaultUrl}user/login/${userId}`
}

export const getFrontendTokenLink = (token) => {
  return `${defaultFrontendUrl}?token=${token}`
}

export const forgotPassword = `${defaultUrl}auth/forgot_password`

export const resetPassword = `${defaultUrl}auth/reset_password`

// user api routes here
export const userGet = (userId) => {
  return `${defaultUrl}user/show/${userId.toString()}`
}

export const userUpdate = (userId) => {
  return `${defaultUrl}user/${userId.toString()}`
}

export const getUserByType = (typeId) => {
  return `${defaultUrl}user/types?type_id=${typeId}`
}

export const createUser = `${defaultUrl}user`

export const getAllUsers = `${defaultUrl}user`

export const sendAccess = (userId, projectId) => {
  return `${defaultUrl}user/access/${userId}/${projectId}`
}

export const getUserById = (userId) => {
  return `${defaultUrl}user/show/${userId}`
}

// user project api routes here
export const getUserProject = (userId) => {
  return `${defaultUrl}buyer/project/list/${userId.toString()}`
}

// project api routes here
export const getProject = (projectId) => {
  return `${defaultUrl}project/show/${projectId}`
}

export const getAllProject = (search) => {
  return `${defaultUrl}project?search=${search || ''}`
}

// project unit api routes here
export const updateProjectUnit = (unitId) => {
  return `${defaultUrl}project/unit/${unitId.toString()}`
}

export const createNewUnit = () => {
  return `${defaultUrl}project/unit`
}

export const getProjectUnits = (projectId) => {
  return `${defaultUrl}project/unit/show/${projectId}`
}

export const getUnsoldUnits = (projectId) => {
  return `${defaultUrl}project/unit/unsold/${projectId}`
}

export const deleteProjectUnit = (unitId) => {
  return `${defaultUrl}project/unit/delete/${unitId}`
}

// project unit types api routes here
export const getAllProjectUnitTypes = `${defaultUrl}project/unit/type`

// project api routes here
export const updateProject = (projectId) => {
  return `${defaultUrl}/project/${projectId}`
}

// documents api routes here
export const getProjectDocuments = (projectId) => {
  return `${defaultUrl}/document/project/${projectId}`
}

export const createDocument = `${defaultUrl}document`

export const getDocumentsByUnit = (unitId) => {
  return `${defaultUrl}document/unit/${unitId}`
}

export const getDocumentsbyCategory = (categoryId) => {
  return `${defaultUrl}document/category/${categoryId}`
}

export const getDocumentsByCategoryAndUnits = (
  categoryId,
  userId,
  projectId,
  unitList,
) => {
  return `${defaultUrl}document/category/${categoryId}/${userId}/${projectId}/sold?units=${unitList}`
}

export const loginWithToken = (token) => {
  return `${defaultUrl}user/signIn/${token}`
}

export const newPassword = `${defaultUrl}user/access/signIn`

export const updateDocument = (documentId) => {
  return `${defaultUrl}document/${documentId}`
}

export const deleteDocument = (documentId) => {
  return `${defaultUrl}document/delete/${documentId}`
}

export const getDocumentCategories = `${defaultUrl}category`

export const createDocumentCategory = `${defaultUrl}category`

export const deleteDocumentCategory = (categoryId) => {
  return `${defaultUrl}category/delete/${categoryId}`
}

export const updateDocumentCategory = (categoryId) => {
  return `${defaultUrl}category/${categoryId}`
}

export const getDocumentCategoryByProject = (projectId) => {
  return `${defaultUrl}category/project/${projectId}`
}

// messages api routes here
export const getAllChats = `${defaultUrl}/chat`

export const getChatByProject = (projectId, search) => {
  if (search) {
    return `${defaultUrl}chat/project/${projectId}?search=${search}`
  }
  return `${defaultUrl}chat/project/${projectId}`
}

export const getChatById = (chatId) => {
  return `${defaultUrl}chat/show/${chatId}`
}

export const getGeneralChatFromBuyer = (buyerId) => {
  return `${defaultUrl}chat/buyer/${buyerId}/${buyerId}`
}

export const getChatsbyProjectUnitUser = (projectId) => {
  return `${defaultUrl}chat/old/${projectId}/${projectId}/0`
}

export const deleteChat = (chatId) => {
  return `${defaultUrl}chat/delete/${chatId}`
}

export const sendMessage = `${defaultUrl}chat/message/send`

export const createChat = `${defaultUrl}chat/`

// buyer api routes here
export const getProjectFromBuyer = (buyerId) => {
  return `${defaultUrl}buyer/project/show/${buyerId}`
}

export const getUsersFromProject = (projectId) => {
  return `${defaultUrl}buyer/project/user/${projectId}`
}

export const createBuyerProject = `${defaultUrl}buyer/project/create`

export const assignBuyerProject = `${defaultUrl}buyer/project`

export const updateBuyerProject = (buyerId) => {
  return `${defaultUrl}buyer/project/${buyerId}`
}

export const deleteBuyerProject = (buyerId) => {
  return `${defaultUrl}buyer/project/delete/${buyerId}`
}

export const getBuyersFromProject = (projectId) => {
  return `${defaultUrl}buyer/project/user/${projectId}`
}

// prospect api routes here
export const createProspect = `${defaultUrl}prospect/project/create`

export const getProspectTypes = `${defaultUrl}status`

// notification api routes here
export const getNotificationOfUsers = (projectId, buyerProjectId, userList) => {
  return `${defaultUrl}notification/project/${projectId}/${buyerProjectId}/users?users=${userList}`
}

export const readChatNotification = (chatId) => {
  return `${defaultUrl}notification/read/admin/${chatId}`
}

export const createNotification = `${defaultUrl}notification`

export const readNotification = (notificationId) => {
  return `${defaultUrl}notification/${notificationId}`
}

// dashboard api routes here
export const getDashboardData = `${defaultUrl}dashboard`

// web projects routes here
export const getWebProjectById = (projectId) => {
  return `${defaultUrl}web/project/show/${projectId}`
}

export const updateWebProjectById = (projectId) => {
  return `${defaultUrl}web/project/update/${projectId}`
}

export const deleteWebProjectImage = (imageId) => {
  return `${defaultUrl}web/project/image/delete/${imageId}`
}
