import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';

import CodesList from './CodesList.view';
import { getAllCodesList, addNewCode, updateCode } from './projects.api';

const CodePopUp = ({
  onClose,
  project,
  usedCodesList,
  handleCodeUpdate,
}) => {
  const useStyles = makeStyles(() => ({
    container: {
      position: 'absolute',
      width: 140,
      borderRadius: 5,
      background: '#fff',
      border: '1px solid #e1e1e1',
      boxShadow: '2px 2px 4px #9e9e9e',
    },
    codeItem: {
      padding: '7px 6px 6px',

      '&:hover': {
        background: '#F7F7F5',

        editIcon: {
          display: 'block',
        },
      },
    },
    editIcon: {
      display: 'block',
      textAlign: 'right',
      cursor: 'pointer',
    },
    addNew: {
      textAlign: 'left',
      padding: '10px 25px 10px',
      cursor: 'pointer',
    },
  }));

  const classes = useStyles();

  const wrapperRef = useRef(null);

  const [codesList, setCodesList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [codeRowHover, setCodeRowHover] = useState(-1);
  const [codeToEdit, toggleCodeToEdit] = useState(undefined);
  const [modalPosition, modalPositionEdit] = useState([]);
  const codeContainer = useRef(null)


  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onClose();
    }
  };
  const positionModal = (event,code) =>{
    modalPositionEdit([wrapperRef.current.clientHeight,event.pageY])
    toggleCodeToEdit(code)
  }

  useEffect(() => {
    getAllCodesList(setIsFetching, setCodesList, usedCodesList);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className={classes.container} ref={wrapperRef}>
      {isFetching ? (
        <div className="text-center padding-top-20 padding-bottom-20">
          Fetching Codes...
        </div>
      ) : (
        <>
          {codesList.map((code, index) => (
            <Grid
              container
              key={code.title}
              className={classes.codeItem}
              onMouseLeave={() => setCodeRowHover(-1)}
              onMouseEnter={() => setCodeRowHover(index)}

            >
              <Grid item md={2} />
              <Grid
                item
                md={8}
                style={{
                  background: code.color,
                  cursor: 'pointer',
                  borderRadius: 4,
                  padding: 1,
                  textAlign:'center'
                }}
                onClick={() => handleCodeUpdate(code, project)}
              >
                {code.title}
              </Grid>
              <Grid item md={2}>
                {codeRowHover === index && (
                  <div
                    className={classes.editIcon}
                    style={{
                      width: 20,
                      // float: 'right',
                      paddingLeft:4
                    }}
                    // onClick={(e) => toggleCodeToEdit(code),positionModal(e)}
                    onClick={(e) => positionModal(e,code)}
                  >
                    <img
                      alt="dots icon"
                      src={require('../../../assets/images/icons/3dots.svg')}
                      style={{
                        height: 5,
                      }}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          ))}

          <div className={classes.addNew} onClick={() => toggleCodeToEdit({})}>
            + Toevoegen
          </div>

          {!isUndefined(codeToEdit) && (
            <CodesList
              codeToEdit={codeToEdit}
              updateCode={updateCode}
              addNewCode={addNewCode}
              existingCodes={codesList}
              setCodesList={setCodesList}
              usedCodesList={usedCodesList}
              onClose={() => toggleCodeToEdit(undefined)}
              handleCodeUpdate={handleCodeUpdate}
              position={modalPosition}
            />
          )}
        </>
      )}
    </div>
  );
};

CodePopUp.styles = {};

CodePopUp.defaultProps = {};

CodePopUp.propTypes = {
  onClose: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  usedCodesList: PropTypes.array.isRequired,
  handleCodeUpdate: PropTypes.func.isRequired,
};

export default CodePopUp;
