import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { SpaceRow } from '../../components/space/space';
import axios from "axios";

import { getNotificationOfUsers } from '../../common/api/routes';
import { SQLDateTimeToString } from '../../common/utils/format';

import MailChat from './emails/mailchat/mailchat';
import MailProfile from './emails/mailprofile/mailprofile';

import "./tab.historiek.scss";

export default function TabContentHistoriek(props) {
  const [ data, setData ] = useState([]);
  const [ modalState, setModalState ] = useState(false);
  const [ modalData, setModalData ] = useState({})

  const { buyerData } = props;

  useEffect(() => {
    if(buyerData?.project?.id) {
      // create a string list of user ids
      let userList = buyerData.user.id;
      if(buyerData?.childs?.length > 0) {
        userList += ",";
      }
      buyerData.childs.map((user, userIndex) => {
        userList = userList + user.user.id;
        if(userIndex !== (buyerData.childs.length - 1)) {
          userList = userList + ",";
        }
      })
      axios.get(getNotificationOfUsers(buyerData.project.id, buyerData.id, userList)).then(response => {
        // we filter out type 0 since it isn't displayed
        const filteredData = response.data.data.filter(notif => notif.type !== 0)
        setData(filteredData)
      }).catch(error => console.log(error))
    }
  }, [buyerData])

  function getNotificationDescription(notif) {
    if(notif.type === 1) {
      // new message
      return `${notif?.user?.firstName || ""} ${notif?.user?.lastName || ""} stuurde een bericht voor document: ${notif?.document?.filename || ""} uit categorie: ${notif?.document?.category?.title || "-"}`
    }
    else if(notif.type === 2) {
      // download document
      return notif?.description || "";
    }
    else if(notif.type === 3) {
      // new user access
      return notif?.description || "";
    }
    else if(notif.type === 4) {
      // document view
      return notif?.description || "";
    }
    else if(notif.type === 5) {
      // document approved
      return `${notif?.user?.firstName || ""} ${notif?.user?.lastName || ""} heeft het document: ${notif?.document?.filename || ""} ${notif.document.status === 1 ? "goedgekeurd" : notif.document.status === 2 ? "afgekeurd" : ""} uit categorie: ${notif?.document?.category?.title || "-"}`
    }
    else if(notif.type === 6) {
      // document disapproved
      return `${notif?.user?.firstName || ""} ${notif?.user?.lastName || ""} heeft een paswoord gecreëerd en heeft zich voor de eerste keer geregistreerd op het platform.`
    }
  }

  function getIcon(notifType)  {
    if(notifType === 1) {
      return require('../../assets/images/icons/chat2_darkbleu.svg');
    }
    else if(notifType === 3) {
      return require('../../assets/images/icons/person_border_darkbleu.svg');
    }
    else if(notifType === 2 || notifType === 4 || notifType === 5 || notifType === 6) {
      return require('../../assets/images/icons/documents_darkbleu.svg');
    }
  }

  const openEmail = notif => {
    if(notif.mail) {
      setModalData(notif);
      setModalState(true)
    }
  }

  return (
    <>
      <div className="tab-historiek-container">
        {data && data.length > 0 && data.map(notif => (
          <>
            <Grid container>
              <div className = {`tab-historiek-card ${notif.mail && 'cursor-pointer'}`} onClick = {() => openEmail(notif)}>
                <Grid container alignItems = "center">
                  <div className = "flex-row vertical-center margin-right-64">
                    <img src = {require('../../assets/images/icons/agenda_darkbleu.svg')} alt = "agenda" className = "tab-historiek-calender margin-right-14" />
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">{SQLDateTimeToString(notif.created_at)}</p>
                  </div>
                  <div className = "flex-row vertical-center margin-right-66">
                    <img src = {require('../../assets/images/icons/mail_darkbleu.svg')} alt = "mail" className = "tab-historiek-mail margin-right-14" />
                    <div className = "flex-row">
                      <p className = "main-font-regular font-size-14 font-color-blue-grey" style = {{textDecoration: notif.mail ? 'underline' : 'none'}}>YES</p>
                      <p className = "main-font-regular font-size-14 font-color-blue-grey"> / </p>
                      <p className = "main-font-regular font-size-14 font-color-blue-grey" style = {{textDecoration: !notif.mail ? 'underline' : 'none'}}>NO</p>
                    </div>
                  </div>
                  <div className = "flex-row vertical-center">
                    <img src = {require('../../assets/images/icons/person_border_darkbleu.svg')} alt = "person" className = "tab-historiek-person margin-right-14" />
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">{notif?.user?.firstName} {notif?.user?.lastName}</p>
                  </div>
                </Grid>
                <SpaceRow top = "16" />
                <Grid container>
                  <div className = "flex-row vertical-center">
                    <img src = {getIcon(notif.type)} alt = "person" className = "tab-historiek-person margin-right-14" />
                    <p className = "main-font-semi-bold font-size-14 font-color-blue-grey">{getNotificationDescription(notif)}</p>
                  </div>
                </Grid>
              </div>
            </Grid>
            <SpaceRow top = "20" />
          </>
        ))}
        {data.length === 0 && (
          <div className = "height-auto width-auto center-content">
            <p className = "main-font-regular font-size-14 font-color-blue-grey">Nog geen historiek beschikbaar</p>
          </div>
        )}
      </div>
      <div className = "tab-historiek-modal-container center-content animatable-400 ease-in-out-quart" style = {{opacity: modalState ? 1 : 0, transform: modalState ? 'scale(1)' : 'scale(0)'}}>
          {modalData?.type === 1 && (
            <MailChat onClick = {() => setModalState(false)} notif = {modalData} />
          )}
          {modalData?.type === 3 && (
            <MailProfile onClick = {() => setModalState(false)} notif = {modalData} />
          )}
      </div>
    </>
  );
}
