import React from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";

import { SpaceRow } from "../../../components/space/space";
import { SQLDateTimeToString } from "../../../common/utils/format";

import "./message_item.scss";

export default function MessageItem(props) {
    const { type, data } = props;

    return(
        <Grid container direction = "column" alignItems = {type ? 'flex-start' : 'flex-end'} className = "fade-in-translate" >
            <div className = {`message-item-container flex-row ${type ? 'message-item-box-left' : 'message-item-box-right'}`} style = {{justifyContent: type ? 'flex-start' : 'flex-end'}}>
                <p className = "main-font-regular font-size-14 font-color-white">{data?.message}</p>
            </div>
            <div className = {`flex-row ${type ? "margin-left-18" : "margin-right-18"}`} >
                <p className = "main-font-regular font-size-12 font-color-blue-grey">{`${data?.user?.firstName || ""} ${data?.user?.lastName || ""} - ${SQLDateTimeToString(data?.created_at)}`}</p>
            </div>
            <SpaceRow top = "27" />
        </Grid>
    )
}

MessageItem.defaultProps = {
    type: false,
    data: {
        message: "Ik heb juist de plannen van de keuken gezien! 😃 Is het mogelijk om een A++ Frigo hebben? Afhankelijk van de prijzen?",
        name: "Jane Doe",
        date: "04/22/2021",
        time: "13:59"
    }
}

MessageItem.propTypes = {
    type: PropTypes.bool,
}