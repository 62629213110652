import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import axios from "axios";

import { createDocumentCategory, createDocument, getDocumentsbyCategory, deleteDocument, baseUrl } from '../../../common/api/routes';

import "./unit_document_dropdown.scss";

export default function UnitDocumentDropdown(props)  {
    const [ documentFiles, setDocumentFiles ] = useState([]);
    const [ categoryExists, setCategoryExists ] = useState(false);
    const [ dropdownState, setDropdownState ] = useState(false)
    const [ createdCategoryData, setCreatedCategoryData ] = useState({});
    const [ updateState, setUpdateState ] = useState(false)

    // category types, quality, precad, house, netm, meters
    // categoryData gives the data for the category if it exists, categoryType is local defined, if the category doesn't exist then we first create it using categoryType as a reference
    const { categoryData, projectData, categoryType, type } = props;
    const fileRef = useRef(0);

    useEffect(() => {
        // parent calls the category api, if the respective category exists then we can detect it through categoryType
        // if it doesn't exists categoryData Object will be empty
        if(categoryData?.id || createdCategoryData?.id) {
            axios.get(getDocumentsbyCategory(categoryData.id || createdCategoryData?.id)).then(response => {
                setDocumentFiles(response.data.data)
            }).catch(error => console.log(error))
            setCategoryExists(true)
        }
        else {
            setDocumentFiles([])
            setCategoryExists(false)
        }
    }, [categoryData, updateState])

    const uploadButton = () => {
        fileRef.current.click();
    }

    const handleFileSelect = event => {
        if(projectData?.id && categoryType) {
            if(event?.target?.files[0]) {
                const file = event.target.files[0];
                // upload files here
                if(categoryExists) {
                    // simply upload the document
                    let formData = new FormData();
                    formData.append("project_id", projectData.id)
                    formData.append("path", file)
                    formData.append('category_id', categoryData?.id || createdCategoryData?.id)
                    axios.post(createDocument, formData).then(response => {
                        setUpdateState(!updateState)
                    }).catch(error => console.log(error))
                }
                else {
                    // it means the category doesn't exist, so we create it
                    axios.post(createDocumentCategory, {
                        title: categoryType,
                        project_id: projectData.id
                    }).then(response => {
                        // now we upload the document
                        setCategoryExists(true);
                        setCreatedCategoryData(response.data.data);
                        let formData = new FormData();
                        formData.append("project_id", projectData.id)
                        formData.append("path", file)
                        formData.append('category_id', response.data.data.id)
                        axios.post(createDocument, formData).then(response => {
                            setUpdateState(!updateState)
                        }).catch(error => console.log(error))
                    }).catch(error => console.log(error))
                }
            }
        }
    }

    const removeDocument = event => {
        axios.delete(deleteDocument(event.target.name)).then(response => {
            setUpdateState(!updateState)
        }).catch(error => console.log(error))
    }

    const openFile = path => {
        window.open(baseUrl +  path);
    }

    return(
        <div className = "height-auto width-auto center-content relative" onMouseOver = {() => setDropdownState(true)} onMouseLeave = {() => setDropdownState(false)}>
            <button type = "button" className = {`projecten-grid-upload-button-${type === "short" ? 'one' : 'two'} center-content`}>
                <p className = "main-font-semi-bold font-size-14 font-color-blue-grey">Doc. {!categoryExists || documentFiles.length === 0 ? 'upload' : 'zien'}</p>
            </button>
            <div className = "unit-document-dropdown-container animatable-400 ease-in-out-quart" style = {{opacity: dropdownState ? 1 : 0, transform: dropdownState ? 'scaleY(1)' : 'scaleY(0)'}}>
                {documentFiles?.length > 0 && documentFiles.map(file => (
                    <div className = "unit-document-dropdown-item">
                        <p className = "main-font-regular font-size-12 font-color-blue-grey text-underline cursor-pointer" onClick = {() => openFile(file.path)} >{file.filename}</p>
                        <img src = {require('../../../assets/images/icons/minus.svg')} name = {file.id} alt = "minus" className = "unit-document-dropdown-icon" onClick = {removeDocument} />
                    </div>
                ))}
                <div className = "unit-document-dropdown-item">
                    <p className = "main-font-regular font-size-12 font-color-blue-grey">Upload new doc.</p>
                    <img src = {require('../../../assets/images/icons/add_darkbleu.svg')} alt = "add darkbleu" className = "unit-document-dropdown-icon" onClick = {uploadButton} />
                </div>
                <input type = "file" ref = {fileRef} style = {{display: 'none'}} onChange = {handleFileSelect} />
            </div>
        </div>

    )
}

UnitDocumentDropdown.propTypes = {
    categoryType: PropTypes.string.isRequired,
    categoryData: PropTypes.object,
    projectData: PropTypes.object.isRequired,
    type: PropTypes.string,
}

UnitDocumentDropdown.defaultProps = {
    categoryData: {},
    type: 'short'
}
