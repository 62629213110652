import React, { useState, useEffect } from "react";
import UnitDocumentDropdown from "../components/unit_document_dropdown";

import "../projecten.scss";

export function GeneraalItem(props) {
    const [ title, setTitle ] = useState("");
    const [ generalNumber, setGeneralNumber ] = useState("");
    const [ generalLevel, setGeneralLevel ] = useState(null);
    const [ generalRooms, setGeneralRooms ] = useState(null);
    const [ generalQuotity, setGeneralQuotity ] = useState(null);
    const [ precad, setPrecad ] = useState("");
    const [ generalHouseNumber, setGeneralHouseNumber ] = useState("");
    const [ modalState, setModalState ] = useState(false)
    const [ inputEditState, setInputEditState ] = useState(0)

    const { data, handleUpdate, projectUnitTypes } = props;

    useEffect(() => {
        if(data) {
            setTitle(data?.general_type || "");
            setGeneralNumber(data?.general_number || "")
            setGeneralLevel(data?.general_level)
            setGeneralRooms(data?.general_rooms)
            setGeneralQuotity(data?.general_quotity)
            setGeneralHouseNumber(data?.general_house_number || "")
            setPrecad(data?.precad || "");
        }
    }, [data])

    const handleBlur = () => {
        const updatedData = {
            general_number: generalNumber,
            general_level: generalLevel,
            general_rooms: generalRooms,
            general_quotity: generalQuotity,
            general_house_number: generalHouseNumber,
            precad,
        }
        
        setInputEditState(0)
        handleUpdate(updatedData, data.id);
    }

    const showTypeTitle = (id) => {
        if(projectUnitTypes) {
            let typeObject = projectUnitTypes.find(unitType => unitType.id === id);
            return typeObject?.title || "";
        }
        else {
            return "";
        }
    }

    function updateType(id) {
        setTitle(id)
        const updatedData = {
            general_type: id,
        }

        handleUpdate(updatedData, data.id);
        setModalState(false)
    } 

    const resetField = (event) => {
        if(event.target.name === "general_rooms") {
            if(generalRooms == 0) {
                setGeneralRooms('')
            }
        }
        else if(event.target.name === "general_quotity") {
            if(generalQuotity == 0) {
                setGeneralQuotity("")
            }
        }
        else if(event.target.name === "general_level") {
            if(generalLevel == 0) {
                setGeneralLevel("")
            }
        }
        else if(event.target.name === "general_number") {
            if(generalNumber == 0) {
                setGeneralNumber("")
            }
        }
    }
    
    const changeMetricsAndFormat = value => {
        if(typeof value === "number") {
            let newValue = value.toString().split(".");
            newValue[0] = [...newValue[0]].reverse().join("");
            if(newValue[0].length > 3) {
                if(newValue[0].length % 3 !== 0 ) {
                    newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                }
                else {
                    newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                    newValue[0] = newValue[0].slice(0, newValue[0].length - 1)
                }
            }
            newValue[0] = [...newValue[0]].reverse().join("");
            return `${newValue[0] || "0"},${newValue[1] ||  "00"}`;
        }
        else {
            return "-";
        }
    }

    return(
        <div className = "projecten-grid-container-one fade-in-translate">
            <div className = "width-auto projecten-grid-container-border-half">
                <div className = "projecten-grid-row-border">
                    <div className = "projecten-grid-box-one-dropdown vertical-center projecten-grid-border-right relative" tabIndex = "1" onBlur =  {() => setModalState(false)}>
                        <div className = "height-auto width-auto vertical-center cursor-pointer padding-11" onClick = {() => setModalState(!modalState)}>
                            <p className = "main-font-regular font-size-14 font-color-blue-grey">{showTypeTitle(title)}</p>
                        </div>
                        <div className = "projecten-grid-box-dropdown animatable-400 ease-in-out-quart" style = {{height: modalState ? (projectUnitTypes ? projectUnitTypes.length * 45 +"px" : '0px') : '0px', opacity: modalState ? 1 : 0}}>
                            {projectUnitTypes && projectUnitTypes.length > 0 && projectUnitTypes.map((unitType) => (
                                <div className = "projecten-grid-box-dropdown-item vertical-center cursor-pointer" onClick = {() => updateType(unitType.id)}>
                                    <p className = "main-font-regular font-size-14 font-color-blue-grey">{unitType.title}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className = "projecten-grid-box-two vertical-center projecten-grid-border-right relative horizontal-center">
                        <input className = "main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text input-no-arrows" name = "general_number" value = {generalNumber} onChange = {event => setGeneralNumber(event.target.value)} onBlur = {handleBlur} />
                    </div>
                    <div className = "projecten-grid-box-three vertical-center projecten-grid-border-right">
                        <input className = "main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text input-no-arrows" type = "number" name = "general_level" value = {typeof parseInt(generalLevel) === "number" ? generalLevel : "-"} onChange = {event => setGeneralLevel(event.target.value)} onBlur = {handleBlur}  />
                    </div>
                    <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right">
                        <input className = "main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text input-no-arrows" type = "number" name = "general_rooms" value = {typeof parseInt(generalRooms) === "number" ? generalRooms : "-"} onChange = {event => setGeneralRooms(event.target.value)} onBlur = {handleBlur} />
                    </div>
                    <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right">
                        <input className = "main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text input-no-arrows" type = "number" name = "general_quotity" value = {typeof parseInt(generalQuotity) === "number" ? generalQuotity : "-"} onChange = {event => setGeneralQuotity(event.target.value)} onBlur = {handleBlur} />
                    </div>
                    <div className = "projecten-grid-box-fourteen vertical-center projecten-grid-border-right">
                        <input className = "main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text" value = {precad} onChange = {event => setPrecad(event.target.value)} onBlur = {handleBlur} />
                    </div>
                    <div className = "projecten-grid-box-four vertical-center">
                        <input className = "main-font-regular font-size-14 font-color-blue-grey projecten-grid-box-input center-text" value = {generalHouseNumber} onChange = {event => setGeneralHouseNumber(event.target.value)} onBlur = {handleBlur} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export function GeneraalTotal(props) {
    const { data, projectData, documentCategories } = props;

    const getTotalQuotity = () => {
        if(data) {
            let total = 0;
            data.forEach(unit => {
                total = total + (unit?.general_quotity || 0);
            })
            return total;
        }
        else {
            return 0;
        }
    }

    return(
        <div className = "projecten-grid-container-one fade-in-translate">
            <div className = "projecten-grid-total-container flex-row">
                <div className = "projecten-grid-box-one vertical-center projecten-grid-total horizontal-between for-height-over-flow">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{data ? data.length : 0}</p>
                </div>
                <div className = "projecten-grid-box-two vertical-center" />
                <div className = "projecten-grid-box-three vertical-center" />
                <div className = "projecten-grid-box-four vertical-center" />
                <div className = "projecten-grid-box-four vertical-center projecten-grid-total horizontal-between for-height-over-flow">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Totaal:</p>
                    <p className = "main-font-regular font-size-14 font-color-blue-grey text-end">{getTotalQuotity()}</p>
                </div>
            </div>
            <div className = "projecten-grid-total-container flex-row">
                <div className = "projecten-grid-box-one vertical-center" />
                <div className = "projecten-grid-box-two vertical-center" />
                <div className = "projecten-grid-box-three vertical-center" />
                <div className = "projecten-grid-box-four vertical-center" />
                <div className = "projecten-grid-box-four center-content relative">
                    <UnitDocumentDropdown categoryType = "quality" projectData = {projectData} categoryData = {documentCategories.find(category => category.title === "quality")} />
                </div>
                <div className = "projecten-grid-box-fourteen center-content">
                    <UnitDocumentDropdown categoryType = "precad" projectData = {projectData} categoryData = {documentCategories.find(category => category.title === "precad")} />
                </div>
                <div className = "projecten-grid-box-four center-content">
                    <UnitDocumentDropdown categoryType = "house" projectData = {projectData} categoryData = {documentCategories.find(category => category.title === "house")} />
                </div>
            </div>
        </div>
    )
}

export default function Generaal() {

    return(
        <div className = "projecten-grid-container-one fade-in-translate">
            <div className = "width-auto projecten-grid-container-border">
                <div className = "projecten-grid-header-box center-content">
                    <p className = "main-font-semi-bold font-size-14 font-color-blue-grey">Algemeen</p>
                </div>
                <div className = "projecten-grid-row-border background-color-light-grey">
                    <div className = "projecten-grid-box-one vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Type</p>
                    </div>
                    <div className = "projecten-grid-box-two vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Nr.</p>
                    </div>
                    <div className = "projecten-grid-box-three vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Niveau</p>
                    </div>
                    <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Aant. Slpks.</p>
                    </div>
                    <div className = "projecten-grid-box-four vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Quotiteiten</p>
                    </div>
                    <div className = "projecten-grid-box-fourteen vertical-center projecten-grid-border-right">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Précad</p>
                    </div>
                    <div className = "projecten-grid-box-four vertical-center">
                        <p className = "main-font-regular font-size-14 font-color-blue-grey">Huisnummer</p>
                    </div>
                </div>
            </div>
        </div>
    )
}