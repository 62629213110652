// import { get } from 'lodash';

import { get } from 'lodash';
import request from '../../common/request';
import storage from '../../common/storage';

export const updateUserDetails = (setFetching, payload) => {
  setFetching(true);
  const userId = get(storage.get('user'), 'id') || 2;
  const response = request.put(`/user/${userId}`, payload, { 'Content-Type': 'application/json' }, false);
  const dataPromise = response.then((res) => res.data.data);
  setFetching(false);
  return dataPromise;
};
export const updateUserDetailsById = (setFetching, payload) => {
  setFetching(true);
  const response = request.put(`/user/${payload.id}`, payload, { 'Content-Type': 'application/json' }, false);
  const dataPromise = response.then((res) => res.data.data);
  setFetching(false);
  return dataPromise;
};
export const getUserDetails = (setFetching, setResponse) => {
  setFetching(true);

  const userId = get(storage.get('user'), 'id') || 2;
  const response = request.get(`/user/show/${userId}`);

  response
    .then((res) => {
      setFetching(false);
      const typeId = res.data.data;
      setResponse(res.data.data);
      console.log(typeId.type[0].id, 'type');
      localStorage.setItem('type_id', typeId.type[0]?.id);
    })
    .catch(() => {
      setFetching(false);
    });
};

export const adNewAdmin = (setFetching, setResponse, payload) => {
  setFetching(true);

  // const userId = get(storage.get('user'), 'id') || 2;
  const response = request.post(
    '/project',
    payload,
  );

  response
    .then((res) => {
      setFetching(false);
      setResponse(res.data.data);
    })
    .catch(() => {
      setFetching(false);
    });
};

export const updateAdmin = (setResponse, payload) => {
  const response = request.put(
    `/project/${payload.id}`,
    payload,
  );

  response
    .then((res) => {
      setResponse(res.data.data);
    });
};
export const getAdmins = (setFetching, search) => {
  setFetching(true);

  // const userId = get(storage.get('user'), 'id') || 2;
  // 7 is the Admin type id
  const url = search ? `/user/types?type_id=1,5&search=${search}` : '/user/types?type_id=1,5';
  const response = request.get(url);
  const dataPromise = response.then((res) => res.data.data);
  setFetching(false);
  return dataPromise;
};
export const getSellers = (setFetching, search) => {
  setFetching(true);

  // const userId = get(storage.get('user'), 'id') || 2;
  // 4 is the Seller type id
  const url = search ? `/user/types?type_id=4&search=${search}` : '/user/types?type_id=4';
  const response = request.get(url);
  const dataPromise = response
    .then((res) => {
      setFetching(false);
      return res.data.data;
    })
    .catch(() => {
      setFetching(false);
    });
  return dataPromise;
};

export const adNewUser = (setFetching, setResponse, payload, errorModalRef) => {
  setFetching(true);

  // const userId = get(storage.get('user'), 'id') || 2;
  const response = request.post('/user', payload, { 'Content-Type': 'application/json' }, false);
  const dataPromise = response
    .then((res) => {
      setFetching(false);
      return res.data.data;
    })
    .catch((error) => {
      setFetching(false);
      if (error.response.data.data.message.includes('unique_email')) {
        errorModalRef.current.openModal('Dit e-mailadres word reeds gebruikt in het systeem.', 'ERROR', 3000);
      }
    });
  return dataPromise;
};

export const delUser = (setFetching, setResponse, payload) => {
  setFetching(true);
  const response = request.delete(`/user/delete/${payload.id}`, { 'Content-Type': 'application/json' }, false);
  const dataPromise = response
    .then((res) => {
      setFetching(false);
      return res.data.data;
    })
    .catch(() => {
      setFetching(false);
    });
  return dataPromise;
};

export const adSellertoProject = (setFetching, payload) => {
  setFetching(true);
  // const userId = get(storage.get('user'), 'id') || 2;
  const response = request.post(
    '/seller/project',
    payload,
  );
  const dataPromise = response.then((res) => res.data.data);
  setFetching(false);
  return dataPromise;
};
