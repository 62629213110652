import React, { useState, useRef } from 'react';
import {
  Grid, TableBody, TableHead, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import axios from 'axios';
import { debounce } from 'lodash';
import Btn from '../../components/button/button';
import ErrorModal from '../../components/error_modal/error_modal';
import { TCell, TRow, TableCustom } from '../../components/table/table';
import { SearchInput } from '../../components/search_input/search_input';
import { SpaceRow } from '../../components/space/space';
import Select from '../../components/select/select';

import { getAllProjects } from '../dashboard/projects/projects.api';
import {
  getAdmins,
  adNewUser,
  delUser,
  updateUserDetailsById,
  adSellertoProject,
} from './settings.api';
import Spinner from '../../components/spinner/Spinner';
import DropdownMenu from '../../components/DropdownMenu';
import { defaultUrl } from '../../common/api/routes';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '100%',
  },
  spacing: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function TabContentAdmin() {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [isFetching, setIsFetching] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [user, setUser] = React.useState([]);
  const [showSelect, setShowSelect] = useState();
  const [showUpdate, setShowUpdate] = useState();
  const [value, setValue] = useState();
  const [showProject, setShowProject] = React.useState();
  const [projectOptions, setProjectOptions] = React.useState([]);
  const [tableRowHover, setTableRowHover] = useState(-1);
  const titleId = localStorage.getItem('type_id');
  const userStatus = titleId == 1;
  const errorModalRef = useRef(0);
  // const [projects, setProjects] = React.useState([]);
  const options = [
    {
      lable: 'Admin',
      value: '1',
    },
    {
      lable: 'User',
      value: '5',
    },
  ];
  React.useEffect(() => {
    getAllProjects(setIsFetching, setProjects);
    getAdmins(setIsFetching)
      .then((data) => {
        const rowsx = data.map((r) => {
          const temp = r;
          temp.toggle = false;
          return temp;
        });
        setRows(rowsx);
      });
  }, []);
  React.useEffect(() => {
    const tempoptions = projects.map((value) => {
      return {
        value: value.id,
        lable: value.name,
      };
    });
    tempoptions.push({
      value: '',
      lable: 'Select Project',
    });
    setProjectOptions(tempoptions);
  }, [projects, rows]);

  const handleTextFieldChange = (e) => {
    const key = e.target.name;
    const data = row;
    // eslint-disable-next-line no-unused-expressions
    data[key] = e.target.value;
    setRow(data);
  };
  const deleteAdmin = (id) => {
    errorModalRef.current.openDeleteModal('Weet u zeker dat u deze beheerder wenst te verwijderen?', 'Bevestigen', id);
  };

  const adminDelete = (id) => {
    const payload = { id };
    delUser(setIsFetching, () => {
    }, payload)
      .then(() => {
        getAdmins(setIsFetching)
          .then((data) => {
            const rowsx = data.map((rr) => {
              const temp = rr;
              temp.toggle = false;
              return temp;
            });
            setRows(rowsx);
          });
      });
  };

  const handleFieldEdit = (fieldkey, value, index) => {
    const newUserData = { ...user };
    newUserData[fieldkey] = value;
    setUser(newUserData);
    rows[index][fieldkey] = value;
    setRows(rows);
  };
  const handleFormUpdate = (id) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData && userData.id) {
      const payload = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        type: [
          {
            id: user.type_id ? user.type_id : options[0].value,
          },
        ],
        id,
      };
      updateUserDetailsById(setIsFetching, payload)
        .then((response) => {
          payload = {
            seller_id: response.id,
            project_id: user.project_id,
          };
          adSellertoProject(setIsFetching, payload)
            .then(() => {
              getAdmins(setIsFetching)
                .then((data) => {
                  const rowsx = data.map((rr) => {
                    const temp = rr;
                    temp.toggle = false;
                    return temp;
                  });
                  setRows(rowsx);
                  setUser([]);
                });
            });
        })

        .catch((error) => {
          console.log(error);
        });
    }
  };
  const addNewProject = (id) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    console.log('userr', user);
    if (userData.id) {
      let payload = {
        company: user?.company,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phone: user?.phone,
        id,
      };
      updateUserDetailsById(setIsFetching, payload)
        .then((response) => {
          payload = {
            seller_id: response.id,
            project_id: user?.project_id,
          };
          setOpen(false);
          if (user) {
            let status = 0;
            rows
            && rows.map((single) => {
              const abc = single?.sellerProjects?.map((row) => {
                if (row.sellerId === id) {
                  if (row.projectId === user?.project_id) {
                    return (status = 1);
                  }
                }
                if (row.projectId === user?.project_id) {
                  errorModalRef.current.openModal('Dit project is al toegewezen.');
                  return (status = 1);
                }
              });
            });
            if (status === 0) {
              adSellertoProject(setIsFetching, payload)
                .then(() => {
                  getAdmins(setIsFetching)
                    .then((Newdata) => {
                      const rowsx = Newdata.map((r) => {
                        const temp = r;
                        temp.toggle = false;
                        return temp;
                      });
                      setRows(rowsx);
                    });
                });
            } else {
              getAdmins(setIsFetching)
                .then((Newdata) => {
                  const rowsx = Newdata.map((r) => {
                    const temp = r;
                    temp.toggle = false;
                    return temp;
                  });
                  setRows(rowsx);
                });
            }
          } else {
            getAdmins(setIsFetching)
              .then((Newdata) => {
                const rowsx = Newdata.map((r) => {
                  const temp = r;
                  temp.toggle = false;
                  return temp;
                });
                setRows(rowsx);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    setShowSelect();
    setUser();
  };

  const selectProject = (sellerId, id) => {
    if (user?.project_id) {
      rows
      && rows.map((single) => {
        if (single.id === sellerId) {
          const fill = single?.sellerProjects?.filter((row) => row);
          const elementsIndex = fill.findIndex(
            (element) => element.id == id.id,
          );
          const newArray = [...fill];
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            project_id: user?.project_id,
          };
          const payload = {
            company: user?.company,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            phone: user?.phone,
            seller_projects: newArray,
            id: sellerId,
          };
          let status = 0;
          const abc = single?.sellerProjects?.map((row) => {
            if (row.sellerId === id) {
              if (row.projectId === user?.project_id) {
                return (status = 1);
              }
            }
            if (row.projectId === user?.project_id) {
              errorModalRef.current.openModal('Dit project is al toegewezen.');
              return (status = 1);
            }
          });
          if (status === 0) {
            updateUserDetailsById(setIsFetching, payload)
              .then((response) => {
                setOpen(false);
                getAdmins(setIsFetching)
                  .then((Newdata) => {
                    const rowsx = Newdata.map((r) => {
                      const temp = r;
                      temp.toggle = false;
                      return temp;
                    });
                    setRows(rowsx);
                  });
              });
          } else {
            getAdmins(setIsFetching)
              .then((Newdata) => {
                const rowsx = Newdata.map((r) => {
                  const temp = r;
                  temp.toggle = false;
                  return temp;
                });
                setRows(rowsx);
              });
          }
        }
      });
      setShowUpdate();
      setUser();
    }
  };
  const addAdmin = () => {
    const id = row.type_id === undefined ? options[0].value : row.type_id;
    console.log(id);
    if (row.firstName && row.lastName && row.email !== '' && row.phone) {
      const payload = {
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
        phone: row.phone,
        type: [
          {
            id,
          },
        ],
        title_id: '1',
      };

      adNewUser(
        setIsFetching,
        () => {
        },
        payload,
        errorModalRef,
      )
        .then(() => {
          getAdmins(setIsFetching)
            .then((data) => {
              const rowsx = data.map((row) => {
                const temp = row;
                temp.toggle = false;
                return temp;
              });
              setRows(rowsx);
              setOpen(false);
              setRow({});
            });
        });
    }
  };

  // React.useEffect(() => {
  //   getAllProjects(setIsFetching, setProjects);
  //   getDataForAdmins();
  // }, []);

  const Click = () => {
    setOpen(!open);
  };

  const getDataForAdmins = (search) => {
    getAdmins(setIsFetching, search)
      .then((data) => {
        const rowsx = data.map((rr) => {
          const temp = rr;
          temp.toggle = false;
          return temp;
        });
        setRows(rowsx);
      });
  };

  // console.log(rows, 'these are rows length');

  const handleRemoveProject = (sellerId, id) => {
    rows
    && rows.map((single) => {
      if (single.id === sellerId) {
        const filtered = single?.sellerProjects?.filter(
          (row) => row.id !== id,
        );
        const r = window.confirm(
          'Weet u zeker dat u dit project wenst te verwijderen?',
        );
        if (r === true) {
          const payload = {
            seller_projects: filtered,
          };
          axios
            .put(
              `${defaultUrl}user/${sellerId}`,
              payload,
            )
            .then((ress) => {
              getAdmins(setIsFetching)
                .then((data) => {
                  const rowsx = data.map((r) => {
                    const temp = r;
                    temp.toggle = false;
                    return temp;
                  });
                  setRows(rowsx);
                });
            });
        }
      }
    });
  };
  return (
    <div>
      <Grid container>
        <Grid container alignItems="center" item sm={6} md={7}>
          <SearchInput
            handleSearch={debounce((val) => getDataForAdmins(val), 1000)}
          />
        </Grid>
        <Grid container item sm={6} md={5} justify="flex-end">
          <div className={classes.spacing} style={{ marginRight: 22 }}>
            {/* {userStatus === true ? ( */}
            <Btn variation="primary" onClick={Click}>
              + Admin
            </Btn>
            {/* ) : (
              <div />
            )} */}
            <Btn variation="secondary">
              <ReactHTMLTableToExcel
                className="download-table-xls-button"
                id="projects-list-table-id"
                table="admins-list-table"
                buttonText="Download .xls"
                filename="AdminsList"
                sheet="Admins List"
              />
            </Btn>
          </div>
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      {isFetching ? (
        <div className="text-center padding-top-20">
          <Spinner text="Fetching Data..." />
        </div>
      ) : (
        <Grid container>
          <Grid container item md={12}>
            <TableCustom>
              <TableHead>
                <TRow>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/Combined_Shape__1_.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Voornaam
                  </TCell>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/Combined_Shape__1_.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Naam
                  </TCell>
                  <TCell align="left" colSpan={2}>
                    <img
                      src={require('../../assets/images/icons/phone_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Telefoon
                  </TCell>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/mail_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    E-mailadres
                  </TCell>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/dashboard_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Status
                  </TCell>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/dashboard_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Project
                  </TCell>
                  <TCell
                    style={{
                      width: 20,
                      border: 'none',
                    }}
                    className="last"
                  />
                </TRow>
              </TableHead>

              <TableBody>
                {rows.map((row, i) => (
                  <TRow
                    key={row.id}
                    style={{ height: 47 }}
                    onMouseEnter={() => setTableRowHover(i)}
                    onMouseLeave={() => setTableRowHover(-1)}
                  >
                    <TCell align="left" className="setting-project-">
                      <input
                        className="main-font-regular  font-size-14 font-color-black cursor-pointer profile-editable"
                        value={row?.firstName || ''}
                        onChange={(event) => handleFieldEdit('firstName', event.target.value, i)}
                        onBlur={() => {
                          handleFormUpdate(row.id);
                        }}
                      />
                    </TCell>
                    <TCell align="left" className="setting-project-">
                      <input
                        className="main-font-regular  font-size-14 font-color-black cursor-pointer profile-editable"
                        value={row?.lastName || ''}
                        onChange={(event) => handleFieldEdit('lastName', event.target.value, i)}
                        onBlur={() => {
                          handleFormUpdate(row.id);
                        }}
                      />
                    </TCell>
                    <TCell
                      align="left"
                      colSpan={2}
                      className="setting-project-"
                    >
                      <input
                        className="main-font-regular  font-size-14 font-color-black cursor-pointer profile-editable"
                        value={row?.phone || ''}
                        onChange={(event) => handleFieldEdit('phone', event.target.value, i)}
                        onBlur={() => {
                          handleFormUpdate(row.id);
                        }}
                      />
                    </TCell>
                    <TCell align="left" className="setting-project-">
                      <input
                        className="main-font-regular  font-size-14 font-color-black cursor-pointer profile-editable"
                        value={row?.email || ''}
                        onChange={(event) => handleFieldEdit('email', event.target.value, i)}
                        onBlur={() => {
                          handleFormUpdate(row.id);
                        }}
                      />
                    </TCell>

                    <TCell align="left" className="setting-project-">
                      <span
                        style={{ display: 'block' }}
                        onClick={() => {
                          row.toggle = true;
                          rows[i] = row;
                          setRows([...rows]);
                        }}
                      >
                        <Select
                          options={options}
                          onChange={(event) => handleFieldEdit('type_id', event.target.value, i)}
                          onBlur={() => {
                            handleFormUpdate(row.id);
                            row.toggle = true;
                            rows[i] = row;
                            setRows([...rows]);
                          }}
                          name="type_id"
                          selected={row.type[0].id}
                          style={!row.toggle ? { display: 'none' } : {}}
                          value={row.type_id}
                        />
                        <span
                          style={row.toggle ? { display: 'none' } : { display: 'contents' }}
                        >
                          {row.type[0].title}
                        </span>
                      </span>
                    </TCell>
                    {/* <TCell align="left">{row.Password}</TCell> */}
                    <TCell align="left" className="setting-project-">
                      <span
                        onClick={() => {
                          row.toggle = true;
                          rows[i] = row;
                          setRows([...rows]);
                        }}
                      >
                        <div align="left" style={{ width: '100%' }}>
                          {row?.sellerProjects?.length === 0 ? (
                            <Select
                              options={projectOptions}
                              onChange={(event) => handleFieldEdit(
                                'project_id',
                                event.target.value,
                                i,
                              )}
                              onBlur={() => {
                                addNewProject(row.id);
                                row.toggle = true;
                                rows[i] = row;
                                setRows([...rows]);
                              }}
                              name="project_id"
                              selected={
                                !!row?.sellerProjects && !!row?.sellerProjects
                                  ? row?.sellerProjects[0]?.id
                                  : ''
                              }
                              value={row.seller_projects}
                            />
                          ) : (
                            row?.sellerProjects
                              ?.sort((a, b) => a.id - b.id)
                              ?.map((single, index) => (
                                <>
                                  {single.id !== showUpdate && (
                                    <span
                                      style={
                                        single?.project !== null
                                          ? {
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '6px 0px',
                                          }
                                          : {}
                                      }
                                    >
                                      {' '}
                                      <span
                                        onClick={() => {
                                          setShowUpdate(
                                            single.id === showUpdate
                                              ? ''
                                              : single.id,
                                          );
                                          setShowSelect();
                                          setValue(single);
                                        }}
                                      >
                                        {single?.project?.name !== null
                                        && single?.project?.name}
                                      </span>
                                      <div>
                                        <img
                                          src={require('../../assets/images/choose/Combined_Shape__5_.svg')}
                                          alt="no image"
                                          style={{
                                            width: '20px',
                                            heigth: '20px',
                                            cursor: 'pointer',
                                            padding: '0px 5px',
                                          }}
                                          onClick={() => {
                                            handleRemoveProject(
                                              row.id,
                                              single.id,
                                            );
                                          }}
                                        />
                                      </div>
                                    </span>
                                  )}
                                  {single.id === showUpdate && (
                                    <Select
                                      options={projectOptions}
                                      onChange={(event) => handleFieldEdit(
                                        'project_id',
                                        event.target.value,
                                        i,
                                      )}
                                      onBlur={() => {
                                        selectProject(
                                          row.id,
                                          single,
                                          index,
                                          i,
                                        );
                                        row.toggle = true;
                                        rows[i] = row;
                                        setRows([...rows]);
                                      }}
                                      // name="project_id"
                                      selected={value?.project?.id}
                                      // style={!row.toggle ? { display: 'none' } : {}}
                                      value={value?.project?.id}
                                    />
                                  )}
                                </>
                              ))
                          )}
                          {row.id === showSelect && (
                            <Select
                              options={projectOptions}
                              onChange={(event) => handleFieldEdit(
                                'project_id',
                                event.target.value,
                                i,
                              )}
                              onBlur={() => {
                                addNewProject(row.id);
                                row.toggle = true;
                                rows[i] = row;
                                setRows([...rows]);
                              }}
                              name="project_id"
                              // selected={
                              //   !!row?.sellerProjects && !!row?.sellerProjects
                              //     ? row?.sellerProjects[0]?.id
                              //     : ''
                              // }
                              // style={!row.toggle ? { display: 'none' } : {}}
                              // value={row.seller_projects}
                            />
                          )}

                          {(row?.sellerProjects?.length >= 1 && row.id !== showSelect) && (
                            <div style={{ float: 'right' }}>
                              <img
                                src={require('../../assets/images/choose/Combined_Shape__4_.svg')}
                                alt="no image"
                                style={{
                                  width: '20px',
                                  heigth: '20px',
                                  cursor: 'pointer',
                                  padding: '0px 5px',
                                }}
                                onClick={() => {
                                  setShowSelect(row.id === showSelect ? '' : row.id);
                                  setShowUpdate();
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </span>
                    </TCell>
                    <TCell align="left" className="last">
                      {tableRowHover === i ? (
                        <Grid container>
                          <Grid item md={6} style={{ textAlign: 'right' }}>
                            <DropdownMenu
                              handleDeleteAction={() => deleteAdmin(row.id)}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                    </TCell>
                  </TRow>
                ))}

                {open && (
                  <>
                    <TRow style={{ border: '0px solid transparent' }}>
                      <TCell
                        style={{
                          border: '0px solid transparent',
                          height: '21px',
                        }}
                        colSpan={5}
                      />
                    </TRow>
                    <TRow>
                      <TCell className="padding-50 " align="left">
                        <TextField
                          value={row.firstName}
                          name="firstName"
                          onChange={handleTextFieldChange}
                        />
                      </TCell>
                      <TCell align="left">
                        <TextField
                          value={row.lastName}
                          name="lastName"
                          onChange={handleTextFieldChange}
                        />
                      </TCell>
                      <TCell align="left" colSpan={2}>
                        <TextField
                          value={row.phone}
                          name="phone"
                          onChange={handleTextFieldChange}
                        />
                      </TCell>
                      <TCell align="left">
                        <TextField
                          value={row.email}
                          name="email"
                          onChange={handleTextFieldChange}
                        />
                      </TCell>
                      <TCell align="left">
                        <Select
                          options={options}
                          onChange={handleTextFieldChange}
                          name="type_id"
                          value={row.type_id}
                        />
                      </TCell>
                      <TCell align="left" className="last">
                        <Btn
                          variation="secondary"
                          onClick={() => {
                            addAdmin();
                          }}
                        >
                          <span
                            style={{
                              fontSize: 11,
                              lineHeight: 1,
                            }}
                          >
                            {' '}
                            Opslaan en
                            <br />
                            verzend toegang
                          </span>
                        </Btn>
                      </TCell>
                    </TRow>
                  </>
                )}
              </TableBody>
            </TableCustom>

            <table id="admins-list-table" style={{ display: 'none' }}>
              <tr>
                <th>Voornaam</th>
                <th>Naam</th>
                <th>Telefoon</th>
                <th>E-mailadres</th>
                <th>Status</th>
                <th>Project</th>
              </tr>
              {rows.map((rr) => (
                <tr key={rr.id}>
                  <td>{rr?.firstName || ''}</td>
                  <td>{rr?.lastName || ''}</td>
                  <td>{rr?.phone || ''}</td>
                  <td>{rr?.email || ''}</td>
                  <td>{rr?.type[0]?.title}</td>
                  <td>
                    {rr?.sellerProjects
                      ?.sort((a, b) => a.id - b.id)
                      ?.map((single) => (
                        <span>
                          {single?.project?.name !== null
                          && single?.project?.name}
                          <br />
                        </span>
                      ))}
                  </td>
                </tr>
              ))}
            </table>
          </Grid>
        </Grid>
      )}
      <ErrorModal ref={errorModalRef} onDelete={(id) => adminDelete(id)} />
    </div>
  );
}
