/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { get, debounce } from 'lodash';
import {
  Grid, TableBody, TableHead,
} from '@material-ui/core';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Btn from '../../components/button/button';
import BtnCompact from '../../components/button/btnCompact';
import { TCell, TRow, TableCustom } from '../../components/table/table';
import { SearchInput } from '../../components/search_input/search_input';
import { SpaceRow } from '../../components/space/space';
import Wrapper from '../../components/wrapper/wrapper';
import Chip from '../../components/chip/chip';
import { getAllProjects } from '../dashboard/projects/projects.api';
import Dropdown from '../../components/dropdown/dropdown';
import { getBuyer } from './koopers.api';
import Spinner from '../../components/spinner/Spinner';
import {
  generateLoginToken,
  getFrontendTokenLink,
} from '../../common/api/routes';

const IconToggle = ({
  toggle,
  onClick,
}) => {
  const [open, setOpen] = React.useState(toggle);

  return (

    <span
      style={{
        height: '15px',
        width: '15px',
        float: 'right',
        marginTop: '2px',
      }}
      onClick={() => {
        setOpen(!open);
        onClick();
      }}
    >
      {open ? (
        <img
          src={require('../../assets/images/icons/less_darkbleu.svg')}
          alt="icon"
          className="cursor-pointer object-fit-contain"
        />
      ) : (
        <img
          src={require('../../assets/images/icons/add_darkbleu.svg')}
          alt="icon"
          className="cursor-pointer object-fit-contain"
        />
      )}
    </span>
  );
};
const createBuyers = (data, open) => {
  const trow = [];

  // Outer loop to create parent
  for (let i = 1; i < data.length; i++) {
    const children = [];

    children.push(<TCell align="left" colSpan="1" className="last" />);
    children.push(<TCell align="left">{data[i].name}</TCell>);
    children.push(<TCell align="left">{data[i].name}</TCell>);
    children.push(<TCell align="left">{data[i].name}</TCell>);
    children.push(<TCell align="left" colSpan="4" className="last" />);
    trow.push(
      <TRow
        key={`a${i}`}
        style={open ? {} : { display: 'none' }}
      >
        {children}
      </TRow>,
    );
  }

  return trow;
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '100%',
    // borderLeft: '1px solid rgb(223, 223, 222)',
  },
  spacing: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function Kopers() {
  const [rows, setRows] = React.useState([]);
  const [projectData, setProjectData] = useState([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [tableRowHover, setTableRowHover] = useState(-1);
  const [selectedSearch, setSelectedSearch] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState('');

  const history = useHistory();
  React.useEffect(() => {
    getAllProjects(setIsFetching, setProjectData, '', true);
    getBuyer(setIsFetching)
      .then((data) => {
        const rowsx = data.map((r) => {
          const temp = r;
          temp.toggle = false;
          return temp;
        });

        setRows(rowsx);
      });
  }, []);

  function unitData(data) {
    const finaldata = data.map((d) => {
      return `${get(d, 'units.type.title', '-')} ${get(d, 'units.generalNumber', '-')}`;
    });
    return (
      <>
        <TCell style={{
          textAlign: 'left',
          wordWrap: 'break-word',
        }}
        >
          {finaldata[0] ? finaldata[0] : ' - '}
        </TCell>
        <TCell style={{
          textAlign: 'left',
          wordWrap: 'break-word',
        }}
        >
          {finaldata[1] ? finaldata[1] : ' - '}
        </TCell>
        <TCell style={{
          textAlign: 'left',
          wordWrap: 'break-word',
        }}
        >
          {finaldata[2] ? finaldata[2] : ' - '}
        </TCell>
      </>
    );
  }

  const loginAsUser = (userId) => {
    axios.get(generateLoginToken(userId))
      .then((response) => {
        window.open(getFrontendTokenLink(response.data.data.data));
      })
      .catch((error) => console.log(error));
  };

  const returnKopersTable = (isDownload) => {
    return (
      <div style={isDownload ? { display: 'none' } : {}}>
        {isFetching ? (
          <div className="text-center padding-top-20">
            <Spinner text="Fetching Projects..." />
          </div>
        ) : (
          <TableCustom id={isDownload ? 'kopers-list-table' : ''}>
            <TableHead>
              <TRow>

                <TCell style={{ minWidth: 60 }} align="left">
                  <img
                    src={require('../../assets/images/icons/pin_darkbleu.svg')}
                    alt="icon"
                    style={{ height: '15px' }}
                  />
                  Code
                </TCell>

                <TCell align="left">
                  <img
                    src={require('../../assets/images/icons/Combined_Shape__1_.svg')}
                    alt="icon"
                    style={{ height: '15px' }}
                  />
                  Voornaam / Naam
                </TCell>
                <TCell align="left">
                  <img
                    src={require('../../assets/images/icons/phone_darkbleu.svg')}
                    alt="icon"
                    style={{ height: '15px' }}
                  />
                  Telefoon
                </TCell>
                <TCell align="left">
                  <img
                    src={require('../../assets/images/icons/mail_darkbleu.svg')}
                    alt="icon"
                    style={{ height: '15px' }}
                  />
                  E-mailadres
                </TCell>
                <TCell style={{ minWidth: 180 }} colSpan="3">
                  <img
                    src={require('../../assets/images/icons/building_darkbleu.svg')}
                    alt="icon"
                    style={{ height: '15px' }}
                  />
                  Entiteit(en)
                </TCell>
                {!isDownload && (
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/building_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Acties
                  </TCell>
                )}
                <TCell align="left" className="last" />
              </TRow>
            </TableHead>
            <TableBody>
              {rows && rows.length ? (
                rows.map((row, i) => (
                  <>
                    <TRow
                      onMouseEnter={() => setTableRowHover(i)}
                      onMouseLeave={() => setTableRowHover(-1)}
                      key={i}
                    >
                      <TCell align="left">
                        <Chip color={row.project.color}>
                          {row.project.code}
                        </Chip>
                      </TCell>

                      <TCell style={{
                        textAlign: 'left',
                        wordWrap: 'break-word',
                      }}
                      >
                        {row.user.firstName}
                        {' '}
                        {row.user.lastName}
                        {row.user.length > 1 ? (
                          <IconToggle
                            toggle={row.collapse}
                            onClick={() => {
                              rows[i].collapse = !row.collapse;
                              setRows([...rows]);
                            }}
                          />
                        ) : ''}
                      </TCell>
                      <TCell style={{
                        textAlign: 'left',
                        wordWrap: 'break-word',
                      }}
                      >
                        {row.user.phone}
                      </TCell>
                      <TCell style={{
                        textAlign: 'left',
                        wordWrap: 'break-word',
                      }}
                      >
                        {row.user.email}
                      </TCell>
                      {unitData(row.userUnits)}
                      {!isDownload && (
                        <TCell style={{
                          textAlign: 'left',
                          wordWrap: 'break-word',
                        }}
                        >
                          <div style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                          }}
                          >
                            <BtnCompact
                              style={{
                                margin: 5,
                                backgroundColor: row?.project?.doc ? '#F4E1E1' : '#F4F4F4',
                              }}
                              onClick={() => history.push({
                                pathname: `/toevoegen/aankoper/${row.id}`,
                                search: '4',
                              })}
                            >
                              Doc
                            </BtnCompact>
                            {' '}
                            <BtnCompact
                              style={{
                                margin: 5,
                                backgroundColor: row?.project?.message ? '#F4E1E1' : '#F4F4F4',
                              }}
                              onClick={() => history.push({
                                pathname: `/toevoegen/aankoper/${row.id}`,
                                search: '5',
                              })}
                            >
                              Berichten
                            </BtnCompact>
                            {' '}
                            <BtnCompact
                              style={{ margin: 5 }}
                              onClick={() => history.push({
                                pathname: `/toevoegen/aankoper/${row.id}`,
                                search: '2',
                              })}
                            >
                              Profiel
                            </BtnCompact>
                            {' '}
                            <BtnCompact
                              style={{ margin: 5 }}
                              onClick={() => loginAsUser(row.user.id)}
                            >
                              Log
                            </BtnCompact>
                          </div>
                        </TCell>
                      )}
                      <TCell style={{
                        width: 30,
                        border: 'none',
                        textAlign: 'left',
                        wordWrap: 'break-word',
                      }}
                      >
                        {tableRowHover === i ? (
                          <Grid container>
                            <Grid item md={6}>
                              <img
                                alt="search icon"
                                src={require('../../assets/images/icons/eye_square.svg')}
                                style={{
                                  cursor: 'pointer',
                                  height: 30,
                                }}
                                onClick={() => history.push(`/toevoegen/aankoper/${row.id}`)}
                              />
                            </Grid>
                          </Grid>
                        ) : ''}
                      </TCell>
                    </TRow>
                    {row.buyer.length > 1 && (
                      createBuyers(row.buyer, row.collapse)
                    )}
                  </>
                ))) : (
                  <TRow>
                    <TCell
                      component="td"
                      colSpan={10}
                      scope="row"
                      align="center"
                      style={{
                        padding: 20,
                        fontWeight: 500,
                        color: 'red',
                        textAlign: 'center',
                      }}
                    >
                      No Prospects found.
                    </TCell>
                  </TRow>
              )}
            </TableBody>
          </TableCustom>
        )}
      </div>
    );
  };

  const classes = useStyles();

  return (
    <div style={{ overflow: 'auto' }}>

    <Wrapper title="Kopers" pagename="Kopers" isOnTop = {false}>
      <Grid container style={{ marginTop: 80 }} md={12} spacing={2}>
        <Grid container alignItems="center" md={6}>
          <Dropdown
            defaultValue="Alle projecten"
            marginRight={15}
            data={projectData || []}
            selected={projectData[selectedProject]?.name}
            setSelected={(val) => {
              setSelectedProject(val);
              setSelectedProjectId(projectData[val]?.id);
              getBuyer(
                setIsFetching,
                setRows,
                selectedSearch,
                projectData[val]?.id,
              );
            }}
            icon={require('../../assets/images/icons/pin_darkbleu.svg')}
          />
          <SearchInput
            handleSearch={
              debounce((value) => {
                setSelectedSearch(value);
                getBuyer(
                  setIsFetching,
                  setRows,
                  value,
                  selectedProjectId,
                );
              }, 1000)
            }
          />

        </Grid>
        <Grid
          container
          item
          md={6}
          justify="flex-end"
          style={{ paddingRight: tableRowHover === -1 ? 0 : 30 }}
        >
          <div className={classes.spacing}>
            <Btn
              variation="primary"
              onClick={() => history.push('/toevoegen/koper')}
            >
              + Koper
            </Btn>
            <Btn variation="secondary">
              <ReactHTMLTableToExcel
                className="download-table-xls-button"
                buttonText="Download .xls"
                id="kopers-list-table-id"
                table="kopers-list-table"
                filename="KopersList"
                sheet="Kopers List"
              />
            </Btn>
          </div>
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container md={12} >
        <Grid item md={12}>
          {returnKopersTable(false)}
          {returnKopersTable(true)}
        </Grid>
      </Grid>
    </Wrapper>
    </div>

  );
}
