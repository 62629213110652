import React from 'react';

function Chip(props) {
  return (
    <div
      style={{
        background: props.color,
        margin: ' 0 15%;',
        padding: '2px 4px',
        textAlign: 'center',
        borderRadius: '5px',
      }}
    >
       {props.children}
    </div>
  );
}

export default Chip;
