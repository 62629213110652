import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import { useHistory } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import { isEmpty, get, isUndefined } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import ErrorModal from '../../../components/error_modal/error_modal';

import CodePopup from './CodePopup.view';
import AddProject from './AddProject.view';
import Spinner from '../../../components/spinner/Spinner';
import DropdownMenu from '../../../components/DropdownMenu';
import {
  updateProject,
  deleteProject,
  adNewProject,
} from './projects.api';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    boxShadow: 'none',
  },
  code: {
    padding: '2px 4px',
    textAlign: 'center',
    margin: '0 15%',
    borderRadius: 5,
  },
  tableHeading: {
    border: '1px solid #DFDFDE',
    background: '#F8F8F8',
    color: '#45545D',
    fontWeight: 400,
    padding: 6,
  },
  tableHeading2: {
    border: '1px solid #DFDFDE',
    background: '#F8F8F8',
    color: '#45545D',
    fontWeight: 400,
    padding: 6,
    width: 60,
  },
  tableCell: {
    border: '1px solid #DFDFDE',
    padding: 6,
  },
  tableCellDelete: {
    border: 'none',
    padding: 5,
  },
});

const ProjectsList = ({
  handleShowAddRow,
  usedCodesList,
  setProjects,
  showAddRow,
  isFetching,
  projects,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [showCodePopup, toggleShowCodePopup] = useState(-1);
  const [isAdding, setIsAdding] = useState(false);
  const [newProject, setNewProject] = useState({});
  const [keyToEdit, setKeyToEdit] = useState({});
  const [tableRowHover, setTableRowHover] = useState(-1);

  const errorModalRef = useRef(0);

  useEffect(() => {
    if (!isEmpty(newProject)) {
      const updatedProjects = projects;

      if (!isEmpty(keyToEdit)) {
        const index = updatedProjects.findIndex((i) => i.id === newProject.id);
        updatedProjects[index] = newProject;
        setKeyToEdit({});
      } else if (showCodePopup < 0) {
        updatedProjects.push(newProject);
        handleShowAddRow(false);
      }
      toggleShowCodePopup(-1);

      setProjects(updatedProjects);
      setNewProject({});
    }
  }, [newProject]);

  const handleAddProject = (payload) => {
    adNewProject(setIsAdding, setNewProject, payload, history);
  };

  const handleEditClicked = (key, id, value) => {
    setKeyToEdit({ key, id, value });
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    setKeyToEdit({ ...keyToEdit, value });
  };

  const handleEditSave = (key, project) => {
    if ((project[key] !== keyToEdit.value) && !isUndefined(keyToEdit.value)) {
      updateProject(
        setNewProject,
        { ...project, [key]: keyToEdit.value },
        setProjects,
        toggleShowCodePopup,
      );
    }
  };

  const handleCodeUpdate = (code, project) => {
    updateProject(
      setNewProject,
      { ...project, code: code.title, color: code.color },
      setProjects,
      toggleShowCodePopup,
    );
  };

  const handleDeleteProject = (projectId) => {
    // eslint-disable-next-line no-alert
    errorModalRef.current.openDeleteModal('Weet u zeker dat u dit project wenst te verwijderen?', 'Bevestigen', projectId)
    // const projectDelete = window.confirm('Weet u zeker dat u dit project wenst te verwijderen?');

    // if (projectDelete === true) {
    //   deleteProject(projectId, setProjects);
    // }
  };

  const getAppTitle = (project, title) => {
    return `${get(project?.types?.find((p) => p.title === title), 'soldUnits', 0)}\\${get(project?.types?.find((p) => p.title === title), 'totalUnits', 0)}`;
  };

  return (
    <TableContainer>
      {isFetching ? (
        <div className="text-center padding-top-20">
          <Spinner text="Fetching Projects..." />
        </div>
      ) : (
        <Table className={classes.table} aria-label="simple table" id="projects-list-table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="center" className={classes.tableHeading}>Projecten</TableCell>
              <TableCell colSpan={2} align="center" className={classes.tableHeading}>Locatie</TableCell>
              <TableCell colSpan={7} align="center" className={classes.tableHeading}>Verkochte entiteiten</TableCell>
              <TableCell style={{ maxWidth: 30, border: 'none' }} />
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableHeading}>
                <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                  <img
                    alt="search icon"
                    src={require('../../../assets/images/icons/pin_darkbleu.svg')}
                    style={{
                      height: 15,
                      marginRight: 5,
                    }}
                  />
                </span>
                Code
              </TableCell>
              <TableCell className={classes.tableHeading}>
                <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                  <img
                    alt="search icon"
                    src={require('../../../assets/images/icons/building_darkbleu.svg')}
                    style={{
                      height: '15px',
                      marginRight: 10,
                    }}
                  />
                </span>
                Naam
              </TableCell>
              <TableCell className={classes.tableHeading}>
                <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                  <img
                    alt="search icon"
                    src={require('../../../assets/images/icons/bousolle_darkbleu.svg')}
                    style={{
                      height: '15px',
                      marginRight: 10,
                    }}
                  />
                </span>
                Straat & nr
              </TableCell>
              <TableCell className={classes.tableHeading}>
                Postcode & Gemeente
              </TableCell>
              <TableCell className={classes.tableHeading2}>
                App.
              </TableCell>
              <TableCell className={classes.tableHeading2}>
                Park.
              </TableCell>
              <TableCell className={classes.tableHeading2}>
                Berg.
              </TableCell>
              <TableCell className={classes.tableHeading2}>
                Park. & Berg.
              </TableCell>
              <TableCell className={classes.tableHeading2}>
                Kantoor
              </TableCell>
              <TableCell className={classes.tableHeading2}>
                Handel
              </TableCell>
              <TableCell className={classes.tableHeading2}>
                Totaal
              </TableCell>
              <TableCell style={{ maxWidth: 30, border: 'none' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {projects && projects.length ? (
                projects.map((project, index) => (
                  <TableRow
                    key={project.name}
                    style={{ height: 45 }}
                    onMouseEnter={() => setTableRowHover(index)}
                    onMouseLeave={() => setTableRowHover(-1)}
                  >
                    <TableCell scope="row" align="center" className={classes.tableCell} style={{ minWidth: 60 }}>
                      <div
                        className={classes.code}
                        onClick={() => toggleShowCodePopup(index)}
                        style={{ background: project.color || '#f4f4f4', cursor: 'pointer', textAlign: 'center' }}
                      >
                        {project.code || '-'}
                      </div>
                      {(showCodePopup === index) && (
                        <CodePopup
                          project={project}
                          usedCodesList={usedCodesList}
                          handleCodeUpdate={handleCodeUpdate}
                          onClose={() => toggleShowCodePopup(-1)}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      onClick={() => handleEditClicked('name', project.id, project.name)}
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {(keyToEdit.key === 'name' && keyToEdit.id === project.id) ? (
                        <input
                          value={get(keyToEdit, 'value', '')}
                          onBlur={() => handleEditSave('name', project)}
                          onChange={handleOnChange}
                          name="name"
                          type="text"
                        />
                      ) : project.name}
                    </TableCell>
                    <TableCell
                      onClick={() => handleEditClicked('street', project.id, project.street)}
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {(keyToEdit.key === 'street' && keyToEdit.id === project.id) ? (
                        <input
                          value={get(keyToEdit, 'value', '')}
                          onBlur={() => handleEditSave('street', project)}
                          onChange={handleOnChange}
                          name="street"
                          type="text"
                        />
                      ) : project.street}
                    </TableCell>
                    <TableCell
                      onClick={() => handleEditClicked('city', project.id, project.city)}
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {(keyToEdit.key === 'city' && keyToEdit.id === project.id) ? (
                        <input
                          value={get(keyToEdit, 'value', '')}
                          onBlur={() => handleEditSave('city', project)}
                          onChange={handleOnChange}
                          name="city"
                          type="text"
                        />
                      ) : project.city}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {getAppTitle(project, 'App.')}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {getAppTitle(project, 'Park.')}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {getAppTitle(project, 'Berg.')}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {getAppTitle(project, 'Park.+ Berg.')}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {getAppTitle(project, 'Kantoor')}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {getAppTitle(project, 'Handel')}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {`${project.soldUnits}\\${project.totalUnits}`}
                    </TableCell>
                    <TableCell className={classes.tableCellDelete} style={{ width: 55, border: 'none', textAlign: 'left' }}>
                      {tableRowHover === index ? (
                        <Grid container>
                          <Grid item md={6}>
                            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                            <img
                              alt="search icon"
                              src={require('../../../assets/images/icons/eye_square.svg')}
                              style={{
                                cursor: 'pointer',
                                height: 30,
                              }}
                              onClick={() => history.push({
                                pathname: '/lijst/projects/units',
                                search: `${project.id}`,
                              })}
                            />
                          </Grid>
                          <Grid item md={6} style={{ textAlign: 'right' }}>
                            <DropdownMenu
                              handleDeleteAction={() => handleDeleteProject(project.id)}
                            />
                          </Grid>
                        </Grid>
                      ) : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell component="td" colSpan={11} scope="row" align="center" style={{ padding: 20, fontWeight: 500, color: 'red' }}>
                    No Projects found.
                  </TableCell>
                </TableRow>
              )}
            </>
            {showAddRow && (
              <AddProject
                isAdding={isAdding}
                usedCodesList={usedCodesList}
                handleAddProject={handleAddProject}
                handleShowAddRow={handleShowAddRow}
                toggleShowCodePopup={toggleShowCodePopup}
              />
            )}
          </TableBody>
        </Table>
      )}
      <ErrorModal ref = {errorModalRef} onDelete = {(id) => deleteProject(id, setProjects)} />
    </TableContainer>
  );
};

ProjectsList.propTypes = {
  handleShowAddRow: PropTypes.func.isRequired,
  usedCodesList: PropTypes.array.isRequired,
  setProjects: PropTypes.func.isRequired,
  showAddRow: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  projects: PropTypes.array.isRequired,
};

export default ProjectsList;
