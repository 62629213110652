import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';

const Verkoopprijzen = ({
  data,
  tuinPercentage,
  terassPercentage,
}) => {
  const [priceTotal, setPriceTotal] = useState(0);
  const [pricePerM, setPricePerM] = useState(0);
  const [priceGround, setPriceGround] = useState(0);
  const [priceConstruction, setPriceConstruction] = useState(0);

  useEffect(() => {
    if (data) {
      setPriceTotal(data?.price_total || 0);
      setPricePerM(data?.price_m || 0);
      setPriceGround(data?.price_ground || 0);
      setPriceConstruction(data?.price_construction || 0);
    }
  }, [data]);

  const calculatePond = () => {
    let terassValue = data.surface_terrace;
    let tuinValue = data.surface_garden_terrace;
    terassValue *= (terassPercentage / 100);
    tuinValue *= (tuinPercentage / 100);
    return data.surface_brut_m + terassValue + tuinValue;
  };

  const calculatePrijs = () => {
    const pond = calculatePond();
    const totalPrijs = (priceTotal / pond).toFixed(2);
    return (totalPrijs === 'NaN' || totalPrijs === 'Infinity') ? 0 : totalPrijs;
  };

  const changeMetricsAndFormat = (value) => {
    if (value !== null && value !== undefined && isNumber(parseFloat(value))) {
      const newValue = value.toString().split('.');
      newValue[0] = [...newValue[0]].reverse().join('');
      if (newValue[0].length > 3) {
        if (newValue[0].length % 3 !== 0) {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
        } else {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
          newValue[0] = newValue[0].slice(0, newValue[0].length - 1);
        }
      }
      newValue[0] = [...newValue[0]].reverse().join('');
      return `${newValue[0] || '0'}.${newValue[1] || '00'}`;
    }
    return '-';
  };

  return (
    <>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat(calculatePrijs()) || 0}</td>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat(priceTotal) || 0}</td>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat(priceGround) || 0}</td>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat(priceTotal - priceGround) || 0}</td>
    </>
  );
};

Verkoopprijzen.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Verkoopprijzen;

export function VerkoopprijzenTableTotal({
  data,
  projectData,
}) {
  const getTotal = (key) => {
    if (data) {
      let total = 0;
      data.forEach((unit) => {
        total += (unit[key] || 0);
      });
      return total.toFixed(2);
    }

    return 0;
  };

  const calculatePrijsTotal = () => {
    let totalPrijs = 0;
    data.forEach((unit) => {
      if (isNumber(unit.surface_terrace) && isNumber(unit.surface_garden_terrace) && isNumber(unit.surface_brut_m) && isNumber(unit?.price_total)) {
        const terassTotal = (unit.surface_terrace * (projectData?.terass / 100));
        const tuinTotal = (unit.surface_garden_terrace * (projectData?.tuin / 100));
        const totalBrut = unit.surface_brut_m;
        const totalPond = (!isNaN(totalBrut) ? totalBrut : 0) + (!isNaN(terassTotal) ? terassTotal : 0) + (!isNaN(tuinTotal) ? tuinTotal : 0);
        const totalCombined = unit.price_total / totalPond;
        if (!isNaN(totalCombined)) {
          totalPrijs += totalCombined;
        }
      }
    });
    return typeof totalPrijs === 'number' ? totalPrijs.toFixed(2) : 0;
  };

  const changeMetricsAndFormat = value => {
    if(value !== null && value !== undefined && isNumber(parseFloat(value))) {
        let newValue = value.toString().split(".");
        newValue[0] = [...newValue[0]].reverse().join("");
        if(newValue[0].length > 3) {
            if(newValue[0].length % 3 !== 0 ) {
                newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
            }
            else {
                newValue[0] = newValue[0].replace(/(.{3})/g, "$1.")
                newValue[0] = newValue[0].slice(0, newValue[0].length - 1)
            }
        }
        newValue[0] = [...newValue[0]].reverse().join("");
        return `${newValue[0] || "0"},${newValue[1] ||  "00"}`;
    }
    else {
        return "-";
    }
  }

  return (
    <>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(calculatePrijsTotal()) || 0}`}</td>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getTotal('price_total')) || 0}`}</td>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getTotal('price_ground')) || 0}`}</td>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getTotal('price_total') - getTotal('price_ground')) || 0}`}</td>
    </>

  );
}
