import React, { useState } from 'react';
import {
  TextField,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const SearchIcon = () => (
  <img
    alt="search icon"
    src={require('../../assets/images/icons/search_darkbleu.svg')}
    style={{
      height: '15px',
      marginRight: 10,
    }}
  />
);

const SearchField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(223, 223, 222)',
      },
      width: 160,
      marginLeft: -1,
    },
  },
}))(TextField);

export const SearchInput = ({
  handleSearch,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleOnSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    handleSearch(value);
  };

  return (
    <div className="document-panel-input-container vertical-center flex-row margin-right-15 top-2">
      <div className="document-panel-input-icon center-content flex-shrink-disable">
        <img
          className="height-40-p width-40-p object-fit-contain"
          src={require('../../assets/images/icons/search_darkbleu.svg')}
          alt="search"
        />
      </div>
      <input
        style={{ fontSize: 12, paddingTop: 5 }}
        className="document-panel-input"
        onChange={handleOnSearch}
        placeholder="Zoeken"
        value={searchValue}
      />
    </div>
  );
};
