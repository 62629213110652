import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const Spinner = ({
  text,
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        fontSize: 14,
        textAlign: 'center',
      }}
    >
      <div className={classes.root}>
        <CircularProgress />
      </div>
      {text}
    </div>
  );
};

Spinner.defaultProps = {
  text: '',
};

Spinner.propTypes = {
  text: PropTypes.string,
};

export default Spinner;
