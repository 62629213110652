import React, { useState, useEffect } from 'react';
import { Grid } from "@material-ui/core";
import axios from "axios";
import { throttle } from "lodash";

import { getDocumentsByCategoryAndUnits, readNotification, getUserById } from "../../common/api/routes";
import { SQLDateToString } from "../../common/utils/format";
import { DropdownMenuExtended } from '../../components/DropdownMenu';
import { baseUrl } from '../../common/api/routes';

import "./tab.documenten.scss";

export function CheckRound(props) {
  const [ checkState, setCheckState ] = useState(false);

  const { initialState, onClick, disabled } = props;

  useEffect(() => {
    setCheckState(initialState == 1 ? 1 : 0);
  }, [initialState])

  const handleClick = () => {
    if(!disabled) {
      onClick(!checkState === false ? 0 : 1);
      setCheckState(!checkState)
    }
  }

  return(
    <div className = "tab-documenten-check-container cursor-pointer center-content" onClick = {handleClick}>
      <div className = "tab-documenten-check center-content animatable-400 ease-in-out-quart" style = {{backgroundColor: checkState ? 'RGB(46, 160, 180)' : 'RGBA(155, 155, 155, 0.5)'}}>
        <img src = {require('../../assets/images/icons/check_white.svg')} alt = "check white" className = "tab-documenten-check-icon" />
      </div>
    </div>
  )
}

export default function TabContentDocumenten(props) {
  const [ selectedTab, setSelectedTab ] = useState(0);
  const [ adminData, setAdminData ] = useState({})
  const [ data, setData ] = useState([])
  const [ unreadState, setUnreadState ] = useState(false)

  const { buyerData } = props;
  const userData = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if(buyerData && buyerData.project) {
      let unitList = "";
      buyerData.user_units.forEach((unit, index) => {
        unitList = unitList + unit.units.id;
        if(index !== (buyerData.user_units.length - 1)) {
          unitList = unitList + ",";
        }
      })
      let documentList = [];
      // we replace userData.id with the id of the user who has the access state
      const mainUser = getMainUser();
      axios.get(getDocumentsByCategoryAndUnits(1, mainUser, buyerData.project.id, "")).then(response => {

        documentList = response.data.data;
        axios.get(getDocumentsByCategoryAndUnits(0, mainUser, buyerData.project.id, unitList)).then(response => {
          documentList = [...documentList, ...response.data.data];
          setData(documentList)
        }).catch(error => console.log(error))

      }).catch(error => console.log(error))
    }
  }, [buyerData])

  useEffect(() => {
    axios.get(getUserById(userData.id)).then(response => {
      setAdminData(response.data.data)
    }).catch(error => console.log(error))
  }, [])

  useEffect(() => {
    if(data && data.length > 0) {
      // we use for loop so we can use the break syntax
      for(let index = 0; index < data.length; index++) {
        if(data[index]?.app_dpp) {
          if(!data[index]?.app_dpp?.read_at) {
            setUnreadState(true)
            break;
          }
        }
        else {
          setUnreadState(false)
        }
      }
    }
  }, [data])

  function getMainUser() {
    if(buyerData?.access === 0) {
      return buyerData?.user?.id;
    }
    else {
      const mainUser = buyerData.childs.find(childUser => childUser.access === 0);
      return mainUser.user.id
    }
  }

  function getFileType(pathString) {
    let output = pathString.split(".");
    return output[output.length - 1];
  }

  const handleDocumentDownload = (file) => {
    axios.get(baseUrl + file.path, {
        responseType: 'blob'
    }).then(response => {
        const blob = response.data;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = file.filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }).catch(error => console.log(error))
  }

  const onMouseOverEvent = (notification, dataIndex) => {
    if(!notification?.read_at) {
      readDocumentNotif(notification?.id, dataIndex)
    }
  }

  const readDocumentNotif = throttle((notificationId, dataIndex) => {
    if(buyerData?.user?.id && adminData?.id && notificationId) {
      const assignedProject = adminData.seller_projects.find(project => project.project_id == buyerData.project.id )
      if(assignedProject) {
        // this means the admin is responsbile for this project
        axios.put(readNotification(notificationId)).then(response => {
          const datacopy = JSON.parse(JSON.stringify(data));
          datacopy[dataIndex].app_dpp.read_at = new Date();
          setData(datacopy);
        }).catch(error => console.log(error))
      }
    }
  }, 2000)

  return (
    <div className="tab-documenten-container flex-column">
      <div className = "tab-documenten-tab-container flex-shrink-disable">
        <div className = "margin-right-45 relative">
          <div className = "tab-documenten-tab-notif" style = {{opacity: 0}} />
          <p className = "main-font-regular font-size-14 font-color-blue-grey cursor-pointer animatable-400 ease-in-out-quart" style = {{color: selectedTab === 0 ? 'RGB(46, 160, 180)' : 'RGB(69, 84, 93)'}} onClick = {() => setSelectedTab(0)}>Gebouw</p>
        </div>
        <div className = "margin-right-45 relative">
          <div className = "tab-documenten-tab-notif" style = {{opacity: 0}} />
          <p className = "main-font-regular font-size-14 font-color-blue-grey cursor-pointer animatable-400 ease-in-out-quart" style = {{color: selectedTab === 1 ? 'RGB(46, 160, 180)' : 'RGB(69, 84, 93)'}} onClick = {() => setSelectedTab(1)}>Aankoop</p>
        </div>
        <div className = "margin-right-45 relative">
          <div className = "tab-documenten-tab-notif" style = {{opacity: 0}} />
          <p className = "main-font-regular font-size-14 font-color-blue-grey cursor-pointer animatable-400 ease-in-out-quart" style = {{color: selectedTab === 2 ? 'RGB(46, 160, 180)' : 'RGB(69, 84, 93)'}} onClick = {() => setSelectedTab(2)}>Plannen</p>
        </div>
        <div className = "margin-right-45 relative">
          <div className = "tab-documenten-tab-notif" style = {{opacity: unreadState ? 1 : 0}} />
          <p className = "main-font-regular font-size-14 font-color-blue-grey cursor-pointer animatable-400 ease-in-out-quart" style = {{color: selectedTab === 3 ? 'RGB(46, 160, 180)' : 'RGB(69, 84, 93)'}} onClick = {() => setSelectedTab(3)}>Afwerking</p>
        </div>
        <div className = "margin-right-45 relative">
          <div className = "tab-documenten-tab-notif" style = {{opacity: 0}} />
          <p className = "main-font-regular font-size-14 font-color-blue-grey cursor-pointer animatable-400 ease-in-out-quart" style = {{color: selectedTab === 4 ? 'RGB(46, 160, 180)' : 'RGB(69, 84, 93)'}} onClick = {() => setSelectedTab(4)}>Facturatie</p>
        </div>
        <div className = "margin-right-45 relative">
          <div className = "tab-documenten-tab-notif" style = {{opacity: 0}} />
          <p className = "main-font-regular font-size-14 font-color-blue-grey cursor-pointer animatable-400 ease-in-out-quart" style = {{color: selectedTab === 5 ? 'RGB(46, 160, 180)' : 'RGB(69, 84, 93)'}} onClick = {() => setSelectedTab(5)}>Beheer</p>
        </div>
      </div>
      <Grid container>
        <div className = "margin-top-25" />
      </Grid>
      <div className = "tab-documenten-box">
        <Grid container>
          <Grid container alignItems = "center">
            <Grid container item xs = {1} />
            <Grid container item xs = {2} />
            <Grid container item xs = {2}>
              {selectedTab == 4 && (
                <p className = "main-font-regular font-size-12 font-color-grey">Onderwerp</p>
              )}
            </Grid>
            <Grid container item xs = {2}>
              <p className = "main-font-regular font-size-12 font-color-grey">Upload datum</p>
            </Grid>
            <Grid container item xs = {1}>
              <p className = "main-font-regular font-size-12 font-color-grey">Bekeken</p>
            </Grid>
            <Grid container item xs = {1}>
              {(selectedTab == 3 || selectedTab == 4) && (
                <p className = "main-font-regular font-size-12 font-color-grey">Status</p>
              )}
            </Grid>            
            {(selectedTab == 3 || selectedTab == 4) && (<Grid container item xs={2}>
              <p className="main-font-regular font-size-12 font-color-grey">Datum goedkeuring</p>
            </Grid>)}
          </Grid>
          <Grid container>
            <div className = "margin-top-10" />
          </Grid>
          {data && data.length > 0 && data.map((file, fileIndex) => {
            if(file.category_id == (selectedTab + 1) || file.category.parent_id == (selectedTab + 1)) {
              return (
                <Grid container>
                  <div className = "tab-documenten-box-item animatable-400 ease-in-out-quart" onMouseOver = {() => onMouseOverEvent(file?.app_dpp, fileIndex)}>
                    <Grid container alignItems = "center">
                      <Grid container item xs = {1} justify = "center">
                        <div className = "tab-documenten-box-icon-container">
                          {file.filename && getFileType(file.filename) === 'pdf' ? (
                              <img src = {require('../../assets/images/document/pdf.svg')} alt = "pdf" className = "document-panel-main-list-document-icon" />
                          ) : getFileType(file.filename) === 'doc' ? (
                              <img src = {require('../../assets/images/document/doc.svg')} alt = "doc" className = "document-panel-main-list-document-icon" />
                          ) : getFileType(file.filename) === 'xls' ? (
                              <img src = {require('../../assets/images/document/xls.svg')} alt = "xls" className = "document-panel-main-list-document-icon" />
                          ) : (getFileType(file.filename) === 'png' || getFileType(file.filename) === 'jpg' || getFileType(file.filename) === 'jpeg') ? (
                              <img src = {require('../../assets/images/document/img.svg')} alt = "img" className = "document-panel-main-list-document-icon" />
                          ) : (
                              <img src = {require('../../assets/images/document/other.svg')} alt = "other" className = "document-panel-main-list-document-icon" />
                          )}
                        </div>
                      </Grid>
                      <Grid container item xs = {2} direction='column'>
                        <p className = "main-font-semi-bold font-size-14 font-color-blue-grey">{file?.filename || ""}</p>
                        {file?.category.parent_id == 4 && <p className = "font-size-12 font-color-grey main-font-semi-bold">{file?.category?.title || ""}</p>}
                        {file?.category_id == 4 && <p className = "font-size-12 font-color-grey main-font-semi-bold">Andere</p>}
                      </Grid>
                      <Grid container item xs = {2}>
                        <p className = "main-font-regular font-size-12 font-color-blue-grey">{file?.concerned || ""}</p>
                      </Grid>
                      <Grid container item xs = {2}>
                        <p className = "main-font-regular font-size-12 font-color-grey">{file?.created_at ? SQLDateToString(file?.created_at).split(" ")[0] : ""}</p>
                      </Grid>
                      <Grid container item xs = {1}>
                        <p className = "main-font-regular font-size-12 font-color-grey">{file?.lastView ? <CheckRound initialState = {true} disabled = {true} /> : ""}</p>
                      </Grid>
                      <Grid container item xs = {1}>
                        {selectedTab == 4 && (
                          <CheckRound initialState = {file?.status === 0 ? false : 1} disabled = {true} />
                        )}
                        {selectedTab == 3 && (
                          <>
                            {file?.status === 3 ? (
                              <img src = {require('../../assets/images/icons/cross_red.svg')} alt = "cross red" className = "tab-documenten-check-container" />
                            ) : (
                              <CheckRound initialState = {file?.status === 0 ? false : true} disabled = {true} />
                            )}
                          </>
                        )}
                      </Grid>
                      <Grid container item xs = {2}>
                        <p className = "main-font-regular font-size-12 font-color-grey">{file?.updated_at ? SQLDateToString(file?.updated_at).split(" ")[0] : ""}</p>
                      </Grid>
                      <Grid container item xs = {1}>
                        <DropdownMenuExtended disableDelete disableEdit handleDownload = {() => { handleDocumentDownload(file) }} handleView = {() => window.open(baseUrl + file.path)} />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )
            }
          })}
        </Grid>
      </div>
    </div>
  );
}
