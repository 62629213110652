import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const General = ({
  data,
  projectUnitTypes,
}) => {
  const [title, setTitle] = useState('');
  const [generalNumber, setGeneralNumber] = useState('');
  const [generalLevel, setGeneralLevel] = useState(0);
  const [generalRooms, setGeneralRooms] = useState(0);
  const [generalQuotity, setGeneralQuotity] = useState(0);
  const [precad, setPrecad] = useState('');
  const [generalHouseNumber, setGeneralHouseNumber] = useState('');

  useEffect(() => {
    if (data) {
      setTitle(data?.general_type || '');
      setGeneralNumber(data?.general_number || '');
      setGeneralLevel(data?.general_level || 0);
      setGeneralRooms(data?.general_rooms || 0);
      setGeneralQuotity(data?.general_quotity || 0);
      setGeneralHouseNumber(data?.general_house_number || '');
      setPrecad(data?.precad || '');
    }
  }, [data]);

  const showTypeTitle = (id) => {
    if (projectUnitTypes) {
      const typeObject = projectUnitTypes.find((unitType) => unitType.id === id);
      return typeObject?.title || '';
    }

    return '';
  };

  return (
    <>
      <td>{showTypeTitle(title)}</td>
      <td style={{ textAlign: 'center' }}>{generalNumber || 0}</td>
      <td style={{ textAlign: 'center' }}>{generalLevel || 0}</td>
      <td style={{ textAlign: 'center' }}>{generalRooms || 0}</td>
      <td style={{ textAlign: 'center' }}>{generalQuotity || 0}</td>
      <td style={{ textAlign: 'center' }}>{precad || 0}</td>
      <td style={{ textAlign: 'center' }}>{generalHouseNumber || 0}</td>
    </>
  );
};

General.propTypes = {
  data: PropTypes.array.isRequired,
};

export default General;

export const GeneralTotal = ({
  data,
}) => {
  const getTotalQuotity = () => {
    if (data?.length) {
      let total = 0;
      data.forEach((unit) => {
        total += (unit?.general_quotity || 0);
      });
      return total;
    }

    return 0;
  };

  return (
    <>
      <td style={{ background: '#f0f1f2' }}>{`Totaal: ${data ? data.length : 0}`}</td>
      <td />
      <td />
      <td />
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${getTotalQuotity() || 0}`}</td>
    </>
  );
};
