import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import axios from 'axios';

import { SpaceRow } from '../../../components/space/space';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import Loader from '../../../components/loader/loader';
import { newPassword } from '../../../common/api/routes';
import ErrorMessage from '../../../components/error-message/ErrorMessage';

import '../login.scss';

export default function NewPassword(props) {
  const [passwordState, setPasswordState] = useState('');
  const [loadState, setLoadState] = useState(false);
  const [lastErrorState, setLastErrorState] = useState(null);
  const [animationState, setAnimationState] = useState(true);
  const [passwordStateError, setPasswordStateError] = useState(false);
  const [userData, setUserData] = useState(false);
  const [stayLoggedInState, setStayLoggedInState] = useState(false);
  const [sendAccessState, setSendAccessState] = useState(false);

  const { changePhase } = props;
  const emailCheckRef = useRef(0);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const token = location.search;
    setAnimationState(false);
    if (token.includes('user=true')) {
      setSendAccessState(true);
    } else {
      setUserData(JSON.parse(localStorage.getItem('user')));
      isLoggedIn();
    }
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  function isLoggedIn() {
    let stayLoggedIn = localStorage.getItem('stayLoggedIn');
    if (stayLoggedIn) {
      stayLoggedIn = JSON.parse(stayLoggedIn);
      setStayLoggedInState(stayLoggedIn);
    }
  }

  const handlePasswordChange = (event) => {
    setPasswordStateError(false);
    setPasswordState(event.target.value);
  };

  const isValid = () => {
    let validity = true;
    if (passwordState === '' || passwordStateError) {
      validity = false;
      setPasswordStateError(true);
    }
    return validity;
  };

  const handlePasswordUpdateEvent = () => {
    if (emailCheckRef.current.getValue()) {
      let token = location.search.split('?')[1];
      token = token.split('&')[0].replace('token=', '');
      if (isValid()) {
        setLoadState(true);
        axios.post(newPassword, {
          token,
          password: passwordState,
        }).then((response) => {
          if (response.data.data.message === 'your login token expired') {
            alert('your token is invalid or expired');
            localStorage.clear();
            history.push('/');
            history.go(0);
          } else {
            setLoadState(false);
            setAnimationState(true);
            localStorage.setItem('user', JSON.stringify(response.data.data));
            history.push('/Overzicht');
          }
        }).catch((error) => console.log(error));
      }
    } else {
      emailCheckRef.current.showError();
    }
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handlePasswordUpdateEvent();
    }
  };

  const stayLoggedIn = () => {
    localStorage.setItem('stayLoggedIn', true);
  };

  const removeStayLoggedIn = () => {
    localStorage.setItem('stayLoggedIn', false);
  };

  return (
    <Grid container className="animatable-400 ease-in-out-quart" style={{ opacity: animationState ? 0 : 1, transform: animationState ? 'translateY(-25px)' : 'translateY(0px)' }}>
      <SpaceRow top="21" />
      <Grid container direction="column">
        <p className="main-font-regular font-size-14 font-color-white">
          Welkom
          {userData?.firstName}
          {' '}
          {userData?.lastName}
          {' '}
          op het Gaelens-platform. Hier vindt u alle documenten met betrekking tot uw aankoop.
        </p>
        <p className="main-font-regular font-size-14 font-color-white">Kies uw wachtwoord om uzelf te identificeren</p>
      </Grid>
      <SpaceRow top="37" />

      <Grid container>
        <div className="login-input-container">
          <div
            className="login-input-container-layer animatable-400 ease-in-out-quart"
            style={{ transform: passwordStateError ? 'scaleX(1)' : 'scaleX(0)' }}
          />
          <div className="login-input-container-main">
            <img
              className="login-input-icon flex-shrink-disable"
              src={require('../../../assets/images/icons/lock_white.svg')}
              alt="person white"
            />
            <input
              type="password"
              className="login-input flex-shrink-disable main-font-regular font-size-14 font-color-white"
              value={passwordState}
              placeholder="Wachtwoord"
              onChange={handlePasswordChange}
            />
          </div>
        </div>
      </Grid>
      <SpaceRow top="45" />

      <Grid container justify="space-between" alignItems="center">
        <div className="flex-row vertical-center">
          <Checkbox
            initialState={stayLoggedInState}
            isActive={stayLoggedIn}
            isUnactive={removeStayLoggedIn}
          />
          <p
            className="main-font-regular font-size-14 font-color-white margin-left-5"
          >
            Ingelogd blijven
          </p>
        </div>
      </Grid>
      <SpaceRow top="45" />

      <Grid container wrap="nowrap" alignItems="flex-start">
        <Checkbox ref={emailCheckRef} />
        <p
          className="main-font-regular font-size-14 font-color-white margin-left-5"
        >
          Ik ga ermee akkoord per e-mail op de hoogte te worden
          gehouden van wijzigingen en nieuwe documenten op het
          platform
        </p>
      </Grid>
      <SpaceRow top="45" />

      <Grid container>
        <Grid container justify="center" className="absolute">
          <Loader animationState={loadState} />
        </Grid>
        <button
          type="button"
          className="login-button center-content animatable-400 ease-in-out-quart"
          onClick={handlePasswordUpdateEvent}
          style={{ opacity: loadState ? 0 : 1, transform: loadState ? 'translateY(-15px)' : 'translate(0px)' }}
        >
          <p
            className="main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart"
          >
            Log in
          </p>
        </button>
      </Grid>
      <ErrorMessage error={lastErrorState} isOpen={lastErrorState !== null} />
    </Grid>
  );
}
