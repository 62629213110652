import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

import CodePopup from './CodePopup.view';

const useStyles = makeStyles({
  tableCell: {
    border: '1px solid #DFDFDE',
    padding: 6,
  },
  code: {
    padding: '2px 4px',
    textAlign: 'center',
    margin: '0 15%',
    borderRadius: 5,
  },
});

const AddProject = ({
  toggleShowCodePopup,
  handleShowAddRow,
  handleAddProject,
  usedCodesList,
  isAdding,
}) => {
  const classes = useStyles();
  const [codePopup, setCodePopup] = useState(false);
  const [payload, setPayload] = useState({
    code: '',
    projectName: '',
    straat: '',
    gemeente: '',
    appVerkocht: '13/15',
    parkVerkocht: '4/15',
    bergVerkocht: '6/10',
    totalVerkocht: '23/45',
    codeColor: '#F4F4F4',
  });

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    const updatedPayload = { ...payload, [name]: value };
    setPayload(updatedPayload);
  };

  const handleCodeChange = (code) => {
    setPayload({
      ...payload,
      code: code.title,
      codeColor: code.color,
    });
    setCodePopup(false);
  };

  const addProject = () => {
    handleShowAddRow(false);
    handleAddProject({
      color: payload.codeColor,
      code: payload.code,
      name: payload.projectName,
      street: payload.straat,
      city: payload.gemeente,
      zip: '',
      comments: '',
    });
  };

  return (
    <>
      <TableRow key="add-row1">
        <TableCell style={{ borderLeft: '1px solid transparent' }} colSpan={4} />
        <TableCell style={{ border: '1px solid transparent' }} colSpan={4} />
      </TableRow>

      <TableRow key="add-row">
        <TableCell className={classes.tableCell}>
          <div
            className={classes.code}
            onClick={() => setCodePopup(true)}
            style={{ background: payload.codeColor || '#f4f4f4', cursor: 'pointer' }}
          >
            {payload.code || '-'}
          </div>
          {codePopup && (
            <CodePopup
              project={{
                color: payload.codeColor,
                code: payload.code,
                name: payload.projectName,
                street: payload.straat,
                city: payload.gemeente,
                zip: '',
                comments: '',
              }}
              usedCodesList={usedCodesList}
              handleCodeUpdate={handleCodeChange}
              onClose={() => {
                toggleShowCodePopup(undefined);
                setCodePopup(false);
              }}
            />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <input
            onChange={handleOnChange}
            value={payload.projectName}
            name="projectName"
            type="text"
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <input
            onChange={handleOnChange}
            value={payload.straat}
            name="straat"
            type="text"
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <input
            onChange={handleOnChange}
            value={payload.gemeente}
            name="gemeente"
            type="text"
          />
        </TableCell>
        <TableCell
          style={{
            padding: '2px 6px',
            borderRight: '1px solid transparent',
            borderBottom: '1px solid transparent',
            position: 'relative',
          }}
        >
          <Button
            variant="contained"
            className={classes.buttonStyle}
            style={{
              background: '#F0F1F2',
              height: 36,
              width: 250,
              fontWeight: 400,
              color: '#45545D',
              boxShadow: 'none',
              position: 'absolute',
              whiteSpace: 'nowrap',
              left: 20,
              top: 0,
            }}
            disabled={isAdding}
            onClick={addProject}
          >
            {isAdding ? 'Toevoegen...' : 'Project verder uitwerken'}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

AddProject.defaultProps = {
  isAdding: false,
};

AddProject.propTypes = {
  toggleShowCodePopup: PropTypes.func.isRequired,
  handleAddProject: PropTypes.func.isRequired,
  handleShowAddRow: PropTypes.func.isRequired,
  usedCodesList: PropTypes.array.isRequired,
  isAdding: PropTypes.bool,
};

export default AddProject;
