import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Select from '../../../components/select/select';
import { SpaceRow } from '../../../components/space/space';

const salesOptions = [{
  lable: 'Ja',
  value: true,
}, {
  lable: 'Nee',
  value: false,
}];

const AddLinkedProspect2 = ({
  userTitles,
  onChange,
  index,
  removeUser,
  data,
}) => {
  return (
    <>
      <Grid
        container
        style={{
          paddingBottom: 25,
          marginTop: 25,
          borderBottom: '1px solid #45545D',
        }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Aanspreking
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ margin: '-4px 0 10px' }}>
              <Select
                style={{ width: 150 }}
                options={userTitles}
                name="title_id"
                selected={data?.title_id}
                onChange={(event) => onChange(event.target.value, 'title_id')}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Voornaam
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ margin: '-4px 0 10px' }}>
              <input
                id="firstName"
                name="firstName"
                type="text"
                value={data?.firstName || ''}
                className="background-color-transparent main-font-regular font-size-14 font-color-black focused-input"
                style={{
                  width: 200,
                  height: 30,
                  borderBottom: '1px solid #e1e1e1',
                }}
                disabled={data?.id || false}
                autoComplete="none"
                onChange={(event) => onChange(event.target.value, 'firstName')}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Naam
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ margin: '-4px 0 10px' }}>
              <input
                id="lastName"
                name="lastName"
                type="text"
                value={data?.lastName || ''}
                className="background-color-transparent main-font-regular font-size-14 font-color-black focused-input"
                style={{
                  width: 200,
                  height: 30,
                  borderBottom: '1px solid #e1e1e1',
                }}
                disabled={data?.id || false}
                autoComplete="none"
                onChange={(event) => onChange(event.target.value, 'lastName')}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                E-mailadres
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ margin: '-4px 0 10px' }}>
              <input
                id="email"
                name="email"
                type="email"
                value={data?.email || ''}
                className="background-color-transparent main-font-regular font-size-14 font-color-black focused-input"
                style={{
                  width: 200,
                  height: 30,
                  borderBottom: '1px solid #e1e1e1',
                }}
                disabled={data?.id || false}
                autoComplete="none"
                onChange={(event) => onChange(event.target.value, 'email')}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Telefoon
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ margin: '-4px 0 10px' }}>
              <input
                id="phone"
                name="phone"
                type="text"
                value={data?.phone || ''}
                className="background-color-transparent main-font-regular font-size-14 font-color-black focused-input"
                style={{
                  width: 200,
                  height: 30,
                  borderBottom: '1px solid #e1e1e1',
                }}
                disabled={data?.id || false}
                autoComplete="none"
                onChange={(event) => onChange(event.target.value, 'phone')}
              />
            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Straat & nr
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ margin: '-4px 0 10px' }}>
              <input
                id="street"
                name="street"
                type="text"
                value={data?.street || ''}
                className="background-color-transparent main-font-regular font-size-14 font-color-black focused-input"
                style={{
                  width: 200,
                  height: 30,
                  borderBottom: '1px solid #e1e1e1',
                }}
                disabled={data?.id || false}
                autoComplete="none"
                onChange={(event) => onChange(event.target.value, 'street')}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Postcode & Woonpl.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ margin: '-4px 0 10px' }}>
              <input
                id="zip"
                name="zip"
                type="text"
                value={data?.zip || ''}
                className="background-color-transparent main-font-regular font-size-14 font-color-black focused-input"
                style={{
                  width: 95,
                  height: 30,
                  borderBottom: '1px solid #e1e1e1',
                }}
                disabled={data?.id || false}
                autoComplete="none"
                onChange={(event) => onChange(event.target.value, 'zip')}
              />
              <input
                id="city"
                name="city"
                type="text"
                value={data?.city || ''}
                className="background-color-transparent main-font-regular font-size-14 font-color-black focused-input"
                style={{
                  width: 95,
                  height: 30,
                  marginLeft: 10,
                  borderBottom: '1px solid #e1e1e1',
                }}
                disabled={data?.id || false}
                autoComplete="none"
                onChange={(event) => onChange(event.target.value, 'city')}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={4} style={{ marginBottom: 5 }}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Commerciële
                <br />
                Communicatie
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Select
                style={{ width: 150 }}
                options={salesOptions}
                selected={data?.preference_communication}
                onChange={(event) => onChange(event.target.value, 'preference_communication')}
              />
            </Grid>
          </Grid>
          <SpaceRow top="5" />
          <Grid container alignItems="flex-start">
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Extra info
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ marginBottom: 5 }}>
              <textarea
                onChange={(event) => onChange(event.target.value, 'comments')}
                value={data?.comments || ''}
                name="info"
                cols="19"
                rows="10"
                className="background-color-transparent main-font-regular font-size-14 font-color-black focused-input outline-none"
                style={{
                  width: 200,
                  height: 75,
                  resize: 'none',
                  border: '1px solid #e1e1e1',
                }}
                disabled={data?.id || false}
                autoComplete="none"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs = {6} sm = {6} md = {4} alignItems = "flex-start">
          <Grid container>
            <button type = "button" onClick = {removeUser}>
              <p className = "main-font-regular font-size-14 font-color-red text-underline cursor-pointer">Verwijder mede-prospect</p>
            </button>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};

AddLinkedProspect2.defaultProps = {};

AddLinkedProspect2.propTypes = {};

export default AddLinkedProspect2;
