import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, AppBar, Toolbar, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import './appbar.scss'

const drawerWidth = 238

const AppBarComponent = ({ setOpen, open, title, pagename, titleButton, isOnTop }) => {
  const useStyles = makeStyles((theme) => ({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      zIndex: isOnTop && 99999999
    },
    toolbar: {
      minHeight: 90,
    },
    title: {
      flexGrow: 1,
      alignSelf: 'flex-end',
    },
    hide: {
      visibility: 'hidden',
    },
  }))
  const classes = useStyles()
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      color="default"
    >
      <Toolbar className={classes.toolbar}>
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            alignItems="center"
            className={clsx(open && classes.hide)}
          >
            <Grid item>
              {/* <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
              > */}
              <img
                alt="menu-icon"
                onClick={handleDrawerOpen}
                className="margin-right-10 cursor-pointer"
                src={require('../../assets/images/icons/menu.svg')}
                height="23px"
              />
              {/* </IconButton> */}
            </Grid>
            <Grid item md={11}>
              <span className="main-font-regular font-size-14">{pagename}</span>
            </Grid>
          </Grid>
          <Grid item alignItems="flex-start" style={{ display: 'flex' }}>
            <Typography
              className={clsx(classes.title, 'main-font-bold font-size-20')}
              variant="h6"
              noWrap
            >
              {title}
            </Typography>

            {titleButton}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

AppBarComponent.defaultProps = {
  open: false,
  setOpen: () => {
    return false
  },
  title: '',
  pagename: '',
  titleButton: <></>,
}

AppBarComponent.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  pagename: PropTypes.string,
  titleButton: PropTypes.node,
}
export default AppBarComponent
