import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@material-ui/core';
import {
  isObject,
  isEmpty,
  isNull,
  uniqBy,
  get,
} from 'lodash';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import ProspectUnits from './ProspectUnits.view';
import ErrorModal from '../../../components/error_modal/error_modal';
import LinkedProspects from './LinkedProspects.view';
import Select from '../../../components/select/select';
import Wrapper from '../../../components/wrapper/wrapper';
import {
  transferProspectToBuyer,
  getUnsoldUnitsByProject,
  getAllProspectProjects,
  deleteProjectProspect,
  updateProspectDetails,
  getProspectDetails,
  getSellerByProject,
  getUserNameTitles,
  getProspectnBuyer,
  getSalesPersons,
  getAllStatus,
} from './prospectDetails.api';
import Spinner from '../../../components/spinner/Spinner';
import { createChat } from '../../../common/api/routes';

const ProspectDetails = () => {
  const history = useHistory();

  const [isAdding, setIsAdding] = useState(false);
  const [userTitles, setUserTitles] = useState([]);
  const [isTransfer, setIsTransfer] = useState(false);
  const [salesPersons, setSalesPersons] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [projectUnitsList, setProjectUnitsList] = useState([]);
  const [prospectStatuses, setProspectStatuses] = useState([]);
  const [deletingProspect, setDeletingProspect] = useState(false);
  const [isUpdatingProspect, setIsUpdatingProspect] = useState(false);
  const [completeSoldResponse, setCompleteSoldResponse] = useState([]);
  const [isFetchingProspects, setIsFetchingProspects] = useState(false);
  const [selectedUnitProspects, setSelectedUnitProspects] = useState([]);
  const [buyerList, setBuyerList] = useState([]);

  const errorModalRef = useRef(0);

  useEffect(() => {
  }, []);
  const { id } = useParams();

  useEffect(() => {
    getAllProspectProjects(setIsFetchingProspects, setUserProjects);
    getAllStatus(setProspectStatuses);
    getUserNameTitles(setUserTitles);
    // getSalesPersons(setSalesPersons);
    getProspectnBuyer(() => {}, setBuyerList);
  }, []);

  useEffect(() => {
    if (!isEmpty(userProjects) && !isEmpty(id)) {
      getProspectDetails(setSelectedUnitProspects, id)
        .then((res) => {
          if (get(res, 'data.data.project_id')) {
            getSellerByProject(setSalesPersons, res.data.data.project_id);
            getUnsoldUnitsByProject(
              setProjectUnitsList,
              res.data.data.project_id,
              setCompleteSoldResponse,
            );
          }
        });
    }
  }, [userProjects]);

  const handleProjectChange = (event) => {
    if (event && event.target && event.target.value) {
      const { value } = event.target;
      const index = userProjects.findIndex((p) => p.value === value);

      getSellerByProject(setSalesPersons, userProjects[index].value);
      getUnsoldUnitsByProject(
        setProjectUnitsList,
        userProjects[index].value,
        setCompleteSoldResponse,
      );
      // eslint-disable-next-line no-use-before-define
      handleProspectUpdate({
        ...selectedUnitProspects[0],
        project_id: userProjects[index].value,
      });
    }
  };

  const handleProjectUnitChange = (event) => {
    if (event && event.target && event.target.value) {
      const { value } = event.target;
      const prospectId = userProjects.findIndex((p) => p.value === value);

      getProspectDetails(setSelectedUnitProspects, prospectId);
    }
  };

  useEffect(() => {
    if (!isEmpty(userProjects)) {
      handleProjectChange();
    }
  }, [userProjects]);

  const handleAddNewProspect = (payload) => {
    // eslint-disable-next-line no-use-before-define
    handleProspectUpdate(payload);
  };

  const handleTransferToBuyer = () => {
    if (!isEmpty(selectedUnitProspects)) {
      const prospectId = selectedUnitProspects[0].id;
      transferProspectToBuyer(setIsTransfer, prospectId, history, createGeneralChat);
    }
  };

  const createGeneralChat = (buyerId, projectId) => {
    axios.post(createChat, {
      buyer_project_id: buyerId,
      project_id: projectId,
    }).then((chatResponse) => {
      history.push('/lijst/kopers');
    }).catch((error) => console.log(error));
  };

  const handleProspectDelete = () => {
    errorModalRef.current.openDeleteModal('Weet u zeker dat u deze Prospect wenst te verwijderen?', 'Bevestigen');
  };

  const handleSingleProspectDelete = (prospectId) => {
    // const projectDelete = window.confirm('Weet u zeker dat u deze Prospect wenst te verwijderen?');

    // if (projectDelete === true) {

    // }

    deleteProjectProspect(
      setDeletingProspect,
      prospectId,
      setSelectedUnitProspects,
      id,
    );

  };

  const handleProspectUpdate = (payload) => {
    updateProspectDetails(
      payload,
      selectedUnitProspects[0].id,
      setIsUpdatingProspect,
      setSelectedUnitProspects,
      id,
      errorModalRef,
    );
  };

  const handleAddProspectUserToList = (buyer) => {
    const abc = selectedUnitProspects[0].childs;
    abc.push({ user: buyer });
    const xyz = { ...selectedUnitProspects[0], childs: abc };
    const eee = [xyz];
    setSelectedUnitProspects(eee);
    handleAddNewProspect(xyz);
  };

  const getSelectedUnits = () => {
    const units = [];
    if (selectedUnitProspects[0] && selectedUnitProspects[0].user_units
      && selectedUnitProspects[0].user_units.length) {
      selectedUnitProspects[0].user_units.forEach((u) => {
        if (u.units) {
          units.push({
            ...u,
            lable: `${u.units.type.title} ${u.units.general_number}`,
            value: u.unit_id,
          });
        }
      });
    }
    return units;
  };

  const handleStatusChange = (e, key) => {
    const { value } = e.target;

    handleProspectUpdate({ ...selectedUnitProspects[0], [key]: value });
  };

  const handleUnitChange = (units) => {
    const newUnits = [];
    console.log(units);
    units.forEach((unit) => {
      const unittt = completeSoldResponse?.find((u) => (isObject(unit)
        ? u.id === unit.unit_id
        : u.id === unit));
      newUnits.push({
        unit_id: isObject(unit) ? unit.unit_id : unit,
        units: unittt,
      });
    });
    handleProspectUpdate({ ...selectedUnitProspects[0], user_units: newUnits });
  };

  const getUnits = () => {
    if (selectedUnitProspects && selectedUnitProspects[0] && selectedUnitProspects[0].user_units) {
      const list = [];
      selectedUnitProspects[0].user_units.forEach((r) => {
        list.push({
          lable: `${r.units.type.title} ${r.units.general_number}`,
          value: r?.units?.id,
          sold: r?.units?.sold,
        });
      });
      const lol = [...projectUnitsList, ...list];
      return uniqBy(lol, 'lable');
    }
    return projectUnitsList;
  };

  const getProjectList = () => {
    let list = userProjects;
    if (isNull(get(selectedUnitProspects, '[0].project_id'))) {
      list = [{
        lable: '',
        value: '',
      }].concat(userProjects);
    }
    return list;
  };

  const getSalesPersonList = () => {
    let list = salesPersons;
    if (isNull(get(selectedUnitProspects, '[0].seller_id'))) {
      list = [{
        lable: '',
        value: '',
      }].concat(salesPersons);
    }
    return list;
  };

  return (
    <Wrapper
      titleButton={(
        <Button
          variant="contained"
          style={{
            // marginTop: 15,
            background: '#2EA0B4',
            opacity: isTransfer ? 0.5 : 1,
            height: 36,
            width: 192,
            fontWeight: 400,
            color: '#fff',
            boxShadow: 'none',
            float: 'right',
            // top: -45,
            textTransform: 'none',
            whiteSpace: 'nowrap',
          }}
          disabled={isTransfer}
          onClick={handleTransferToBuyer}
        >
          Verzenden naar kopers
        </Button>
      )}
      title={`Prospect info ${get(selectedUnitProspects, '[0].user.firstName')} ${get(selectedUnitProspects, '[0].user.lastName')}`}
      pagename="prospectInfo"
    >
      {isFetchingProspects ? (
        <div className="text-center padding-top-100">
          <Spinner text="Fetching Details..." />
        </div>
      ) : (
        <div className="profile-details-container margin-top-85">

          <Grid container style={{ paddingBottom: 25, borderBottom: '1px solid #45545D' }}>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <p
                    className="main-font-regular font-size-14 font-color-grey"
                  >
                    Project
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={8} >
                  <Select
                    style={{ width: 150 }}
                    options={getProjectList()}
                    onChange={handleProjectChange}
                    selected={get(selectedUnitProspects, '[0].project_id') || getProjectList()[0]?.value}
                  />
                </Grid>
              </Grid>

              <ProspectUnits
                totalUnits={selectedUnitProspects[0]?.user_units?.length}
                onlySoldList={uniqBy(projectUnitsList, 'value')}
                handleProjectUnitChange={handleProjectUnitChange}
                handleUnitChange={handleUnitChange}
                selectedUnits={getSelectedUnits()}
                userProjectsUnits={getUnits()}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} style={{ marginBottom: 5 }}>
                  <p
                    className="main-font-regular font-size-14 font-color-grey"
                  >
                    Verantwoordelijke verkoop
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Select
                    style={{ width: 150 }}
                    options={getSalesPersonList()}
                    onChange={(e) => handleStatusChange(e, 'seller_id')}
                    selected={get(selectedUnitProspects, '[0].seller_id') || getSalesPersonList[0]?.value}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <p
                    className="main-font-regular font-size-14 font-color-grey"
                  >
                    Status
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={8} style={{ marginBottom: 5 }}>
                  <Select
                    style={{ width: 150, height: 35 }}
                    options={prospectStatuses}
                    onChange={(e) => handleStatusChange(e, 'status_id')}
                    selected={get(selectedUnitProspects, '[0].status_id')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <LinkedProspects
            handleAddProspectUserToList={handleAddProspectUserToList}
            handleSingleProspectDelete={handleSingleProspectDelete}
            selectedUnitProspects={selectedUnitProspects}
            handleAddNewProspect={handleAddNewProspect}
            handleProspectDelete={handleProspectDelete}
            handleProspectUpdate={handleProspectUpdate}
            isUpdatingProspect={isUpdatingProspect}
            selectedUserProject={projectUnitsList}
            deletingProspect={deletingProspect}
            prospectUsers={salesPersons}
            userTitles={userTitles}
            buyerList={buyerList}
            isAdding={isAdding}
          />

        </div>
      )}
      <ErrorModal ref={errorModalRef} onDelete={() => deleteProjectProspect(setDeletingProspect, id, setSelectedUnitProspects, null, history)} />
    </Wrapper>
  );
};

export default ProspectDetails;
