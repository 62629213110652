import request from '../../common/request';

const getQueryParams = (params) => Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');

export const URLQueryParams = {
  construct: (params) => {
    return getQueryParams(params);
  },
};

export const getAllProspects = (setFetching, setResponse, search, project, user, comm) => {
  setFetching(true);
  search = search?.trim();
  project = project?.trim();
  user = user ? user?.trim() : '';
  // comm = comm ? comm?.trim() : '';

  const queryParams = URLQueryParams.construct({
    ...(search && { search }),
    ...(project && { project }),
    ...(user && { user }),
    ...(comm && { comm }),
  });
  const url = `/prospect/project?${queryParams}`;
  const response = request.get(url);
  // const response = request.get('/user/types?type_id=4');
  response
    .then((res) => {
      setFetching(false);
      setResponse(res.data.data);
    })
    .catch(() => {
      setFetching(false);
    });
};

export const getAllSellers = (setResponse) => {
  const response = request.get('/user/types?type_id=4');
  // const response = request.get('/user/types?type_id=4');
  response
    .then((res) => {
      const list = res.data.data.map((a) => {
        return {
          name: `${a.firstName} ${a.lastName}`,
          value: a.id,
        };
      });
      list.unshift({
        name: 'Alle verantwoordelijken',
        value: '',
      });
      setResponse(list);
    })
    .catch(() => {});
};
