import React from "react"
import PropTypes from "prop-types";

import "./email_extension.scss";

export default function EmailExtension(props) {
    const { fromEmail, toEmail, date, time, onClick } = props;

    return(
        <div className = "email-extension-container vertical-center">
            <div className = "flex-row width-auto horizontal-between">
                <div className = "flex-column width-auto flex-7">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Van : {fromEmail}</p>
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Naar : {toEmail}</p>
                </div>
                <div className = "flex-column width-auto flex-3">
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Date : {date}</p>
                    <p className = "main-font-regular font-size-14 font-color-blue-grey">Uur : {time}</p>
                </div>
            </div>
            <img src = {require('../../../assets/images/icons/cross_gray.svg')} alt = 'close' className = "email-extension-icon flex-shrink-disable" onClick = {onClick} />
        </div>
    )
}

EmailExtension.propTypes = {
    fromEmail: PropTypes.string,
    toEmail: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    onClick: PropTypes.func,
}

EmailExtension.defaultProps = {
    fromEmail: "",
    toEmail: "",
    date: "",
    time: "",
    onClick: () => {}
}