import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types"

import AppBarComponent from '../appbar/appbar';
import MenuStrip from '../menu_strip/menu_strip';

const drawerWidth = 238;

export default function Wrapper({
  titleButton,
  children,
  pagename,
  title,
  isOnTop
}) {
  const [open, setOpen] = React.useState(true);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: 21,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBarComponent
        open={open}
        setOpen={setOpen}
        pagename={pagename}
        title={title}
        titleButton={titleButton}
      />
      <MenuStrip open={open} setOpen={setOpen} isOnTop = {isOnTop} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {children}
      </main>
    </div>
  );
}

Wrapper.defaultProps = {
  isOnTop: false
}