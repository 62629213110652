import React, { useEffect, useState } from 'react';
import { isNumber } from 'lodash';

export const Oppervlaktegegeven = ({
  data,
  terassPercentage,
  tuinPercentage,
}) => {
  const [netM, setNetM] = useState(0);
  const [brutM, setBrutM] = useState(0);
  const [terrace, setTerrace] = useState(0);
  const [tuin, setTuin] = useState(0);
  const [pond, setPond] = useState(0);

  useEffect(() => {
    if (data) {
      setNetM(data?.surface_net_m);
      setBrutM(data?.surface_brut_m);
      setTerrace(data?.surface_terrace);
      setTuin(data?.surface_garden_terrace);
      setPond(data?.surface_ponderation);
    }
  }, [data]);

  const calculatePond = () => {
    let terassValue = terrace;
    let tuinValue = tuin;
    terassValue *= (terassPercentage / 100);
    tuinValue *= (tuinPercentage / 100);
    return brutM + terassValue + tuinValue;
  };

  const changeMetricsAndFormat = (value) => {
    if (value !== null && value !== undefined && isNumber(parseFloat(value))) {
      const newValue = value.toString().split('.');
      newValue[0] = [...newValue[0]].reverse().join('');
      if (newValue[0].length > 3) {
        if (newValue[0].length % 3 !== 0) {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
        } else {
          newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
          newValue[0] = newValue[0].slice(0, newValue[0].length - 1);
        }
      }
      newValue[0] = [...newValue[0]].reverse().join('');
      return `${newValue[0] || '0'}.${newValue[1] || '00'}`;
    }
    return '-';
  };

  return (
    <>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat(netM) || 0}</td>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat(brutM) || 0}</td>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat(terrace) || 0}</td>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat(tuin) || 0}</td>
      <td style={{ textAlign: 'center' }}>{changeMetricsAndFormat(parseFloat(calculatePond()).toFixed(2)) || 0}</td>
    </>
  );
};

export default Oppervlaktegegeven;

export const OppervlaktegegevensTableTotal = ({
  data,
  documentCategories,
  projectData,
}) => {
  const getTotal = (key) => {
    if (data?.length) {
      let total = 0;
      data.forEach((unit) => {
        total += (unit[key] || 0);
      });
      return total;
    }
    return 0;
  };

  const calculatePondTotal = () => {
    let terassTotal = 0;
    let tuinTotal = 0;
    let totalBrut = 0;
    data.forEach((unit) => {
      terassTotal += (unit.surface_terrace * (projectData.terass / 100));
      tuinTotal += (unit.surface_garden_terrace * (projectData.tuin / 100));
      totalBrut += unit.surface_brut_m;
    });
    return (totalBrut + terassTotal + tuinTotal).toFixed(2);
  };

  const changeMetricsAndFormat = (value) => {
    const newValue = value.toString().split('.');
    newValue[0] = [...newValue[0]].reverse().join('');
    if (newValue[0].length > 3) {
      if (newValue[0].length % 3 !== 0) {
        newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
      } else {
        newValue[0] = newValue[0].replace(/(.{3})/g, '$1.');
        newValue[0] = newValue[0].slice(0, newValue[0].length - 1);
      }
    }
    newValue[0] = [...newValue[0]].reverse().join('');
    return `${newValue[0] || '0'}.${newValue[1] || '00'}`;
  };

  return (
    <>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getTotal('surface_net_m')) || 0}`}</td>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getTotal('surface_brut_m')) || 0}`}</td>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getTotal('surface_terrace')) || 0}`}</td>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(getTotal('surface_garden_terrace')) || 0}`}</td>
      <td style={{ textAlign: 'center', background: '#f0f1f2' }}>{`Totaal: ${changeMetricsAndFormat(calculatePondTotal()) || 0}`}</td>
    </>
  );
};
