import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ProjectsList from './HuidigeList.view';
import { getAllWebProjects } from '../../projects/projects.api';
import AppBarComponent from '../../../../components/appbar/appbar';
import MenuStrip from '../../../../components/menu_strip/menu_strip';

const drawerWidth = 238;

const Projects = () => {
  const [showAddRow, handleShowAddRow] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getAllWebProjects(setIsFetching, setProjects, false);
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingRight: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    buttonStyle: {
      padding: '10px 40px',
      whiteSpace: 'nowrap',
      textTransform: 'inherit',
    },
  }));

  const getAllUsedCodesList = () => {
    const list = [];
    if (projects) {
      projects.forEach((p) => {
        list.push({
          color: p.color,
          code: p.code,
        });
      });
    }
    return list;
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBarComponent open={open} setOpen={setOpen} pagename="Project" title="Huidige Projecten" />
      <MenuStrip open={open} setOpen={setOpen} />
      <main className={clsx(classes.content, { [classes.contentShift]: open })}>
        <div className={classes.drawerHeader} style={{ marginTop: 80 }} />

        <div>
          <Grid container style={{ marginTop: 80 }}>
            <Grid item md={12}>
              <ProjectsList
                usedCodesList={getAllUsedCodesList()}
                handleShowAddRow={handleShowAddRow}
                setProjects={setProjects}
                showAddRow={showAddRow}
                isFetching={isFetching}
                projects={projects}
              />
            </Grid>
          </Grid>
        </div>

      </main>

    </div>
  );
};

export default Projects;
