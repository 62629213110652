import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBarComponent from '../../components/appbar/appbar';
import MenuStrip from '../../components/menu_strip/menu_strip';
import Tabs from '../../components/tabs/tabs';
import './Settings.view.scss';

const drawerWidth = 238;

export default function Settings() {
  const [open, setOpen] = React.useState(true);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBarComponent open={open} setOpen={setOpen} pagename="Instellingen" title="Instellingen" />
      <MenuStrip open={open} setOpen={setOpen} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} style={{ marginTop: 80 }} />
        <Tabs />

      </main>
    </div>
  );
}
