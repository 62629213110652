import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { getWebProjectById, updateWebProjectById, deleteWebProjectImage } from "../../../../common/api/routes";
import clsx from 'clsx';
import AppBarComponent from '../../../../components/appbar/appbar';
import PropTypes from "prop-types";
import MenuStrip from '../../../../components/menu_strip/menu_strip';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { baseUrl } from '../../../../common/api/routes';
import axios from "axios";

import "./project_detail.scss";


const drawerWidth = 238;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function UploadSection(props) {
    const [ isFilled, setIsFilled ] = useState(false)

    const { files, onChange, fileLimit, onDelete } = props;
    const fileRef = useRef(0);

    useEffect(() => {
        if(files?.length >= fileLimit) {
            setIsFilled(true)
        }
        else {
            setIsFilled(false)
        }
    }, [files])

    useEffect(() => {
        console.log(files?.length)
    }, [isFilled])

    const handleFileAdd = () => {
        fileRef.current.click();
    }

    const handleFileChange = event => {
        onChange(event.target.files[0])
    }

    return(
        <Grid container item spacing = {4} className = "margin-top-25">
            {!isFilled && (
                <div className = "project-detail-upload-default center-content">
                    <div className = "project-detail-upload-default-icon-container center-content" onClick = {handleFileAdd}>
                        <img className = "project-detail-upload-default-icon" src = {require('../../../../assets/images/icons/plus_blue.svg')} alt = "plus" />
                        <input type = "file" ref = {fileRef} style = {{display: 'none'}} multiple = {false} onChange = {handleFileChange} />
                    </div>
                </div>
            )}
            {files && files.length > 0 && files.map((file, fileIndex) => (
                <Grid item>
                    <div className = "project-detail-upload-image-container relative" >
                        <img className = "project-detail-upload-image" src={`${baseUrl}` + file.image} />
                        <div className = "project-detail-upload-image-delete-container center-content">
                            <img src = {require('../../../../assets/images/icons/trash_red.svg')} alt = "trash" className = "project-detail-upload-image-delete" onClick = {() => onDelete(file.id)} />
                        </div>
                    </div>
                </Grid>
            ))}

        </Grid>
    )
}

function RadioButton(props) {
    const { state, setState } = props;

    return(
        <div className = "project-detail-radio-button-container" onClick = {setState}>
            <div className = "project-detail-radio-button-border center-content">
                <div className = "project-detail-radio-button-center animatable-400 ease-in-out-quart" style = {{backgroundColor: state ? '#2da0b3' : 'white'}} />
            </div>
        </div>
    )
}

function ProjectDetailView() {
    const [ projectData, setProjectData ] = useState({});
    const [ buttonState, setButtonState ] = useState(false);
    const [ descriptionHeader, setDescriptionHeader ] = useState("");
    const [ descriptionBody, setDescriptionBody ] = useState("")
    const [ linkDetails, setLinkDetails ] = useState("")
    const [ paginaHeader, setPaginaHeader ] = useState("")
    const [ paginaBody, setPaginaBody ] = useState("")
    const [ updateState, setUpdateState ] = useState(false)
    const [ editorState, setEditorState ] = useState(EditorState.createEmpty());
    const [ editorStatePagina, setEditorStatePagina ] = useState(EditorState.createEmpty());
    const [ isPageLoaded, setIsPageLoaded ] = useState(false)

    const { id } = useParams();
    const history = useHistory();
    const toolbarProps = {
        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
        fontFamily: {
            options: ['Vietnam']
        }
    }

    useEffect(() => {
        if(id) {
            axios.get(getWebProjectById(id)).then(response => {
                setProjectData(response.data.data)
                setButtonState(!response.data.data.project_detail.link)
                setLinkDetails(response.data.data.project_detail.link_detail)

                if(response.data.data?.project_detail?.detail) {
                    // check if html tags exist
                    if(response.data.data?.project_detail?.detail.includes("</")) {
                        let contentBlock = htmlToDraft(response.data.data.project_detail?.detail)
                        if(contentBlock) {
                            const editorContentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            setEditorState( EditorState.createWithContent(editorContentState) )
                        }
                    }
                    else {
                        // this implies that there is no html or it is simply blank, if it is blank then we don't have to load it
                    }
                }

                if(response.data.data?.project_detail?.pagina_body) {
                     // check if html tags exist
                     if(response.data.data?.project_detail?.pagina_body.includes("</")) {
                        let contentBlock = htmlToDraft(response.data.data.project_detail?.pagina_body)
                        if(contentBlock) {
                            const editorContentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            setEditorStatePagina( EditorState.createWithContent(editorContentState) )
                        }
                    }
                    else {
                        // this implies that there is no html or it is simply blank, if it is blank then we don't have to load it
                    }
                }

                setIsPageLoaded(true)
            }).catch(error => console.log(error))
        }
    }, [id, updateState])

    useEffect(() => {
        if(projectData?.id && isPageLoaded) {
            updateProject()
        }
    }, [buttonState])

    const updateProject = () => {
        let descriptionMarkup = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        let paginaDescriptionMarkup = draftToHtml(convertToRaw(editorStatePagina.getCurrentContent()))
        axios.put(updateWebProjectById(id), {
            project_detail: {
                detail: descriptionMarkup,
                link: !buttonState,
                link_detail: linkDetails,
                pagina_body: paginaDescriptionMarkup
            }
        }).then(response => console.log(response.data.data)).catch(error => console.log(error))
    }

    const updateBaseProjectFiles = file => {
        let formData = new FormData();
        formData.append('name', projectData.name);
        formData.append('street', projectData.street);
        formData.append('zip', projectData.zip)
        formData.append('city', projectData.city)
        formData.append('comments', projectData.comments)
        formData.append('visible', true)
        formData.append('project_detail', JSON.stringify(projectData.project_detail))
        formData.append('project_images', file);
        axios.put(updateWebProjectById(id), formData).then(response => {
            const uploadedFiles = response.data.data.project_images
            let projectDataCopy = JSON.parse(JSON.stringify(projectData))
            projectDataCopy.project_images = uploadedFiles;
            setProjectData(projectDataCopy)
        }).catch(error => console.log(error))
    }

    const updateReferenceProjectFiles = file => {
        let formData = new FormData();
        formData.append('name', projectData.name);
        formData.append('street', projectData.street);
        formData.append('zip', projectData.zip)
        formData.append('city', projectData.city)
        formData.append('comments', projectData.comments)
        formData.append('visible', true)
        formData.append('project_detail', JSON.stringify(projectData.project_detail))
        formData.append('reference_images', file);
        axios.put(updateWebProjectById(id), formData).then(response => {
            const uploadedFiles = response.data.data.project_images
            let projectDataCopy = JSON.parse(JSON.stringify(projectData))
            projectDataCopy.project_images = uploadedFiles;
            setProjectData(projectDataCopy)
        }).catch(error => console.log(error))
    }

    const updatePaginaProjectFiles = file => {
        let formData = new FormData();
        formData.append('name', projectData.name);
        formData.append('street', projectData.street);
        formData.append('zip', projectData.zip)
        formData.append('city', projectData.city)
        formData.append('comments', projectData.comments)
        formData.append('visible', true)
        formData.append('project_detail', JSON.stringify(projectData.project_detail))
        formData.append('project_images_pagina', file);
        axios.put(updateWebProjectById(id), formData).then(response => {
            const uploadedFiles = response.data.data.project_images_pagina
            let projectDataCopy = JSON.parse(JSON.stringify(projectData))
            projectDataCopy.project_images_pagina = uploadedFiles;
            setProjectData(projectDataCopy)
        }).catch(error => console.log(error))
    }

    function deleteImage(imageId) {
        axios.delete(deleteWebProjectImage(imageId)).then(response => {
            setUpdateState(!updateState)
        }).catch(error => console.log(error))
    }

    const onEditorStateChange = editor => {
        setEditorState(editor)
    }

    const onEditorStateChangePagina = editor => {
        setEditorStatePagina(editor)
    }

    return(
        <Grid container justify = "flex-start">
            <Grid container item xs = {1} alignItems = "flex-start">
                <img src = {require('../../../../assets/images/icons/arrow_previous_blue.svg')} alt = "back arrow" className = "project-detail-arrow-back" onClick = {() => history.goBack()}  />
            </Grid>
            <Grid container item xs = {10} direction = "column">
                <p className = "main-font-semi-bold font-size-14 font-color-grey">Project naam</p>
                <p className = "main-font-semi-bold font-size-16 font-color-blue-grey margin-top-5">{projectData?.name || ""}</p>
                <p className = "main-font-semi-bold font-size-14 font-color-grey margin-top-35">Project beschrijving</p>
                <div className = "project-detail-input-container margin-top-10">
                    <Editor toolbar = {toolbarProps} editorState = {editorState} onEditorStateChange = {onEditorStateChange} toolbarClassName="toolbarClassName project-detail-draft-toolbar" wrapperClassName="wrapperClassName project-detail-draft-wrapper" editorClassName="editorClassName project-detail-draft-editor" onBlur = {updateProject} />
                </div>
                <div className = "flex-row margin-top-35">
                    <div className = "flex-column margin-right-100">
                        <p className = "main-font-semi-bold font-size-14 font-color-grey margin-bottom-35">Upload foto: Home & Project detail (Max. 1)</p>
                        <UploadSection files = {projectData?.project_images?.filter(images => !images.reference)} onChange = {updateBaseProjectFiles} onDelete = {deleteImage} />
                    </div>
                    <div className = "flex-column">
                        <p className = "main-font-semi-bold font-size-14 font-color-grey margin-bottom-35">Upload foto: Referentie (Max. 1)</p>
                        <UploadSection files = {projectData?.project_images?.filter(images => images.reference)} onChange = {updateReferenceProjectFiles} onDelete = {deleteImage} />
                    </div>
                </div>
                <p className = "main-font-bold font-size-14 font-color-blue-grey margin-top-35">Detail pagina Project</p>
                <div className = "flex-row margin-top-25">
                    <div className = "flex-row">
                        <RadioButton state = {!buttonState} setState = {() => setButtonState(!buttonState)} />
                        <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-15">Link</p>
                    </div>
                    <div className = "flex-row margin-left-100">
                        <RadioButton state = {buttonState} setState = {() => setButtonState(!buttonState)} />
                        <p className = "main-font-regular font-size-14 font-color-blue-grey margin-left-15">Pagina</p>
                    </div>
                </div>
                {!buttonState ? (
                    <input className = "project-detail-link-input main-font-semi-bold font-size-14 font-color-black margin-top-15" placeholder = "EX:  https://gealens.be" value = {linkDetails} onChange = {event => setLinkDetails(event.target.value)} onBlur = {updateProject} />
                ): (
                    <>
                        <p className = "main-font-semi-bold font-size-14 font-color-grey margin-top-35">Project beschrijving</p>
                        <div className = "project-detail-input-container margin-top-10">
                            <Editor toolbar = {toolbarProps} editorState = {editorStatePagina} onEditorStateChange = {onEditorStateChangePagina} toolbarClassName="toolbarClassName project-detail-draft-toolbar" wrapperClassName="wrapperClassName project-detail-draft-wrapper" editorClassName="editorClassName project-detail-draft-editor" onBlur = {updateProject} />
                        </div>
                        <p className = "main-font-semi-bold font-size-14 font-color-grey margin-top-35">Upload foto (Max. 4)</p>
                        <UploadSection fileLimit = {4} files = {projectData?.project_images_pagina} onChange = {updatePaginaProjectFiles} onDelete = {deleteImage} />
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default function ProjectDetail() {
    const [open, setOpen] = useState(true);
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <AppBarComponent
                open={open}
                setOpen={setOpen}
                pagename="Project Detail"
                title={"Project Detail"}
            />
            <MenuStrip open={open} setOpen={setOpen} isOnTop = {false} />
            <main
                className={clsx(classes.content, {
                [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} style={{ marginTop: 80 }} />
                <ProjectDetailView />
            </main>
        </div>
    )
}

RadioButton.propTypes = {
    state: PropTypes.bool.isRequired,
    setState: PropTypes.func.isRequired
}

UploadSection.propTypes = {
    fileLimit: PropTypes.number,
}

UploadSection.defaultProps = {
    fileLimit: 1,
}
