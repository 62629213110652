import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, isUndefined, isEmpty } from 'lodash';

import Button from '@material-ui/core/Button';
import Select from '../../../components/select/select';
import AddLinkedProspect from './AddLinkedProspect.view';
import { SpaceRow } from '../../../components/space/space';
import { CustomDropdown } from '../../aankoper/tab.medeeigennaar';

const salesOptions = [{
  lable: 'Ja',
  value: true,
}, {
  lable: 'Nee',
  value: false,
}];

const ProspectDetails = ({
  isAdding,
  buyerList,
  userTitles,
  deletingProspect,
  isUpdatingProspect,
  selectedUserProject,
  handleProspectUpdate,
  handleAddNewProspect,
  handleProspectDelete,
  selectedUnitProspects,
  handleSingleProspectDelete,
  handleAddProspectUserToList,
}) => {
  const [showNewProspectForm, toggleShowNewProspectForm] = useState(false);
  const [keyToEdit, setKeyToEdit] = useState({});

  const getPayload = (key, value, childId, isNew) => {
    let payload = {};
    if (childId) {
      const childUserIndex = selectedUnitProspects[0].childs.findIndex((f) => f.id === childId);

      const child = selectedUnitProspects[0].childs[childUserIndex];
      const updatedChild = {
        ...child,
        user: {
          ...child.user,
          [key]: value,
          parent: isNew ? [] : child.user.parent,
        },
      };

      selectedUnitProspects[0].childs[childUserIndex] = updatedChild;

      payload = {
        ...selectedUnitProspects[0],
      };
    } else {
      payload = {
        ...selectedUnitProspects[0],
        user: {
          ...selectedUnitProspects[0].user,
          [key]: value,
        },
      };
    }

    return payload;
  };

  const handleEditSave = (key, prospect) => {
    if ((prospect[key] !== keyToEdit.value) && !isUndefined(keyToEdit.value)) {
      handleProspectUpdate(
        getPayload(key, keyToEdit.value, keyToEdit.childId),
        prospect.id,
      );
    }
    setKeyToEdit({});
  };

  const onUpdateDropDown = (e, key, prospect, childId) => {
    const { value } = e.target;
    let payload = {};

    if (key === 'comm') {
      if (childId) {
        const childUserIndex = selectedUnitProspects[0].childs.findIndex((f) => f.id === childId);

        const child = selectedUnitProspects[0].childs[childUserIndex];
        const updatedChild = {
          ...child,
          [key]: value === 'true',
        };

        selectedUnitProspects[0].childs[childUserIndex] = updatedChild;

        payload = {
          ...selectedUnitProspects[0],
        };
      } else {
        payload = {
          ...selectedUnitProspects[0],
          [key]: value === 'true',
        };
      }
    } else {
      payload = getPayload(key, { id: value }, childId);
    }

    handleProspectUpdate(
      payload,
      prospect.id,
    );
  };

  const handleEditClicked = (key, id, value, childId) => {
    setKeyToEdit({
      key,
      id,
      value,
      childId,
    });
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    if (keyToEdit.key === 'zip') {
      keyToEdit.value = keyToEdit.value.split('-')[0];
    } else if (keyToEdit.key === 'city') {
      keyToEdit.value = keyToEdit.value.split('-')[1];
    }
    setKeyToEdit({
      ...keyToEdit,
      value,
    });
  };

  const handleProspectAdd = (e, index) => {
    const value = e[index];
    handleAddProspectUserToList(value);
  };

  const removeProspect = (uuId) => {

    // console.log("selectedUnitProspects[0]?.id",selectedUnitProspects[0]?.id );
    // console.log("uuId",uuId);
    // console.log("selectedUnitProspects[0]?.childs[0]?.id",selectedUnitProspects[0]?.childs[0]?.id)

    if (selectedUnitProspects[0]?.id === uuId && selectedUnitProspects[0]?.childs[0]?.id) {
      const newL = selectedUnitProspects[0];
      newL.original_id=selectedUnitProspects[0]?.childs[0]?.id;
      newL.user_id = newL.childs[0].user_id;
      newL.user = newL.childs[0].user;
      newL.user.parent = [];
      newL.childs.splice(0, 1);
      // console.log("newL",newL)
      handleProspectUpdate(newL);
    } else {
      handleSingleProspectDelete(uuId);
    }
  };

  const showUserData = (p, childId) => {
    const d = p;
    p = p.user;
    const prospectChilds = get(selectedUnitProspects, '[0].childs', 0);
    return (
      <Grid
        container
        style={{
          paddingBottom: 25,
          marginTop: 25,
          borderBottom: '1px solid #45545D',
        }}
        key={p.id}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Aanspreking
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <Select
                onChange={(e) => onUpdateDropDown(e, 'title', p, childId)}
                selected={get(p, 'title_id', '1')}
                disabled={isUpdatingProspect}
                style={{ width: 150 }}
                options={userTitles}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Voornaam
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <p
                className=" main-font-semi-bold font-size-14 font-color-black"
                onClick={() => handleEditClicked('firstName', p.id, get(p, 'firstName', ''), childId)}
              >
                {(keyToEdit.key === 'firstName' && keyToEdit.id === p.id) ? (
                  <input
                    style={{
                      borderBottom: '1px solid #e1e1e1',
                      maxWidth: 150,
                    }}
                    onBlur={() => handleEditSave('firstName', p)}
                    value={get(keyToEdit, 'value', '')}
                    disabled={isUpdatingProspect}
                    onChange={handleOnChange}
                    name="firstName"
                    type="text"
                  />
                ) : (
                  <>
                    {isEmpty(get(p, 'firstName', ''))
                      ? <>&nbsp;</>
                      : get(p, 'firstName', '')}
                  </>
                )}
              </p>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Naam
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <p
                className=" main-font-semi-bold font-size-14 font-color-black"
                onClick={() => handleEditClicked('lastName', p.id, get(p, 'lastName', ''), childId)}
              >
                {(keyToEdit.key === 'lastName' && keyToEdit.id === p.id) ? (
                  <input
                    style={{
                      borderBottom: '1px solid #e1e1e1',
                      maxWidth: 150,
                    }}
                    onBlur={() => handleEditSave('lastName', p)}
                    value={get(keyToEdit, 'value', '')}
                    disabled={isUpdatingProspect}
                    onChange={handleOnChange}
                    name="lastName"
                    type="text"
                  />
                ) : (
                  <>
                    {isEmpty(get(p, 'lastName', ''))
                      ? <>&nbsp;</>
                      : get(p, 'lastName', '')}
                  </>
                )}
              </p>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                E-mailadres
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <p
                className=" main-font-semi-bold font-size-14 font-color-black"
                onClick={() => handleEditClicked('email', p.id, get(p, 'email', ''), childId)}
              >
                {(keyToEdit.key === 'email' && keyToEdit.id === p.id) ? (
                  <input
                    style={{
                      borderBottom: '1px solid #e1e1e1',
                      maxWidth: 150,
                    }}
                    onBlur={() => handleEditSave('email', p)}
                    value={get(keyToEdit, 'value', '')}
                    disabled={isUpdatingProspect}
                    onChange={handleOnChange}
                    name="email"
                    type="email"
                  />
                ) : (
                  <>
                    {isEmpty(get(p, 'email', ''))
                      ? <>&nbsp;</>
                      : get(p, 'email', '')}
                  </>
                )}
              </p>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Telefoon
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <p
                className=" main-font-semi-bold font-size-14 font-color-black"
                onClick={() => handleEditClicked('phone', p.id, get(p, 'phone', ''), childId)}
              >
                {(keyToEdit.key === 'phone' && keyToEdit.id === p.id) ? (
                  <input
                    style={{
                      borderBottom: '1px solid #e1e1e1',
                      maxWidth: 150,
                    }}
                    onBlur={() => handleEditSave('phone', p)}
                    value={get(keyToEdit, 'value', '')}
                    disabled={isUpdatingProspect}
                    onChange={handleOnChange}
                    name="phone"
                    type="text"
                  />
                ) : (
                  <>
                    {isEmpty(get(p, 'phone', ''))
                      ? <>&nbsp;</>
                      : get(p, 'phone', '')}
                  </>
                )}
              </p>
            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Straat & nr
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <p
                className=" main-font-semi-bold font-size-14 font-color-black"
                onClick={() => handleEditClicked('street', p.id, get(p, 'street', ''), childId)}
              >
                {(keyToEdit.key === 'street' && keyToEdit.id === p.id) ? (
                  <input
                    style={{
                      borderBottom: '1px solid #e1e1e1',
                      maxWidth: 150,
                    }}
                    onBlur={() => handleEditSave('street', p)}
                    value={get(keyToEdit, 'value', '-')}
                    disabled={isUpdatingProspect}
                    onChange={handleOnChange}
                    name="street"
                    type="text"
                  />
                ) : (
                  <>
                    {isEmpty(get(p, 'street', ''))
                      ? <>&nbsp;</>
                      : get(p, 'street', '')}
                  </>
                )}
              </p>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Postcode & Woonpl.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <p
                className=" main-font-semi-bold font-size-14 font-color-black"
                onClick={() => handleEditClicked('city', p.id, `${get(p, 'zip', '')}-${get(p, 'city', '')}`, childId)}
              >
                {(keyToEdit.key === 'city' && keyToEdit.id === p.id) ? (
                  <>
                    <input
                      style={{
                        borderBottom: '1px solid #e1e1e1',
                        maxWidth: 75,
                      }}
                      value={keyToEdit.value.split('-')[0]}
                      onBlur={() => handleEditSave('zip', p)}
                      disabled={isUpdatingProspect}
                      onChange={handleOnChange}
                      name="zip"
                      type="text"
                    />

                    <input
                      style={{
                        borderBottom: '1px solid #e1e1e1',
                        maxWidth: 75,
                      }}
                      value={keyToEdit.value.split('-')[1]}
                      onBlur={() => handleEditSave('city', p)}
                      disabled={isUpdatingProspect}
                      onChange={handleOnChange}
                      name="city"
                      type="text"
                    />
                  </>
                ) : (
                  <>
                    {(isEmpty(get(p, 'zip', '')) && isEmpty(get(p, 'city', '')))
                      ? <>&nbsp;</>
                      : `${get(p, 'zip') ? get(p, 'zip') : ''} ${get(p, 'city') ? get(p, 'city') : ''}`}
                  </>
                )}
              </p>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} >
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Commerciële Communicatie
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Select
                selected={get(d, 'comm', false)}
                onChange={(e) => onUpdateDropDown(e, 'comm', p, childId)}
                disabled={isUpdatingProspect}
                style={{ width: 150 }}
                options={salesOptions}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <p
                className="main-font-regular font-size-14 font-color-grey"
              >
                Extra info
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={9} style={{ marginBottom: 5 }}>
              <p
                className=" main-font-semi-bold font-size-14 font-color-black"
                onClick={() => handleEditClicked('comments', p.id, get(p, 'comments', ''), childId)}
              >
                {(keyToEdit.key === 'comments' && keyToEdit.id === p.id) ? (
                  <input
                    style={{
                      borderBottom: '1px solid #e1e1e1',
                      maxWidth: 150,
                    }}
                    onBlur={() => handleEditSave('comments', p)}
                    value={get(keyToEdit, 'value', '')}
                    disabled={isUpdatingProspect}
                    onChange={handleOnChange}
                    name="comments"
                    type="text"
                  />
                ) : (
                  <>
                    {isEmpty(get(p, 'comments', ''))
                      ? <>&nbsp;</>
                      : get(p, 'comments', '')}
                  </>
                )}
              </p>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          {prospectChilds && prospectChilds.length >= 1 && (
            <p
              className="main-font-regular font-size-14 font-color-red cursor-pointer text-underline"
              onClick={() => removeProspect(d.id)}
            >
              Verwijder mede-prospect
            </p>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {selectedUnitProspects && selectedUnitProspects.length && selectedUnitProspects.map((p) => (
        <>
          {p && p.user ? showUserData(p) : <></>}
          {(p && p.childs && p.childs.length) ? (
            p.childs.map((c) => (
              showUserData(c, c.id)
            ))
          ) : <></>}
        </>
      ))}

      {showNewProspectForm && (
        <AddLinkedProspect
          isAdding={isAdding}
          userTitles={userTitles}
          selectedUserProject={selectedUserProject}
          handleAddNewProspect={handleAddNewProspect}
          selectedUnitProspects={selectedUnitProspects}
          toggleShowNewProspectForm={toggleShowNewProspectForm}
          selectedProjectId={selectedUnitProspects[0].project_id}
        />
      )}

      {!showNewProspectForm && (
        <>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <SpaceRow top="15" />
            </Grid>
            <Grid container item xs={9} sm={9} md={9}>
              <Button
                variant="contained"
                style={{
                  opacity: 1,
                  background: '#F0F1F2',
                  height: 36,
                  width: 184,
                  fontWeight: 400,
                  color: '#45545D',
                  boxShadow: 'none',
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                  marginTop: 15,

                }}
                onClick={() => toggleShowNewProspectForm(true)}
                disabled={deletingProspect || isUpdatingProspect}
              >
                + Informatie Prospect
              </Button>
              <CustomDropdown
                dropdownData={buyerList}
                dataKey="firstName"
                onClick={handleProspectAdd}
              />
            </Grid>

            <Grid item md={3}>
              {selectedUnitProspects && selectedUnitProspects.length ? (
                <Button
                  variant="contained"
                  style={{
                    marginTop: 15,
                    background: '#fff',
                    border: '1px solid #9e9e9e',
                    float: 'right',
                    height: 36,
                    width: 184,
                    fontWeight: 400,
                    color: '#45545D',
                    boxShadow: 'none',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={handleProspectDelete}
                  disabled={deletingProspect || isUpdatingProspect}
                >
                  <img
                    src={require('../../../assets/images/icons/trash_red.svg')}
                    alt="icon"
                    style={{
                      cursor: 'pointer',
                      height: 12,
                      marginRight: 8,
                    }}
                  />
                  Delete Prospect
                </Button>
              ) : (<></>)}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

ProspectDetails.defaultProps = {
  isAdding: false,
  deletingProspect: false,
  isUpdatingProspect: false,
};

ProspectDetails.propTypes = {
  isAdding: PropTypes.bool,
  deletingProspect: PropTypes.bool,
  isUpdatingProspect: PropTypes.bool,
  buyerList: PropTypes.array.isRequired,
  userTitles: PropTypes.array.isRequired,
  handleProspectUpdate: PropTypes.func.isRequired,
  handleAddNewProspect: PropTypes.func.isRequired,
  handleProspectDelete: PropTypes.func.isRequired,
  selectedUserProject: PropTypes.object.isRequired,
  selectedUnitProspects: PropTypes.object.isRequired,
  handleSingleProspectDelete: PropTypes.func.isRequired,
  handleAddProspectUserToList: PropTypes.func.isRequired,
};

export default ProspectDetails;
