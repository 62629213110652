import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tableCell: {
    border: '1px solid #DFDFDE',
    padding: 6,
  },
  code: {
    padding: '2px 4px',
    textAlign: 'center',
    margin: '0 15%',
    borderRadius: 5,
  },
});

const AddLaatste = ({
  handleShowAddRow,
  handleAddProject,
  isAdding,
}) => {
  const classes = useStyles();
  const [payload, setPayload] = useState({
    projectName: '',
    straat: '',
    gemeente: '',
  });

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    const updatedPayload = { ...payload, [name]: value };
    setPayload(updatedPayload);
  };

  const addProject = () => {
    handleShowAddRow(false);
    handleAddProject({
      name: payload.projectName,
      street: payload.straat,
      city: payload.gemeente,
      zip: '',
    });
  };

  return (
    <>
      <TableRow key="add-row1">
        <TableCell style={{ border: '1px solid transparent' }} />
        <TableCell colSpan={3} />
        <TableCell style={{ border: '1px solid transparent' }} colSpan={4} />
      </TableRow>

      <TableRow key="add-row">
        <TableCell style={{ border: 'none' }} />
        <TableCell className={classes.tableCell}>
          <input
            value={payload.projectName}
            onChange={handleOnChange}
            name="projectName"
            type="text"
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <input
            onChange={handleOnChange}
            value={payload.straat}
            name="straat"
            type="text"
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <input
            onChange={handleOnChange}
            value={payload.gemeente}
            name="gemeente"
            type="text"
          />
        </TableCell>
        <TableCell
          colSpan={2}
          style={{
            padding: '2px 6px',
            borderRight: '1px solid transparent',
            borderBottom: '1px solid transparent',
            position: 'relative',
          }}
        >
          <Button
            variant="contained"
            className={classes.buttonStyle}
            style={{
              background: '#F0F1F2',
              height: 36,
              minWidth: 250,
              fontWeight: 400,
              color: '#45545D',
              boxShadow: 'none',
              position: 'absolute',
              whiteSpace: 'nowrap',
              left: 20,
              top: 0,
            }}
            disabled={isAdding}
            onClick={addProject}
          >
            {isAdding ? 'Toevoegen...' : 'Project verder uitwerken'}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

AddLaatste.defaultProps = {
  isAdding: false,
};

AddLaatste.propTypes = {
  handleAddProject: PropTypes.func.isRequired,
  handleShowAddRow: PropTypes.func.isRequired,
  isAdding: PropTypes.bool,
};

export default AddLaatste;
