import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { getDashboardData, generateLoginToken, getFrontendTokenLink } from '../../common/api/routes';

import './Dashboard.view.scss';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Wrapper from '../../components/wrapper/wrapper';
import image from '../../assets/images/icons/overlapsing_circles.svg';
import { SQLDateToString } from '../../common/utils/format';

const menuItem1 = [
  {
    name: 'Projecten',
    icon: require('../../assets/images/icons/building_white.svg'),
    link: '/lijst/projects',
  },
  {
    name: 'Prospecten',
    icon: require('../../assets/images/icons/users_white.svg'),
    link: '/lijst/prospects',
  },
  {
    name: 'Kopers',
    icon: require('../../assets/images/icons/key_white.svg'),
    link: '/lijst/kopers',
  },
];


const Dashboard = () => {
  const [ dashboardData, setDashboardData ] = useState({})
  const [ aktesData, setAktesData ] = useState({});

  const history = useHistory();

  useEffect(() => {
    axios.get(getDashboardData).then(response => {
      setDashboardData(response.data.data.docAndMessage)
      setAktesData(response.data.data.Actes)
    })
  }, []); 

  const useStyles = makeStyles(() => ({
    item1Styles: {
      margin: 10,
      height: 40,
      padding: 20,
      fontSize: 16,
      color: '#fff',
      paddingTop: 25,
      fontWeight: 600,
      borderRadius: 10,
      paddingBottom: 15,
      cursor: 'pointer',
      background: '#2EA0B4',

      '&:first-child': {
        marginLeft: 0,
      },
    },
    item2Styles: {
      margin: 10,
      height: 70,
      padding: 20,
      fontSize: 16,
      color: '#fff',
      paddingTop: 23,
      fontWeight: 600,
      borderRadius: 10,
      paddingBottom: 17,
      background: '#45545D',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 20%',
      backgroundImage: `url(${image})`,

      '&:first-child': {
        marginLeft: 0,
      },
    },
    table: {
      border: '1px solid #e1e1e1',
      boxShadow: 'none',
    },
    code: {
      padding: '2px 4px',
      textAlign: 'center',
      margin: '0 15%',
      borderRadius: 5,
    },
    tableHeading: {
      border: '1px solid #DFDFDE',
      background: '#F8F8F8',
      color: '#45545D',
      fontWeight: 400,
      padding: 6,
    },
    tableCell: {
      border: '1px solid #DFDFDE',
      padding: 6,
    },
    acties: {
      marginRight: 3,
      borderRadius: 5,
      textAlign: 'center',
    },
  }));

  const classes = useStyles();

  const loginAsUser = userId => {
    axios.get(generateLoginToken(userId)).then(response => {
      window.open(getFrontendTokenLink(response.data.data.data))
    }).catch(error => console.log(error))
  }

  return (
    <Wrapper pagename="Overzicht" title="Overzicht">
      <Grid container style={{ marginTop: 100 }}>
        {menuItem1.map((item) => {
          return (
            <Grid item md={4}>
              <Link to={item.link}>
                <div className={classes.item1Styles}>
                  <div style={{ float: 'left' }}>
                    <img
                      alt="icon"
                      src={item.icon}
                      style={{
                        height: 22,
                        marginRight: 10,
                        marginTop: 2,
                      }}
                    />
                  </div>

                  <div style={{ float: 'left' }}>
                    {item.name}
                  </div>

                  <div style={{ float: 'right' }}>
                    <img
                      alt="search icon"
                      src={require('../../assets/images/icons/nextarrow_white.svg')}
                      style={{
                        height: 22,
                        marginTop: 3,
                      }}
                    />
                  </div>
                </div>
              </Link>
            </Grid>
          );
        })}
      </Grid>

      <Grid container>
        <Grid item md={4}>
          <div className={classes.item2Styles}>
            Nieuwe documenten
            <p className="font-size-20">
              {dashboardData?.docs || 0}
            </p>
            <p className="font-size-12">
           
            </p>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={classes.item2Styles}>
            Nieuwe berichten
            <p className="font-size-20">
              {dashboardData?.messages || 0}
            </p>
            <p className="font-size-12">
              
            </p>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={classes.item2Styles}>
            Actes deze week
            <p className="font-size-20">
              {aktesData?.count || 0}
            </p>
            <p className="font-size-12">
              7 dagen
            </p>
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={8}>
          <div className="padding-right-10">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeading}>
                    <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                      <img
                        alt="search icon"
                        src={require('../../assets/images/icons/pin_darkbleu.svg')}
                        style={{
                          height: 15,
                          marginRight: 5,
                        }}
                      />
                    </span>
                    Code
                  </TableCell>
                  <TableCell className={classes.tableHeading}>
                    <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                      <img
                        alt="search icon"
                        src={require('../../assets/images/icons/Combined_Shape__1_.svg')}
                        style={{
                          height: 15,
                          marginRight: 10,
                        }}
                      />
                    </span>
                    Voornaam / Naam
                  </TableCell>
                  <TableCell className={classes.tableHeading}>
                    <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                      <img
                        alt="search icon"
                        src={require('../../assets/images/icons/bousolle_darkbleu.svg')}
                        style={{
                          height: 15,
                          marginRight: 10,
                        }}
                      />
                    </span>
                    Acties
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboardData?.list && dashboardData?.list.length > 0 ? (
                  dashboardData?.list.map((project) => (
                    <TableRow key={project?.project?.name}>
                      <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                        <div
                          className={classes.code}
                          style={{ background: project?.project?.color || '#f4f4f4', cursor: 'pointer' }}
                        >
                          {project?.project?.code || 'AAA'}
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Grid container>
                          <Grid item md={11}>
                            {project?.user?.firstName} {project?.user?.lastName}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Grid container>
                          <Grid item md={3}>
                            <div className={classes.acties + " cursor-pointer"} style={{ background: project?.project?.doc?.id ? '#F4E1E1' : '#F4F4F4' }} onClick = {() => history.push({
                              pathname: `/toevoegen/aankoper/${project.id}`,
                              search: '4'
                            })}>Doc</div>
                          </Grid>
                          <Grid item md={3}>
                            <div className={classes.acties + " cursor-pointer"} style={{ background: project?.project?.message?.id ? '#F4E1E1' : '#F4F4F4' }} onClick = {() => history.push({
                              pathname: `/toevoegen/aankoper/${project.id}`,
                              search: '5'
                            })}>Berichten</div>
                          </Grid>
                          <Grid item md={3}>
                            <div className={classes.acties + " cursor-pointer"} style={{ background: '#f4f4f4' }} onClick = {() => history.push({
                              pathname: `/toevoegen/aankoper/${project.id}`,
                              search: '2'
                            })}>Profiel</div>
                          </Grid>
                          <Grid item md={3}>
                            <div className={classes.acties + " cursor-pointer"} style={{ background: '#f4f4f4' }} onClick = {() => loginAsUser(project.user.id)}>Login</div>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell component="td" colSpan={8} scope="row" align="center" style={{ padding: 20, fontWeight: 500, color: 'red' }}>
                      No data found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </Grid>

        <Grid item md={4}>
          <div className="padding-right-10">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeading}>
                    <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                      <img
                        alt="search icon"
                        src={require('../../assets/images/icons/pin_darkbleu.svg')}
                        style={{
                          height: 15,
                          marginRight: 5,
                        }}
                      />
                    </span>
                    Code
                  </TableCell>
                  <TableCell className={classes.tableHeading}>
                    <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                      <img
                        alt="search icon"
                        src={require('../../assets/images/icons/Combined_Shape__1_.svg')}
                        style={{
                          height: '15px',
                          marginRight: 10,
                        }}
                      />
                    </span>
                    Voornaam / Naam
                  </TableCell>
                  <TableCell className={classes.tableHeading}>
                    <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                      <img
                        alt="search icon"
                        src={require('../../assets/images/icons/agenda_darkbleu.svg')}
                        style={{
                          height: '15px',
                          marginRight: 10,
                        }}
                      />
                    </span>
                    Datum
                  </TableCell>
                </TableRow>
              </TableHead>
              {aktesData?.list && aktesData?.list?.length ? (
                aktesData?.list?.map((project) => (
                  <TableRow key={project?.project?.name}>
                    <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                      <div
                        className={classes.code}
                        style={{ background: project?.project?.color || '#f4f4f4', cursor: 'pointer' }}
                      >
                        {project?.project?.code || 'AAA'}
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {project?.user?.firstName} {project?.user?.lastName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {SQLDateToString(project?.deed_date).split(" ")[0]}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell component="td" colSpan={8} scope="row" align="center" style={{ padding: 20, fontWeight: 500, color: 'red' }}>
                    No data found.
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

Dashboard.styles = {
  item1Styles: {

  },
};

export default Dashboard;
