// eslint-disable-next-line import/no-dynamic-require
import React from 'react';
import {
  List, ListItem, Collapse, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './_menu_list.scss';
import PropTypes from 'prop-types';
import { NavLink, Link, useHistory } from 'react-router-dom';

const Dropdown = ({
  obj,
  key,
}) => {
  const history = useHistory();
  const useStyles = makeStyles(() => ({
    nested: {
      paddingLeft: '65px',
    },
  }));

  const classes = useStyles();
  const [openMenu, setOpenMenu] = React.useState(false);
  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  const firstLocation = window.location.pathname.split('/')[1];

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        key={key}
        className="relative listitem"
      >
        <span className="carret absolute">{(openMenu || firstLocation === obj.url) ? '▼' : '▶'}</span>
        <ListItemIcon
          className=" padding-top-15 padding-bottom-15 padding-left-20 "
        >
          <img
            /* eslint-disable-next-line import/no-dynamic-require */
            src={require(`../../assets/images/icons/${obj.icon}`)}
            alt={obj.title}
            className="icon-img "
          />
        </ListItemIcon>
        <ListItemText
          primary={obj.title}
          className="main-font-semi-bold font-size-14"
        />

      </ListItem>
      <Collapse in={(openMenu || firstLocation === obj.url)} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {obj.children.map((nobj) => {
            return (
              <Link to={nobj.url} key={nobj.title} onClick={() => history.push(nobj.url)}>
                <ListItem
                  selected={nobj.url === window.location.pathname}
                  className={classes.nested}
                  key={nobj.title}
                  button
                >
                  <ListItemText primary={nobj.title} />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

Dropdown.defaultProps = {
  obj: {},
  key: undefined,
};

Dropdown.propTypes = {
  obj: PropTypes.object,
  key: PropTypes.string,
};

export const MenuList = () => {
  const history = useHistory();
  const MenuItems = [
    {
      title: 'Overzicht',
      icon: 'dashboard_darkbleu.svg',
      url: '/Overzicht',
      children: [],
    },
    {
      title: 'Toevoegen',
      icon: 'add_darkbleu.svg',
      url: 'toevoegen',
      children: [
        {
          title: 'Project',
          url: '/toevoegen/projects',
        },
        {
          title: 'Prospect',
          url: '/toevoegen/prospects',
        },
        {
          title: 'Koper',
          url: '/toevoegen/koper',
        },
      ],
    },
    {
      title: 'Lijst',
      icon: 'list_darkbleu.svg',
      url: 'lijst',
      children: [
        {
          title: 'Projecten',
          url: '/lijst/projects',
        },
        {
          title: 'Prospecten',
          url: '/lijst/prospects',
        },
        {
          title: 'Kopers',
          url: '/lijst/kopers',
        },
      ],
    },
    {
      title: 'Documenten',
      icon: 'documents_darkbleu.svg',
      url: '/documenten',
      children: '',
    },
    {
      title: 'Berichten',
      icon: 'chat2_darkbleu.svg',
      url: '/berichten',
      children: '',
    },
    {
      title: 'Instellingen',
      icon: 'settings_darkbleu.svg',
      url: '/instellingen',
      children: '',
    },
    {
      title: 'Website',
      icon: 'website_icon.svg',
      url: 'website',
      children: [
        {
          title: 'Huidige Projecten',
          url: '/website/huidige',
        },
        {
          title: 'Referenties',
          url: '/website/referenties',
        },
      ],
    },
    {
      title: 'Afmelden',
      icon: 'logout_darkbleu.svg',
      url: '/',
      children: '',
    },
  ];

  return (
    <List className="MenuList">
      {MenuItems.map((obj) => {
        if (obj.children.length > 0) {
          return (
            <Dropdown obj={obj} key={obj.title} />
          );
        }
        return (
          <NavLink to={obj.url} key={obj.title} onClick={() => {
              history.push(obj.url)
              if(obj.title === "Afmelden"){localStorage.clear()}
            }}>
            <ListItem
              button
              selected={obj.url === window.location.pathname}
              className="relative listitem"
            >
              <ListItemIcon
                className="padding-top-15 padding-bottom-15 padding-left-20"
              >
                <img
                  /* eslint-disable-next-line import/no-dynamic-require */
                  src={require(`../../assets/images/icons/${obj.icon}`)}
                  alt="close"
                  className="icon-img"
                />
              </ListItemIcon>
              <ListItemText
                primary={obj.title}
                className="main-font-semi-bold font-size-14"
              />
            </ListItem>
          </NavLink>
        );
      })}

    </List>
  );
};
