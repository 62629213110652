import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';

import "./error_modal.scss";

const ErrorModal = forwardRef((props, ref) => {
    const [ modalState, setModalState ] = useState(false)
    const [ messageState, setMessageState ] = useState("")
    const [ titleState, setTitleState ] = useState("ERROR");
    const [ timerState, setTimerState ] = useState(false)
    const [ typeState, setTypeState ] = useState(false)
    const [ deleteData, setDeleteData ] = useState({});
    const [ redirect, setRedirect ] = useState(0);
    const history =  useHistory();

    const { onDelete } = props;

    useEffect(() => {
        if(timerState) {
            setTimeout(() => {
                setModalState(false)
                setTimerState(false)
            }, 3000);
        }
    }, [timerState])

    useImperativeHandle(ref, () => ({
        openModal: (message, title, timer,redirection) => {
            setModalState(true)
            setMessageState(message || "")
            setTitleState(title || "ERROR")
            if(redirection === 1){
                setRedirect(redirection);
            }
            if(timer) {
                setTimerState(true);
            }
            setTypeState(false);
        },
        openDeleteModal: (message, title, data) => {
            setModalState(true)
            setMessageState(message || "")
            setTitleState(title || "ERROR")
            setTypeState(true);
            if(data) {
                setDeleteData({
                    id: data,
                })
            }
        }
    }))

    const handleSecondaryClick = () => {

        if(deleteData?.id) {
            onDelete(deleteData.id)
        }
        else {
            onDelete();
        }

        setModalState(false)

    }

    return(
        <div className = "error-modal-container center-content animatable-400 ease-in-out-quart" style = {{opacity: modalState ? 1 : 0, transform: modalState ? 'scale(1)' : 'scale(0)'}}>
            <div className = "error-modal-mask" onClick = {() => setModalState(false)} />
            <div className = "error-modal-box flex-column center-content">
                <div className = "error-modal-box-header">
                    <p className = "main-font-semi-bold font-size-18 font-color-white">{titleState}</p>
                    <p className = "main-font-regular font-size-14 font-color-white">{messageState}</p>
                </div>
                <div className = "error-modal-sub-container horizontal-end vertical-center">
                    <button type = "button" className = "error-modal-button center-content" style = {{background: typeState ? '#B4422E' : 'RGB(46, 160, 180)'}}  onClick = {() => {redirect === 1 ? history.push("/") : setModalState(false)}}>
                        <p className = "main-font-regular font-size-14 font-color-white">{typeState ? 'Nee' : 'Ok'}</p>
                    </button>
                    {typeState && (
                        <button type = "button" className = "error-modal-button center-content margin-left-15" onClick = {handleSecondaryClick}>
                            <p className = "main-font-regular font-size-14 font-color-white">Ja</p>
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
})

export default ErrorModal;

ErrorModal.defaultProps = {
    message: '',
}

ErrorModal.propTypes = {
    message: PropTypes.string,
}