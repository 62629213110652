import request from '../../../common/request';

export const getUserNameTitles = (setResponse) => {
  const response = request.get('/user/title');

  response
    .then((res) => {
      const data = res.data.data.map((r) => ({
        lable: r.title,
        value: r.id,
      }));
      setResponse(data);
    });
};

export const getSalesPersons = (setResponse) => {
  const response = request.get('/user/types?type_id=4');
  response
    .then((res) => {
      const data = res.data.data.map((r) => ({
        lable: r.firstName,
        value: r.id,
        user: r,
      }));
      setResponse(data);
    });
};

export const getUnsoldUnitsByProject = (setResponse, projectId, setFullResponse) => {
  const response = request.get(`/project/unit/unsold/${projectId}`);

  response
    .then((res) => {
      const data = res.data.data.map((r) => ({
        lable: `${r.type.title} ${r.generalNumber}`,
        value: r.id,
        sold: r?.sold,
      }));

      setResponse(data);
      if (setFullResponse) {
        setFullResponse(res.data.data);
      }
    });
};

export const getSellerByProject = (setResponse, projectId) => {
  const response = request.get(`/seller/project/${projectId}`, {}, false);
  response
    .then((res) => {
      // const data = res.data.data;
      const data = res.data.data.map((r) => ({
        lable: r?.seller?.firstName,
        value: r?.seller?.id,
        user: r?.seller,
      }));
      setResponse(data);
    });
};

export const getAllProspectProjects = (setFetching, setResponse, withEmpty) => {
  const response = request.get('/project');
  setFetching(true);

  response
    .then((res) => {
      const data = res.data.data.map((r) => ({
        lable: r.name,
        value: r.id,
      }));
      if (withEmpty) {
        data.unshift({
          lable: 'Selecteren',
          value: '0',
          units: [],
        });
      }
      setResponse(data);
      setFetching(false);
    })
    .catch(() => {
      setFetching(false);
    });
};

export const getProspectDetails = (setResponse, prospectId) => {
  const response = request.get(`/prospect/project/show/${prospectId}`, {}, false);

  response
    .then((res) => {
      setResponse([res.data.data]);
    })
    .catch(() => {
      setResponse([]);
    });

  return response;
};

export const addNewProspect = (
  setFetching,
  setResponse,
  payload,
  setFormVisibility,
  setSelectedUnitProspects,
  projectId,
) => {
  setFetching(true);

  const response = request.post(
    '/prospect/project/create',
    payload,
  );

  response
    .then((res) => {
      setFetching(false);
      setResponse(res.data.data);
      setFormVisibility(false);
      getProspectDetails(setSelectedUnitProspects, projectId);
    })
    .catch(() => {
      setFetching(false);
    });
};
export const createNewProspect = (
  setFetching, payload, setResponse,
) => {
  setFetching(true);
  const response = request.post(
    '/prospect/project/create',
    payload,
    {},
    false,
  );
  response
    .then((res) => {
      setFetching(false);
      setResponse(res.data.data);
    })
    .catch(() => {
      setFetching(false);
    });
};

export const getProspectnBuyer = (setFetching, setResponse) => {
  setFetching(true);
  const response = request.get('/user/types?type_id=2,3');
  response
    .then((res) => {
      setFetching(false);
      setResponse(res.data.data);
    })
    .catch(() => {
      setFetching(false);
    });
};

export const transferProspectToBuyer = (setFetching, prospectId, history, createGeneralChat) => {
  setFetching(true);

  const response = request.get(`/prospect/project/to/buyer/${prospectId}`);

  response
    .then((res) => {
      setFetching(false);
      if (res.data?.data?.error === 'Unsuccessful') {
        alert(res.data?.data?.message || '');
      } else {
        createGeneralChat(res?.data?.data?.id, res?.data?.data?.project_id);
      }
    })
    .catch(() => {
      setFetching(false);
    });
};

export const deleteProjectProspect = (setFetching, prospectId, setResponse, unitId, history) => {
  setFetching(true);

  const response = request.delete(`/prospect/project/delete/${prospectId}`);

  response
    .then(() => {
      setFetching(false);
      if (unitId) {
        getProspectDetails(setResponse, unitId);
      }
      if (history) { history.push('/lijst/prospects'); }
    })
    .catch(() => {
      setFetching(false);
    });
};

export const updateProspectDetails = (
  payload,
  prospectProjectId,
  setFetching,
  setResponse,
  unitId,
  errorModalRef,
) => {
  setFetching(true);

  const response = request.put(
    `/prospect/project/${prospectProjectId}`,
    payload,
    {},
    false,
  );

  response
    .then(() => {
      setFetching(false);
      getProspectDetails(setResponse, unitId);
    })
    .catch((error) => {
      setFetching(false);
      if (error.response.data.data.message.includes('unique_email')) {
        errorModalRef.current.openModal('Dit e-mailadres word reeds gebruikt in het systeem.', 'ERROR', 3000);
      }
    });
};

export const getAllStatus = (setResponse) => {
  const response = request.get('/status');
  response
    .then((res) => {
      // setResponse([res.data.data]);
      const data = res.data.data.map((r) => ({
        lable: r.title,
        value: r.id,
      }));
      setResponse(data);
    })
    .catch(() => {
      setResponse([]);
    });
};
