import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { get, debounce } from 'lodash';
import {
  Grid, TableBody, TableHead,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Btn from '../../components/button/button';
import { TCell, TRow, TableCustom } from '../../components/table/table';
import { SearchInput } from '../../components/search_input/search_input';
import { SpaceRow } from '../../components/space/space';
import { getAllProspects, getAllSellers } from './prospect.api';
import Wrapper from '../../components/wrapper/wrapper';
import Dropdown from '../../components/dropdown/dropdown';
import { getAllProjects } from '../dashboard/projects/projects.api';
import { getProspectTypes } from '../../common/api/routes';
import Spinner from '../../components/spinner/Spinner';
import { SQLDateToString } from '../../common/utils/format';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '100%',
    // borderLeft: '1px solid rgb(223, 223, 222)',
  },
  spacing: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function Prospect() {
  const [rows, setRows] = React.useState([]);
  const [projectData, setProjectData] = useState([]);
  const [sellersList, setSellersList] = useState([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [tableRowHover, setTableRowHover] = useState(-1);
  const [selectedSearch, setSelectedSearch] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [selectedResponsible, setSelectedResponsible] = useState('');
  const [selectedCommercial, setSelectedCommercial] = useState('');
  const [prospectTypes, setProspectTypes] = useState([]);

  useEffect(() => {
    getAllSellers(setSellersList);
    getAllProjects(setIsFetching, setProjectData, '', true);
    getAllProspects(setIsFetching, setRows);
    axios.get(getProspectTypes)
      .then((response) => {
        setProspectTypes(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  function unitData(data) {
    const finaldata = data.map((d, i) => {
      return `${d.units?.type?.title} ${d.units?.generalNumber}`;
    });
    return (
      <>
        <TCell style={data[0]?.units?.sold ? { color: 'red' } : {}}>{finaldata[0] ? finaldata[0] : ' - '}</TCell>
        <TCell style={data[1]?.units?.sold ? { color: 'red' } : {}}>{finaldata[1] ? finaldata[1] : ' - '}</TCell>
        <TCell style={data[2]?.units?.sold ? { color: 'red' } : {}}>{finaldata[2] ? finaldata[2] : ' - '}</TCell>
      </>
    );
  }

  const classes = useStyles();
  const history = useHistory();

  const optionsEmail = [{
    name: 'Commercieel',
    value: '',
  }, {
    name: 'Ja',
    value: '1',
  }, {
    name: 'Nee',
    value: '0',
  },
  ];

  const Click = () => {
    history.push('/toevoegen/prospects');
  };

  const Statusdata = (data) => {
    if (data && prospectTypes.length > 0) {
      const selectedType = prospectTypes.find((type) => type.id === data);
      return <TCell align="left">{selectedType?.title || ''}</TCell>;
    }
    return <TCell align="left" />;
  };

  const SalesPerson = (data) => {
    if (data) {
      return `${data.firstName} ${data.lastName}`;
    }
    return '';
  };

  return (
    <Wrapper title="Lijst Propsects" pagename="Prospecten">
      <Grid container style={{ marginTop: 80 }}>
        <Grid
          item
          alignItems="baseline"
          wrap="nowrap"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          lg={7}
        >
          <Dropdown
            defaultValue="Alle projecten"
            marginRight={5}
            data={projectData || []}
            selected={projectData[selectedProject]?.name}
            setSelected={(val) => {
              setSelectedProject(val);
              setSelectedProjectId(projectData[val]?.id);
              getAllProspects(
                setIsFetching,
                setRows,
                selectedSearch,
                    projectData[val]?.id,
                    selectedResponsible,
                    selectedCommercial,
              );
            }}
            icon={require('../../assets/images/icons/pin_darkbleu.svg')}
          />
          <Dropdown
            defaultValue="Alle verantwoordelijken"
            icon={require('../../assets/images/icons/Combined_Shape__1_.svg')}
            data={sellersList || []}
            selected={sellersList[selectedResponsible]?.name}
            setSelected={(val) => {
              setSelectedResponsible(val);
              getAllProspects(
                setIsFetching,
                setRows,
                selectedSearch,
                selectedProjectId,
                sellersList[val].value,
                selectedCommercial,
              );
            }}
            styles={{ width: 210 }}
            marginRight={45}
          />
          <SearchInput
            handleSearch={
                  debounce(
                    (value) => {
                      setSelectedSearch(value);
                      getAllProspects(
                        setIsFetching,
                        setRows,
                        value,
                        selectedProjectId,
                        selectedResponsible,
                        selectedResponsible,
                      );
                    }, 1000,
                  )
                }
          />
          <Dropdown
            defaultValue="Commercieel"
            icon={require('../../assets/images/icons/mail_darkbleu.svg')}
            data={optionsEmail || []}
            selected={optionsEmail[selectedCommercial]?.name}
            setSelected={(val) => {
              setSelectedCommercial(val);
              getAllProspects(
                setIsFetching,
                setRows,
                selectedSearch,
                selectedProjectId,
                selectedResponsible,
                    optionsEmail[val]?.value,
              );
            }}
            marginRight={15}
          />
        </Grid>

        <Grid
          container
          item
          lg={5}
          justify="flex-end"
          style={{ paddingRight: 30 }}
        >
          <div className={classes.spacing}>
            <Btn variation="primary" onClick={Click}>
              + Prospect
            </Btn>
            <Btn variation="secondary">
              <ReactHTMLTableToExcel
                className="download-table-xls-button"
                id="prospects-list-table-id"
                table="prospects-list-table"
                buttonText="Download .xls"
                filename="ProspectsList"
                sheet="Prospects List"
              />
            </Btn>
          </div>
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container>
        <Grid container item md={12}>
          {isFetching ? (
            <div className="text-center padding-top-20">
              <Spinner text="Fetching Projects..." />
            </div>
          ) : (
            <TableCustom id="prospects-list-table">
              <TableHead>
                <TRow>

                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/pin_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Code
                  </TCell>

                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/Combined_Shape__1_.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Naam
                  </TCell>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/phone_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Telefoon
                  </TCell>
                  <TCell align="left">
                    <img
                      src={require('../../assets/images/icons/mail_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    E-mailadres
                  </TCell>
                  <TCell align="left" colSpan="3">
                    <img
                      src={require('../../assets/images/icons/building_darkbleu.svg')}
                      alt="icon"
                      style={{ height: '15px' }}
                    />
                    Entiteit(en)
                  </TCell>
                  <TCell align="left">
                    Verantwoordelijk
                  </TCell>
                  <TCell align="left">
                    Status
                  </TCell>
                  <TCell align="left">
                    Comm.
                  </TCell>
                  <TCell align="left">
                    Aangemaakt op
                  </TCell>
                  <TCell align="left" className="last" />
                </TRow>
              </TableHead>
              <TableBody>

                {rows && rows.length ? (
                  rows.map((row, i) => (
                    <TRow
                      key={i}
                      style={{ height: 51 }}
                      onMouseEnter={() => setTableRowHover(i)}
                      onMouseLeave={() => setTableRowHover(-1)}
                    >
                      <TCell>
                        <div
                          className={classes?.code}
                          style={{
                            background: get(row, 'project.color'),
                            margin: ' 0 15%;',
                            padding: '2px 4px',
                            textAlign: 'center',
                            borderRadius: '5px',
                          }}
                        >
                          {row?.project?.code}
                        </div>
                      </TCell>

                      <TCell style={{ textAlign: 'left', wordWrap: 'break-word' }}>
                        {' '}
                        {row?.user?.firstName}
                        {' '}
                        {row?.user?.lastName}
                      </TCell>

                      <TCell style={{ textAlign: 'left', wordWrap: 'break-word' }}>{row?.user?.phone}</TCell>
                      <TCell style={{ textAlign: 'left', wordWrap: 'break-word' }}>{row?.user?.email}</TCell>
                      {unitData(row?.userUnits)}
                      <TCell style={{ textAlign: 'left', wordWrap: 'break-word' }}>
                        {SalesPerson(row?.seller)}
                      </TCell>

                      {Statusdata(row?.statusId)}
                      <TCell style={{ textAlign: 'left', wordWrap: 'break-word' }}>
                        {row?.comm ? 'Ja' : 'Nee'}
                      </TCell>
                      <TCell style={{ textAlign: 'left', wordWrap: 'break-word' }}>
                        {SQLDateToString(row?.createdAt).split(" ")[0]}
                      </TCell>
                      <TCell style={{
                        width: 30,
                        border: 'none',
                      }}
                      >
                        {tableRowHover === i ? (
                          <Grid container>
                            <Grid item md={6}>
                              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                              <img
                                alt="search icon"
                                src={require('../../assets/images/icons/eye_square.svg')}
                                style={{
                                  cursor: 'pointer',
                                  height: 30,
                                }}
                                onClick={() => history.push(`/toevoegen/prospects/${row?.id}`)}
                              />
                            </Grid>
                          </Grid>
                        ) : ''}
                      </TCell>
                    </TRow>
                  ))
                ) : (
                  <TRow>
                    <TCell
                      component="td"
                      colSpan={10}
                      scope="row"
                      align="center"
                      style={{
                        padding: 20, fontWeight: 500, color: 'red', textAlign: 'center',
                      }}
                    >
                      No Prospects found.
                    </TCell>
                  </TRow>
                )}

              </TableBody>
            </TableCustom>
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
}
