import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import { useHistory } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import { isEmpty, get, isUndefined } from 'lodash';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useRef } from 'react';
import TableContainer from '@material-ui/core/TableContainer';

import Button from '@material-ui/core/Button';
import CodePopup from '../../projects/CodePopup.view';
import Spinner from '../../../../components/spinner/Spinner';
import ErrorModal from '../../../../components/error_modal/error_modal';
import {
  updateWebProject,
  updateProject,
  deleteProject,
} from '../../projects/projects.api';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    boxShadow: 'none',
  },
  code: {
    padding: '2px 4px',
    textAlign: 'center',
    margin: '0 15%',
    borderRadius: 5,
  },
  tableHeading: {
    border: '1px solid #DFDFDE',
    background: '#F8F8F8',
    color: '#45545D',
    fontWeight: 400,
    padding: 6,
  },
  tableHeading2: {
    border: '1px solid #DFDFDE',
    background: '#F8F8F8',
    color: '#45545D',
    fontWeight: 400,
    padding: 6,
    width: 60,
  },
  tableCell: {
    border: '1px solid #DFDFDE',
    padding: 6,
  },
  tableCellDelete: {
    border: 'none',
    padding: 5,
  },
});

const ProjectsList = ({
  handleShowAddRow,
  usedCodesList,
  setProjects,
  isFetching,
  projects,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [newProject, setNewProject] = useState({});
  const [keyToEdit, setKeyToEdit] = useState({});
  const [tableRowHover, setTableRowHover] = useState(-1);
  const [showCodePopup, toggleShowCodePopup] = useState(-1);

  const errorModalRef = useRef(0);

  useEffect(() => {
    if (!isEmpty(newProject)) {
      const updatedProjects = projects;

      if (!isEmpty(keyToEdit)) {
        const index = updatedProjects.findIndex((i) => i.id === newProject.id);
        updatedProjects[index] = newProject;
        setKeyToEdit({});
      } else if (showCodePopup < 0) {
        updatedProjects.push(newProject);
        handleShowAddRow(false);
      }
      toggleShowCodePopup(-1);

      setProjects(updatedProjects);
      setNewProject({});
    }
  }, [newProject]);

  const handleEditClicked = (key, id, value) => {
    setKeyToEdit({ key, id, value });
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    setKeyToEdit({ ...keyToEdit, value });
  };

  const handleEditSave = (key, project) => {
    if ((project[key] !== keyToEdit.value) && !isUndefined(keyToEdit.value)) {
      updateProject(
        setNewProject,
        { ...project, [key]: keyToEdit.value },
        setProjects,
        toggleShowCodePopup,
      );
    }
  };

  const handleCodeUpdate = (code, project) => {
    updateProject(
      setNewProject,
      { ...project, code: code.title, color: code.color },
      setProjects,
      toggleShowCodePopup,
    );
  };

  const setShowOnlineStatus = (project) => {
    const formData = new FormData();
    formData.append('name', project?.name);
    formData.append('street', project?.street);
    formData.append('zip', project?.zip);
    formData.append('city', project?.city);
    formData.append('comments', project?.comments);
    formData.append('visible', !project?.visible);
    formData.append('visible_huidige', !project?.visibleHuidige);

    const abc = JSON.stringify({
      project_id: project?.id,
      detail: project?.projectDetail?.detail,
      visible: !project?.visible,
      link_detail: project?.projectDetail?.linkDetail,
    });

    formData.append('project_detail', abc);

    updateWebProject(
      setProjects,
      project?.id,
      formData,
      false,
    );
  };

  return (
    <TableContainer>
      {isFetching ? (
        <div className="text-center padding-top-20">
          <Spinner text="Fetching Projects..." />
        </div>
      ) : (
        <Table className={classes.table} aria-label="simple table" id="projects-list-table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="center" className={classes.tableHeading}>Projecten</TableCell>
              <TableCell colSpan={2} align="center" className={classes.tableHeading}>Locatie</TableCell>
              <TableCell rowSpan={2} align="center" style={{ whiteSpace: 'nowrap' }} className={classes.tableHeading}>Online zichtbaar</TableCell>
              <TableCell rowSpan={2} align="center" className={classes.tableHeading}>Action</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableHeading}>
                <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                  <img
                    alt="search icon"
                    src={require('../../../../assets/images/icons/pin_darkbleu.svg')}
                    style={{
                      height: 15,
                      marginRight: 5,
                    }}
                  />
                </span>
                Code
              </TableCell>
              <TableCell className={classes.tableHeading}>
                <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                  <img
                    alt="search icon"
                    src={require('../../../../assets/images/icons/building_darkbleu.svg')}
                    style={{
                      height: '15px',
                      marginRight: 10,
                    }}
                  />
                </span>
                Naam
              </TableCell>
              <TableCell className={classes.tableHeading}>
                <span style={{ display: 'inline', top: 3, position: 'relative' }}>
                  <img
                    alt="search icon"
                    src={require('../../../../assets/images/icons/bousolle_darkbleu.svg')}
                    style={{
                      height: '15px',
                      marginRight: 10,
                    }}
                  />
                </span>
                Straat & nr
              </TableCell>
              <TableCell className={classes.tableHeading}>
                Postcode & Gemeente
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {projects && projects.length ? (
                projects.map((project, index) => (
                  <TableRow
                    key={project.name}
                    style={{ height: 45 }}
                    onMouseEnter={() => setTableRowHover(index)}
                    onMouseLeave={() => setTableRowHover(-1)}
                  >
                    <TableCell scope="row" align="center" className={classes.tableCell} style={{ minWidth: 60 }}>
                      <div
                        className={classes.code}
                        // onClick={() => toggleShowCodePopup(index)}
                        style={{ background: project.color || '#f4f4f4', cursor: 'pointer', textAlign: 'center' }}
                      >
                        {project.code || '-'}
                      </div>
                      {(showCodePopup === index) && (
                        <CodePopup
                          project={project}
                          usedCodesList={usedCodesList}
                          handleCodeUpdate={handleCodeUpdate}
                          onClose={() => toggleShowCodePopup(-1)}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      // onClick={() => handleEditClicked('name', project.id, project.name)}
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {(keyToEdit.key === 'name' && keyToEdit.id === project.id) ? (
                        <input
                          value={get(keyToEdit, 'value', '')}
                          onBlur={() => handleEditSave('name', project)}
                          onChange={handleOnChange}
                          name="name"
                          type="text"
                        />
                      ) : project.name}
                    </TableCell>
                    <TableCell
                      // onClick={() => handleEditClicked('street', project.id, project.street)}
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {(keyToEdit.key === 'street' && keyToEdit.id === project.id) ? (
                        <input
                          value={get(keyToEdit, 'value', '')}
                          onBlur={() => handleEditSave('street', project)}
                          onChange={handleOnChange}
                          name="street"
                          type="text"
                        />
                      ) : project.street}
                    </TableCell>
                    <TableCell
                      //  onClick={() => handleEditClicked('city', project.id, project.city)}
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {(keyToEdit.key === 'city' && keyToEdit.id === project.id) ? (
                        <input
                          value={get(keyToEdit, 'value', '')}
                          onBlur={() => handleEditSave('city', project)}
                          onChange={handleOnChange}
                          name="city"
                          type="text"
                        />
                      ) : project.city}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }} className={classes.tableCell}>
                      <button
                        type="button"
                        className="renew-password-eye-container"
                        onClick={() => setShowOnlineStatus(project)}
                      >
                        {project?.visibleHuidige ? (
                          <img
                            alt="eye"
                            src={require('../../../../assets/images/icons/see_darkbleu.svg')}
                            className="see_darkbleu renew-password-eye animatable-400 ease-in-out-quart"
                          />
                        ) : (
                          <img
                            alt="eye"
                            src={require('../../../../assets/images/icons/see_no_darkbleu.svg')}
                            className="see_darkbleu renew-password-eye animatable-400 ease-in-out-quart"
                          />
                        )}
                      </button>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }} className={classes.tableCell}>
                      <Button
                        variant="contained"
                        style={{
                          // marginTop: 15,
                          background: '#2EA0B4',
                          opacity: 1,
                          height: 36,
                          width: 192,
                          fontWeight: 400,
                          color: '#fff',
                          boxShadow: 'none',
                          textTransform: 'none',
                          whiteSpace: 'nowrap',
                        }}
                        onClick={() => history.push(`/website/projecten/${project.id}`)}
                      >
                        Aanpassen
                      </Button>
                    </TableCell>
                    <TableCell className={classes.tableCellDelete} style={{ width: 55, border: 'none', textAlign: 'left' }}>
                      &nbsp;&nbsp;
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell component="td" colSpan={11} scope="row" align="center" style={{ padding: 20, fontWeight: 500, color: 'red' }}>
                    No Projects found.
                  </TableCell>
                </TableRow>
              )}
            </>

          </TableBody>
        </Table>
      )}
      <ErrorModal ref={errorModalRef} onDelete={(id) => deleteProject(id, setProjects)} />
    </TableContainer>
  );
};

ProjectsList.propTypes = {
  handleShowAddRow: PropTypes.func.isRequired,
  usedCodesList: PropTypes.array.isRequired,
  setProjects: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  projects: PropTypes.array.isRequired,
};

export default ProjectsList;
