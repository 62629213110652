import React, { useEffect, useState, useRef } from 'react';
import {
  AppBar,
  Grid, Tab,
} from '@material-ui/core';

import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams, useLocation} from 'react-router-dom';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import ErrorModal from '../../components/error_modal/error_modal';
import { debounce } from "lodash";
import Button from '@material-ui/core/Button';
import {
  createBuyerProject, getProjectFromBuyer, getProject, getUsersFromProject, deleteBuyerProject, updateBuyerProject, createChat
} from '../../common/api/routes';
import { SpaceRow } from '../../components/space/space';
import Wrapper from '../../components/wrapper/wrapper';
import TabContentEenheid from './tab.eenheid';
import TabContentInformatie from './tab.informatie';
import TabContentMedeEigennaar from './tab.medeeigennaar';
import TabContentDocumenten from './tab.documenten';
import TabContentBerichten from './tab.berichten';
import TabContentHistoriek from './tab.historiek';

import './aankoper.scss';
import { SQLDateToString } from '../../common/utils/format';

const useStyles = makeStyles((theme) => ({

  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  spacing: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function AankoperModal(props) {
  const { modalState, setModalState } = props;

  return(
    <div className = "aankoper-modal-container center-content animatable-400 ease-in-out-quart" style = {{opacity: modalState ? 1 : 0, transform: modalState ? 'scale(1)' : 'scale(0)'}}>
<div className = "aankoper-modal-sub-container center-content absolute" onClick = {() => setModalState(false)} />
  <div className = "aankoper-modal-message relative">
    {props.children}
    </div>
    </div>
)
}

export default function Aankoper() {
  const [buyerData, setBuyerData] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(0);
  const [value, setValue] = React.useState('1');
  const [newFormState, setNewFormState] = useState(true);
  const [ modalState, setModalState ] = useState(false);
  const [updatePromptState, setUpdatePromptState] = useState(false)
  const [ isTabLoaded, setIsTabLoaded ] =  useState(true)

  // tab informatie variables
  const [userProjects, setUserProjects] = useState([]);
  const [selectedUserProject, setSelectedUserProject] = useState([]);
  const [projectUnits, setProjectUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [informatieData, setInformatieData] = useState({
    sales_agreement: null,
    sales_number: 0,
    condition_precedent: '',
    bank_notification: null,
    notary_notification: null,
    deed_date: null,
    warranty_act: null,
    deed_month: 0,
  });

  // tab medeeigennaar variables
  const [newUserList, setNewUserList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);

  const classes = useStyles();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const errorModalRef = useRef(0)
  const adminData = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (id) {
      axios.get(getProjectFromBuyer(id)).then((response) => {
        setBuyerData(response.data.data);
        setInformatieData({
          sales_agreement: response.data.data.sales_agreement ? new Date(SQLDateToString(response.data.data.sales_agreement, true)) : null,
          sales_number: response.data.data.sales_number,
          condition_precedent: response.data.data.condition_precedent,
          bank_notification: new Date(SQLDateToString(response.data.data.bank_notification, true)),
          notary_notification: new Date(SQLDateToString(response.data.data.notary_notification, true)),
          deed_date: new Date(SQLDateToString(response.data.data.deed_date, true)),
          warranty_act: new Date(SQLDateToString(response.data.data.warranty_act, true)),
          deed_month: response.data.data.deed_month
        });
      }).catch((error) => console.log(error));
    }
  }, [value]);

  useEffect(() => {
    if (buyerData) {
      getUserData();
      getUnitsData();
    }
  }, [buyerData]);

  useEffect(() => {
    if (id) {
      setNewFormState(false);
    }
  }, [id]);

  useEffect(() => {
    if(location.search.length > 0 && buyerData?.id && isTabLoaded) {
      const searchParam = location.search.substring(1, location.search.length);
      setValue(searchParam)
      setIsTabLoaded(false)
    }
  }, [location.search, buyerData])

  useEffect(() => {
    debouncedUpdatePrompt();
  }, [updatePromptState])

  const debouncedUpdatePrompt = debounce(() => {
    setUpdatePromptState(false)
  }, 3000);

  function getUserData() {
    if (buyerData?.project?.id) {
      axios.get(getUsersFromProject(buyerData.project.id)).then((response) => {
        const userData = [];
        response.data.data.forEach((user) => {
          if (user.id == buyerData.id) {
            userData.push(user.user);
          }
        });
        setUsersData(userData);
      }).catch((error) => console.log(error));
    }
  }

  function getUnitsData() {
    if (buyerData?.project?.id) {
      axios.get(getProject(buyerData.project.id)).then((response) => {
        setUnitsData(response?.data?.data?.project_units || []);
      }).catch((error) => console.log(error));
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const submitNewBuyer = () => {
    let validation = false;
    if (newUserList && newUserList.length > 0) {
      validation = true;
    } else {
      validation = false;
      errorModalRef.current.openModal('Create or select co-owners.')
    }

    if(validation) {
      // we do this so that we don't see both the error messages
      if (selectedUnits && selectedUnits.length > 0) {
        validation = true;
      } else {
        validation = false;
        errorModalRef.current.openModal('Select units.')
      }
    }

    if(validation) {
      let users = [...newUserList];
      let user_units = [...selectedUnits]
      user_units.forEach((unit, unitIndex) => {
        user_units[unitIndex] = {
          unit_id: unit.id,
        };
      });

      const {
        sales_agreement, sales_number, condition_precedent, bank_notification, notary_notification, deed_date, warranty_act, deed_month
      } = informatieData;
      let newUserListCopy = [...newUserList];
      newUserListCopy.forEach(user => {
        if(!user.id) {
          user.parent = [];
          user.child = [];
        }
      })

      axios.post(createBuyerProject, {
        users: newUserListCopy,
        project: {
          project_id: selectedUserProject.value,
          user_units,
          sales_agreement,
          sales_number,
          condition_precedent,
          bank_notification,
          notary_notification,
          deed_date,
          warranty_act,
          deed_month,
          buyer: true,
        },
      }).then(response => {
        // this is to create a general chat for all the users of that buyer group
        axios.post(createChat, {
          buyer_project_id: response.data.data.project_data.id,
          project_id: response.data.data.project_data.project_id
        }).then(chatResponse => {
          history.push("/toevoegen/aankoper/" + response.data.data.project_data.id)
          history.go(0);
        }).catch(error => console.log(error))

      }).catch(error => {
        if(error.response.data.data.message.toLowerCase().includes('unique')) {
          errorModalRef.current.openModal('Dit e-mailadres word reeds gebruikt in het systeem.', 'ERROR', 3000)
        }
        console.log(error)
      })
    }
  };
  const removeDuplicates = (originalArray, prop) =>{
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}
  const updateBuyer = () => {
    // update is comprised of 4 things, units object, informatie object, user object, and childs object
    let childs = [];
    let newUsers = false;
    // new Users list has the main user as well so we have to remove that from the list and make sure it goes in the user object
    let newUserListCopy = [...newUserList];
    const updatedUser = newUserListCopy.shift();
    newUserListCopy.forEach(user => {
      childs.push({
        access: user.access,
        percentage: user.percentage,
        status: user.status,
        id: user?.buyer_project_id || null,
        user: {...user, parent: []},
      })
    })
    let userUnitsCopy = [...buyerData.user_units];

    console.log("==+>",buyerData.user_units)
    userUnitsCopy.forEach((unit, unitIndex) => {
      if(userUnitsCopy.indexOf({unit_id: unit.units.id}) === -1){
        userUnitsCopy[unitIndex] = {
          id: unit.id,
          unit_id: unit.units.id
        }
      }

    })
    console.log("==+>",userUnitsCopy)
  
    userUnitsCopy = removeDuplicates(userUnitsCopy,"unit_id");
  
    // userUnitsCopy = [...new Map(userUnitsCopy.map((item, key) => [item[key], item])).values()]
    console.log("==+>",userUnitsCopy)

    let payload = {};
    // if updatedUser comes across is undefined that means that the user hasn't opened second tab
    // that means no changes have been made to users or childs, we only send first page data
    if(updatedUser) {
      payload = {
        user: updatedUser,
        childs,
        ...informatieData,
        access: updatedUser.access,
        percentage: updatedUser.percentage,
        status: updatedUser.status,
        user_units: userUnitsCopy,
      }
      if(buyerData?.user?.id) {
        let userCount = 1;
        userCount = userCount + buyerData.childs.length;
        if(userCount !== newUserList.length) {
          newUsers = true;
        }
      }
    }
    else {
      payload = {
        ...informatieData,
        user: buyerData.user,
        childs: buyerData.childs,
        user_units: userUnitsCopy,
      };
    }
    console.log("payload",payload)
    axios.put(updateBuyerProject(id), payload).then(response => {
      setUpdatePromptState(true)
      if(newUsers) {
        history.push("/toevoegen/aankoper/" + id + "?2")
        history.go(0);
      }
      // errorModalRef.current.openModal('Succesvol geüpdatet.', 'Succes', true)
    }).catch(error => {
      if(error.response.data.data.message.toLowerCase().includes('unique')) {
        errorModalRef.current.openModal('Dit e-mailadres word reeds gebruikt in het systeem.', 'ERROR', 3000)
      }
      console.log(error)
    })
  }

  const deleteBuyer = () => {
    axios.delete(deleteBuyerProject(buyerData.id)).then(response => {
      // now we route to buyers page
      history.push('/lijst/kopers');
    }).catch(error => console.log(error))
  }

  const removeUser = (userIndex) => {
    // there are two different situations, if the first user is the one to be deleted then the second user is set as the as buyer.user_id
    // everyone below the second user is then pushed into childs array
    if(id) {
      // first we check if the user actually exists in the list
      const user = {...newUserList[userIndex]};
      let exists = false;
      if(buyerData.user.id === user.id) {
        exists = true;
      }
      buyerData.childs.forEach(childUser => {
        if(childUser.user.id === user.id) {
          exists = true;
        }
      })
      if(exists) {
        if(userIndex === 0) {
          // if the first user is being deleted, then if the first user is the last one then we give message
          // otherwise we simply make the second user the main user
          if(buyerData?.childs?.length > 0) {
            let newChildList = [...buyerData.childs];
            const newParent = newChildList.shift();
            axios.put(updateBuyerProject(id), {
              user: newParent.user,
              childs: newChildList,
            }).then(response => {
              const newUserListCopy = [...newUserList];
              newUserListCopy.splice(userIndex, 1);
              setNewUserList(newUserListCopy);
            }).catch(error => console.log(error));

            axios.delete(deleteBuyerProject(newParent.id)).catch(error => console.log(error))
          }
          else {
            errorModalRef.current.openModal('Een andere gebruiker toevoegen voordat de laatste gebruiker wordt verwijderd')
          }
        }
        else {
          // this means that this is child user
          let newChildList = [...buyerData.childs];
          axios.delete(deleteBuyerProject(newChildList[userIndex - 1].id)).then(response => {
            newChildList.splice(userIndex - 1, 1);
            setBuyerData(st => ({
              ...st,
              childs: newChildList
            }));
          }).catch(error => console.log(error));
        }
      }
      else {
        const newUserListCopy = [...newUserList];
        newUserListCopy.splice(userIndex, 1);
        setNewUserList(newUserListCopy);
      }
    }
    else {
      const newUserListCopy = [...newUserList];
      newUserListCopy.splice(userIndex, 1);
      setNewUserList(newUserListCopy);
    }
  }

  function updateUnits(id, payload) {
    axios.put(updateBuyerProject(id), payload).then(response => {
      setBuyerData(response.data.data);
    }).catch(error => console.log(error))
  }

  return (
    <Wrapper pagename="Koper" style={{ opacity: 0 }}>
      <Grid container md={12} justify="space-between" alignItems="center" className="margin-top-25">
        <p className="main-font-bold font-size-20 font-color-black margin-left-8" style={{ zIndex: 99999 }}>{newFormState ? 'Koper toevoegen' : (usersData && usersData.length > 0) ? (`${usersData[0]?.firstName || ''} ${usersData[0]?.lastName || ''} details`) : ''}</p>
        {newFormState ? (
          <button type="button" className="aankoper-submit center-content" onClick={submitNewBuyer}>
            <p className="main-font-semi-bold font-size-14 font-color-white">Opslaan</p>
          </button>
        ) : (
          <div className = "flex-row">
            <button type="button" className="aankoper-button center-content margin-right-15 animatable-400 ease-in-out-quart" style = {{opacity: updatePromptState ? 1 : 0, transform: updatePromptState ? 'translateX(0px) scaleY(1)' : 'translateX(10px) scaleY(0)'}}>
              <p className="main-font-regular font-size-14 font-color-white aankoper-button-text">Update succesvol</p>
            </button>
            <Button
              variant="contained"
              style={{
                background: '#fff',
                border: '1px solid #9e9e9e',
                height: 40,
                width: 184,
                fontWeight: 400,
                color: '#45545D',
                boxShadow: 'none',
                textTransform: 'none',
                whiteSpace: 'nowrap',
                zIndex: 9999
              }}
              onClick={() => setModalState(true)}
              disabled = {false}
            >
              <img
                src={require('../../assets/images/icons/trash_red.svg')}
                alt="icon"
                style={{
                  cursor: 'pointer',
                  height: 12,
                  marginRight: 8,
                }}
              />
              Delete koper
            </Button>
          </div>
        )}
      </Grid>
      <Grid container md={12} style={{ marginTop: newFormState ? 10 : 0 }}>
        {!newFormState && (
          <Grid container item sm={6} md={4} justify="flex-start">
            <div className={classes.spacing}>
              <p>
                {buyerData && buyerData?.project && buyerData?.project?.name || ''}
              </p>
              <p>
                {buyerData && buyerData?.user_units?.length > 0 && buyerData.user_units.map((unit) => {
                  return `${unit?.units?.type?.title} ${unit?.units?.general_number} `;
                })}
              </p>
            </div>
          </Grid>
        )}
      </Grid>
      <SpaceRow top="15" />
      <Grid container md={12}>
        <Grid container className={classes.root} item md={12}>
          <TabContext value={value} className="width-auto">
            <AppBar color="transparent" elevation={0} position="static">
              <TabList onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Informatie" value="1" />
                <Tab label="Mede-eigennaar(s)" value="2" />
                <Tab label="Entiteit(en)" value="3" style={{ display: newFormState ? 'none' : 'block' }} />
                <Tab label="Documenten" value="4" style={{ display: newFormState ? 'none' : 'block' }} />
                <Tab label="Berichten" value="5" style={{ display: newFormState ? 'none' : 'block' }} />
                <Tab label="Historiek" value="6" style={{ display: newFormState ? 'none' : 'block' }} />
              </TabList>
            </AppBar>
            <TabPanel value="1" className="w-100">
              <TabContentInformatie buyerData={buyerData} setBuyerData={setBuyerData} newFormState={newFormState} userProjects={userProjects} setUserProjects={setUserProjects} selectedUserProject={selectedUserProject} setSelectedUserProject={setSelectedUserProject} projectUnits={projectUnits} setProjectUnits={setProjectUnits} selectedUnits={selectedUnits} setSelectedUnits={setSelectedUnits} informatieData={informatieData} setInformatieData={setInformatieData} updateUnits = {updateUnits} errorModalRef = {errorModalRef} updateBuyer = {updateBuyer} />
            </TabPanel>
            <TabPanel value="2" className="w-100">
              <TabContentMedeEigennaar buyerData={buyerData} setBuyerData={setBuyerData} newFormState={newFormState} newUserList={newUserList} setNewUserList={setNewUserList} buyerList={buyerList} setBuyerList={setBuyerList} removeUser = {removeUser} adminData = {adminData} errorModalRef = {errorModalRef} updateBuyer = {updateBuyer} />
            </TabPanel>
            {!newFormState && (
              <>
                <TabPanel value="3">
                  <TabContentEenheid buyerData={buyerData} unitsData={unitsData} />
                </TabPanel>
                <TabPanel value="4" className="width-auto">
                  <TabContentDocumenten buyerData={buyerData} />
                </TabPanel>
                <TabPanel value="5" className="width-auto">
                  <TabContentBerichten buyerData={buyerData} />
                </TabPanel>
                <TabPanel value="6" className="width-auto">
                  <TabContentHistoriek buyerData = {buyerData} />
                </TabPanel>
              </>
            )}
          </TabContext>
        </Grid>
      </Grid>
      <AankoperModal modalState = {modalState} setModalState = {setModalState}>
        <div className = "error-modal-container center-content animatable-400 ease-in-out-quart" style = {{opacity: modalState ? 1 : 0, transform: modalState ? 'scale(1)' : 'scale(0)'}}>
          <div className = "error-modal-mask" onClick = {() => setModalState(false)} />
          <div className = "error-modal-box flex-column center-content">
            <div className = "error-modal-box-header">
              <p className = "main-font-semi-bold font-size-18 font-color-white">Bevestigen</p>
              <p className = "main-font-regular font-size-14 font-color-white">Bent u zeker dat u deze koper wenst te verwijderen?</p>
            </div>
            <div className = "error-modal-sub-container horizontal-end vertical-center">
              <button type = "button" className = "error-modal-button center-content margin-right-15" onClick = {deleteBuyer}>
                <p className = "main-font-regular font-size-14 font-color-white">Ja</p>
              </button>
              <button type = "button" style = {{background: '#B4422E'}} className = "error-modal-button center-content" onClick = {() => setModalState(false)}>
                <p className = "main-font-regular font-size-14 font-color-white">Nee</p>
              </button>
            </div>
          </div>
        </div>
      </AankoperModal>
      <ErrorModal ref = {errorModalRef} />
    </Wrapper>
  );
}
